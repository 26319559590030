import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, compose } from 'redux';
import { Button, IconButton, Paper, Typography, InputBase, Divider } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { DossierData } from '../../types/dossier';
import { DocumentData } from '../../types/document';
import { RootState } from '../../store/reducers';

import DocumentPageContainer from '../Dossier/DocumentPageContainer';

import { observer } from 'mobx-react';
import { withLocalReaktor } from '../../utils/reaktor';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    padding: '0 48px',
  },
  page: {
    margin: theme.spacing(4, 0),
  },
  fabAdd: {
    // position: 'fixed',
    // zIndex: 10,
    // top: 110,
    // left: 844,
  },
  annotations: {

  },
  overlayContainer: {
    transform: 'translate(-100%)',
  }
})

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
  dossier: DossierData;
  document: DocumentData;
}

interface Params {
  documentId: string;
  dossierId: string;
}

export type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

interface State {
  active: boolean;
  newAnnotation: boolean;
}

class EvidenceView extends React.Component<Props, State> {
  render() {
    const { classes, document } = this.props;
    const sectionName = document.name;
    return (
      <div className={classes.container}>
        <div style={{ position: 'relative' }}>
          <div ref='preview' style={{ overflow: 'hidden', position: 'fixed', right: 0, top: 64, zIndex: 999 }}></div>
          <div id="annotation-area" ref="area" className='paper1'>
            {document && document.pages ?
              document.pages.map((page, index, pages) => (
                <DocumentPageContainer
                  key={page._id}
                  document={document}
                  className={classes.page}
                  sectionName={sectionName}
                  pageName={`${document.name}${pages.length > 1 ? ` ${index + 1} / ${pages.length}` : ''}`}
                  actions={[]}
                  page={page}
                  pageNumber={1 + index}
                />
              ))
              : null}
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(EvidenceView);
