import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { grey } from '@material-ui/core/colors';
import { 
  mdiFileQuestion,
} from '@mdi/js';

import { MessageAttachment } from '../../types/chat';
import FileIcon from '../Common/FileIcon';
import TargetIcon from '../Common/TargetIcon';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

interface Props extends SvgIconProps {
  item: MessageAttachment;
}

const AttachmentIcon: React.SFC<Props> = (props) => {
  const { item, ...restProps } = props;
  if (item.type === 'file' && item.file) {
    return <FileIcon file={item.file} {...restProps} />;
  } else if (item.type === 'target' && item.target) {
    return <TargetIcon item={item.target} {...restProps} />;
  } else {
    return <SimpleSvgIcon path={mdiFileQuestion} htmlColor={grey[500]} {...restProps} />;
  }
}

export default AttachmentIcon;
