import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import Layout from '../Common/Layout';
import CenterBox from '../Common/CenterBox';
import AdminNav from './AdminNav';
import Tips from '../Common/Tips';
import { getUsername } from '../../utils/data';

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

interface StateProps {
  user: UserData | null;
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
  onCreateTeam: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithStyles<typeof styles>;

interface State {
}

class AdminPanelIndex extends React.Component<Props, State> {
  render() {
    const { user } = this.props;
    return (
      <Layout>
        <CenterBox paper>
          {!user || !user.isAdmin ?
            <Tips
              message={__('errors.forbidden')}
            />
          :
            
            <>
              <Typography color="textSecondary">{__('admin.welcome', { username: getUsername(user) })}</Typography>
              <AdminNav />
            </>
          }
        </CenterBox>
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  user: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AdminPanelIndex);
