import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link, Paper, List, ListItem, ListSubheader, ListItemText, ListItemAvatar, ListItemIcon } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';
import { mdiAccountMultiple } from '@mdi/js';

import { UserData } from '../../types/user';
import { TeamData } from '../../types/team';
import { memberRoleLabels } from '../../types/member';
import { __, intl } from '../../utils/intl';
import { getUsername } from '../../utils/data';
import { RootState } from '../../store/reducers';
import client, { ClientRequestOptions } from '../../store/client';
import UserAvatar from '../Common/UserAvatar';
import Loading from '../Common/Loading';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
    },
  },
  infoBox: {
    [theme.breakpoints.up('md')]: {
      width: 280,
      borderRight: '1px solid #eee',
    },
  },
  secondaryList: {
    flexGrow: 1,
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
}

interface Params {
  userId: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params>
  & WithSnackbarProps & WithStyles<typeof styles>;

interface State {
  user: UserData | null;
  teamList: TeamData[];
  dossierCount: number;
}

class AdminUserDetail extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<UserData>>();
  state: State = {
    user: null,
    teamList: [],
    dossierCount: 0,
  }

  componentDidMount() {
    const { userId } = this.props.match.params;
    this.fetchData(userId);
  }

  componentWillReceiveProps(nextProps: Props) {
    const { userId } = nextProps.match.params;
    if (userId !== this.props.match.params.userId) {
      this.fetchData(userId);
    }
  }

  async fetchData(userId: string) {
    const options: ClientRequestOptions = { params: { userId } };
    const user = await client.get<UserData>('/admin/users/:userId', options);
    const teamList = await client.get<TeamData[]>('/admin/users/:userId/teams', options);
    const { count: dossierCount } = await client.get<{ count: number }>('/admin/users/:userId/dossier-count', options);
    this.setState({ user, teamList, dossierCount });
  }

  renderDetail() {
    const { classes, history } = this.props;
    const { user, teamList, dossierCount } = this.state;
    if (!user) return <Loading />;
    return (
      <Paper className={classes.content}>
        <div className={classes.infoBox}>
          <List>
            <ListItem>
              <ListItemAvatar><UserAvatar user={user} /></ListItemAvatar>
              <ListItemText primary={getUsername(user)} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('member.role')}
                secondary={user.isAdmin ? __('member.role.admin') : __('member.role.normal')}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('member.status')}
                secondary={user.isDisabled ? __('user.status.disabled') : __('member.status.normal')}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('user.field.mobile')}
                secondary={user.mobile}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('dossier.totalCount')}
                secondary={dossierCount}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('common.field.dateCreated')}
                secondary={intl.formatTime(user.dateCreated, 'L LTS')}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('user.field.dateLastActive')}
                secondary={intl.formatTime(user.dateLastActive, 'L LTS')}
              />
            </ListItem>
          </List>
        </div>
        <div className={classes.secondaryList}>
          <List subheader={<ListSubheader>{__('team.team')}</ListSubheader>}>
            {teamList.map(team => (
              <ListItem
                button
                key={team._id}
                onClick={() => history.push(`/admin/teams/view/${team._id}`)}
              >
                <ListItemIcon>
                  <SimpleSvgIcon path={mdiAccountMultiple} />
                </ListItemIcon>
                <ListItemText
                  primary={team.name}
                  secondary={team.member && memberRoleLabels[team.member.role]}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Paper>
    );
  }

  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Link color="inherit" onClick={() => history.push('/admin/users')}>{__('admin.nav.users')}</Link>
            <Typography color="textPrimary">{__('admin.users.detail')}</Typography>
          </Breadcrumbs>
          {this.renderDetail()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminUserDetail);
