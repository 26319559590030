import _ from 'lodash';
import qs from 'qs';
import { SERVER_URL } from '../constants';
import { history } from './configureStore';
import { JSONParseDate } from '../utils/json';
import { KeyValueMap } from '../types/common';
import { intl, __ } from '../utils/intl';
import { initSocket } from './socket';

export type Params = KeyValueMap;

export interface ClientRequestOptions {
  query?: KeyValueMap;
  params?: Params;
  data?: any;
  cached?: boolean;
  fetchOptions?: RequestInit;
}

export interface FieldError {
  code: string | number | null;
  property: string;
  reason: string;
  message: string;
}

export interface ApiError {
  status: number,
  code: string,
  message: string,
  errors?: FieldError[],
}

export class ClientError extends Error implements ApiError {

  public method: string;
  public url: string;
  public data?: any;
  public status: number;
  public code: string;
  public message: string;
  public errors?: FieldError[];

  constructor(method: string, url: string, reqData: any, status: number, resData: ApiError) {
    super(resData.message);
    this.method = method;
    this.url = url;
    this.data = reqData;
    this.status = status;
    this.code = resData.code;
    this.message = resData.message;
    this.errors = resData.errors;
    this.debug();
  }

  format() {
    const { code, message } = this;
    return __(code, undefined, undefined, { fallback: message });
  }

  debug() {
    console.error(`ApiError: ${this.method} ${this.url}`);
    if (this.data) {
      console.info('> post data:', this.data);
    }
    console.info(`> error: [${this.code}] ${this.message}`);
  }

}

export interface ClientOptions {
  tokenStorageName: string;
}

export class Client {

  public serverUrl: string;
  public options: ClientOptions = {
    tokenStorageName: 'token',
  };
  private cache!: Map<string, any>;

  constructor(serverUrl: string, options: Partial<ClientOptions> = {}) {
    this.serverUrl = serverUrl;
    this.options = _.extend(this.options, options);
    this.cache = new Map();
    this.init();
  }

  init() {
    if (this.getToken()) {
      setTimeout(() => {
        initSocket();
      }, 0);
    }
  }

  createByPathname(pathname: string) {
    return new Client(`${this.serverUrl}${pathname}`);
  }

  getToken() {
    const { tokenStorageName } = this.options;
    return localStorage.getItem(tokenStorageName) || sessionStorage.getItem(tokenStorageName);
  }

  setToken(token: string, remember = false) {
    const { tokenStorageName } = this.options;
    this.removeToken();
    if (remember) {
      localStorage.setItem(tokenStorageName, token);
    } else {
      sessionStorage.setItem(tokenStorageName, token);
    }
    this.init();
  }

  removeToken() {
    const { tokenStorageName } = this.options;
    localStorage.removeItem(tokenStorageName);
    sessionStorage.removeItem(tokenStorageName);
  }

  getUrl(pathname: string, options?: ClientRequestOptions) {
    let url = `${this.serverUrl}${pathname}`;
    if (options) {
      const { query, params } = options;
      if (params) {
        Object.keys(params).map(key => {
          const pattern = new RegExp(`:${_.escapeRegExp(key)}`, 'g');
          url = url.replace(pattern, params[key]);
        });
      }
      if (query) {
        url += `?${qs.stringify(query)}`
      }
    }
    return url;
  }

  clientRedirect(url: string, query: KeyValueMap<any> = {}) {
    const queryString = qs.stringify({
      ...query,
      token: this.getToken(),
    });
    url += `${url.includes('?') ? '&' : '?'}${queryString}`;
    url = this.getUrl(url);
    window.location.assign(url);
  }

  getRequestHeaders() {
    const token = this.getToken();
    const headers: KeyValueMap<string> = {
      'Accept-Language': intl.language,
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  async request<T = any>(method: string, pathname: string, options: ClientRequestOptions = {}): Promise<T> {
    const url = this.getUrl(pathname, options)
    const init: RequestInit = {
      method,
    };
    _.defaultsDeep(init, {
      headers: this.getRequestHeaders(),
    });

    // PUT, POST, DELETE
    if (method !== 'GET') {
      if (options && options.data) {
        if (_.isPlainObject(options.data) || _.isFunction(options.data.toJSON)) {
          _.defaultsDeep(init, {
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(options.data),
          });
        } else {
          init.body = options.data;
        }
      }
    }

    if (options && options.fetchOptions) {
      _.defaultsDeep(init, options.fetchOptions);
    }
    const response = await fetch(url, init);
    const resultText = await response.text();
    const data = JSONParseDate(resultText);
    if (response.status >= 400) {
      if (response.status === 401) {
        setTimeout(() => history.push('/login'));
      }
      throw new ClientError(method, url, options.data, response.status, data);
    } else {
      return data;
    }
  }

  async get<T = any>(pathname: string, options: ClientRequestOptions = {}) {
    if (options.cached) {
      const key = this.getUrl(pathname, options);
      if (!this.cache.has(key)) {
        const result = this.request<T>('GET', pathname, options);
        this.cache.set(key, result);
      }
      return this.cache.get(key) as T;
    }
    return this.request<T>('GET', pathname, options);
  }

  async put<T = any>(pathname: string, options: ClientRequestOptions = {}) {
    return this.request<T>('PUT', pathname, options);
  }

  async post<T = any>(pathname: string, options: ClientRequestOptions = {}) {
    return this.request<T>('POST', pathname, options);
  }

  async delete<T = any>(pathname: string, options: ClientRequestOptions = {}) {
    return this.request<T>('DELETE', pathname, options);
  }
}

const client = new Client(SERVER_URL);

// export client for debugging
(global as any).client = client;

export default client;
