export interface ExperimentFeatures {
  stackViewManagement: boolean;
  entityAnalysis: boolean;
  inboxManagement: boolean;
}

export const experimentFeatures: ExperimentFeatures = {
  stackViewManagement: false,
  entityAnalysis: false,
  inboxManagement: false,
}

export const isFeatureEnabled = (key: keyof ExperimentFeatures): boolean => {
  return experimentFeatures[key] || localStorage.getItem(key) !== null;
};
