import _ from 'lodash';
import { Action } from './actions';
import { ContactPersonData } from '../../types/contact.person';

export interface PersonState {
  current: ContactPersonData | null;
  list: ContactPersonData[];
  loading: boolean;
  error?: Error;
}

const initialState: PersonState = {
  current: null,
  list: [],
  loading: false,
}

const dossierReducer = (state: PersonState = initialState, action: Action): PersonState => {
  switch (action.type) {
    case '@@person/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@person/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@person/SET_LIST':
      return { ...state, list: action.list };

    case '@@person/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }
    case '@@person/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    case '@@person/CREATE_ITEM_START':
      return { ...state, loading: true }
    case '@@person/CREATE_ITEM_SUCCESS':
      return { ...state, loading: false, current: action.item }
    case '@@person/CREATE_ITEM_FAILURE':
      return { ...state, loading: false, error: action.error}
    default:
      return state;
  }
}

export default dossierReducer;
