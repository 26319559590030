import _ from 'lodash';
import { DocumentData } from '../../types/document';
import { ThunkActionType } from '../types';
import client from '../client';
import { TreeItem } from '../../types/tree';
import { fetchItemRemote as  fetchDossierItemRemote } from '../dossier/actions';

// Action Definition
export interface SetCurrentItem {
  type: '@@document/SET_CURRENT_ITEM';
  item: DocumentData;
}

export interface ClearCurrentItem {
  type: '@@document/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@document/SET_LIST';
  list: DocumentData[];
}

export interface MergeList {
  type: '@@document/MERGE_LIST';
  list: DocumentData[];
}

export interface UpdateListItem {
  type: '@@document/UPDATE_LIST_ITEM';
  item: DocumentData;
}

export interface DeleteListItem {
  type: '@@document/DELETE_LIST_ITEM';
  id: string;
}

export interface SetTree {
  type: '@@document/SET_TREE';
  tree: TreeItem;
}

export interface UpdateTreeItem {
  type: '@@document/UPDATE_TREE_ITEM';
  path: TreeItem[];
  item: TreeItem;
}

export interface DeleteTreeItem {
  type: '@@document/DELETE_TREE_ITEM';
  path: TreeItem[];
  item: TreeItem;
}

export interface DeleteTreeItems {
  type: '@@document/DELETE_TREE_ITEMS';
  items: TreeItem[];
}

export interface UpdateTreeItemChildIndex {
  type: '@@document/UPDATE_TREE_ITEM_CHILD_INDEX';
  path: TreeItem[];
  item: TreeItem;
  movement: number;
}

// Union Action Types
export type Action = (
  SetCurrentItem | ClearCurrentItem |
  SetList | MergeList | UpdateListItem | DeleteListItem | 
  SetTree | UpdateTreeItem | DeleteTreeItem | DeleteTreeItems | UpdateTreeItemChildIndex
);

// Action Creators
export const setItem = (item: DocumentData): SetCurrentItem => ({
  type: '@@document/SET_CURRENT_ITEM',
  item,
});

export const clearUser = (): ClearCurrentItem => ({
  type: '@@document/CLEAR_CURRENT_ITEM',
});

export const setList = (list: DocumentData[]): SetList => ({
  type: '@@document/SET_LIST',
  list,
});

export const updateListItem = (item: DocumentData): UpdateListItem => ({
  type: '@@document/UPDATE_LIST_ITEM',
  item,
});

export const removeListItem = (id: string): DeleteListItem => ({
  type: '@@document/DELETE_LIST_ITEM',
  id,
});

export const setTree = (tree: TreeItem): SetTree => ({
  type: '@@document/SET_TREE',
  tree,
});

export const updateTreeItem = (path: TreeItem[], item: TreeItem): UpdateTreeItem => ({
  type: '@@document/UPDATE_TREE_ITEM',
  path,
  item,
});

export const deleteTreeItem = (path: TreeItem[], item: TreeItem): DeleteTreeItem => ({
  type: '@@document/DELETE_TREE_ITEM',
  path,
  item,
});

export const deleteTreeItems = (items: TreeItem[]): DeleteTreeItems => ({
  type: '@@document/DELETE_TREE_ITEMS',
  items,
});

export const updateTreeItemChildIndex = (path: TreeItem[], item: TreeItem, movement: number): UpdateTreeItemChildIndex => ({
  type: '@@document/UPDATE_TREE_ITEM_CHILD_INDEX',
  path,
  item,
  movement,
});

export function getBaseUrl(dossierId?: string) {
  return dossierId ? '/dossiers/:dossierId' : '';
}

export const fetchItemRemote = (dossierId: string, documentId: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<DocumentData>(`/dossiers/:dossierId/documents/:documentId`, {
      params: { dossierId, documentId },
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
  }
}

export const removeItemRemote = (dossierId: string, documentId: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    await client.delete(`/dossiers/:dossierId/documents/:documentId`, {
      params: { dossierId, documentId },
    });
    dispatch(removeListItem(documentId));
    if (dossierId) {
      dispatch(fetchDossierItemRemote(dossierId));
    }
  }
}

export const fetchListRemote = (dossierId: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<DocumentData[]>(`/dossiers/:dossierId/documents`, {
      params: { dossierId },
    });
    dispatch(setList(item));
  }
}

export const getCachedItem = (dossierId: string, documentId: string) : ThunkActionType<DocumentData | undefined> => {
  return async (dispatch, getState) => {
    const state = getState();
    let document = _.find(state.document.list, {
      _id: documentId,
      dossier_id: dossierId || undefined,
    });
    if (!document) {
      document = await client.get<DocumentData>(`/dossiers/:dossierId/documents/:documentId`, {
        params: {
          dossierId,
          documentId,
        },
      });
      dispatch(updateListItem(document));
    }
    return document;
  }
}

export const fetchTreeRemote = (dossierId: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const tree = await client.get<TreeItem>('/dossiers/:dossierId/documents/root/tree', {
      params: { dossierId },
    });
    dispatch(setTree(tree));
  }
}

export const updateItemRemote = (dossierId: string, data: Partial<DocumentData>): ThunkActionType<DocumentData> => {
  return async (dispatch) => {
    let item: DocumentData;
    if (!data._id) {
      item = await client.post<DocumentData>(`/dossiers/:dossierId/documents`, {
        params: { dossierId },
        data,
      });
    } else {
      item = await client.put<DocumentData>(`/dossiers/:dossierId/documents/:documentId`, {
        params: { dossierId, documentId: data._id },
        data
      });
    }
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    if (dossierId) {
      dispatch(fetchDossierItemRemote(dossierId));
    }
    return item
  }
}

export const updateTreeItemChildIndexRemote = (dossierId: string, documentId: string, movement: number): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    await client.post('/dossiers/:dossierId/documents/:documentId', {
      params: { dossierId, documentId },
      data: { movement },
    });
  }
}

export const createItemRemote = (dossierId: string, data: Partial<DocumentData>): ThunkActionType<DocumentData | undefined> => {
  return async (dispatch) => {
    if (!data.parent_id || !data.document_template_id) {
      return;
    }
    const item = await client.post<DocumentData>(`/dossiers/:dossierId/documents/:documentId/create-from-template`, {
      params: { dossierId, documentId: data.parent_id },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    if (dossierId) {
      dispatch(fetchDossierItemRemote(dossierId));
    }
    return item;
  }
}
