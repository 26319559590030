import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';

import { __, intl } from '../../utils/intl';
import { AdminLogData } from '../../types/admin.log';
import { ListQueryResult } from '../../types/base';
import { RootState } from '../../store/reducers';
import { getUsername } from '../../utils/data';
import { getTargetTitle, getTargetName } from '../../utils/notification';
import client, { ClientError } from '../../store/client';
import { parseListQuery, parseListQueryResult, localization } from '../../utils/material-table';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import {
  mdiOpenInNew,
} from '@mdi/js';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
  adminOnly?: boolean; 
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithSnackbarProps &
  WithStyles<typeof styles>;

interface State {
  selectedItem: AdminLogData | null;
  updateData: Partial<AdminLogData> | null;
  updateTip: string | null;
}

const columns: Column<AdminLogData>[] = [
  {
    field: 'user_id',
    title: __('profile.field.name'),
    render: (log) => getUsername(log.user),
  },
  {
    field: 'messageId',
    title: __('adminLog.field.message'),
    render: (log) => log.message,
  },
  {
    field: 'dateCreated',
    title: __('common.field.dateCreated'),
    render: (log) => intl.formatTime(log.dateCreated, 'L LTS'),
  },
]

const tableOptions: Options = {
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  exportButton: true,
}

class AdminLogs extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<AdminLogData>>();
  state: State = {
    selectedItem: null,
    updateData: null,
    updateTip: null,
  }

  fetchData = async (query: Query<AdminLogData>): Promise<QueryResult<AdminLogData>> => {
    const { adminOnly } = this.props;
    const result = await client.get<ListQueryResult<AdminLogData>>('/admin/logs', {
      query: {
        ...parseListQuery(query),
        admin: adminOnly && 1,
      },
    });
    return parseListQueryResult(result);
  }

  refreshData = () => {
    const { current: table } = this.tableRef;
    if (table) {
      (table as any).onQueryChange();
    }
  }

  handleViewDetail = () => {

  }

  render() {
    const { classes, history, adminOnly } = this.props;
    const { selectedItem, updateTip } = this.state;
    const title = adminOnly ? __('admin.nav.administrators') : __('admin.nav.logs');
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
        </div>
        <MaterialTable<AdminLogData>
          tableRef={this.tableRef}
          localization={localization}
          title={title}
          columns={columns}
          data={this.fetchData}
          options={tableOptions}
          actions={[
            {
              icon: 'refresh',
              tooltip: __('common.refresh'),
              isFreeAction: true,
              onClick: this.refreshData,
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminLogs);
