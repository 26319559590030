import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';

import { __, intl } from '../../utils/intl';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import { getUsername } from '../../utils/data';
import client, { ClientError } from '../../store/client';
import { ListQueryResult } from '../../types/base';
import { parseListQuery, parseListQueryResult, localization } from '../../utils/material-table';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import {
  mdiPauseCircle,
  mdiCheckCircle,
  mdiTooltipAccount,
  mdiShieldKey,
  mdiShieldRemove,
} from '@mdi/js';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
  adminOnly?: boolean; 
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithSnackbarProps &
  WithStyles<typeof styles>;

interface State {
  selectedItem: UserData | null;
  updateData: Partial<UserData> | null;
  updateTip: string | null;
}

const columns: Column<UserData>[] = [
  {
    field: 'isAdmin',
    title: __('member.role'),
    render: (user) => user.isAdmin ? __('member.role.admin') : __('member.role.normal'),
  },
  {
    field: 'profile.lastName',
    title: __('profile.field.lastName'),
  },
  {
    field: 'profile.firstName',
    title: __('profile.field.firstName'),
  },
  {
    field: 'mobile',
    title: __('user.field.mobile'),
  },
  {
    field: 'dateCreated',
    title: __('common.field.dateCreated'),
    render: (user) => intl.formatTime(user.dateCreated, 'L LTS'),
  },
  {
    field: 'dateLastActive',
    title: __('user.field.dateLastActive'),
    render: (user) => intl.formatTime(user.dateLastActive, 'L LTS'),
  },
]

const tableOptions: Options = {
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  exportButton: true,
}

class AdminUsers extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<UserData>>();
  state: State = {
    selectedItem: null,
    updateData: null,
    updateTip: null,
  }

  fetchData = async (query: Query<UserData>): Promise<QueryResult<UserData>> => {
    const { adminOnly } = this.props;
    const result = await client.get<ListQueryResult<UserData>>('/admin/users', {
      query: {
        ...parseListQuery(query),
        admin: adminOnly && 1,
      },
    });
    return parseListQueryResult(result);
  }

  async handleUpdate(user: UserData, data: Partial<UserData>) {
    let updateTip = '';
    const username = getUsername(user);
    if (!_.isUndefined(data.isAdmin)) {
      updateTip = data.isAdmin
        ? __('admin.users.setAdmin.tip', { username })
        : __('admin.users.removeAdmin.tip', { username });
    } else if (!_.isUndefined(data.isDisabled)) {
      updateTip = data.isDisabled
        ? __('admin.users.setDisabled.tip', { username })
        : __('admin.users.setEnabled.tip', { username });
    } else {
      return;
    }
    this.setState({
      selectedItem: user,
      updateData: data,
      updateTip,
    });
  }

  handleUpdateConfirm = async () => {
    const { enqueueSnackbar } = this.props;
    const { selectedItem, updateData } = this.state;
    if (!selectedItem) return;
    try {
      await client.put('/admin/users/:userId', {
        params: { userId: selectedItem._id },
        data: updateData,
      });
      this.refreshData();
    } catch (err) {
      if (err instanceof ClientError && err.status >= 400) {
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      }
    }
    this.setState({ selectedItem: null });
  }

  handleViewDetail = async (event: React.MouseEvent, user: UserData | UserData[]) => {
    const { history } = this.props;
    if (!_.isArray(user)) {
      history.push(`/admin/users/view/${user._id}`);
    }
  }

  refreshData() {
    const { current: table } = this.tableRef;
    if (table) {
      (table as any).onQueryChange();
    }
  }

  render() {
    const { classes, history, adminOnly } = this.props;
    const { selectedItem, updateTip } = this.state;
    const title = adminOnly ? __('admin.nav.administrators') : __('admin.nav.users');
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
        </div>
        <MaterialTable<UserData>
          tableRef={this.tableRef}
          localization={localization}
          title={title}
          columns={columns}
          data={this.fetchData}
          options={tableOptions}
          actions={[
            {
              icon: 'refresh',
              tooltip: __('common.refresh'),
              isFreeAction: true,
              onClick: () => this.refreshData(),
            },
            (user) => ({
              icon: () => <SimpleSvgIcon color="primary" path={mdiTooltipAccount} />,
              tooltip: __('admin.users.detail'),
              onClick: this.handleViewDetail,
            }),
            (user) => ({
              icon: () => (
                <SimpleSvgIcon
                  color={user.isAdmin ? 'primary' : 'disabled'}
                  path={user.isAdmin ? mdiShieldKey : mdiShieldRemove}
                />
              ),
              tooltip: user.isAdmin ? __('admin.users.removeAdmin') : __('admin.users.setAdmin'),
              onClick: () => this.handleUpdate(user, { isAdmin: !user.isAdmin }),
            }),
            (user) => ({
              icon: () => (
                <SimpleSvgIcon
                  color={user.isDisabled ? 'disabled' : 'primary'}
                  path={user.isDisabled ? mdiPauseCircle : mdiCheckCircle}
                />
              ),
              tooltip: user.isDisabled ? __('common.resume') : __('common.disable'),
              onClick: () => this.handleUpdate(user, { isDisabled: !user.isDisabled }),
            }),
          ]}
        />
        <Confirm
          open={Boolean(selectedItem)}
          content={updateTip}
          onConfirm={this.handleUpdateConfirm}
          onClose={() => this.setState({ selectedItem: null })}
        />
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminUsers);
