import _ from 'lodash';
import { TreeItem, TreeItemLike } from '../types/tree';

export type TreeItemFinder = (item: TreeItem) => boolean;

export function findItemInTree<T extends TreeItemLike<T>>(tree: T, finder: TreeItemFinder): T | null {
  if (finder(tree)) {
    return tree;
  }
  if (tree.type === 'folder' && tree.children) {
    for (const child of tree.children) {
      const found = findItemInTree(child, finder);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findFirstLeaf<T extends TreeItemLike<T>>(tree: T): T | null {
  return findItemInTree(tree, item => item.type === 'file');
}

export function getTreeItemByPath<T extends TreeItemLike<T>>(tree: T, path: TreeItemIdentity[] = []): T | null {
  const _path = [...path];
  let found: T | null = tree;
  let pathItem: TreeItemIdentity | undefined = _path.shift();
  if (!pathItem || found._id !== pathItem._id) {
    return null;
  }
  while (found && (pathItem = _path.shift())) {
    const { children } = found as T;
    const { _id } = pathItem as TreeItemIdentity;
    if (!children) {
      return null;
    }
    found = children.find(item => item._id === _id) || null;
  }
  return found;
}

export function updateTreeItem<T extends TreeItemLike<T>>(path: T[], item: T) {
  let current = item;
  const revPath = [...path].reverse();
  revPath.forEach(folder => {
    const children = (folder.children || []).map(child => child._id === current._id ? current : child);
    current = {
      ...folder,
      children: [...children, current],
    }
  });
  return current;
}

export function deleteTreeItem<T extends TreeItemLike<T>>(path: T[], item: T) {
  const parentPath = [...path];
  const parent = parentPath.pop();
  if (!parent) {
    throw new Error('Can not delete root node');
  }
  return updateTreeItem(parentPath, {
    ...parent,
    children: _.without(parent.children, item),
  });
}

export interface TreeItemResult {
  path: TreeItem[];
  item: TreeItem;
}

export interface TreeItemIdentity {
  _id?: string;
}

export function getTreeItemPath<T extends TreeItemLike<T>>(root: T, item: TreeItemIdentity, path: T[] = []): T[] | null {
  path = [...path, root];
  if (root._id === item._id) {
    return path;
  }
  if (root.type === 'folder' && root.children) {
    for (let i = 0; i < root.children.length; i++) {
      const child = root.children[i];
      const found = getTreeItemPath(child, item, path);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function getMultiTreeItemsPaths<T extends TreeItemLike<T>>(root: T, items: T[], path: T[] = [], paths: T[][] = []): T[][] {
  const found: T | undefined = _.find<T, T>(items, { _id: root._id } as any);
  if (path.length === 0) {
    path = [root];
  }
  if (found !== undefined) {
    paths.push([...path, found]);
  }
  if (root.type === 'folder' && root.children) {
    for (let i = 0; i < root.children.length; i++) {
      const child = root.children[i];
      getMultiTreeItemsPaths(child, items, path, paths);
    }
  }
  return paths;
}

export function deleteTreeItems<T extends TreeItemLike<T>>(root: T, items: T[], mutateItems?: T[]): T {
  if (mutateItems === undefined) {
    const paths = getMultiTreeItemsPaths(root, items);
    mutateItems = _.flatten(paths);
  }
  if (mutateItems.includes(root) && root.type === 'folder' && root.children) {
    return {
      ...root,
      children: root.children
        .filter(child => !items.includes(child))
        .map(child => deleteTreeItems(child, items, mutateItems)),
    };
  } else {
    return root;
  }
}

export function updateTreeItemChildIndex<T extends TreeItemLike<T>>(path: T[], item: T, movement: number) {
  const parentPath = [...path];
  const parent = parentPath.pop();
  if (parent === undefined) {
    throw new Error('Can not update root node');
  }
  const { children } = parent;
  if (children === undefined) {
    throw new Error('Invalid tree path');
  }
  const target: T | undefined = _.find<T, T>(children, { _id: item._id } as any);
  if (target === undefined) {
    throw new Error('Invalid target');
  }
  const oldIndex = target.childIndex
  let newIndex = oldIndex + movement;
  if (newIndex < 0) {
    newIndex = 0;
  } else if (newIndex > children.length - 1) {
    newIndex = children.length - 1;
  }
  const range: { left: number, right: number} = movement < 0 ? {
    left: newIndex,
    right: oldIndex - 1,
  }: {
    left: oldIndex + 1,
    right: newIndex,
  };
  const restIncrement = movement > 0 ? -1 : 1;
  return updateTreeItem(parentPath, {
    ...parent,
    children: children.map(child => {
      if (child._id === item._id) {
        return {
          ...child,
          childIndex: newIndex,
        };
      } else if (child.childIndex >= range.left && child.childIndex <= range.right) {
        return {
          ...child,
          childIndex: child.childIndex + restIncrement, 
        }
      } else {
        return child;
      }
    }),
  });
}

export interface TreeWalkingCallback<T extends TreeItemLike<T>> {
  (item: T, path: T[]): boolean | void;
}

export type TreeQueryCallback<T extends TreeItemLike<T>> = (item: T, path?: T[]) => boolean;
export type IterateeArray<T, K extends keyof T> = [K, T[K]];
export type IterateeObject<T> = Partial<T>;
export type TreeQueryFilter<T extends TreeItemLike<T>> = TreeQueryCallback<T> | IterateeArray<T, any> | IterateeObject<T>;

export function walk<T extends TreeItemLike<T>>(item: T, cb: TreeWalkingCallback<T>, path: T[] = []) {
  if (cb(item, path)) {
    return;
  }
  const { children } = item;
  if (item.type == 'folder' && children) {
    const newPath = [...path, item];
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      walk(child, cb, newPath);
    }
  }
}

export function walkDeepFirst<T extends TreeItemLike<T>>(item: T, cb: TreeWalkingCallback<T>, path: T[] = []) {
  const { children } = item;
  if (item.type == 'folder' && children) {
    const newPath = [...path, item];
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      walk(child, cb, newPath);
    }
  }
  if (cb(item, path)) {
    return;
  }
}

export function find<T extends TreeItemLike<T>>(item: T, filter?: TreeQueryFilter<T> | null): T | null {
  if (!filter) {
    return null;
  }
  if (!_.isFunction(filter)) {
    filter = _.iteratee(filter);
  }
  let result: T | null = null;
  walk(item, (item, path) => {
    if ((filter as any)(item, path)) {
      result = item;
      return true;
    }
  });
  return result;
}

export interface ItemWithPath<T> {
  item: T | null;
  path: T[]
}


export function findItemAndPath<T extends TreeItemLike<T>>(item: T, filter: TreeQueryFilter<T>): ItemWithPath<T> {
  if (!_.isFunction(filter)) {
    filter = _.iteratee(filter);
  }
  const result: ItemWithPath<T> = {
    item: null,
    path: [],
  };
  walk(item, (item, path) => {
    if ((filter as TreeQueryCallback<T>)(item, path)) {
      result.item = item;
      result.path = path;
      return true;
    }
  });
  return result;
}

export interface UITreeItem extends TreeItemLike<UITreeItem> {
  expanded?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  document_template_id?: string;
}

export interface CheckboxTreeOptions {
  initialChecked?: boolean;
  initialExpandedIds: string[];
}

export function buildCheckboxTree(tree: TreeItem, options: CheckboxTreeOptions = {initialExpandedIds: []}): UITreeItem {
  const checked = !!options.initialChecked;
  const newTree: UITreeItem = _.cloneDeep(tree);
  const {initialExpandedIds} = options;
  walk(newTree, (item, path) => {
    item.checked = checked;
    item.indeterminate = false;
    if (initialExpandedIds.includes(item._id)) {
      item.expanded = true
    }
  });
  walkDeepFirst(newTree, (item) => {
    normalizeCheckboxTreeItem(item);
  });
  return newTree;
}

export function normalizeCheckboxTreeItem(item: UITreeItem) {
  let checkedCount = 0;
  let indeterminateCount = 0;
  let childCount = 0;
  const { children } = item;
  if (!children) {
    return;
  }
  childCount = children.length;
  children.forEach(child => {
    if (child.checked) checkedCount++;
    if (child.indeterminate) indeterminateCount++;
  });
  if (indeterminateCount > 0) {
    item.checked = true;
    item.indeterminate = true;
  } else {
    if (checkedCount === childCount) {
      item.checked = true
      item.indeterminate = false;
    } else if (checkedCount === 0) {
      item.checked = false;
      item.indeterminate = false;
    } else {
      item.checked = true;
      item.indeterminate = true;
    }
  }
}

export function onCheckChange(tree: UITreeItem, item: TreeItemIdentity) {
  const currentPath = getTreeItemPath(tree, item);
  if (!currentPath || currentPath.length === 0) {
    throw new Error('item not found');
  }
  currentPath.reverse();
  const [current, ...parentPath] = currentPath;
  const newChecked = current.indeterminate ? true : !current.checked;
  walk(current, (child) => {
    child.checked = newChecked;
    child.indeterminate = false;
  });
  parentPath.forEach(parent => {
    normalizeCheckboxTreeItem(parent);
  });
}

export function findAll<T extends TreeItemLike<T>>(item: T, filter?: TreeQueryFilter<T> | null): T[] {
  if (!filter) {
    return [];
  }
  if (!_.isFunction(filter)) {
    filter = _.iteratee(filter);
  }
  let result: T[] = [];
  walk(item, (item) => {
    if ((filter as TreeQueryCallback<T>)(item)) {
      result.push(item);
    }
  });
  return result;
}

export function rebuildCheckboxTree(tree: UITreeItem, checkedItems: string[]) {
  walk(tree, (item) => {
    item.checked = checkedItems.includes(item._id);
    item.indeterminate = false;
  });
  walkDeepFirst(tree, (item) => {
    normalizeCheckboxTreeItem(item);
  });
}
