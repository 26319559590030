import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { List, Divider, IconButton, Box } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { grey } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import ContactIcon from '@material-ui/icons/ContactPhone';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {
  mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiUnfoldLessVertical,
  mdiUnfoldMoreVertical,
  mdiFolderPlus,
  mdiFileTree,
  mdiShieldHome,
} from '@mdi/js';

import { __ } from '../../utils/intl';
import { DossierListQuery } from '../../types/dossier';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import { togglePanel, togglePanelMinified } from '../../store/ui/actions';
import { logoutCurrentUser } from '../../store/user/actions';
import { fetchPendingCountRemote } from '../../store/invitation/actions';
import { PanelStatus, PanelSide } from '../../store/ui/reducers';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import SimpleListItem from '../Common/SimpleListItem';
import Sidebar from '../Common/Sidebar';
import { isInDossierRoute } from '../Dossier/utils';
import RedAlertIcon from '../Common/RedAlertIcon';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  title: {
    flexGrow: 1,
    color: grey[700],
    fontSize: '14px',
    padding: '19px 16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
  },
  minifiedTitle: {
    flexShrink: 1,
    padding: '19px 0',
  },
  minifyButton: {
    margin: 4,
  },
  list: {
    overflow: 'auto',
  },
  actions: {
    margin: theme.spacing(1),
  },
});

interface StateProps {
  user: UserData | null;
  panelStatus: PanelStatus;
  pendingCount: number;
}

interface DispatchProps {
  togglePanel: (side: PanelSide, status?: boolean) => void;
  togglePanelMinified: (side: PanelSide, status?: boolean) => void;
  fetchPendingCountRemote: () => Promise<void>;
  logoutCurrentUser: () => void;
}

interface OwnProps { }

interface OwnProps {
  onCreateTeam: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithWidth & WithStyles<typeof styles>;

interface State {
}

class SideNav extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  componentDidMount() {
    const { fetchPendingCountRemote } = this.props;
    fetchPendingCountRemote();
  }

  componentWillReceiveProps(nextProps: Props) {
  }

  getQuery(): DossierListQuery {
    const { location } = this.props;
    if (location.pathname === '/dashboard/list') {
      return qs.parse(location.search.slice(1));
    } else {
      return {};
    }
  }

  handleQueryChange(listQuery: DossierListQuery) {
    const { history } = this.props;
    let url = '/dashboard/list';
    if (listQuery.type) {
      url += '?' + qs.stringify(listQuery);
    }
    this.autoClose();
    history.push(url);
  }

  handleLogout() {
    const { logoutCurrentUser } = this.props;
    logoutCurrentUser();
  }

  autoClose = () => {
    const { panelStatus, togglePanel } = this.props;
    if (!panelStatus.side.pinned && panelStatus.side.open) {
      togglePanel('side', false);
    }
  }

  handleToggleLeftPanel = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    const { panelStatus, togglePanel } = this.props;
    togglePanel('left');
    if (!panelStatus.side.pinned) {
      togglePanel('side', false);
    }
  }

  render() {
    const {
      classes,
      location,
      user,
      panelStatus,
      togglePanel,
      togglePanelMinified,
      pendingCount,
    } = this.props;
    const status = panelStatus.side;
    const listQuery = this.getQuery();
    if (!user) return null;
    return (
      <Sidebar
        open={status.open}
        width={status.width}
        autoClose={!status.pinned}
        onClose={() => togglePanel('side', false)}
        resizable={false}
      >
        <div className={classes.root}>
          <header className={classes.header}>
            <div className={clsx(classes.title, { [classes.minifiedTitle]: status.minified })}>
              {__('nav.mainMenu')}
            </div>
            <IconButton
              className={classes.minifyButton}
              onClick={() => togglePanelMinified('side')}
            >
              {status.minified ?
                <SimpleSvgIcon path={mdiUnfoldMoreVertical} />
                :
                <SimpleSvgIcon path={mdiUnfoldLessVertical} />
              }
            </IconButton>
          </header>
          <Divider />
          {isInDossierRoute() &&
            <>
              <List
                className={classes.list}
              >
                <SimpleListItem
                  minified={status.minified}
                  title={__('nav.toggleLeftPanel')}
                  icon={<SimpleSvgIcon path={mdiFileTree} />}
                  selected={panelStatus.left.open}
                  onClick={this.handleToggleLeftPanel}
                />
              </List>
              <Divider />
            </>
          }
          <List className={classes.list}>
            <SimpleListItem
              minified={status.minified}
              title={__('nav.homepage')}
              icon={<HomeIcon />}
              selected={location.pathname === '/dashboard/list' && !listQuery.type}
              onClick={() => this.handleQueryChange({})}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('fileExplorer.newCase')}
              icon={<SimpleSvgIcon path={mdiFolderPlus} />}
              link="/dashboard/create"
              onClick={this.autoClose}
            />
            {/* <SimpleListItem
              minified={status.minified}
              title={__('dossier.list.recent')}
              icon={<HistoryIcon />}
              selected={listQuery.type === 'recent'}
              onClick={() => this.handleQueryChange({ type: 'recent' })}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('dossier.list.mine')}
              icon={<PersonIcon />}
              selected={listQuery.type === 'mine'}
              onClick={() => this.handleQueryChange({ type: 'mine' })}
            /> */}
            <SimpleListItem
              minified={status.minified}
              title={__('dossier.list.all')}
              icon={<ListIcon />}
              selected={listQuery.type === 'all'}
              onClick={() => this.handleQueryChange({ type: 'all' })}
            />
          </List>
          <Divider />
          <List className={classes.list}>
            <SimpleListItem
              minified={status.minified}
              title={__('team.teams')}
              icon={<SimpleSvgIcon path={mdiAccountMultiple} />}
              link="/team"
            />
            <SimpleListItem
              minified={status.minified}
              title={__('invitation.invitation')}
              icon={<SimpleSvgIcon path={mdiAccountMultiplePlus} />}
              link="/invitation"
              badge={pendingCount > 0 ? <RedAlertIcon /> : undefined}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('nav.contacts')}
              icon={<ContactIcon />}
              link="/contacts"
              onClick={this.autoClose}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('nav.profile')}
              icon={<ProfileIcon />}
              link="/account/profile"
              exact
              onClick={this.autoClose}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('nav.settings')}
              icon={<SettingsIcon />}
              link="/account/settings"
              onClick={this.autoClose}
            />
            <Divider />
            {user.isAdmin &&
              <>
                <SimpleListItem
                  minified={status.minified}
                  title={__('nav.adminPanel')}
                  icon={<SimpleSvgIcon path={mdiShieldHome}/>}
                  link="/admin"
                  onClick={this.autoClose}
                />
                <Divider />
              </>
            }
            <SimpleListItem
              minified={status.minified}
              title={__('nav.about')}
              icon={<InfoIcon />}
              link="/account/about"
              onClick={this.autoClose}
            />
            <SimpleListItem
              minified={status.minified}
              title={__('nav.logout')}
              icon={<LogoutIcon />}
              onClick={() => this.handleLogout()}
            />
          </List>
        </div>
      </Sidebar>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  user: states.user.current,
  panelStatus: states.ui.panelStatus,
  pendingCount: states.invitation.pendingCount,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  togglePanel: (side, status) => dispatch(togglePanel(side, status)),
  togglePanelMinified: (side, status) => dispatch(togglePanelMinified(side, status)),
  fetchPendingCountRemote: () => dispatch(fetchPendingCountRemote()),
  logoutCurrentUser: () => dispatch(logoutCurrentUser()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(SideNav);
