import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { Button, List, ListItem, ListItemIcon, ListItemText, ListSubheader, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import LaptopIcon from '@material-ui/icons/Laptop';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import RemoveLoginIcon from '@material-ui/icons/RemoveCircle';

import { TokenData } from '../../types/token';
import { __ } from '../../utils/intl';
import client, { ClientError } from '../../store/client';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import Loading from '../Common/Loading';

const styles = (theme: Theme) => createStyles({
  root: {
    maxWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
});

interface OwnProps {
  open: boolean;
  onClose: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  tokens: TokenData[];
}

class TokenManageDialog extends React.Component<Props, State> {
  
  changeLanguageTimer: any = null;

  state: State = {
    tokens: [],
  }

  componentDidMount() {
    if (this.props.open) {
      this.loadData();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.setState({ tokens: [] });
      this.loadData();
    }
  }

  async loadData() {
    const tokens = await client.get<TokenData[]>('/account/tokens');
    this.setState({ tokens });
  }

  async handleRevokeToken(token: TokenData) {
    await client.post('/account/tokens/:tokenId/revoke', { params: { tokenId: token._id }});
    this.setState({
      tokens: _.without(this.state.tokens, token),
    });
  } 

  renderDeviceIcon(token: TokenData) {
    const { userAgent } = token.clientInfo;
    if (/Windows/.test(userAgent)) {
      return <LaptopWindowsIcon />;
    } else if (/Mac OS/.test(userAgent)) {
      return <LaptopMacIcon />;
    } else if (/Android/.test(userAgent)) {
      return <PhoneAndroidIcon />;
    } else if (/iPhone/.test(userAgent)) {
      return <PhoneIphoneIcon />;
    } else {
      return <LaptopIcon />;
    }
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { tokens } = this.state;
    return (
      <ResponsiveDialog
        modal
        open={open}
        title={__('settings.tokenManagement')}
        onClose={() => onClose()}
        actions={
          <>
            <Button
              color="inherit"
              onClick={onClose}
            >
              {__('dialog.close')}
            </Button>
          </>
        }
      >
        {tokens.length ?
          <List
            className={classes.root}
            subheader={
              <ListSubheader>
                {__('settings.tokenManagement.listHeader')}
              </ListSubheader>
            }
          >
            {tokens.map(token => (
              <ListItem key={token._id}>
                <ListItemIcon>
                  {this.renderDeviceIcon(token)}
                </ListItemIcon>
                {/* __('settings.tokenManagement.clientInfo') */}
                {/* __('settings.tokenManagement.timeInfo') */}
                <ListItemText
                  primary={__('settings.tokenManagement.clientInfo', {
                    userAgentMessage: token.clientInfo.userAgentMessage || '',
                    ipAddressMessage: token.clientInfo.ipAddressMessage || '',
                    ipAddress: token.clientInfo.ipAddress,
                  })}
                  secondary={<>
                    {token.isCurrent && __('settings.tokenManagement.currentDevice')}
                    {__('settings.tokenManagement.timeInfo', {
                      dateCreated: moment(token.dateCreated).fromNow(),
                      dateUpdated: moment(token.dateUpdated).fromNow(),
                    })}
                  </>}
                />
                {!token.isCurrent &&
                  <ListItemSecondaryAction>
                    <Tooltip title={__('settings.tokenManagement.remove')}>
                      <IconButton edge="end" onClick={() => this.handleRevokeToken(token)}>
                        <RemoveLoginIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            ))}
          </List>
        :
          <Loading />
        }
      </ResponsiveDialog>
    );
  }
}

export default withStyles(styles)(TokenManageDialog);
