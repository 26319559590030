import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { Paper, FormControlLabel, Switch, Tabs, Tab, Link } from '@material-ui/core';
import DmsCoordinates from 'dms-conversion';
import { TreeItem } from '../../types/tree';
import DataTable, { Column, ColumnRenderer } from '../Common/DataTable';
import { getDemoData } from '../../demo';
import { GeoCoordinates, LocationEntity } from '../../types/entity';

const { entities } = getDemoData();

type EntityTypes = 'person' | 'organization' | 'location';

const coordinateRenderer: ColumnRenderer = (value) => {
  if (!value) return '';
  const coordinates = value as GeoCoordinates;
  const dmsCoords = new DmsCoordinates(coordinates.latitude, coordinates.longitude);
  const { latitude: lat, longitude: lon } = dmsCoords.getDmsArrays();
  return `${lat[0]}°${lat[1]}′${Math.round(lat[2])}′′${lat[3]}, ${lon[0]}°${lon[1]}′${Math.round(lon[2])}′′${lon[3]}`;
}

const mapLinkRenderer: ColumnRenderer = (value, record) => {
  let query = '';
  const { coordinates, address } = record as LocationEntity;
  if (coordinates) {
    query = `/place/${coordinates.latitude},${coordinates.longitude}`;
  } else {
    query = `/search/${encodeURIComponent(address)}`;
  }
  const mapUrl = `https://www.google.com/maps${query}`;
  return <Link target="_blank" href={mapUrl}>Google Map</Link>;
}

interface EntityDefine {
  key: EntityTypes,
  label: string,
  columns: Column[],
}

const entityTabs: EntityDefine[] = [
  {
    key: 'person',
    label: 'Persons',
    columns: [
      { field: 'name', label: 'Name' },
      { field: 'sex', label: 'Sex' },
      { field: 'birthdate', label: 'Birthdate' },
      { field: 'phoneNumber', label: 'Phone Number' },
      { field: 'idNumber', label: 'ID Number' },
      { field: 'role', label: 'Role' },
      { field: 'attorney', label: 'Attorney' },
    ],
  },
  {
    key: 'organization',
    label: 'Organizations',
    columns: [
      { field: 'name', label: 'Name' },
      { field: 'legalRep', label: 'Legal Representative' },
      { field: 'phoneNumber', label: 'Phone Number' },
      { field: 'idNumber', label: 'ID / ISIN' },
      { field: 'source', label: 'Business Files' },
      { field: 'role', label: 'Role' },
      { field: 'attorney', label: 'Attorney' },
    ],
  },
  {
    key: 'location',
    label: 'Locations',
    columns: [
      { field: 'name', label: 'Location' },
      { field: 'address', label: 'Address' },
      { field: 'coordinates', label: 'Position', renderer: coordinateRenderer },
      { field: 'map', label: 'Map', renderer: mapLinkRenderer },
    ],
  },
];

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },
  toolbar: {
    flex: '0 0 40px',
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {

  },
});

interface OwnProps {

}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  tabValue: EntityTypes;
}

class EntityView extends React.Component<Props, State> {

  rootElement: HTMLElement | null = null;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      tabValue: 'person',
    };
  }

  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    const tabDefine = entityTabs.find(tab => tab.key === tabValue);
    if (!tabDefine) return;
    const data = entities.filter(tab => tab.type === tabValue);
    return (
      <div
        ref={ref => this.rootElement = ref}
        className={classes.root}
      >
        <Tabs
          indicatorColor="secondary"
          className="tabs"
          value={tabValue}
          onChange={(event, newValue) => this.setState({ tabValue: newValue})}
        >
          {entityTabs.map(({ key, label }) => (
            <Tab key={key} value={key} label={label}></Tab>
          ))}
        </Tabs>
        <div className={classes.table}>
          <DataTable
            columns={tabDefine.columns}
            data={data}
          />
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(EntityView);
