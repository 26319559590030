import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Typography, Button, TextField } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import client from '../../store/client';
import { RootState } from '../../store/reducers';
import {
  fetchItemRemote as fetchDossierRemote,
  updateItemRemote as updateDossierRemote,
  removeItemRemote as removeDossierRemote,
} from '../../store/dossier/actions';
import { fetchListRemote as fetchDocumentList } from '../../store/document/actions'
import { DossierData, DossierContext } from '../../types/dossier';
import { DossierTemplateData } from '../../types/dossier.template';
import FormControl from '../Form/FormControl';
import Confirm from '../Common/Confirm';
import { PanelProps } from './common';
import Panel from './Panel';
import DossierTransferDialog from './DossierTransferDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
  },
  formControl: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
  },
  tip: {
    fontSize: '14px',
  },
  formView: {
    flexGrow: 1,
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    '&:first-child': {
      marginTop: 'none',
    }
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});

interface OwnProps {
}

interface StateProps {
  dossier: DossierData | null;
  dossierTemplate: DossierTemplateData | null;
}

interface DispatchProps {
  fetchDossierRemote: (dossierId: string) => Promise<DossierData>;
  updateDossierRemote: (data: Partial<DossierData>) => Promise<DossierData>;
  removeDossierRemote: (dossierId: string, password: string) => Promise<void>;
  fetchDocumentList: (dossierId: string) => Promise<void>;
}

interface Params {
  dossierId: string,
  viewMode: string,
}

type RouteProps = RouteComponentProps<Params>

interface OwnProps extends PanelProps {
}

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

interface State {
  editing: boolean;
  archiveConfirmOpen: boolean;
  deleteConfirmOpen: boolean;
  context: DossierContext;
  password: string;
  transferDialogOpen: boolean;
}

class InfoPanel extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState(props: Props): State {
    const { dossier } = props;
    return {
      editing: false,
      archiveConfirmOpen: false,
      deleteConfirmOpen: false,
      context: dossier ? dossier.context : {},
      password: '',
      transferDialogOpen: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.dossier !== this.props.dossier) {
      this.setState(this.getInitialState(nextProps));
    }
  }

  handleCancel() {
    this.setState(this.getInitialState(this.props));
  }

  async handleSave() {
    const { updateDossierRemote, fetchDocumentList, dossier } = this.props;
    const { context } = this.state;
    this.setState({ editing: false });
    if (!dossier) return;
    await updateDossierRemote({
      _id: dossier._id,
      context,
    });
    await fetchDocumentList(dossier._id)
  }

  async handleArchive() {
    const { fetchDossierRemote, dossier } = this.props;
    if (!dossier) return;
    await client.post(`/dossiers/${dossier._id}/archive`, {
      data: {
        archive: !dossier.isArchived
      }
    })
    await fetchDossierRemote(dossier._id);
    this.setState({ archiveConfirmOpen: false });
  }

  async handleRemove() {
    const { removeDossierRemote, dossier, history } = this.props;
    const { password } = this.state;
    if (!dossier) return;
    try {
      await removeDossierRemote(dossier._id, password)
      history.replace('/');
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    const { classes, open, dossier, dossierTemplate } = this.props;
    const { editing, archiveConfirmOpen, deleteConfirmOpen, transferDialogOpen, context, password } = this.state;
    if (!dossier || !dossierTemplate) return null;
    return (
      <Panel open={open} className={classes.root}>
        <Typography className={`${classes.formControl} ${classes.tip}`}>
          {__('panels.info.dossierFormTitle')}
        </Typography>
        <FormControl
          readOnly={!editing}
          className={classes.formView}
          items={dossierTemplate.form}
          value={context}
          onChange={value => this.setState({ context: value })}
        />
        <div className={classes.actions}>
          {editing ?
            <>
              <Button
                className={classes.button}
                variant="outlined"
                color="default"
                onClick={() => this.handleCancel()}
              >
                {__('dialog.cancel')}
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => this.handleSave()}
              >
                {__('dialog.save')}
              </Button>
            </>
            :
            <>
              <Button
                className={classes.button}
                variant="outlined"
                color="default"
                onClick={() => this.setState({ editing: true })}
              >
                {__('dialog.edit')}
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ archiveConfirmOpen: true })}
              >
                {dossier.isArchived ? __('dossier.restore') : __('dossier.archive')}
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => this.setState({ transferDialogOpen: true })}
              >
                {__('dossier.transfer')}
              </Button>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={() => this.setState({ deleteConfirmOpen: true })}
              >
                {__('dossier.delete')}
              </Button>
            </>
          }
        </div>
        <Confirm
          open={archiveConfirmOpen}
          title={dossier.isArchived ? __('dossier.restore') : __('dossier.archive')}
          content={dossier.isArchived ? __('dossier.restoreConfirmHint') : __('dossier.archiveConfirmHint')}
          onClose={() => this.setState({ archiveConfirmOpen: false })}
          onConfirm={() => this.handleArchive()}
        />
        <DossierTransferDialog
          dossierId={dossier._id}
          open={transferDialogOpen}
          onClose={() => this.setState({ transferDialogOpen: false })}
        />
        <Confirm
          open={deleteConfirmOpen}
          title={__('dossier.delete')}
          content={
            <>
              {__('dossier.deleteConfirmHint')}
              <TextField
                fullWidth
                type="password"
                label={__('dossier.deleteConfirmPasswordLabel')}
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </>
          }
          confirmButtonText={__('dossier.delete')}
          confirmButtonProps={{ color: 'secondary' }}
          onClose={() => this.setState({
            deleteConfirmOpen: false,
            password: '',
          })}
          onConfirm={() => this.handleRemove()}
        />
      </Panel>
    )
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  dossier: states.dossier.current,
  dossierTemplate: states.dossierTemplate.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  removeDossierRemote: (dossierId, password) => dispatch(removeDossierRemote(dossierId, password)),
  updateDossierRemote: (data) => dispatch(updateDossierRemote(data)),
  fetchDossierRemote: (dossierId) => dispatch(fetchDossierRemote(dossierId)),
  fetchDocumentList: (dossierId) => dispatch(fetchDocumentList(dossierId)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(InfoPanel);
