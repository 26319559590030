import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { List, Divider, IconButton, Box } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';

import { RootState } from '../../store/reducers';
import ChatSidebar from './ChatSidebar';
import ChatWindow from './ChatWindow';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  list: {
    overflow: 'auto',
  },
  actions: {
    margin: theme.spacing(1),
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps { }

interface OwnProps {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithWidth & WithStyles<typeof styles>;

interface State {
}

class Chat extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps: Props) {
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <ChatSidebar />
        <ChatWindow />
      </>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Chat);
