import _ from 'lodash';
import { Action } from './actions';
import { DossierData } from '../../types/dossier';
import { DossierTemplateData } from '../../types/dossier.template';

export interface DossierTemplateState {
  current: DossierTemplateData | null;
  list: DossierTemplateData[];
}

const initialState: DossierTemplateState = {
  current: null,
  list: [],
}

const dossierTemplateReducer = (state: DossierTemplateState = initialState, action: Action): DossierTemplateState => {
  switch (action.type) {
    case '@@dossierTemplate/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@dossierTemplate/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@dossierTemplate/SET_LIST':
      return { ...state, list: action.list };

    case '@@dossierTemplate/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list);
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }

    case '@@dossierTemplate/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };

    default:
      return state;
  }
}

export default dossierTemplateReducer;
