import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { Button, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import PreviewIcon from '@material-ui/icons/RemoveRedEye';

import { intl, __ } from '../../utils/intl';
import { TreeItem } from '../../types/tree';
import { PrintCacheResult, RenderOptions } from '../../types/print';
import { DossierData } from '../../types/dossier';
import client from '../../store/client';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import { PanelProps } from './common';
import DocumentTree from './DocumentTree';
import ExportPreviewDialog from './ExportPreviewDialog';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  formControl: {
  },
  tip: {
    fontSize: '12px',
  },
  treeSelect: {
    minHeight: 300,
    overflow: 'auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});

interface OwnProps {
  open: boolean;
  dossier: DossierData;
  selectedItem: TreeItem | null;
  onClose: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  selectedItem: TreeItem | null;
  previewDialogOpen: boolean;
  exporting: boolean;
  includingToc: boolean;
  checkedItems: string[];
  cacheKey: string;
}

class ExportDialog extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
    previewDialogOpen: false,
    exporting: false,
    includingToc: true,
    checkedItems: [],
    cacheKey: '',
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.setState({ selectedItem: nextProps.selectedItem });
    }
  }

  handleSelect = (item: TreeItem) => {
    this.setState({ selectedItem: item });
  }

  async handlePreview() {
    const { dossier } = this.props;
    const { includingToc, checkedItems } = this.state;
    const data: RenderOptions = {
      toc: includingToc,
      selectedItems: checkedItems.length ? checkedItems : undefined,
      locale: intl.language,
    };
    this.setState({ exporting: true });
    try {
      const result = await client.post<PrintCacheResult>(`/print/dossier/${dossier._id}/cache`, { data });
      this.setState({
        previewDialogOpen: true,
        cacheKey: result.key,
        exporting: false,
      });
    } catch(err) {
      console.error(err);
    }
  }

  render() {
    const { classes, open, dossier, selectedItem, onClose } = this.props;
    const { previewDialogOpen, exporting, includingToc, checkedItems, cacheKey } = this.state;
    return (
      <ResponsiveDialog
        modal
        open={open}
        className={classes.root}
        title={__('panels.export.title')}
        onClose={onClose}
        actions={
          <>
            <Button onClick={onClose}>
              {__('dialog.cancel')}
            </Button>
            <Button
              disabled={exporting}
              variant="contained"
              color="primary"
              onClick={() => this.handlePreview()}
            >
              {exporting ? __('panels.export.previewButtonExporting') : __('panels.export.previewButton')}
              <PreviewIcon className={classes.buttonIcon} />
            </Button>
          </>
        }
      >
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              checked={includingToc}
              onChange={(event, checked) => this.setState({ includingToc: checked })}
            />
          }
          label={__('panels.export.includingToc')}
        />
        <Typography className={`${classes.formControl} ${classes.tip}`}>
          {__('panels.export.selectDocumentTip')}
        </Typography>
        <section className={classes.treeSelect}>
          <DocumentTree
            checkbox
            tree={dossier.tree}
            selectedItem={selectedItem}
            onSelect={this.handleSelect}
            checkedItems={checkedItems}
            onCheckChange={checkedItems => this.setState({ checkedItems })}
          />
        </section>
        <div className={classes.actions}>
          
        </div>
        <ExportPreviewDialog
          open={previewDialogOpen}
          dossier={dossier}
          cacheKey={cacheKey}
          onClose={() => this.setState({ previewDialogOpen: false })}
        />
      </ResponsiveDialog>
    )
  }
}

export default withStyles(styles)(ExportDialog);
