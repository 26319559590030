import _ from 'lodash';
import React from 'react';

export type ZoomMode = 'fit' | 'free';

export interface ScrollViewState {
  onlyDocument: boolean;
  zoomMode: ZoomMode;
  zoom: number;
  changeZoom: (zoom: number) => void;
}

export const defaultScrollViewState: ScrollViewState = {
  onlyDocument: false,
  zoomMode: 'fit',
  zoom: 1,
  changeZoom: () => {},
};

export const ScrollViewContext = React.createContext<ScrollViewState>(defaultScrollViewState);
