import { toPx } from '../../utils/unit';

export const STACK_EXPLODING_DURATION = 0.5;
export const STACK_IMPLODING_DURATION = 0.3;
export const STACK_MIN_ITEMS_PER_ROW = 3;
export const STACK_ITEM_DEFAULT_WIDTH = 210;
export const STACK_ITEM_DEFAULT_HEIGHT = 297;
export const STACK_ITEM_PADDING = 4;

export const PAPER_WIDTH = toPx('210mm');
export const PAPER_HEIGHT = toPx('297mm');
export const PAPER_EXTRA_MARGIN_LARGE = 32;
export const PAPER_EXTRA_MARGIN_SMALL = 16;
export const PAPER_PADDING_TOP = toPx('2.54cm');
export const PAPER_PADDING_BOTTOM = toPx('2.54cm');
export const PAPER_PADDING_LEFT = toPx('3.17cm');
export const PAPER_PADDING_RIGHT = toPx('3.17cm');
export const PAPER_PADDING_INNER = toPx('0.5cm');
export const PAPER_HEADER_HEIGHT = toPx('1.5cm');
export const PAPER_FOOTER_HEIGHT = toPx('1.75cm');
export const MIN_PAPER_WIDTH = 320;
export const ZOOM_LEVELS = [
  0.10,
  0.20,
  0.30,
  0.40,
  0.50,
  0.60,
  0.70,
  0.80,
  0.90,
  1.00,
  1.10,
  1.20,
  1.30,
  1.40,
  1.50,
  1.75,
  2.00,
  3.00,
  4.00,
];

export function getPaperExtraMargin(): number {
  return window.innerWidth < 600 ? PAPER_EXTRA_MARGIN_SMALL : PAPER_EXTRA_MARGIN_LARGE;
}
