import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

import { DossierData } from '../../types/dossier';
import { DocumentData } from '../../types/document';
import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { updateItemRemote as updateDocument } from '../../store/document/actions'
import { updateItemRemote as updateDossier } from '../../store/dossier/actions'
import { fetchItemRemote as fetchDocument } from '../../store/document/actions';
import { fetchItemRemote as fetchDossier } from '../../store/dossier/actions';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import DocumentEditor from './DocumentEditor'
import { KeyValueMap } from '../../types/common';

interface StateProps {
  dossier: DossierData | null,
  document: DocumentData | null,
}

interface DispatchProps {
  updateDocument: (dosserId: string | null, document: Partial<DocumentData>) => void;
  updateDossier: (dossier: Partial<DossierData>) => void;
  fetchDocument: (dossierId: string | null, documentId: string) => void;
  fetchDossier: (dossierId: string) => void;
}

interface OwnProps {

}

interface State {
  formDialogOpen: boolean;
  factDialogOpen: boolean;
  content?: string;
  context?: KeyValueMap
  saved: boolean;
}

const styles = (theme: Theme) => createStyles({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: 0,
  },
});

interface Params {
  documentId: string;
  dossierId: string;
}

export type RouteProps = RouteComponentProps<Params>


type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

class DocumentEditorDialog extends React.Component<Props, State> {
  changeText: (text: string) => void;
  constructor(props: Props) {
    super(props)
    this.state = {
      formDialogOpen: false,
      factDialogOpen: false,
      saved: false
    }
    this.changeText = _.debounce(this.handleChangeText, 500)
  }
  async componentDidMount() {
    await this.init()
  }
  
  async init() {
    const { document, fetchDocument, match, dossier } = this.props
    const { documentId, dossierId } = match.params
    if (!document) {
      await fetchDocument(dossierId, documentId)
    }
     if (!dossier) {
       await fetchDossier(dossierId)
     }
  }

  revertTemplate(content: string) {
    return content.replace(/<span class="quill-variable(?: filled)?" data-field-name="([^"]+)" data-type="string">.?<span contenteditable="false">[^<]+<\/span>.?<\/span>/gi, (match: string, key1: string, key2: string) => {
         const key = key1 || key2;
      return `【${key}】`
    })
  }

  async handleSave() {
    const { document, updateDocument, updateDossier, match } = this.props;
    const {dossierId} = match.params;
    if (!document || this.state.content == undefined) {
      return;
    }
    await updateDocument(document.dossier_id || null, {
      _id: document._id,
      dossier_id: document.dossier_id,
      fileType: 'html',
      template: this.revertTemplate(this.state.content),
    });
    const {context} = this.state;
    if (context) {
      await updateDossier({
        _id: dossierId,
        context,
      })
    }
    this.setState({saved: true}, this.handleClose)
  }

  handleClose = () => {
    const { history } = this.props;
    history.goBack();
  }

  handleChangeText = (text: string) => {
    this.setState({content: text})
  }

  render() {
    const { classes, dossier, document, fetchDocument, match } = this.props
    const { documentId, dossierId } = match.params
    if (!document || !dossier) {
      return null;
    }
    const {context} = this.state;
    return (
      <ResponsiveDialog
        classes={{
          dialogContent: classes.dialogContent,
        }}
        title={document.name}
        onClose={this.handleClose}
        actions={
          <Button color="inherit" onClick={() => this.handleSave()}>
            {__('dialog.save')}
          </Button>
        }
        fullScreen
        open
        disableEscapeKeyDown
      >
        <Prompt
          message={"确认放弃编辑吗？"}
          when={!!(this.state.context || this.state.content && !this.state.saved)}
        />
        <DocumentEditor
          template={document.template || ''}
          context={context || dossier.context}
          onChangeContext={(ctx: KeyValueMap) => this.setState({context: ctx})}
          onChange={this.changeText}
          refetchContent={() => fetchDocument(dossierId, documentId)}
        />
      </ResponsiveDialog>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
  dossier: states.dossier.current,
  document: states.document.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchDocument: (dossierId, documentId) => dispatch(fetchDocument(dossierId, documentId)),
  fetchDossier: (dossierId) => dispatch(fetchDossier(dossierId)),
  updateDocument: (dossierId, document) => dispatch(updateDocument(dossierId, document)),
  updateDossier: (data) => dispatch(updateDossier(data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DocumentEditorDialog);
