import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CheckIcon from '@material-ui/icons/Check';
import MenuIcon from '@material-ui/icons/Menu';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InboxIcon from '@material-ui/icons/Inbox';
import ContactsIcon from '@material-ui/icons/Contacts';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsIcon from '@material-ui/icons/Notifications';
import UserIcon from '@material-ui/icons/AccountCircle';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import { mdiFileTree, mdiChatProcessing } from '@mdi/js';
import { Link, RouteComponentProps } from 'react-router-dom';
import { history } from '../../store/configureStore';

import { __ } from '../../utils/intl';
import { isFeatureEnabled } from '../../constants/experiment';
import { ChatThreadData } from '../../types/chat';
import { UserData } from '../../types/user';
import { DossierData, DossierListQuery } from '../../types/dossier';
import { ViewModes, ViewMode } from '../../types/view';
import { togglePanel } from '../../store/ui/actions';
import { PanelStatus, PanelSide } from '../../store/ui/reducers';
import { RootState } from '../../store/reducers';
import { getUnreadMessageCount } from '../../store/chat/reducers';
import { fetchListRemote } from '../../store/dossier/actions';
import { fetchCurrentUser, logoutCurrentUser } from '../../store/user/actions';
import { getUsername } from '../../utils/data';
import UserAvatar from '../Common/UserAvatar';
import NotificationPopup from '../Notification/NotificationPopup';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    '& a, a:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  titleContainer: {
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '64px',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '56px',
    },
  },
  dropdown: {
    flexGrow: 0,
  },
  logo: {
    width: 32,
    height: 32,
  },
  menuIcon: {
    opacity: 0,
  },
  userAvatar: {
    marginRight: 8,
  },
  selectedMenuIcon: {
    opacity: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      // display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  actions: {
    flexShrink: 0,
  },
});

const viewModeMenuTitles: { [key in ViewMode]: string} = {
  scroll: __('viewMode.scroll'),
  stacks: __('viewMode.stacks'),
  entities: __('viewMode.entities'),
  facts: __('viewMode.facts'),
  map: __('viewMode.map'),
  timeline: __('viewMode.timeline'),
};

interface StateProps {
  threadList: ChatThreadData[];
  unreadCount: number;
  panelStatus: PanelStatus;
  currentUser: UserData | null;
  dossier: DossierData | null;
  dossierList: DossierData [];
}

interface DispatchProps {
  togglePanel: (panel: PanelSide, status?: boolean) => void;
  fetchCurrentUser: () => Promise<void>;
  logoutCurrentUser: () => Promise<void>;
  fetchDossierList: (query: DossierListQuery) => Promise<DossierData[]>;
}

interface OwnProps {};

interface Params {
  dossierId: string
  viewMode?: string
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  userMenuAnchorEl: HTMLElement | null;
  mobileMoreAnchorEl: HTMLElement | null;
  viewModeMenuAnchorEl: HTMLElement | null;
  dossierListMenuAnchorEl: HTMLElement | null;
  notificationPopupAnchorEl: HTMLElement | null;
}

class TopNav extends React.Component<Props, State> {

  state: State = {
    userMenuAnchorEl: null,
    mobileMoreAnchorEl: null,
    viewModeMenuAnchorEl: null,
    dossierListMenuAnchorEl: null,
    notificationPopupAnchorEl: null,
  }

  componentDidMount() {
    const { fetchCurrentUser, fetchDossierList } = this.props;
    fetchCurrentUser();
    fetchDossierList({ type: 'recent' });
  }

  componentWillReceiveProps(nextProps: Readonly<Props>) {

  }
  
  handleGotoInbox = () => {
    const { location: { pathname } } = this.props;
    if (/\/inbox\b/.test(pathname)) return;
    if (pathname.includes('/dossier')) {
      history.push('/dossier/1/inbox');
    } else {
      history.push('/inbox');
    }
  }

  handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ userMenuAnchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    this.setState({
      userMenuAnchorEl: null,
      mobileMoreAnchorEl: null,
      viewModeMenuAnchorEl: null,
      dossierListMenuAnchorEl: null,
    });
  }

  handleViewModeMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      viewModeMenuAnchorEl: event.currentTarget,
    });
  }

  handleDossierListMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      dossierListMenuAnchorEl: event.currentTarget,
    });
  }

  handleNotificationPopupOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ notificationPopupAnchorEl: event.currentTarget });
  }

  handleNotificationPopupClose = () => {
    this.setState({ notificationPopupAnchorEl: null });
  }

  async handleLogout() {
    const { logoutCurrentUser } = this.props;
    this.handleMenuClose();
    logoutCurrentUser();
  }

  renderUserMenu() {
    const { classes, currentUser } = this.props;
    const { userMenuAnchorEl: anchorEl } = this.state;
    if (!currentUser) return null;
    return (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClick={this.handleMenuClose}
        onClose={this.handleMenuClose}
      >
        <MenuItem>
          <ListItemIcon>
            <UserAvatar user={currentUser} />
          </ListItemIcon>
          <Typography variant="inherit">{getUsername(currentUser)}</Typography>
        </MenuItem>
        <MenuItem onClick={() => history.push('/account/profile')}>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <Typography variant="inherit">{__('nav.profile')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => history.push('/account/settings')}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <Typography variant="inherit">{__('nav.settings')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => history.push('/account/about')}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <Typography variant="inherit">{__('nav.about')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => this.handleLogout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography variant="inherit">{__('nav.logout')}</Typography>
        </MenuItem>
      </Menu>
    );
  }

  renderViewModeMenu() {
    const { classes, location, match: { params: { dossierId }} } = this.props;
    const { viewModeMenuAnchorEl: anchorEl } = this.state;
    let viewMode: ViewMode = location.pathname.split('/').pop() as ViewMode || ViewMode.Scroll;
    if (!ViewModes.includes(viewMode)) {
      viewMode = ViewMode.Scroll;
    }
    return (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!dossierId && Boolean(anchorEl)}
        onClose={this.handleMenuClose}
      >
        {ViewModes
        .filter(mode => ['scroll', 'stacks'].includes(mode) || isFeatureEnabled('entityAnalysis'))
        .map(mode => (
          <MenuItem
            key={mode}
            onClick={() => {
              setTimeout(() => {
                history.push(`/dossier/${dossierId}/${mode}`);
              }, 0);
              this.handleMenuClose();
            }}
          >
            <ListItemIcon>
              <CheckIcon className={mode === viewMode ? classes.selectedMenuIcon : classes.menuIcon} />
            </ListItemIcon>
            <ListItemText>
              {viewModeMenuTitles[mode]}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    );
  }

  renderDossierListMenu() {
    const { classes, dossierList, history, dossier } = this.props;
    const { dossierListMenuAnchorEl: anchorEl } = this.state;
    return (
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClick={this.handleMenuClose}
        onClose={this.handleMenuClose}
      >
        {dossierList.map(item => (
          <MenuItem
            key={item._id}
            onClick={() => history.push(`/dossier/${item._id}`)}
          >
            <ListItemIcon>
              <CheckIcon className={(dossier && dossier._id === item._id) ? classes.selectedMenuIcon : classes.menuIcon} />
            </ListItemIcon>
            <Typography variant="inherit">{item.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    );
  }

  renderNotificationPopup() {
    const { notificationPopupAnchorEl } = this.state;
    return (
      <NotificationPopup
        open={Boolean(notificationPopupAnchorEl)}
        anchorEl={notificationPopupAnchorEl}
        onClose={this.handleNotificationPopupClose}
      />
    )
  }

  render() {
    const {
      classes,
      threadList,
      panelStatus,
      unreadCount,
      dossier,
      togglePanel,
      match: { params: { dossierId } },
    } = this.props;
    const { userMenuAnchorEl: anchorEl } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const isInDossier = !!dossierId;
    const unreadMessageCount = getUnreadMessageCount(threadList);
    return (
      <>
        <AppBar position="absolute" className={`top-nav ${classes.root}`}>
          <Toolbar>
            <Tooltip title={__('topNav.toggleMainMenu')}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  setTimeout(() => togglePanel('side', !panelStatus.side.open), 0);
                }}
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={__('nav.homepage')}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                aria-label="Home page"
                onClick={() => history.push('/')}
              >
                <img className={classes.logo} src={`${process.env.PUBLIC_URL}/images/logo-white.png`} />
              </IconButton>
            </Tooltip>
            {dossierId ?
              <>
                <Tooltip title={__('topNav.dossierList')}>
                  <IconButton
                    color="inherit"
                    className={classes.dropdown}
                    onClick={event => this.handleDossierListMenuOpen(event)}
                  >
                    <DropDownIcon />
                  </IconButton>
                </Tooltip>
                <Link to={`/dossier/${dossierId}`} className={classes.titleContainer}>
                  <Typography className={classes.title} variant="h6" color="inherit">
                    {dossier && dossier.name}
                  </Typography>
                </Link>
              </>
              :
              <Link to="/dashboard" className={classes.titleContainer}>
                <Typography className={classes.title} variant="h6" color="inherit">
                  {__('app.productName')}
                </Typography>
              </Link>
            }
            <div className={classNames(classes.search, classes.sectionDesktop)}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder={__('common.search')}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <div className={classes.actions}>
              {isInDossier &&
                <Tooltip title={__('topNav.changeViewMode')}>
                  <IconButton color="inherit" onClick={this.handleViewModeMenuOpen}>
                    <ViewArrayIcon />
                  </IconButton>
                </Tooltip>
              }
              {isFeatureEnabled('inboxManagement') &&
                <Tooltip title={__('topNav.inbox')}>
                  <IconButton color="inherit" onClick={this.handleGotoInbox}>
                    <Badge badgeContent={undefined} color="secondary">
                      <InboxIcon/>
                    </Badge>
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title={__('nav.chat')}>
                <IconButton color="inherit" onClick={() => togglePanel('chat')}>
                  <Badge badgeContent={unreadMessageCount || undefined} color="secondary">
                    <SimpleSvgIcon path={mdiChatProcessing} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={__('nav.notification')}>
                <IconButton color="inherit" onClick={this.handleNotificationPopupOpen}>
                  <Badge badgeContent={unreadCount} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={__('topNav.myAccount')}>
                <IconButton
                  edge="end"
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                >
                  <UserIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        
        {this.renderUserMenu()}
        {this.renderViewModeMenu()}
        {this.renderDossierListMenu()}
        {this.renderNotificationPopup()}
      </>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  threadList: states.chat.threadList,
  unreadCount: states.notification.unreadCount,
  panelStatus: states.ui.panelStatus,
  currentUser: states.user.current,
  dossier: states.dossier.current,
  dossierList: states.dossier.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  togglePanel: (panel, status) => dispatch(togglePanel(panel, status)),
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  logoutCurrentUser: () => dispatch(logoutCurrentUser()),
  fetchDossierList: (query) => dispatch(fetchListRemote(query)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TopNav);
