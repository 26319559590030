import _ from 'lodash';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import LockIcon from '@material-ui/icons/Lock';
import DevicesIcon from '@material-ui/icons/Devices'
import { Link, RouteComponentProps } from 'react-router-dom';

import { __, intl } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { UserData } from '../../types/user';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import LanguagePicker from './LanguagePicker';
import ChangePasswordDialog from './ChangePasswordDialog';
import TokenManageDialog from './TokenManageDialog';

const styles = (theme: Theme) => createStyles({
});

interface StateProps {
  currentUser: UserData | null;
}

interface DispatchProps {
}

interface OwnProps {};

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  languageSelectOpen: boolean;
  changePasswordDialogOpen: boolean;
  tokenManageDialogOpen: boolean;
}

class Settings extends React.Component<Props, State> {

  state: State = {
    languageSelectOpen: false,
    changePasswordDialogOpen: false,
    tokenManageDialogOpen: false,
  }

  render() {
    const { classes, history } = this.props;
    const {
      languageSelectOpen,
      changePasswordDialogOpen,
      tokenManageDialogOpen,
    } = this.state;
    return (
      <ResponsiveDialog
        open
        title={__('nav.settings')}
        actions={[]}
        onClose={() => history.push('/account')}
      >
        <ListItem
          button
          onClick={() => this.setState({ languageSelectOpen: true })}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText
            primary={__('settings.language')}
            secondary={__('intl.language')}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => this.setState({ changePasswordDialogOpen: true })}
        >
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText
            primary={__('settings.changePassword')}
            secondary={__('settings.changePasswordTip')}
          />
        </ListItem>
        <ListItem
          button
          onClick={() => this.setState({ tokenManageDialogOpen: true })}
        >
          <ListItemIcon>
            <DevicesIcon />
          </ListItemIcon>
          <ListItemText
            primary={__('settings.tokenManagement')}
            secondary={__('settings.tokenManagementTip')}
          />
        </ListItem>
        <LanguagePicker
          open={languageSelectOpen}
          onSelect={lang => intl.setLang(lang)}
          onClose={() => this.setState({ languageSelectOpen: false })}
        />
        <ChangePasswordDialog
          open={changePasswordDialogOpen}
          onClose={() => this.setState({ changePasswordDialogOpen: false })}
        />
        <TokenManageDialog
          open={tokenManageDialogOpen}
          onClose={() => this.setState({ tokenManageDialogOpen: false })}
        />
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Settings);
