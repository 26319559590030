import { Query, QueryResult, Localization } from 'material-table';
import { ListQueryOptions, ListQueryResult } from '../types/base';
import { __ } from './intl';

export function parseListQuery<T extends object>(query: Query<T>): ListQueryOptions {
  return {
    search: query.search,
    size: query.pageSize,
    from: query.page * query.pageSize,
    orderBy: query.orderBy ? query.orderBy.field : undefined,
    orderDirection: query.orderBy ? (query.orderDirection === 'asc' ? 1 : -1) : undefined,
  };
}

export function parseListQueryResult<T extends object>(result: ListQueryResult<T>): QueryResult<T> {
  return {
    data: result.data,
    page: Math.floor(result.from / result.size),
    totalCount: result.totalCount,
  };
}

export const localization: Localization = {
  body: {
    dateTimePickerLocalization: {},
    emptyDataSourceMessage: __('materialTable.body.emptyDataSourceMessage'),
    filterRow: {
      filterTooltip: __('materialTable.body.filterRow.filterTooltip'),
    },
    editRow: {
      saveTooltip: __('materialTable.body.editRow.saveTooltip'),
      cancelTooltip: __('materialTable.body.editRow.cancelTooltip'),
      deleteText: __('materialTable.body.editRow.deleteText'),
    },
    addTooltip: __('materialTable.body.addTooltip'),
    deleteTooltip: __('materialTable.body.deleteTooltip'),
    editTooltip: __('materialTable.body.editTooltip'),
  },
  header: {
    actions: __('materialTable.header.actions'),
  },
  grouping: {
    groupedBy: __('materialTable.grouping.groupedBy'),
    placeholder: __('materialTable.grouping.placeholder'),
  },
  pagination: {
    firstTooltip: __('materialTable.pagination.firstTooltip'),
    firstAriaLabel: __('materialTable.pagination.firstAriaLabel'),
    previousTooltip: __('materialTable.pagination.previousTooltip'),
    previousAriaLabel: __('materialTable.pagination.previousAriaLabel'),
    nextTooltip: __('materialTable.pagination.nextTooltip'),
    nextAriaLabel: __('materialTable.pagination.nextAriaLabel'),
    labelDisplayedRows: __('materialTable.pagination.labelDisplayedRows'),
    labelRowsPerPage: __('materialTable.pagination.labelRowsPerPage'),
    lastTooltip: __('materialTable.pagination.lastTooltip'),
    lastAriaLabel: __('materialTable.pagination.lastAriaLabel'),
    labelRowsSelect: __('materialTable.pagination.labelRowsSelect'),
  },
  toolbar: {
    addRemoveColumns: __('materialTable.toolbar.addRemoveColumns'),
    nRowsSelected: __('materialTable.toolbar.nRowsSelected'),
    showColumnsTitle: __('materialTable.toolbar.showColumnsTitle'),
    showColumnsAriaLabel: __('materialTable.toolbar.showColumnsAriaLabel'),
    exportTitle: __('materialTable.toolbar.exportTitle'),
    exportAriaLabel: __('materialTable.toolbar.exportAriaLabel'),
    exportName: __('materialTable.toolbar.exportName'),
    searchTooltip: __('materialTable.toolbar.searchTooltip'),
    searchPlaceholder: __('materialTable.toolbar.searchPlaceholder'),
  },
}
