import _ from 'lodash';
import { Action } from './actions';
import { ContactOrganizationData } from '../../types/contact.organization';

export interface OrganizationState {
  current: ContactOrganizationData | null;
  list: ContactOrganizationData[];
  loading: boolean;
  error?: Error;
}

const initialState: OrganizationState = {
  current: null,
  list: [],
  loading: false,
}

const dossierReducer = (state: OrganizationState = initialState, action: Action): OrganizationState => {
  switch (action.type) {
    case '@@organization/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@organization/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@organization/SET_LIST':
      return { ...state, list: action.list };

    case '@@organization/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }
    case '@@organization/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    case '@@organization/CREATE_ITEM_START':
      return { ...state, loading: true }
    case '@@organization/CREATE_ITEM_SUCCESS':
      return { ...state, loading: false, current: action.item }
    case '@@organization/CREATE_ITEM_FAILURE':
      return { ...state, loading: false, error: action.error}
    default:
      return state;
  }
}

export default dossierReducer;
