import { Parser } from 'html-to-react';
import jsonata from 'jsonata';
import _ from 'lodash';
import { FieldMap, TemplateContent } from '../types/template';
import { execCondition, getContextValue } from './condition';
import { KeyValueMap } from '../types/common';
import moment from 'moment';
import 'moment/locale/zh-cn'

moment.locale('zh-cn')
const htmlToReactParser = new Parser;

export function html2react(html: string): React.ReactNode {
  return htmlToReactParser.parse(html);
}

export type Path = Array<string | number>
type FieldName = string[]
export const fieldNameToPath = (fields: FieldName, context: any, map: FieldMap, path: Path = []) => {
  let ctx = context
  for (let i = 0; i < fields.length; i++) {
    const fieldStr = fields[i];
    const mapDef = map[fieldStr]
    if (!mapDef) {
      return [[...path, ...fields.slice(i)]]
      // continue;
    }
    switch (mapDef.type) {
      case 'basic':
        break;
      case 'switch':
        break;
      case 'query':
        ctx = jsonata(mapDef.path).evaluate(ctx)
        // console.log(ctx)
        if (ctx) {
          path = [...path, ...mapDef.path.replace(/\[.*?\]/g, '').split('.')]
          const curCtx = _.get(context, path.slice(0, i + 1).join('.'));
          if (ctx.sequence) {
            return ctx.map((c: any) => {
              return [...path, curCtx.indexOf(c), ...fieldNameToPath(fields.slice(i + 1), c, map)]
            })
          } else if (_.isArray(curCtx)) {
            path.push(curCtx.indexOf(ctx))
          }
        } else {

          const curCtx = _.get(context, path.slice(0, i + 1).join('.'));
          if (_.isArray(curCtx)) {
            // path.push(curCtx.length)
            return []
          }
          path = [...path, ...mapDef.path.replace(/\[.*?\]/g, '').split('.')]
        }
        break;
      default:
        break;
    }
  }
  return [path]
}

const QUILL_VAR_PATTERN = /<span class="quill-variable(?: filled)?" data-field-name="([^"]+)">[^<]+<\/span>|【(.+?)】/gi;

export function renderTemplate(template: string | null, map: FieldMap, context: any, plainText: boolean = false): string | null {
  if (!template) {
    return template;
  }
  return template.replace(QUILL_VAR_PATTERN, (match: string, key1: string, key2: string) => {
    const key = key1 || key2;
    const result = transformField(key, map, context);
    if (!result) {
      return match;
    }
    let text = result;
    if (_.isDate(text)) {
      text = moment(text).format('LL')
    }
    if (_.isArray(result)) {
      text = result.join('');
    }

    return plainText ? text : `<span class="quill-variable filled" data-field-name="${key}">${text}</span>`;
  });
}

export function renderPartialTemplate(template: TemplateContent, map: FieldMap, context: any): string | null {
  if (_.isString(template)) {
    if (/^#/.test(template)) {
      const id = template.substr(1);
      const t = map[id];
      if (t.type === 'query') {
        return '';
      } else {
        return renderPartialTemplate(t as any, map, context);
      }
    }
    return renderTemplate(template, map, context, true);
  } else if (_.isArray(template)) {
    return template.map(t => renderPartialTemplate(t, map, context)).join('');
  } else {
    switch (template.type) {
      case 'basic':
        return renderPartialTemplate(template.content, map, context);
      case 'import': {
        const t = transformField(template.key, map, context);
        return renderPartialTemplate(t, map, context);
      }
      case 'condition': {
        const result = execCondition(template.condition, [context], map);
        if (result) {
          return renderPartialTemplate(template.content, map, context);
        }
        break;
      }
      case 'iteration': {
        if (_.isArray(context)) {
          let result = '';
          context.forEach((part, i) => {
            result += renderPartialTemplate(template.content, map, part);
            if (template.separator && i < context.length - 1) {
              result += template.separator;
            }
          });
          return result;
        }
        break;
      }
      case 'switch': {
        const value = getContextValue([context], template.key, map);
        const c = template.cases.find(item => item.value === value);
        if (c) {
          return renderPartialTemplate(c.content, map, context);
        }
        break;
      }
    }
  }
  return '';
}

export function transformField(field: string, map: FieldMap = {}, context: any): any {
  const fields = field.split('+');
  const contexts = [context];

  for (let i = 0; i < fields.length; i++) {
    const part = fields[i];
    const rule = map[part];

    let lastContext = _.last(contexts);
    let value: any = null;

    if (!rule) {
      value = getValue(part, lastContext);
      contexts.push(value);
    } else {
      if (rule.type === 'query') {
        const expression = jsonata(rule.path);
        value = expression.evaluate(lastContext);
      } else {
        value = renderPartialTemplate(rule, map, lastContext);
      }
      contexts.push(value);
    }
  }

  return _.last(contexts);
}

function getValue(key: string, context: KeyValueMap<string>) {
  if (_.isArray(context)) {
    return _.map(context, key)
  }
  if (_.isObject(context)) {
    return context[key];
  }
  return context
}
