import React from 'react'
import { ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { MemberData, memberRoleLabels, memberStatusLabels } from '../../types/member'
import { getUsername } from '../../utils/data';
import UserAvatar from '../Common/UserAvatar';
import MemberStatusIcon from './MemberStatusIcon';

const styles = (theme: Theme) => createStyles({
  combinedIcon: {
    position: 'relative',
  },
  smallIcon: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: theme.spacing(2),
    height: theme.spacing(2),
    boxShadow: theme.shadows[1],
  },
});

interface OwnProps {
  selected?: boolean;
  member: MemberData;
  onClick?: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class MemberListItem extends React.Component<Props> {
  render() {
    const { classes, selected, member, onClick } = this.props;
    return (
      <ListItem
        component="div"
        button={(!!onClick) as any}
        selected={selected}
        onClick={onClick}
      >
        <ListItemIcon>
          <div className={classes.combinedIcon}>
            <UserAvatar user={member.user} />
            <Avatar className={classes.smallIcon} title={memberStatusLabels[member.status]}>
              <MemberStatusIcon status={member.status} fontSize="small" />
            </Avatar>
          </div>
        </ListItemIcon>
        <ListItemText
          primary={getUsername(member.user)}
          secondary={memberRoleLabels[member.role]}
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(MemberListItem);
