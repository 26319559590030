export enum ViewMode {
  Scroll = 'scroll',
  Stacks = 'stacks',
  Entities = 'entities',
  Facts = 'facts',
  Map = 'map',
  Timeline = 'timeline',
};

export const ViewModes: ViewMode[] = [
  ViewMode.Scroll,
  ViewMode.Stacks,
  ViewMode.Entities,
  ViewMode.Facts,
  ViewMode.Map,
  ViewMode.Timeline,
];
