import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { KeyValueMap } from '../../types/common';
import { FormItem } from '../../types/form';
import FormControlItem from './FormControlItem';
import { isFlatFormItem, getFormItemPath, parseReference } from './utils';
import { BaseFormControlProps } from './types';

const styles = (theme: Theme) => createStyles({
  form: {
    padding: 20,
  },
});

interface OwnProps extends BaseFormControlProps {
  className?: string;
  items: FormItem[];
  value: any;
  onChange: (values: any) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  errors: any;
}

class FormControl extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    scopes: [],
    items: [],
    path: '',
    pathFilters: null,
    value: {},
    readOnly: false,
    showEmpty: false,
    onChange: () => {},
  }

  handleFieldChange(item: FormItem, value: any) {
    const { value: values, onChange } = this.props;
    if (isFlatFormItem(item)) {
      onChange({
        ...values,
        ...value,
      });
    } else {
      const newValues = {
        ...values,
        [item.key]: value,
      };
      if (item.syncValueTo) {
        const oldValue = values[item.key];
        const targets = _.isString(item.syncValueTo) ? [item.syncValueTo] : item.syncValueTo;
        targets.forEach(key => {
          if (oldValue === values[key]) {
            newValues[key] = value;
          }
        });
      }
      onChange(newValues);
    }
  }

  render() {
    const {
      className,
      scopes,
      definitions,
      items,
      path,
      pathFilters,
      value:
      values,
      readOnly,
      showEmpty,
    } = this.props;
    const newScopes = [...scopes, values];
    return (
      <div className={classNames('form', className)}>
        {items.map((item, i) => {
          if (item.type === 'reference') {
            item = parseReference(definitions, item);
          }
          const value = isFlatFormItem(item) ? values : values[item.key];
          return (
            <FormControlItem
              definitions={definitions}
              scopes={newScopes}
              readOnly={readOnly}
              key={`${item.key}-${i}`}
              value={value}
              item={item}
              showEmpty={showEmpty}
              path={getFormItemPath(path, item)}
              pathFilters={pathFilters}
              onChange={value => this.handleFieldChange(item, value)}
            />
          );
        })}
      </div>
    );
  }
}

export default withStyles(styles)(FormControl);
