import clsx from 'clsx';
import React from 'react';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'none',
    flexDirection: 'column',
    '&.open': {
      display: 'flex',
    }
  },
});

interface OwnProps {
  open: boolean;
  className?: string
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {

}

class Panel extends React.Component<Props, State> {

  render() {
    const { classes, open, className, children } = this.props; 
    return (
      <div className={clsx(className, classes.root, { open })}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Panel);
