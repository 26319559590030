import React from 'react';
import { Breadcrumbs, Paper, Link, Typography } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import HomeIcon from '@material-ui/icons/Home';
import { TreeItem } from '../../types/tree';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(1, 2),
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
});

interface OwnProps {
  path: TreeItem[];
  onItemClick: (item: TreeItem, index: number) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {

}

class FolderBreadcrumbs extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    path: [],
    onItemClick: () => {},
  }

  render() {
    const { classes, path, onItemClick } = this.props;
    return (
      <Breadcrumbs
        className={classes.root}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="Breadcrumb"
      >
        {path.map((item, i) => {
          const Icon = item.type === 'folder' ? FolderIcon : FileIcon;
          if (i < path.length - 1) {
            return (
              <Link
                key={item._id}
                className={classes.link}
                color="inherit"
                onClick={() => onItemClick(item, i)}
              >
                <Icon className={classes.icon} />
                {item.name}
              </Link>
            );
          } else {
            return (
              <Typography key={item._id} color="textPrimary" className={classes.link}>
                <Icon className={classes.icon} />
                {item.name}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    )
  }
}

export default withStyles(styles)(FolderBreadcrumbs);
