import _ from 'lodash';
import { Action } from './actions';
import { MemberData } from '../../types/member';

export interface MemberState {
  current: MemberData | null;
  list: MemberData[];
  error?: Error;
}

const initialState: MemberState = {
  current: null,
  list: [],
}

const memberReducer = (state: MemberState = initialState, action: Action): MemberState => {
  switch (action.type) {
    case '@@member/SET_CURRENT_ITEM': {
      return { ...state, current: action.item };
    }
    case '@@member/CLEAR_CURRENT_ITEM': {
      return { ...state, current: null };
    }
    case '@@member/SET_LIST': {
      return { ...state, list: action.list };
    }
    case '@@member/UPDATE_LIST_ITEM': {
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list };
    }
    case '@@member/REMOVE_LIST_ITEM': {
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    }
    default:
      return state;
  }
}

export default memberReducer;
