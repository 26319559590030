import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { PageContent, DocumentData } from '../../types/document';
import { ScrollViewContext } from './context';
import DocumentPage from './DocumentPage';

export interface DocumentPageProps {
  container?: string;
  className?: string;
  document: DocumentData | null;
  sectionName: string;
  pageName: string;
  actions: React.ReactNode;
  page: PageContent | null;
  pageNumber: string | number | null;
  onDownload?: () => void;
  onEdit?: () => void;
}

class DocumentPageContainer extends React.Component<DocumentPageProps> {

  render() {
    return (
      <ScrollViewContext.Consumer>
        {({ zoom }) => (
          <DocumentPage {...this.props} zoom={zoom} />
        )}
      </ScrollViewContext.Consumer>
    );
  }

}

export default DocumentPageContainer;
