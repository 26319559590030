import './style.css'
import _ from 'lodash'
// import moment from 'moment'
// import faker from 'faker'
import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import Layout from '../Common/Layout'
// import * as d3 from 'd3'

const fakedata = [
  {
    title: 'Plaintiff’s Home',
    desc: 'Purchased Tickets for skiing',
    position: { lat: 34.293430, lng: -109.805138 },
    date: 'Mar 13, 2017 8:00 AM',
  },

  {
    title: 'Defendant’s premises',
    desc: 'Boarded defendant’s premises',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: 'Mar 13, 2017 11:00 AM',
    children: [
      {
        title: 'Defendant’s premises',
        desc: 'Skis fell to the ground',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 11:30 AM',
      },

      {
        title: 'Defendant’s premises',
        desc: 'Began Waving arms and yelling',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 11:35AM',
      },

      {
        title: 'Defendant’s premises',
        desc: 'Standing at the observation window',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 11:36 AM',
      },

      {
        title: 'Defendant’s premises',
        desc: 'The lift operator did not stop the lift',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 11:37AM',
      },

      {
        title: 'Defendant’s premises',
        desc: 'Fell under the platform',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 11:40 AM',
      },

      {
        title: 'Defendant’s premises',
        desc: 'Carried plaintiff down the mountain on a sled',
        position: { lat: 33.9106043, lng: -109.5872813 },
        date: 'Mar 13, 2017 12:30 PM',
      },
    ]
  },

  {
    title: 'White Mountain Hospital',
    desc: 'Sent plaintiff to hospital',
    position: { lat: 34.131646, lng: -109.2878447, },
    date: 'Mar 13, 2017 12:30 PM',
  },
]

export default class MapComp extends Component {
  componentWillUnmount() {
    if (!this.infos) return
    this.infos.forEach(({ maps, mevt, marker }) => {
      maps.event.removeListener(mevt)
    })
    console.log('unmount map comp')
  }

  render() {
    const data = this.props.data || fakedata
    return (
      <Layout>
        <div style={{ height: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBSGIYbJVgo01hqGwYNNzju6HZIGfzr2vA' }}
            defaultCenter={{ lat: 45.715, lng: 126.622 }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              const buildCard = item => `<div class='info-card' style='width: 240px; margin-bottom: 16px;'>
                <div class='title'>${item.title}</div>
                <div class='desc'>${item.desc}</div>
                <div class='date'>${item.date}</div>
              </div>`

              this.infos = _.map(data, ({ position, title, desc, date, children }) => {
                let content

                if (children) {
                  content = `<div'>
                    ${children.map(({ position, title, desc, date }) => buildCard({ position, title, desc, date })).join('')}
                  </div>`
                } else {
                  content = buildCard({ position, title, desc, date })
                }

                const card = new maps.InfoWindow({ content })

                const marker = new maps.Marker({ map, position, title })

                const mevt = marker.addListener('click', function () {
                  if (card.status) {
                    card.close(map, marker)
                    card.status = false
                  } else {
                    card.open(map, marker)
                    card.status = true
                  }
                })

                card.open(map, marker)
                card.status = true

                return {
                  maps,
                  mevt,
                  card,
                  marker,
                }
              })

              const lineSymbol = {
                path: maps.SymbolPath.FORWARD_OPEN_ARROW,
                scale: 8,
                strokeColor: '#e7443c'
              }

              const flightPlanCoordinates = _.map(data, 'position')

              const flightPath = new maps.Polyline({
                path: flightPlanCoordinates,
                geodesic: true,
                strokeColor: '#38445d',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                icons: [{
                  icon: lineSymbol,
                  offset: '100%'
                }],
              })

              flightPath.setMap(map)

              animateCircle(flightPath)

              function animateCircle(line) {
                let count = 0
                let icons = line.get('icons')
                window.setInterval(function () {
                  count = (count + 1) % 200
                  icons[0].offset = (count / 2) + '%'
                  line.set('icons', icons)
                }, 20)
              }
            }}
          >

          </GoogleMapReact>
        </div>
      </Layout>
    )
  }
}
