import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { Button, IconButton } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import _ from 'lodash';

import { DossierData, DossierTreeItem } from '../../types/dossier';
import { DocumentData, hasFlag, hasFlagIteratee } from '../../types/document';
import { RootState } from '../../store/reducers';
import { fetchItemRemote, getCachedItem as getCachedDocument, removeListItem as removeDocumentFromList } from '../../store/document/actions';
import { __ } from '../../utils/intl';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import EvidenceEditor from './EvidenceView'
import EvidenceSidebar from './EvidenceSidebar';
import { find, findItemAndPath } from '../../utils/tree';

interface StateProps {
  dossier: DossierData | null,
  document: DocumentData | null,
}

interface DispatchProps {
  // fetchListRemote: (dossierId: string) => void;
  // fetchDocument: (documentId: string) => void;
  fetchItemRemote: (dossierId: string, documentId: string) => void;
}

interface OwnProps {

}

interface State {
  docSidebarOpen: boolean
}

const styles = (theme: Theme) => createStyles({
  dialogContent: {
    padding: 0,
  },
});

interface Params {
  documentId: string;
  dossierId: string;
}

export type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

class EvidenceViewDialog extends React.Component<Props, State> {

  state = {
    docSidebarOpen: true,
  }

  componentDidMount() {
    const {document, fetchItemRemote, match} = this.props
    const {documentId, dossierId } = match.params
    if (!document) {
      fetchItemRemote(dossierId, documentId)
    }
  }

  async handleSave() {
    const { document } = this.props;
    if (!document) {
      return;
    }
    // await updateItemRemote({
    //   _id: document._id,
    //   dossier_id: document.dossier_id,
    //   fileType: 'html',
    //   content: this.state.content,
    // });
    this.handleClose();
  }

  handleFetchDocument = async () => {
    const { dossier, document, fetchItemRemote } = this.props;
    if (!dossier || !document) {
      return 
    }
    const { item: evidence, path: eviPath } = findItemAndPath(dossier.tree, {_id: document._id})
    if (!evidence || !eviPath) {
      return;
    }
    const clientFolder = eviPath.reverse().find((item: DossierTreeItem) => {
      return item.type === 'folder' && hasFlag(item, 'evidence');
    })
    if (!clientFolder) {
      return;
    }
    const catalog = find(clientFolder, hasFlagIteratee('catalog'))
    if (catalog) {
      await fetchItemRemote(dossier._id, catalog._id);
    }
  }

  handleClose = async () => {
    const {history} = this.props;
    await this.handleFetchDocument()
    history.goBack()
  }

  handleToggleDocSidebar = () => {
    this.setState({docSidebarOpen: !this.state.docSidebarOpen})
  }

  render() {
    const { classes, dossier, document, match } = this.props;
    const { documentId, dossierId } = match.params;
    const { docSidebarOpen } = this.state;
    if (!dossier || !document) {
      return null;
    }
    return (
      <ResponsiveDialog
        classes={{
          dialogContent: classes.dialogContent,
        }}
        fullScreen
        open={true}
        title={document.name}
        onClose={this.handleClose}
        actions={
          <IconButton
            color="inherit"
            onClick={this.handleToggleDocSidebar}
          >
            <MenuIcon />
          </IconButton>
        }
      >
        <EvidenceEditor document={document} />
        <EvidenceSidebar
          align='right'
          title="文件信息"
          className=""
          dossier={dossier}
          document={document}
          open={docSidebarOpen}
          dossierId={dossierId}
        />
      </ResponsiveDialog>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
  dossier: states.dossier.current,
  document: states.document.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchItemRemote: (dossierId, documentId) => dispatch(fetchItemRemote(dossierId, documentId)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(EvidenceViewDialog);
