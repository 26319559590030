import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  mdiFileImage,
  mdiFilePdf,
  mdiFileWord,
  mdiFile,
} from '@mdi/js';

import { KeyValueMap } from '../../types/common';
import { DocumentBase } from '../../types/document';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import {
  red,
  teal,
  lightBlue,
  grey,
} from '@material-ui/core/colors';

interface IconProps {
  path: string;
  color: string;
}

const iconMap: KeyValueMap<IconProps> = {
  pdf: { path: mdiFilePdf, color: red[700] },
  'image-list': { path: mdiFileImage, color: teal[200] },
  html: { path: mdiFileWord, color: lightBlue[700] },
  empty: { path: mdiFile, color: grey[500] },
}

interface Props extends SvgIconProps {
  document: DocumentBase;
}

const DocumentIcon: React.SFC<Props> = (props) => {
  const { document, ...restProps } = props;
  const icon = iconMap[document.fileType] || iconMap.empty;
  return (
    <SimpleSvgIcon
      htmlColor={icon.color}
      path={icon.path}
      {...restProps}
    />
  );
}

export default DocumentIcon;
