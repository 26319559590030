import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { PersonFormItem, FormItem, GroupFormItem } from '../../types/form';
import { __ } from '../../utils/intl';
import GroupControl from './GroupControl';
import { BaseFormControlProps } from './types';

const fields: FormItem[] = [
  { key: 'name', label: __('profile.field.name'), type: 'text' },
  // { key: 'firstName', label: __('profile.field.firstName'), type: 'text' },
  // { key: 'lastName', label: __('profile.field.lastName'), type: 'text' },
  {
    key: 'sex', label: __('profile.field.sex'), type: 'select',
    options: [
      { label: __('profile.sex.male'), value: 'M' },
      { label: __('profile.sex.female'), value: 'F' },
    ],
  },
  { key: 'birthday', label: __('profile.field.birthday'), type: 'date' },
  { key: 'ethnic', label: __('profile.field.ethnic'), type: 'text' },
  { key: 'phone', label: __('profile.field.phone'), type: 'text' },
  // { key: 'mobilePhone', label: __('profile.field.mobilePhone'), type: 'text' },
  // { key: 'officePhone', label: __('profile.field.officePhone'), type: 'text' },
  { key: 'organization', label: __('profile.field.organization'), type: 'text' },
  { key: 'jobTitle', label: __('profile.field.jobTitle'), type: 'text' },
  { key: 'province', label: __('profile.field.province'), type: 'text' },
  { key: 'city', label: __('profile.field.city'), type: 'text' },
  { key: 'address', label: __('profile.field.address'), type: 'text' },
];

const styles = (theme: Theme) => createStyles({
});

interface OwnProps extends BaseFormControlProps {
  item: PersonFormItem;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class PersonControl extends React.Component<Props, State> {

  render() {
    const { classes, item, ...restProps } = this.props;
    let items = fields;
    if (item.items) {
      items = [...fields, ...item.items];
    }
    return (
      <GroupControl
        className="person-control"
        item={{
          ...item,
          type: 'group',
          items,
        }}
        {...restProps}
      />
    )
  }

}

export default withStyles(styles)(PersonControl);
