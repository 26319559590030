import _ from 'lodash';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';

import { __ } from '../../utils/intl';
import { ClientError } from '../../store/client';
import { MemberData } from '../../types/member';
import { parseValidationError } from '../../utils/error-parser';
import { TeamData } from '../../types/team';
import { getFullName } from '../../utils/data';


const styles = (theme: Theme) => createStyles({
  dialogContent: {
    width: 320,
    height: 300,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  selectionIcon: {
    marginRight: theme.spacing(1),
  }
});

interface CustomizeOptions {
  selectLabel?: string;
  selectTip?: string;
}

interface OwnProps {
  open: boolean;
  members?: MemberData[];
  onClose: () => void;
  onSave: (type: TransferOptionType['type'], id: string, password: string) => Promise<void>;
  teams?: TeamData[];
  customize?: CustomizeOptions
}

export interface TransferOptionType {
  value: string;
  label: string;
  type: 'team' | 'user'
}

type Props = OwnProps & WithStyles<typeof styles>;

interface Errors {
  selected: string;
  password: string;
}

interface Form {
  selected: TransferOptionType | null;
  password: string;
}

interface State extends Form {
  errors: Errors;
}

class TransferDialog extends React.Component<Props> {

  state: State = this.getInitialState();

  getInitialState(): State {
    return {
      selected: null,
      password: '',
      errors: {
        selected: '',
        password: '',
      },
    }
  }

  parseError(err: any): Partial<State> | null {
    if (err instanceof ClientError && err.code === 'validation_error') {
      return {
        errors: {
          ...this.state.errors,
          ...parseValidationError(err) as any,
        },
      };
    }
    return null;
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  handleChange<K extends keyof Form>(key: K, value: Form[K]) {
    const { errors } = this.state;
    this.setState({
      [key]: value,
      errors: {
        ...errors,
        [key]: '',
      },
    });
  }
  
  async handleSave() {
    const { onClose, onSave } = this.props;
    const { selected, password } = this.state;
    if (!selected || !password) {
      let { errors } = this.state;
      if (!selected) {
        errors = { ...errors, selected: __('team.transfer.errors.targetUserRequired') };
      }
      if (!password) {
        errors = { ...errors, password: __('errors.passwordRequired') };
      }
      this.setState({ errors });
      return;
    };
    try {
      await onSave(selected.type, selected.value, password);
      onClose();
    } catch (err) {
      const newState = this.parseError(err);
      if (newState) {
        this.setState(newState);
      }
    }
  }

  renderOption = (option: TransferOptionType) => {
    return (
      <>
        <span className={this.props.classes.selectionIcon}>
          {option.type === 'team' && <GroupIcon/>}
          {option.type === 'user' && <PersonIcon/>}
        </span>
        {option.label}
      </>
    )
  }

  render() {
    const { classes, open, members, teams, onClose } = this.props;
    const { selected, password, errors } = this.state;
    const options:TransferOptionType[] = []
    if (teams) {
      teams.forEach(t => {
        options.push({type: 'team', label: t.name, value: t._id})
      })
    }
    if (members) {
      members.forEach(m => {
        const name = m.user && getFullName(m.user.profile) || '';
        options.push({type: 'user', label: name, value: m.user_id})
      })
    }
    const customize = this.props.customize || {}
    
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{__('team.transfer')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Autocomplete
            options={options}
            getOptionLabel={option => option.label}
            onChange={(event: React.ChangeEvent<{}>, value: TransferOptionType) => this.handleChange('selected', value)}
            value={selected}
            renderOption={this.renderOption}
            renderInput={params => (
              <TextField {...params} label={customize.selectLabel || __('memberSelect.label')} fullWidth />
            )}
          />
          <FormHelperText error={!!errors.selected}>
            {errors.selected || __('team.transfer.selectMemberTip')}
          </FormHelperText>
          <TextField
            fullWidth
            className={classes.formControl}
            label={__('account.password')}
            type="password"
            value={password}
            onChange={(event) => this.handleChange('password', event.target.value)}
            error={!!errors.password}
            helperText={errors.password || __('account.passwordRequiredTip')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{__('dialog.cancel')}</Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!this.state.selected}
            onClick={() => this.handleSave()}
          >
            {__('team.transfer.button')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

export default withStyles(styles)(TransferDialog);
