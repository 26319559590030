import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button, Typography, IconButton } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { ContactPersonData } from '../../types/contact.person';
import { OrganizationProfile, ContactOrganizationData } from '../../types/contact.organization';
import { fetchListRemote as fetchPersonListRemote } from '../../store/person/actions';
import {
  fetchItemRemote,
  updateItemRemote,
  createItemRemote,
  removeItemRemote,
} from '../../store/organization/actions';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import OrganizationForm from './OrganizationForm';
import PersonListPartial from './PersonListPartial';

const styles = (theme: Theme) => createStyles({
  relatedList: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
});

interface StateProps {
  current: ContactOrganizationData | null;
  personList: ContactPersonData[];
}

interface DispatchProps {
  fetchPersonListRemote: () => Promise<void>,
  fetchItemRemote: (id: string) => Promise<void>,
  updateItemRemote: (id: string, data: Partial<ContactOrganizationData>) => Promise<void>,
  createItemRemote: (data: Partial<ContactOrganizationData>) => Promise<void>,
  removeItemRemote: (id: string) => Promise<void>,
}

interface OwnProps {};

interface Params {
  organizationId?: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  editing: boolean;
  data: OrganizationProfile;
}

class OrganizationEditor extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      editing: !props.match.params.organizationId,
      data: this.updateData(props),
    };
  }

  componentDidMount() {
    const { fetchItemRemote, fetchPersonListRemote, match: { params: { organizationId }} } = this.props;
    if (!organizationId) return;
    fetchItemRemote(organizationId);
    fetchPersonListRemote();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.current !== this.props.current) {
      this.setState({
        editing: !nextProps.match.params.organizationId,
        data: this.updateData(nextProps),
      });
    }
  }

  updateData(props: Props) {
    if (!props.match.params.organizationId) {
      return {};
    }
    return props.current ? props.current.profile : {};
  }

  handleEdit() {
    this.setState({
      editing: true,
    });
  }

  handleCancel() {
    this.setState({
      editing: false,
      data: this.updateData(this.props),
    });
  }

  handleDelete() {
    const { removeItemRemote, history, match: { params: { organizationId }} } = this.props;
    if (!organizationId) return;
    this.setState({
      editing: false,
    }, async () => {
      await removeItemRemote(organizationId);
      history.push('/contacts/organization');
    });
  }

  handleSave() {
    const {
      updateItemRemote,
      createItemRemote,
      history,
      match: { params: { organizationId } },
    } = this.props;
    const { data } = this.state;
    this.setState({
      editing: false,
    }, async () => {
      if (organizationId) {
        updateItemRemote(organizationId, { profile: data });
      } else {
        await createItemRemote({ profile: data });
        history.push('/contacts/organization');
      }
    });
  }

  render() {
    const { classes, current, personList, history, match: { params: { organizationId }} } = this.props;
    const { editing, data } = this.state;
    let relatedPersonList: ContactPersonData[] = [];
    if (current) {
      relatedPersonList = personList.filter(item => item.organization_ids.includes(current._id));
    }
    return (
      <ResponsiveDialog
        open
        hasBackButton
        title={organizationId ? __('contacts.organization.detail') : __('contacts.organization.create')}
        actions={editing?
          <>
            {organizationId &&
              <>
                <Button
                  color="inherit"
                  onClick={() => this.handleCancel()}
                >
                  {__('dialog.cancel')}
                </Button>
                <Button
                  color="inherit"
                  onClick={() => this.handleDelete()}
                >
                  {__('dialog.delete')}
                </Button>
              </>
            }
            <Button
              color="inherit"
              onClick={() => this.handleSave()}
            >
              {__('dialog.save')}
            </Button>
          </>
          :
          <Button
            color="inherit"
            onClick={() => this.handleEdit()}
          >
            {__('dialog.edit')}
          </Button>
        }
        onClose={() => history.push('/contacts/organization')}
      >
        <OrganizationForm
          title={__('nav.profile')}
          readOnly={!editing}
          data={data}
          onChange={data => this.setState({ data })}
        />
        {!editing &&
          <PersonListPartial
            className={classes.relatedList}
            title={__('contacts.organization.relatedPersons')}
            list={relatedPersonList}
          />
        }
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  current: states.organization.current,
  personList: states.person.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchPersonListRemote: () => dispatch(fetchPersonListRemote()),
  fetchItemRemote: (id: string) => dispatch(fetchItemRemote(id)),
  updateItemRemote: (id: string, data: Partial<ContactOrganizationData>) => dispatch(updateItemRemote(id, data)),
  createItemRemote: (data: Partial<ContactOrganizationData>) => dispatch(createItemRemote(data)),
  removeItemRemote: (id: string) => dispatch(removeItemRemote(id)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(OrganizationEditor);
