import _ from 'lodash';
import classNames from 'classnames';
import React, { Component, ErrorInfo } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtil from '@date-io/moment';

import './App.scss';
import './styles/common.scss';
import { __ } from './utils/intl';
import { TreeItem } from './types/tree';
import { store, history } from './store';
import Dashboard from './components/Dashboard/Dashboard';
import Dossier from './components/Dossier/Dossier';
import Login from './components/Account/Login';
import SignUp from './components/Account/SignUp';
import Inbox from './components/StacksView/Inbox';
import Account from './components/Account/Account';
import TeamIndex from './components/Team/TeamIndex';
import InvitationIndex from './components/Invitation/InvitationIndex';
import TemplateEditor from './components/Template/Index';
import Contacts from './components/Contacts/Contacts';
import Playground from './components/Playground/Playground';
import AdminPanel from './components/AdminPanel/Index';
import theme from './theme';
import './storex';

const pageDefaultTitle = __('page.defaultTitle');

type Props = {};

interface State {
}

class App extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
    }
  }

  handleTreeItemSelect(item: TreeItem) {
    const pageElement = document.getElementById(item.name);
    if (!pageElement) return;
    pageElement.scrollIntoView();
  }

  componentDidCatch(err: Error, info: ErrorInfo) {
    console.log(err, info);
  }

  
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <MuiPickersUtilsProvider utils={MomentUtil}>
                <CssBaseline />
                <div className="App">
                  <Switch>
                    <Route path="/index.html" component={Dashboard} />
                    <Route path="/admin" component={AdminPanel} />
                    <Route path="/login" component={Login} />
                    <Route path="/account" component={Account} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/sign-up" component={SignUp} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/team" component={TeamIndex} />
                    <Route path="/invitation" component={InvitationIndex} />
                    <Route path="/dossier/:dossierId/:viewMode?" component={Dossier} />
                    <Route path="/inbox" component={Inbox} />
                    <Route path="/template" component={TemplateEditor} />
                    <Route path="/playground" render={() => (<Playground />)} />
                    <Route
                      path="/"
                      render={({location}) => {
                        if (location.pathname === '/') {
                          return <Redirect to="/dashboard" />
                        } else {
                          return null;
                        }
                      }}
                    />
                  </Switch>
                </div>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
