import _ from 'lodash';
import { Action } from './actions';
import { DocumentData } from '../../types/document';
import { TreeItem } from '../../types/tree';
import {
  updateTreeItem,
  deleteTreeItem,
  updateTreeItemChildIndex,
  deleteTreeItems,
} from '../../utils/tree';

export interface DocumentState {
  current: DocumentData | null;
  list: DocumentData[];
  tree: TreeItem | null;
}

const initialState: DocumentState = {
  current: null,
  list: [],
  tree: null,
}

const documentReducer = (state: DocumentState = initialState, action: Action): DocumentState => {
  switch (action.type) {
    case '@@document/SET_CURRENT_ITEM':
      return { ...state, current: action.item };

    case '@@document/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@document/SET_LIST':
      return { ...state, list: action.list };
    
    case '@@document/MERGE_LIST':
      return { ...state, list: _.intersectionBy(state.list, action.list, '_id') };

    case '@@document/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }

    case '@@document/DELETE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
      
    case '@@document/SET_TREE':
      return { ...state, tree: action.tree };

    case '@@document/UPDATE_TREE_ITEM':
      return { ...state, tree: updateTreeItem(action.path, action.item) };

    case '@@document/DELETE_TREE_ITEM':
      return { ...state, tree: deleteTreeItem(action.path, action.item) };

    case '@@document/DELETE_TREE_ITEMS':
      if (!state.tree) return state;
      return { ...state, tree: deleteTreeItems(state.tree, action.items) };

    case '@@document/UPDATE_TREE_ITEM_CHILD_INDEX':
      return { ...state, tree: updateTreeItemChildIndex(action.path, action.item, action.movement) };

    default:
      return state;
  }
}

export default documentReducer;
