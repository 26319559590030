import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import FormControl from '../Form/FormControl';
import { baseForm, partyFormItem } from './info';
import classes from '*.module.scss';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 500,
    margin: '0 auto',
  },
  content: {

  },
});

interface OwnProps {
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  value: any;
}

class Playground extends React.Component<Props, State> {

  state = {
    value: {},
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        <FormControl
          items={baseForm}
          value={value}
          onChange={newValue => this.setState({ value: newValue })}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Playground);
