import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import { TreeItem } from '../../types/tree';
import { RootState } from '../../store/reducers';

import { ViewMode } from '../../types/view';
import StacksView from './StacksView';
import { fetchTreeRemote, updateTreeItem, deleteTreeItems, updateTreeItemChildIndex } from '../../store/document/actions';
import Layout from '../Common/Layout';

interface StateProps {
  tree: TreeItem | null;
}

interface DispatchProps {
  fetchTreeRemote: (dossierId: string | null) => void;
  updateTreeItem: (path: TreeItem[], item: TreeItem) => void;
  deleteTreeItems: (items: TreeItem[]) => void;
  updateTreeItemChildIndex: (path: TreeItem[], item: TreeItem, movement: number) => void;
}

interface OwnProps {
  dossierId?: string;
}

interface Params {
  viewMode?: ViewMode;
  dossierId?: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params>;

interface State {
}

class InBox extends React.Component<Props, State> {

  state: State = {
    viewMode: ViewMode.Scroll,
  }

  componentDidMount() {
    const { fetchTreeRemote, dossierId } = this.props;
    fetchTreeRemote(dossierId || null);
  }

  render() {
    const { tree, updateTreeItem, deleteTreeItems, updateTreeItemChildIndex } = this.props;
    if (!tree) return null;
    return (
      <Layout>
        <StacksView
          id="inbox"
          tree={tree}
          selectedItem={null}
          onSelectedItemChange={() => {}}
          onUpdate={updateTreeItem}
          onDelete={deleteTreeItems}
          onMove={updateTreeItemChildIndex}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  tree: states.document.tree,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchTreeRemote: (dossierId: string | null) => dispatch(fetchTreeRemote(dossierId)),
  updateTreeItem: (path: TreeItem[], item: TreeItem) => dispatch(updateTreeItem(path, item)),
  deleteTreeItems: (items: TreeItem[]) => dispatch(deleteTreeItems(items)),
  updateTreeItemChildIndex: (path: TreeItem[], item: TreeItem, movement: number) => dispatch(updateTreeItemChildIndex(path, item, movement)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(InBox);
