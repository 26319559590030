import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __, intl } from '../../utils/intl';
import ResponsiveDialog from './ResponsiveDialog';

const styles = (theme: Theme) => createStyles({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
});

interface OwnProps {
  open: boolean;
  title: string;
  url: string;
  onClose: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface States {

}

class PopupPage extends React.Component<Props, States> {

  render() {
    const { classes, open, title, url, onClose } = this.props;
    // const language = intl.language;
    const language = 'zh-CN';
    return (
      <ResponsiveDialog
        fullScreen
        withoutContentContainer
        open={open}
        title={title}
        actions={[]}
        onClose={onClose}
      >
        <iframe
          src={url}
          className={classes.iframe}
        />
      </ResponsiveDialog>
    );
  }
}


export default withStyles(styles)(PopupPage);
