import _ from 'lodash';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import classNames from 'classnames';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import DataTable, { Column, ColumnRenderer } from '../Common/DataTable';
import { FactData, EntityReference, Activity } from '../../types/fact';

import { RootState } from '../../store/reducers';
import { fetchListRemote } from '../../store/fact/actions';
import { RouteComponentProps } from 'react-router';

const entityLinkRender: ColumnRenderer = (value: EntityReference) => 'demo';
const activityRenderer: ColumnRenderer = (activity: Activity) => (
  <div>
    <strong>{activity.type}</strong>
    <div>{activity.content}</div>
  </div>
);

const columns: (Column<FactData>)[] = [
  { field: 'who', label: 'Who', renderer: entityLinkRender },
  { field: 'where', label: 'Where', renderer: entityLinkRender },
  { field: 'when', label: 'Birthdate', renderer: entityLinkRender },
  { field: 'activity', label: 'Activity', renderer: activityRenderer },
  { field: 'claims', label: 'Claims' },
  { field: 'evidence', label: 'Evidence' },
  { field: 'note', label: 'Note' },
];

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
  },
  toolbar: {
    flex: '0 0 40px',
    margin: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {

  },
});

interface StateProps {
  list: FactData[] | null
}

interface DispatchProps {
  fetchListRemote: (dossierId: string) => void;
}
interface OwnProps {

}

interface Params {
  dossierId?: string
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
}

class EntityView extends React.Component<Props, State> {

  rootElement: HTMLElement | null = null;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      tabValue: 'person',
    };
  }

  componentDidMount() {
    const { fetchListRemote, list, match } = this.props;
    if (!list && match.params.dossierId) {
      fetchListRemote(match.params.dossierId);
    }
  }


  render() {
    const { classes, list } = this.props;
    return (
      <div
        ref={ref => this.rootElement = ref}
        className={classes.root}
      >
        <div className={classes.table}>
          <DataTable
            columns={columns}
            data={list || []}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  list: states.fact.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchListRemote: (dossierId) => dispatch(fetchListRemote(dossierId)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(EntityView);
