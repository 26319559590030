import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Paper,
  withMobileDialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import ArrowBackButton from '@material-ui/icons/ArrowBack';
import CenterBox from './CenterBox';

const styles = (theme: Theme) => createStyles({
  container: {
    
  },
  dialogContent: {

  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backButton: {
    marginLeft: -theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(4),
  },
});

export interface OwnProps extends DialogProps {
  fullScreen?: boolean;
  hasBackButton?: boolean;
  withoutContentContainer?: boolean;
  modal?: boolean;
  title: string;
  actions: React.ReactNode;
  onClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

interface State {}


class ResponsiveDialog extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    title: '',
    fullScreen: false,
    modal: false,
  }

  render() {
    const {
      classes,
      fullScreen,
      className,
      modal,
      hasBackButton,
      withoutContentContainer,
      title,
      actions,
      children,
      onClose,
      ...dialogProps
    } = this.props;
    if (fullScreen) {
      return (
        <Dialog
          className={clsx(className, { fullscreen: fullScreen})}
          fullScreen={fullScreen}
          onClose={onClose}
          {...dialogProps}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onClose} aria-label="Close">
                <ArrowBackButton />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.title}>
                {title}
              </Typography>
              {actions}
            </Toolbar>
          </AppBar>
          {withoutContentContainer ? children :
            <DialogContent className={classes.dialogContent}>
              {children}
            </DialogContent>
          }
        </Dialog>
      );
    } else if (modal) {
      return (
        <Dialog
          className={clsx(className, { fullscreen: fullScreen})}
          fullScreen={fullScreen}
          onClose={onClose}
          {...dialogProps}
        >
          <DialogTitle>
            {title}
          </DialogTitle>
          {withoutContentContainer ? children :
            <DialogContent className={classes.dialogContent}>
              {children}
            </DialogContent>
          }
          <DialogActions>
            {actions}
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <CenterBox paper className={clsx(className, classes.container, { fullscreen: fullScreen })}>
          <Typography variant="h6">
            {hasBackButton &&
              <IconButton
                className={classes.backButton}
                onClick={onClose}
              >
                <ArrowBackButton />
              </IconButton>
            }
            {title}
          </Typography>
          {children}
          <div className={classes.actions}>
            {actions}
          </div>
        </CenterBox>
      );
    }
  }
}

export default withStyles(styles)(withMobileDialog<Props>({ breakpoint: 'sm' })(ResponsiveDialog));
