import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { SelectOption } from '../../types/form';

const styles = (theme: Theme) => createStyles({
  control: {
  },
  label: {

  }
});

export type RadioOption = SelectOption;

interface OwnProps {
  className?: string;
  options: RadioOption[];
  label: string;
  value: string;
  onChange: (value: string) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class RadioSelect extends React.Component<Props> {

  handleChange(value: string) {
    const { onChange } = this.props;
    onChange(value);
  }

  render() {
    const { classes, className, options, label, value: selectedValue, onChange } = this.props;
    return (
      <FormControl
        fullWidth
        className={classNames(className, classes.control)}
      >
        <FormLabel className={classNames(classes.label, 'MuiInputLabel-shrink')}>{label}</FormLabel>
        <RadioGroup
          value={selectedValue}
          onChange={(event, value) => this.handleChange(value)}
        >
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio />}
              label={label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }
}

export default withStyles(styles)(RadioSelect);
