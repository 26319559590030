import _ from 'lodash';
import { SelectOption } from '../types/form';

export async function getJSON<T = any>(url: string): Promise<T> {
  const response = await fetch(url);
  let data: T;
  try {
    data = await response.json();
  } catch(error) {
    throw error;
  }
  if (response.status >= 400) {
    throw new Error('invalid json response');
  }
  return data;
}

export function getSelectOptions(result: any): SelectOption[] {
  if (!result || !_.isArray(result.hits)) {
    throw new TypeError('invalid search result');
  }
  return _.map(result.hits, item => {
    const value = _.get(item, '_source.value');
    return {
      value,
      label: value,
    };
  });
}
