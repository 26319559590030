import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Markup } from 'interweave';
import Quill, { RangeStatic } from 'quill';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Button, IconButton, Dialog, TextField, DialogTitle, DialogActions } from '@material-ui/core';
import _ from 'lodash';

import InsertVariable from '@material-ui/icons/FontDownload'

import QuillVariable, { QuillVariableContext } from './QuillVariable'
import FormRightPanel from './FormRightPanel'
import { fetchItemRemote as fetchDossier } from '../../store/dossier/actions'
import { fetchItemRemote as fetchDossierTemplate } from '../../store/dossierTemplate/actions'

import './Document.scss'
import './DocumentEditor.quill.snow.scss'
import './DocumentEditor.scss'

Quill.register({ 'formats/variable': QuillVariable })

import {
  PAPER_WIDTH,
  PAPER_HEIGHT,
  PAPER_PADDING_LEFT,
  PAPER_PADDING_RIGHT,
  PAPER_HEADER_HEIGHT,
  PAPER_FOOTER_HEIGHT,
} from '../Dossier/constants';
import { KeyValueMap } from '../../types/common';
import { DossierData } from '../../types/dossier';
import { RootState } from '../../store/reducers';
import client, { ClientError } from '../../store/client';
import Confirm from '../Common/Confirm';
import { __ } from '../../utils/intl';
import MultiSelect from '../Form/MultipleSelect';
import { SelectOption } from '../../types/form';
import { DocumentData } from '../../types/document';
import classNames from 'classnames';
import { renderTemplate } from '../../utils/template';
import { DossierTemplateData } from '../../types/dossier.template';


const TOOLBAR_HEIGHT = 40;
const styles = (theme: Theme) => createStyles({
  page: {
    width: PAPER_WIDTH,
    minHeight: PAPER_HEIGHT,
    margin: `${theme.spacing(4)}px auto`,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[6],
    [theme.breakpoints.down(PAPER_WIDTH)]: {
      width: '100%'
    },
  },
  select: {
    minWidth: 400,
  },
  labeledBtn: {
    width: 'auto !important'
  },
  wrapper: {
    height: `calc(100vh - ${(64 + TOOLBAR_HEIGHT)}px )`,
    transition: 'width 0.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - ${(56 + TOOLBAR_HEIGHT)}px )`
    },
    '& .ql-editor': {
      paddingTop: PAPER_HEADER_HEIGHT,
      paddingBottom: PAPER_FOOTER_HEIGHT,
      paddingLeft: PAPER_PADDING_LEFT,
      paddingRight: PAPER_PADDING_RIGHT,
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      }
    }
  }
})

interface OwnProps {
  template: string;
  onChange: (text: string) => void;
  onChangeContext: (ctx: KeyValueMap) => void;
  refetchContent: () => void;
  context: KeyValueMap
}


interface StateProps {
  dossier: DossierData | null;
  document: DocumentData | null;
  dossierTemplate: DossierTemplateData | null;
  text: string | null;
}

interface DispatchProps {
  fetchDossier: (id: string) => void
  fetchDossierTemplate: (id: string) => void
}

type Props = OwnProps & WithStyles<typeof styles> & DispatchProps & StateProps;



interface State {
  context?: KeyValueMap
  formPanelPinned: boolean
  contextFormOpen: boolean;
  contextFormFields: string[];
  varInsertOpen: boolean;
  varValue?: SelectOption;
  panelWidth: number;
}

interface IQuillVariable {
  target: HTMLElement
  context: QuillVariableContext
}

class DocumentEditor extends React.Component<Props, State> {
  
  quill!: Quill;
  range!: RangeStatic;
  editor!: HTMLDivElement | null;
  variable!: IQuillVariable
  
  constructor(props: Props) {
    super(props)
    this.state = {
      contextFormOpen: false,
      contextFormFields: [],
      varInsertOpen: false,
      formPanelPinned: false,
      panelWidth: 300,
    }
  }

  async componentDidMount() {
    if (!this.props.dossierTemplate) {
      const {dossier} = this.props;
      if (dossier) {
        await this.props.fetchDossierTemplate(dossier.dossier_template_id)
      }
    }
    setTimeout(() => {
      this.init();
    }, 0);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.template !== this.props.template) {
      const {template} = nextProps;
      const contents = this.quill.clipboard.convert(template);
      this.quill.setContents(contents);
    }
  }

  init() {
    const { template, onChange } = this.props;
    if (!this.editor) {
      return;
    }
    const quill = new Quill(this.editor, {
      theme: 'snow',
      modules: {
        // Equivalent to { toolbar: { container: '#toolbar' }}
        // toolbar: toolbarOptions
        toolbar: '#toolbar'
      }
    });
    quill.on('text-change', (delta, oldDelta, source) => {
      onChange(quill.root.innerHTML);
    })
    quill.on('click-variable', ({ context, target }: { target: HTMLElement, context: QuillVariableContext }) => {
      this.setState({ contextFormOpen: true, contextFormFields: [context.fieldName] })
      this.variable = { target, context }
    })
    this.quill = quill;
  }
  
  handleUnlink() {
    const {target, context} = this.variable
    const selection = this.quill.getSelection()
    if (selection) {
      target.remove()
      this.quill.insertText(selection.index, context.value || context.fieldName)
    } 
  }


  handleSaveContext = async (context?: KeyValueMap) => {
    if (context) {
      const {dossier, onChangeContext} = this.props;
      if (!dossier) {
        return;
      }
      onChangeContext(context)
    }

    this.setState({ contextFormOpen: false })
  }

  handleOpenInsertDialog = () => {
    const editor = this.quill;
    const range = editor.getSelection()
    if (!range) {
      return;
    }
    this.range = range;
    if (range.length) {
      // editor.deleteText(range.index, range.length)
    }
    // const varname = prompt("Select Variable Name", "variable");
    this.setState({varInsertOpen: true, varValue: undefined})
  }

  handleInsertVar = () => {
    const {varValue} = this.state
    if (varValue) {
      const variable = varValue
      this.quill.insertEmbed(this.range.index, 'variable', {
        value: variable.value,
        fieldName: variable.value,
        type: 'string',
      })
    }
    this.setState({varInsertOpen: false})
  }

  buildOptions = () => {
    const {dossier} = this.props;
    if (!dossier) {
      return
    }
    return dossier.variables.map((v) => ({
      label: v,
      value: v,
    }));
  }

  handleRenderTemplate = () => {
    const {template, dossier, dossierTemplate} = this.props
    if (!dossierTemplate || !dossier) {
      return null
    }
    const result = renderTemplate(template, dossierTemplate.map, dossier.context)
    return result
  }

  render() {
    const { classes, template, dossier, context } = this.props;
    const { contextFormOpen, contextFormFields, varInsertOpen, varValue, formPanelPinned, panelWidth } = this.state;
    if (!dossier) {
      return null
    }
    const variableOptions = this.buildOptions()
    const resizeStyle = {
      width: `calc(100vw - ${formPanelPinned && contextFormOpen ? panelWidth : 0}px)`
    }
    // console.log(this.props.text)
    return (
      <div className={classNames(["editor-wrapper", classes.wrapper])} style={resizeStyle}>
        <div id="toolbar">
          <span className="ql-formats">
            <button className="ql-bold" title="加粗"></button>
            <button className="ql-italic" title="斜体"></button>
            <button className="ql-underline" title="下划线"></button>
            <button className="ql-strike" title="删除线"></button>
          </span>
          <span className="ql-formats">
            <button className="ql-list" value="ordered" title="排序列表"></button>
            <button className="ql-list" value="bullet" title="无序列表"></button>
          </span>
          <span className="ql-formats">
            <button className="ql-indent" value="-1" title="向左缩进"></button>
            <button className="ql-indent" value="+1" title="向右缩进"></button>
          </span>
          <span className="ql-formats">
            <select className="ql-header" defaultValue="0">
              <option value="1">标题 1</option>
              <option value="2">标题 2</option>
              <option value="3">标题 3</option>
              <option value="4">标题 4</option>
              <option value="0">普通文本</option>
            </select>
          </span>
          <span className="ql-formats">
            <select className="ql-color" title="颜色"></select>
            <select className="ql-background" title="背景"></select>
          </span>
          <span className="ql-formats">
            <select className="ql-font">
              <option value="serif">宋体</option>
              <option value="monospace">黑体</option>
            </select>
          </span>
          <span className="ql-formats">
            <select className="ql-align" title="对齐"></select>
          </span>
          <span className="ql-formats">
            <button className="ql-clean" title="清除样式"></button>
          </span>
          <span className="ql-formats">
            <Button className={classes.labeledBtn} onClick={this.handleOpenInsertDialog}>
              <InsertVariable fontSize="small"  />
                {__('document.insertVar')}
            </Button>
          </span>
        </div>
        <div ref={ref => this.editor = ref} className={`${classes.page} page-content`} dangerouslySetInnerHTML={{ __html: this.props.text }} />
        <FormRightPanel
          context={context}
          open={contextFormOpen}
          onChange={this.handleSaveContext}
          onClose={() => this.setState({ contextFormOpen: false })}
          fields={contextFormFields}
          onPin={() => this.setState({formPanelPinned: !formPanelPinned})}
          onUnlink={() => this.handleUnlink()}
          onWidthChange={(width) => this.setState({panelWidth: width})}
          pinned={formPanelPinned}
        />
        <Confirm
          open={varInsertOpen}
          title={__('document.insertVar')}
          content={
            <>
              <MultiSelect isMulti={false} value={varValue} options={variableOptions} onChange={(value) => this.setState({varValue: value})} className={classes.select} />
            </>
          }
          confirmButtonText={__('dialog.ok')}
          onClose={() => this.setState({ varInsertOpen: false })}
          onConfirm={() => this.handleInsertVar()}
        />
      </div>
    )
  }
}


const mapStateToProps = (states: RootState): StateProps => ({
  dossier: states.dossier.current,
  document: states.document.current,
  dossierTemplate: states.dossierTemplate.current,
  text: renderTemplate(_.get(states, 'document.current.template', ''), _.get(states, 'dossierTemplate.current.map', {}), _.get(states, 'dossier.current.context', {}))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchDossier: (dossierId) => dispatch(fetchDossier(dossierId)),
  fetchDossierTemplate: (id) => dispatch(fetchDossierTemplate(id))
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DocumentEditor);
