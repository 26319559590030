import './tl.css'

// import _ from 'lodash'
import moment from 'moment'
import * as d3 from 'd3'

export default (container, data, { startAt, endAt }) => {
  const containerWidth = container.getBoundingClientRect().width
  const containerHeight = container.getBoundingClientRect().height
  // const timelineHeight = 4

  startAt = moment(new Date(startAt)).subtract(3, 'hours')
  endAt = moment(new Date(endAt)).add(3, 'hours')

  const svg = d3.select(container)
    .append('svg')
    .style('width', '100%')
    .style('height', containerHeight)
    .style('position', 'absolute')
    .style('background-color', '#f5f5f5')
    .append('g')

  // const circleShadow = svg
  //   .append('defs')
  //   .append('filter')
  //   .attr('id', 'circle-shadow')
  //   .append('feDropShadow')
  //   .attr('dx', '.5')
  //   .attr('dy', '1')
  //   .attr('stdDeviation', '1')

  const float = d3.select(container)
    .append('div')
    .style('overflow', 'hidden')
    .style('width', '100%')
    .style('height', containerHeight + 'px')
    .style('pointer-events', 'none')
    .style('position', 'relative')

  const timeScale = d3.scaleTime()
    .domain([startAt, endAt])
    .range([0, containerWidth])

  const xAxis = d3
    .axisTop(timeScale)
    .tickSize(0)
    .tickPadding(0)

  const xScaleGroup = svg
    .append('g')
    .attr('class', 'xscale-group')

  const xAxisGroup = xScaleGroup
    .append('g')
    .attr('class', 'xaxis-group')
    .call(xAxis)
    .call(g => g.select('.domain').attr('opacity', 0))

  const xDomain = d3.select('.domain')

  const timelineGroup = xScaleGroup
    .append('g')
    .attr('class', 'timeline-group')
    .style('transform', 'translateY(32px)')

  // const timelineBgGroup = timelineGroup
  //   .append('g')
  //   .attr('class', 'timeline-bg-group')

  // const timelineBgRect = timelineBgGroup
  //   .append('rect')
  //   .attr('width', containerWidth)
  //   .attr('height', timelineHeight)
  //   .attr('fill', '#39455e')

  const eventGroup = timelineGroup
    .selectAll('.event-group')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'event-group')
    .style('transform', d => `translate(${timeScale(d.startAt)}px, 0px)`)

  // const eventCircle = eventGroup
  //   .append('circle')
  //   .attr('r', 16)
  //   .attr('fill', '#6ab5ba')
  //   .attr('stroke', 'white')
  //   .attr('stroke-width', 2)
  //   .attr('filter', 'url(#circle-shadow)')
  //   .each(function (d) {
  //     d.circle = this
  //   })

  const xScaleGroupHeight = xScaleGroup.node().getBBox().height

  xScaleGroup
    .style('transform', `translate(0px, ${containerHeight / 2 - xScaleGroupHeight / 2}px)`)

  const eventRectGroup = eventGroup
    .append('g')
    .attr('class', 'event-rect-group')

  // const eventRect = eventRectGroup
  //   .append('rect')
  //   .attr('width', 240)
  //   .attr('height', 160)
  //   .attr('fill', ({ color }) => color)
  //   .each(function (d) {
  //     d.rect = this
  //   })

  eventRectGroup
    .style('transform', function (d, i) {
      if ((i + 1) % 2) {
        return `translate(-${240 / 2}px, ${80}px)`
      } else {
        return `translate(-${240 / 2}px, -${160 + 80}px)`
      }
    })

  // const line = eventGroup
  //   .append('line')
  //   .lower()
  //   .attr('stroke', '#aed9d6')
  //   .attr('stroke-width', 2)
  //   .attr('y0', 0)
  //   .attr('x0', 0)
  //   .attr('x1', 0)
  //   .attr('y1', (d, i) => {
  //     if ((i + 1) % 2) {
  //       return 180
  //     } else {
  //       return -180
  //     }
  //   })

  const eventLayer = float
    .selectAll('div')
    .data(data)
    .enter()
    .append('div')
    .style('pointer-events', 'auto')
    .style('overflow', 'hidden')
    .style('position', 'absolute')
    .style('width', '240px')
    .style('height', '160px')
    .style('background-color', ({ color }) => color)
    .style('border', '2px solid #add9d6')
    .style('transform', d => {
      const { x, y } = d.rect.getBoundingClientRect()
      return `translate(${x}px, ${y - 64}px)`
    })

  // const eventLayerLabel = eventLayer
  //   .append('div')
  //   .style('font-size', '16px')
  //   .style('font-weight', 'bold')
  //   .style('background-color', '#add9d6')
  //   .style('padding', '8px')
  //   .text(({ label }) => label)

  // const eventLayerDesc = eventLayer
  //   .append('div')
  //   .style('font-size', '14px')
  //   .style('font-weight', 400)
  //   .style('padding', '8px')
  //   .text(({ desc }) => desc)

  // const eventLayerDate = eventLayer
  //   .append('div')
  //   .style('font-size', '16px')
  //   .style('font-weight', 'bold')
  //   .style('padding', '8px')
  //   .text(({ date }) => moment(date, 'M, D, YYYY hh:ss a').format('YYYY.M.D hh:ss a'))

  eventLayer.on('mouseenter', function (d) {
    d3
      .select(this)
      .raise()
      .transition(50)
      .style('transform', d => {
        const { x, y } = d.rect.getBoundingClientRect()
        return `translate(${x}px, ${y - 64}px) scale(1.5)`
      })
    d3.select(d.circle.parentNode).raise()
  })

  eventLayer.on('mouseleave', function () {
    d3
      .select(this)
      .transition(50)
      .style('transform', d => {
        const { x, y } = d.rect.getBoundingClientRect()
        return `translate(${x}px, ${y - 64}px) scale(1)`
      })
  })

  const zoom = d3.zoom()
    // .scaleExtent([1, 32])
    .translateExtent([[0, 0], [containerWidth, containerHeight]])
    .extent([[0, 0], [containerWidth, containerHeight]])
    .on('zoom', onZoom)

  d3.select(svg.node().parentNode).call(zoom)
    .on('dblclick.zoom', null)

  function onZoom() {
    xDomain.attr('opacity', 0)

    var t = d3.event.transform, xt = t.rescaleX(timeScale)

    xAxisGroup.call(xAxis.scale(xt))

    eventGroup
      .style('transform', ({ startAt }) => `translateX(${xt(startAt)}px)`)

    eventLayer
      .style('transform', d => {
        const { x, y } = d.rect.getBoundingClientRect()
        return `translate(${x}px, ${y - 64}px) scale(1)`
      })
  }
}
