import _ from 'lodash';
import { Action } from './actions';
import { ServerInfo } from '../../types/info';

export interface InfoState {
  info: ServerInfo | null;
}

const initialState: InfoState = {
  info: null,
}

const documentReducer = (state: InfoState = initialState, action: Action): InfoState => {
  switch (action.type) {
    case '@@document/SET_SERVER_INFO':
      return { ...state, info: action.info };

    default:
      return state;
  }
}

export default documentReducer;
