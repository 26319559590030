import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { List, ListSubheader } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { DossierData, DossierListQuery } from '../../types/dossier';
import { fetchListRemote } from '../../store/dossier/actions';
import DossierListItem from './DossierListItem';

const styles = (theme: Theme) => createStyles({
});

interface StateProps {
  list: DossierData[];
}

interface DispatchProps {
  fetchListRemote: (query: DossierListQuery) => Promise<DossierData[]>;
}

interface OwnProps {
  query?: DossierListQuery;
  title?: string;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

interface State {
}

class DossierList extends React.Component<Props, State> {

  componentDidMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!_.isEqual(nextProps.query, this.props.query)) {
      this.loadData(nextProps);
    }
  }

  loadData(props: Props) {
    const { query, fetchListRemote } = props
    fetchListRemote(query || {});
  }

  render() {
    const { list, title } = this.props;
    return (
      <List
        className="file-list"
        subheader={title ? <ListSubheader className="list-header">{title}</ListSubheader> : undefined}
      >
        {list.map(item => (
          <DossierListItem key={item._id} item={item} />
        ))}
      </List>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  list: states.dossier.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchListRemote: (query) => dispatch(fetchListRemote(query)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DossierList);
