import React from 'react';
import { Route } from 'react-router';
import { Tabs, Tab, IconButton, SvgIcon, ClickAwayListener, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Remove';
import { mdiPin, mdiPinOff } from '@mdi/js';

import { __ } from '../../utils/intl';
import { TreeItem } from '../../types/tree';
import { DossierData } from '../../types/dossier';
import { SinglePanelStatus, LeftPanelType } from '../../store/ui/reducers';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import ExportPanel from './ExportDialog';
import ExplorerPanel from './ExplorerPanel';
import InfoPanel from './InfoPanel';
import TeamPanel from './TeamPanel';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  header: {
    display: 'flex',
    backgroundColor: '#e0e0e0',
  },
  tabs: {
    flexGrow: 1,
  },
  topActions: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  tab: {
    minWidth: 60,
  },
  tabContent: {
    flexGrow: 1,
    overflow: 'auto',
  },
});

interface OwnProps {
  status: SinglePanelStatus<LeftPanelType>;
  dossier: DossierData;
  onPin: () => void;
  onChange: (key: LeftPanelType) => void;
  onClose: () => void;
  selectedItem: TreeItem | null;
  onSelect: (item: TreeItem) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {

}

class LeftPanel extends React.Component<Props, State> {

  renderContent() {
    const {
      status,
      dossier,
      selectedItem,
      onSelect,
    } = this.props; 
    return (
      <>
        <ExplorerPanel
          open={status.key === 'tree'}
          tree={dossier.tree}
          selectedItem={selectedItem}
          onSelect={onSelect}
        />
        <TeamPanel
          open={status.key === 'team'}
        />
        <Route
          render={routeProps => (
            <InfoPanel
              {...routeProps}
              open={status.key === 'info'}
            />
          )}
        />
      </>
    );
  }

  handleClickAway() {
    const { status, onClose } = this.props;
    if (status.open && !status.pinned) {
      onClose();
    }
  }

  render() {
    const {
      classes,
      status,
      onPin,
      onClose,
      onChange,
    } = this.props;
    return (
      <>
        <header className={classes.header}>
          <Tabs
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
            value={status.key}
            onChange={(event, key) => onChange(key)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab className={classes.tab} label={__('panels.tree.title')} value="tree" />
            <Tab className={classes.tab} label={__('panels.team.title')} value="team" />
            <Tab className={classes.tab} label={__('panels.info.title')} value="info" />
          </Tabs>
          <div className={classes.topActions}>
            <Tooltip title={__('panels.pin')}>
              <IconButton size="small" onClick={onPin}>
                <SimpleSvgIcon path={status.pinned ? mdiPin : mdiPinOff} />
              </IconButton>
            </Tooltip>
            <Tooltip title={__('panels.close')}>
              <IconButton size="small" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </header>
        <div className={classes.tabContent}>
          {this.renderContent()}
        </div>
      </>
    )
  }
}

export default withStyles(styles)(LeftPanel);
