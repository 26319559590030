import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface Props extends SvgIconProps {
  path: string;
}

const SimpleSvgIcon: React.SFC<Props> = (props) => {
  const { path, ...restProps } = props;
  return (
    <SvgIcon {...restProps}>
      <path d={path} />
    </SvgIcon>
  );
}

export default SimpleSvgIcon;
