import clsx from 'clsx';
import React from 'react'
import { ListItem, ListItemIcon, ListItemText, Box, ListItemSecondaryAction, Badge, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { history } from '../../store';

const styles = (theme: Theme) => createStyles({
  root: {
    overflow: 'hidden',
  },
  shrink: {
    height: 48,
  },
  selected: {
    color: '#38445e',
  },
  minifiedIconWrapper: {
    minWidth: 24,
    paddingTop: 4,
    paddingBottom: 4,
  },
});

interface OwnProps {
  title: string;
  subTitle?: string;
  minified?: boolean;
  icon: React.ReactElement;
  badge?: React.ReactElement;
  selected?: boolean;
  link?: string;
  exact?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class SimpleListItem extends React.Component<Props> {

  handleClick = (event: React.MouseEvent) => {
    const { link, onClick } = this.props;
    if (link) {
      history.push(link);
    }
    if (onClick) {
      onClick(event);
    }
  }

  getSelected() {
    const { link, exact } = this.props;
    const { pathname } = history.location;
    if (link) {
      return exact ? pathname === link : pathname.indexOf(link) === 0;
    }
    return false;
  }

  render() {
    const { classes, title, subTitle, minified, selected = this.getSelected(), icon, badge } = this.props;
    return (
      <Tooltip title={minified ? title : ''} placement="right">
        <ListItem
          button
          className={clsx(classes.root, {
            [classes.selected]: selected,
            [classes.shrink]: !subTitle,
          })}
          selected={selected}
          onClick={this.handleClick}
        >
          {minified ?
            <ListItemIcon className={classes.minifiedIconWrapper}>
              <Badge badgeContent={badge}>
                {icon}
              </Badge>
            </ListItemIcon>
            :
            <>
              {icon &&
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
              }
              <ListItemText
                primary={title}
                secondary={subTitle}
              />
              {badge &&
                <ListItemSecondaryAction>
                  {badge}
                </ListItemSecondaryAction>
              }
            </>
          }
        </ListItem>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(SimpleListItem);
