import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import Loading from '../Common/Loading';
import Layout from '../Common/Layout';
import AdminDossierTemplates from './AdminDossierTemplates';
import AdminLogs from './AdminLogs';
import AdminPanelIndex from './AdminPanelIndex';
import AdminUserDetail from './AdminUserDetail';
import AdminUsers from './AdminUsers';
import AdminTeamDetail from './AdminTeamDetail';
import AdminTeams from './AdminTeams';

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

interface StateProps {
  user: UserData | null;
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
  onCreateTeam: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithStyles<typeof styles>;

interface State {
}

class Index extends React.Component<Props, State> {
  render() {
    const { user } = this.props;
    return (
      <Layout>
        {!user || !user.isAdmin ?
          <Loading />
          :
          <Switch>
            <Route path="/admin" exact component={AdminPanelIndex} />
            <Route path="/admin/users" exact component={AdminUsers} />
            <Route path="/admin/users/view/:userId" exact component={AdminUserDetail} />
            <Route path="/admin/teams" exact component={AdminTeams} />
            <Route path="/admin/teams/view/:teamId" exact component={AdminTeamDetail} />
            <Route path="/admin/dossier-templates" exact component={AdminDossierTemplates} />
            <Route path="/admin/administrators" exact render={routeProps => <AdminUsers adminOnly {...routeProps} />} />
            <Route path="/admin/logs" exact component={AdminLogs} />
          </Switch>
        }
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  user: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Index);
