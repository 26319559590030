import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { Action } from './types';
import createRootReducer, { RootState } from './reducers';

export const history = createBrowserHistory();

export default function configureStore(preloadedState: any): Store<RootState, Action> {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
      ),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (a: any) => a,
    ),
  );

  return store;
}
