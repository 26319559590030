import _ from 'lodash';
import React from 'react';
import { Condition } from '../../types/form';
import { execCondition } from '../../utils/condition';

type Control = React.ReactNode;
type ControlRenderer = () => Control;

interface Props {
  scopes: any[];
  condition?: Condition;
  render?: ControlRenderer;
  component?: Control;
}

class FormCondition extends React.Component<Props> {

  render() {
    const { scopes, condition, render, component } = this.props;
    const result = execCondition(condition, scopes);
    if (result) {
      if (component) {
        return component;
      } else if (render) {
        if (!_.isFunction(render)) {
          throw new TypeError('render must be a function returns React Element');
        }
        return render();
      }
    }
    return null;
  }
}

export default FormCondition;
