// https://github.com/loadingio/css-spinner

import React from 'react';

import './Loading.scss';
import clsx from 'clsx';

interface Props {
  className?: string;
  color?: string;
  type?: 'facebook' | 'ring';
  onLoad?: () => void;
}

const Loading: React.SFC<Props> = (props) => {
  const { className, color, type } = props;
  let style: React.CSSProperties | undefined = undefined;
  switch (type) {
    case 'facebook':
      style = {
        backgroundColor: color,
      };
      break;
    case 'ring':
      style = {
        borderTopColor: color,
      };
      break;
  }
  return (
    <div ref={props.onLoad} className={clsx('loading', `lds-${type}`, className)}>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
    </div>
  );
}

Loading.defaultProps = {
  color: '#999',
  type: 'ring',
}

export default Loading;
