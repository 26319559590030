import _ from 'lodash';
import { intl } from '../utils/intl';
import { KeyValueMap } from '../types/common';
import { DemoData } from './types';

const demoData: KeyValueMap<DemoData> = {
  'zh-CN': require('./zh-CN').default,
  'en': require('./en').default,
}

export function getDemoData() {
  const lang = intl.language;
  return demoData[lang];
} 
