import _ from 'lodash';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { UserData, UserProfile } from '../../types/user';
import { fetchCurrentUser, updateUserProfileRemote } from '../../store/user/actions';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import PersonForm from '../Contacts/PersonForm';

const styles = (theme: Theme) => createStyles({
  
});

interface StateProps {
  currentUser: UserData | null;
}

interface DispatchProps {
  fetchCurrentUser: () => void,
  updateUserProfileRemote: (data: UserProfile) => void,
}

interface OwnProps {};

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  editing: boolean;
  data: UserProfile;
}

class Profile extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      data: this.updateData(props),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.currentUser !== this.props.currentUser) {
      this.setState({
        data: this.updateData(nextProps),
      });
    }
  }

  updateData(props: Props) {
    return props.currentUser ? props.currentUser.profile : {};
  }

  handleEdit() {
    this.setState({
      editing: true,
    });
  }

  handleCancel() {
    this.setState({
      editing: false,
      data: this.updateData(this.props),
    });
  }

  handleSave() {
    const { updateUserProfileRemote } = this.props;
    const { data } = this.state;
    this.setState({
      editing: false,
    }, () => {
      updateUserProfileRemote(data);
    });
  }

  render() {
    const { classes, history, currentUser } = this.props;
    const { editing, data } = this.state;
    return (
      <ResponsiveDialog
        open
        title={__('nav.profile')}
        actions={editing?
          <>
            <Button
              color="inherit"
              onClick={() => this.handleCancel()}
            >
              {__('dialog.cancel')}
            </Button>
            <Button
              color="inherit"
              onClick={() => this.handleSave()}
            >
              {__('dialog.save')}
            </Button>
          </>
          :
          <Button
            color="inherit"
            onClick={() => this.handleEdit()}
          >
            {__('dialog.edit')}
          </Button>
        }
        onClose={() => history.push('/account')}
      >
        <PersonForm
          title={''}
          readOnly={!editing}
          data={data}
          onChange={data => this.setState({ data })}
        />
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  updateUserProfileRemote: (data: UserProfile) => dispatch(updateUserProfileRemote(data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Profile);
