import _ from 'lodash';
import { Action } from './actions';
import { TeamData } from '../../types/team';

export interface TeamState {
  current: TeamData | null;
  list: TeamData[];
  error?: Error;
}

const initialState: TeamState = {
  current: null,
  list: [],
}

const dossierReducer = (state: TeamState = initialState, action: Action): TeamState => {
  switch (action.type) {
    case '@@team/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@team/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@team/SET_LIST':
      return { ...state, list: action.list };

    case '@@team/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }
    case '@@team/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    default:
      return state;
  }
}

export default dossierReducer;
