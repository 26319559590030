import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import {
  TextField,
  FormControl,
  InputLabel,
  NativeSelect,
  Checkbox,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { FormItem, SelectOption } from '../../types/form';
import { BaseFormControlProps } from './types';
import { shouldFormItemShow, buildSelectOptions } from './utils';
import MultipleSelect from './MultipleSelect';
import CheckboxGroup from './CheckboxGroup';
import RadioSelect from './RadioSelect';
import GroupControl from './GroupControl';
import ArrayControl from './ArrayControl';
import BooleanControl from './BooleanControl';
import FormCondition from './FormCondition';
import OrganizationControl from './OrganizationControl';
import PersonControl from './PersonControl';
import DelayedTextField from './DelayedTextField';
import DateControl from './DateControl';
import AutoCompleteSelect from './AutocompleteSelect';

const styles = (theme: Theme) => createStyles({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

interface OwnProps extends BaseFormControlProps {
  scopes: any[];
  item: FormItem;
  value: any;
  readOnly?: boolean;
  onChange: (values: any) => void;
  showEmpty?: boolean;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  errors: any;
}

class FormControlItem extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    scopes: [],
    value: undefined,
    readOnly: false,
    showEmpty: false,
    onChange: () => {},
  }

  handleChange(newValue: any) {
    const { onChange } = this.props;
    onChange(newValue);
  }

  renderControl() {
    const {
      classes,
      definitions,
      noIndent,
      scopes,
      item,
      path,
      pathFilters,
      value: _value,
      onChange,
      readOnly: _readOnly,
      showEmpty,
    } = this.props;
    if (!shouldFormItemShow(pathFilters, path, item)) {
      return null;
    }
    let value = _value;
    const readOnly = item.readOnly || _readOnly;
    if (_.isUndefined(value)) {
      if (readOnly && !showEmpty) {
        return null;
      }
      if (!_.isUndefined(item.defaultValue)) {
        value = item.defaultValue;
      } else if (item.type === 'date' || item.type === 'datetime') {
        value = null;
      } else if (item.type === 'select' && item.multiple) {
        value = [];
      } else if (item.type === 'group') {
        value = item.optional ? null : {};
      } else {
        value = '';
      }
      setTimeout(() => {
        // this.handleChange(value);
      }, 0);
    }
    
    const simpleProps = {
      label: item.label,
      className: classes.formControl,
      value,
    }

    const combinedProps = {
      definitions,
      noIndent,
      readOnly,
      scopes,
      value,
      onChange,
      path,
      pathFilters,
    };

    let selectOptions: SelectOption[] = [];
    if (item.type === 'select') {
      selectOptions = buildSelectOptions(item);
    }

    let readOnlyElement: React.ReactNode = null;
    if (readOnly) {
      if (item.type === 'select') {
        const matchedOption = _.find(selectOptions, { value });
        if (matchedOption) {
          value = matchedOption.label;
        }
      }
      readOnlyElement = (
        <TextField
          {...simpleProps}
          value={value}
          fullWidth
          name={item.key}
          type="text"
          onChange={event => this.handleChange(event.target.value)}
          inputProps={{
            readOnly,
          }}
        />
      );
    }
    
    switch (item.type) {

      case 'text':
      case 'textarea':
      case 'number': {
        if (readOnly && readOnlyElement) {
          return readOnlyElement
        }
        let type: string | undefined;
        switch (item.type) {
          case 'number':
            type = item.type;
            break;
          default:
            type = undefined;
            break;
        }
        return (
          <DelayedTextField
            {...simpleProps}
            fullWidth
            name={item.key}
            type={type}
            multiline={item.type === 'textarea'}
            onChange={value => this.handleChange(value)}
          />
        );
      }

      case 'date':
      case 'time':
      case 'datetime':
        return (
          <DateControl
            {...simpleProps}
            readOnly={readOnly}
            item={item}
            onChange={value => this.handleChange(value)}
          />
        );
    
      case 'boolean': {
        if (!item.control || item.control === 'checkbox') {
          return (
            <BooleanControl
              readOnly={readOnly}
              className={simpleProps.className}
              item={item}
              value={value}
              onChange={value => this.handleChange(value)}
            />
          );         
        } else if (item.control === 'radio') {
          return (
            <RadioSelect
              className={simpleProps.className}
              label={item.label}
              options={[
                { label: item.trueLabel || 'Yes', value: 'true' },
                { label: item.falseLabel || 'No', value: 'false' },
              ]}
              value={value}
              onChange={value => this.handleChange(value === 'true' ? true : false)}
            />
          );
        } else if (item.control === 'switch') {
          return (
            <FormControlLabel
              control={
                <Switch
                  className={simpleProps.className}
                  checked={value}
                  onChange={(event, checked) => this.handleChange(checked)}
                />
              }
              label={item.label}
            />
          );
        }
        break;
      }

      case 'select': {
        if (readOnly && readOnlyElement) {
          return readOnlyElement
        }
        if (item.control === 'autocomplete' || item.optionsType === 'remote') {
          return (
            <AutoCompleteSelect
              {...simpleProps}
              item={item}
              onChange={value => this.handleChange(value)}
            />
          );
        } else if (item.multiple) {
          if (!item.control || item.control === 'select') {
            return (
              <MultipleSelect
                {...simpleProps}
                value={selectOptions.filter(option => value.includes(option.value))}
                options={selectOptions}
                onChange={values => this.handleChange(values.map(option => option.value))}
              />
            )
          } else if (item.control === 'checkbox') {
            return (
              <CheckboxGroup
                {...simpleProps}
                options={selectOptions}
                onChange={value => this.handleChange(value)}
              />
            )
          }
        } else {
          if (!item.control || item.control === 'select') {
            return (
              <FormControl
                fullWidth
                key={item.key}
                className={classes.formControl}
              >
                <InputLabel>{item.label}</InputLabel>
                <NativeSelect
                  value={value}
                  name={item.key}
                  readOnly={readOnly}
                  onChange={event => this.handleChange(event.target.value)}
                >
                  <option value=""></option>
                  {selectOptions.map(({ label, value }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            );
          } else if (item.control === 'radio') {
            return (
              <RadioSelect
                {...simpleProps}
                options={selectOptions}
                onChange={value => this.handleChange(value)}
              />
            );
          }
        }
        break;
      }

      case 'group': {
        return (
          <GroupControl item={item} {...combinedProps} />
        );
      }

      case 'person': {
        return (
          <PersonControl item={item} {...combinedProps} />
        );
      }

      case 'organization': {
        return (
          <OrganizationControl item={item} {...combinedProps} />
        );
      }

      case 'array': {
        return (
          <ArrayControl item={item} {...combinedProps} />
        );
      }

      default:
        return null;
    }
    return null;
  }

  render() {
    const { scopes, item } = this.props;
    return (
      <FormCondition
        scopes={scopes}
        condition={item.condition}
        render={() => this.renderControl()}
      />
    );
  }

}

export default withStyles(styles)(FormControlItem);
