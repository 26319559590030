import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
// import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { RootState } from '../../store/reducers';
import { transferDossierRemote } from '../../store/dossier/actions'
import { fetchListRemote as fetchTeams } from '../../store/team/actions'
import { __ } from '../../utils/intl';
import { MemberData } from '../../types/member';
import TransferDialog, { TransferOptionType } from '../Team/TransferDialog'
import { TeamData } from '../../types/team';
import { DossierData } from '../../types/dossier';

const styles = (theme: Theme) => createStyles({
  dialogContent: {
    width: 320,
    height: 300,
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
});

interface StateProps {
  memberList: MemberData[];
  teams: TeamData[];
  dossier: DossierData | null;
}

interface DispatchProps {
  transferDossierRemote: (dossierId: string, type: TransferOptionType['type'], id: string, password: string) => Promise<void>
  fetchTeams: () => Promise<TeamData[]>
}

interface OwnProps {
  dossierId: string;
  open: boolean;
  onClose: () => void;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;


interface State {
}

class DossierTransferDialog extends React.Component<Props> {

  state: State = {

  }

  componentDidMount() {
    this.props.fetchTeams()
  }

  componentWillReceiveProps(nextProps: Props) {
  }
  onTransferDossier = async (type: TransferOptionType['type'], id: string, password: string) => {
    await this.props.transferDossierRemote(this.props.dossier!._id, type, id, password)
  }

  render() {
    const { classes, onClose, open, memberList, teams } = this.props;
    return (
      <TransferDialog
        open={open}
        teams={teams}
        members={memberList}
        onClose={onClose}
        onSave={this.onTransferDossier}
        customize={{
          selectTip: __('dossier.transfer.selectMemberTip')
        }}
      />
    );
  }

}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  teams: states.team.list,
  memberList: states.member.list,
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  transferDossierRemote: (dossierId: string, type: TransferOptionType['type'], id: string, password: string) => dispatch(transferDossierRemote(dossierId, type, id, password)),
  fetchTeams: () => dispatch(fetchTeams()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DossierTransferDialog);
