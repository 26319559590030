import { history } from '../store';
import { KeyValueMap } from '../types/common';
import { MessageAttachment } from '../types/chat';
import { TargetIdentity } from '../types/notification';
import { getUsername } from './data';
import { __ } from './intl';
import { getFileTypeTitle } from './drive';

export const openTarget = (target: TargetIdentity) => {
  switch (target.type) {
    case 'invitation':
      history.push(`/invitation?type=received&status=all`);
      break;
    case 'dossier':
      history.push(`/dossier/${target.dossier_id}`);
      break;
    case 'document':
      history.push(`/dossier/${target.dossier_id}/scroll?document=${target.document_id}`);
      break;
    case 'team':
      history.push(`/team/view/${target.team_id}`);
      break;
  }
}

export const targetTitles: KeyValueMap<string> = {
  document: __('document.document'),
  dossier: __('dossier.dossier'),
  invitation: __('invitation.invitation'),
  team: __('team.team'),
  thread: __('thread.thread'),
  user: __('user.user'),
}

export function getTargetTitle(target: TargetIdentity): string {
  return targetTitles[target.type] || __('target.undefined');
}

export function getTargetName(target: TargetIdentity): string {
  let name: string = '';
  if (target.type === 'team' && target.team) {
    name = target.team.name;
  } else if (target.type === 'dossier' && target.dossier) {
    name = target.dossier.name;
  } else if (target.type === 'document' && target.document) {
    name = target.document.name;
  } else if (target.type === 'user' && target.user) {
    name = getUsername(target.user);
  }
  return name;
}

export function getAttachmentName(attachment: MessageAttachment): string {
  if (attachment.type === 'file' && attachment.file) {
    return attachment.file.filename;
  } else if (attachment.type === 'target' && attachment.target) {
    return getTargetName(attachment.target);
  } else {
    return '';
  }
}

export function getAttachmentTypeTitle(attachment: MessageAttachment): string {
  if (attachment.type === 'file' && attachment.file) {
    return getFileTypeTitle(attachment.file.filename);
  } else if (attachment.type === 'target' && attachment.target) {
    return getTargetTitle(attachment.target);
  } else {
    return __('target.undefined');
  }
}
