import _ from 'lodash';
import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    boxSizing: 'border-box',
    borderRadius: 32,
    padding: 4,
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.primary.main,
  },
});

interface OwnProps extends HTMLAttributes<HTMLDivElement> {}

type Props = OwnProps & WithStyles<typeof styles>;

class CombinedFab extends React.Component<Props> {

  render() {
    const { classes, className, children, ...restProps } = this.props;
    return (
      <div className={classNames(classes.root, className)} {...restProps}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(CombinedFab);
