import clsx from 'clsx';
import React from 'react';
import { compose } from 'recompose';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ListItem, List, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { mdiFolderOpen } from '@mdi/js';
import ExpandIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { TreeItem } from '../../types/tree';
import { UITreeItem } from '../../utils/tree';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const FolderOpenIcon = () => <SimpleSvgIcon path={mdiFolderOpen} />;

const styles = (theme: Theme) => createStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    // background: 'rgb(240, 240, 240)',
    '& .more-icon': {
      display: 'none',
    },
    '&:hover .more-icon': {
      display: 'inline-flex'
    },
    '&.selected, &:hover, &.selected:hover': {
      backgroundColor: '#ccc'
    }
  },
  expandIconWrapper: {
    minWidth: 24,
  },
  iconWrapper: {
    minWidth: 36,
  },
  checkbox: {
    padding: theme.spacing(1),
  },
  itemIcon: {
    transformOrigin: 'center center',
    transition: 'transform 0.2s ease-in-out'
  },
  expanded: {
    transform: 'rotate(45deg)',
  },
  text: {
    fontSize: 14,
  },
});

interface OwnProps {
  checkbox?: boolean;
  sortable?: boolean;
  item: UITreeItem;
  level: number;
  selectedItem: TreeItem | null;
  onCheckChange: (item: TreeItem, status?: boolean) => void;
  onExpandChange: (item: TreeItem, status?: boolean) => void;
  onSelect: (item: TreeItem) => void;
  onContextMenu: (event: React.MouseEvent, item: TreeItem) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {

}

class DocumentTreeNode extends React.Component<Props, State> {

  static defaultProps: Partial<OwnProps> = {
    level: 1,
  }

  handleExpandChange(event: React.MouseEvent, status: boolean) {
    const { item, onExpandChange } = this.props;
    event.stopPropagation();
    onExpandChange(item, status);
  }

  handleClick = (event: React.MouseEvent) => {
    const { item, onSelect, onExpandChange } = this.props
    if (item.type === 'folder' && !item.expanded) {
      onExpandChange(item, true);
    }
    onSelect(item);
  }

  handleContextMenu = async (event: React.MouseEvent) => {
    const { onContextMenu, item, onSelect } = this.props;
    if (!onContextMenu) {
      return;
    }
    event.preventDefault();
    onContextMenu(event, item);
    onSelect(item);
  }

  render() {
    const { classes, checkbox, item, selectedItem, level, ...props } = this.props;
    const selected = selectedItem ? selectedItem._id === item._id : false;
    return (
      <>
        <Draggable
          isDragDisabled={!props.sortable || item.type === 'folder'}
          index={item.childIndex}
          draggableId={item._id || 'root'}
        >
          {(provided, snapshot) => (
            <ListItem
              button
              className={clsx(classes.root, { selected })}
              id={`nav-tree-item-${item._id}`}
              onClick={this.handleClick}
              onContextMenu={this.handleContextMenu}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                paddingLeft: level * 16,
              }}
            >
              <ListItemIcon
                className={classes.expandIconWrapper}>
                {item.type === 'folder' && item.children && item.children.length > 0 ?
                  <ExpandIcon
                    className={clsx(classes.itemIcon, { [classes.expanded]: item.expanded })}
                    onClick={event => this.handleExpandChange(event, !item.expanded)}
                  />
                  :
                  <span />
                }
              </ListItemIcon>
              {checkbox &&
                <ListItemIcon className={classes.iconWrapper}>
                  <Checkbox
                    size="small"
                    className={classes.checkbox}
                    checked={item.checked}
                    indeterminate={item.indeterminate}
                    onClick={event => event.stopPropagation()}
                    onChange={event => props.onCheckChange(item, event.target.checked)}
                  />
                </ListItemIcon>
              }
              <ListItemIcon className={classes.iconWrapper}>
                {item.type === 'folder'?
                  (item.expanded ? <FolderOpenIcon /> : <FolderIcon />)
                  :
                  <FileIcon />
                }
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.text }} primary={item.name} />
            </ListItem>
          )}
        </Draggable>
        {item.type === 'folder' &&
          <Droppable
            isDropDisabled={!props.sortable}
            droppableId={item._id}
            type={item._id}
          >
            {(provided, snapshot) => (item.expanded ?
              <List
                component="section" disablePadding
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {item.children && item.children.map((child, index) => (
                  <DocumentTreeNode
                    classes={classes}
                    key={child.name}
                    level={level + 1}
                    item={child}
                    checkbox={checkbox}
                    selectedItem={selectedItem}
                    {...props}
                  />
                ))}
                {provided.placeholder}
              </List>
            :
              <span ref={provided.innerRef} {...provided.droppableProps} />
            )}
          </Droppable>
        }
      </>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(DocumentTreeNode);
