import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import { Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { mdiChat } from '@mdi/js';

import { __ } from '../../utils/intl';
import { ThreadOptions, ChatThreadData } from '../../types/chat';
import { MemberData, checkPermission, MemberIdentity } from '../../types/member';
import { DossierData } from '../../types/dossier';
import { InvitationOptions } from '../../types/invitation';
import { UserData, PublicUserInfo } from '../../types/user';
import { RootState } from '../../store/reducers';
import {
  inviteMemberRemote,
  updateItemRemote as updateMemberRemote,
  removeItemRemote as removeMemberRemote,
} from '../../store/member/actions';
import { getThreadRemote } from '../../store/chat/actions';
import Loading from '../Common/Loading';
import MemberList from '../Team/MemberList';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import { PanelProps } from './common';
import Panel from './Panel';

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
  },
  actions: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});

interface OwnProps extends PanelProps {
  open: boolean;
}

interface StateProps {
  user: UserData | null;
  dossier: DossierData | null;
  memberList: MemberData[];
}

interface DispatchProps {
  inviteMemberRemote: (query: MemberIdentity, options: InvitationOptions) => Promise<MemberData>;
  updateMemberRemote: (query: MemberIdentity, memberId: string, data: Partial<MemberData>) => Promise<MemberData>;
  removeMemberRemote: (query: MemberIdentity, memberId: string) => Promise<void>;
  getThreadRemote: (options: ThreadOptions) => Promise<ChatThreadData>;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

interface State {
}

class TeamPanel extends React.Component<Props, State> {

  state: State = {
    collaborators: null,
  }

  getDossierId() {
    const { dossier } = this.props;
    return dossier ? dossier._id : '';
  }

  getMemberQuery(): MemberIdentity {
    return {
      type: 'dossier',
      dossier_id: this.getDossierId(),
    };
  }

  onOpenChatWindow = async () => {
    const { dossier, getThreadRemote } = this.props;
    if (!dossier) return;
    await getThreadRemote({
      to: {
        type: 'dossier',
        dossier_id: dossier._id,
      },
    });
  }

  render() {
    const { classes, open, dossier, user, memberList } = this.props;
    if (!dossier || !user) return (
      <Loading />
    );
    return (
      <Panel open={open} className={classes.root}>
        <div className={classes.actions}>
          <Button
            fullWidth
            variant="outlined"
            onClick={this.onOpenChatWindow}
          >
            {__('im.openChatWindow')}
            <SimpleSvgIcon className={classes.buttonIcon} path={mdiChat} />
          </Button>
        </div>
        <MemberList
          title={__('dossier.collaborators')}
          query={{ type: 'dossier', dossier_id: dossier._id }}
        />
      </Panel>
    )
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  user: states.user.current,
  dossier: states.dossier.current,
  memberList: states.member.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  inviteMemberRemote: (query, options) => dispatch(inviteMemberRemote(query, options)),
  updateMemberRemote: (query, memberId, data) => dispatch(updateMemberRemote(query, memberId, data)),
  removeMemberRemote: (query, memberId) => dispatch(removeMemberRemote(query, memberId)),
  getThreadRemote: (options) => dispatch(getThreadRemote(options)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TeamPanel);
