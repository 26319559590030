import React from 'react';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { mdiFileDownloadOutline } from '@mdi/js';

import '../Editor/Document.scss';
import { __ } from '../../utils/intl';
import { html2react } from '../../utils/template';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import LazyloadImage from '../Common/LazyloadImage';
import { DocumentPageProps } from './DocumentPageContainer';
import {
  PAPER_HEIGHT,
  PAPER_WIDTH,
  PAPER_PADDING_TOP,
  PAPER_PADDING_BOTTOM,
  PAPER_PADDING_LEFT,
  PAPER_PADDING_RIGHT,
  PAPER_HEADER_HEIGHT,
  PAPER_FOOTER_HEIGHT,
  PAPER_PADDING_INNER,
} from './constants';
import { tooltipProps } from './Document';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    cursor: 'default',
  },
  page: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[6],
    height: '297mm',
    width: '210mm',
    paddingTop: PAPER_PADDING_TOP - PAPER_HEADER_HEIGHT,
    paddingBottom: PAPER_PADDING_BOTTOM - PAPER_FOOTER_HEIGHT,
    paddingLeft: PAPER_PADDING_LEFT,
    paddingRight: PAPER_PADDING_RIGHT,
    backgroundColor: '#fff',
    transformOrigin: 'top left',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '0.4in',
    lineHeight: '0.4in',
    borderBottom: '1px solid #000',
    opacity: 0.6,
  },
  sectionName: {

  },
  pageName: {

  },
  actionsWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
    height: '100%',
  },
  actions: {
    position: 'sticky',
    top: 0,
    width: 48,
  },
  content: {
    paddingTop: PAPER_PADDING_INNER,
    paddingBottom: PAPER_PADDING_INNER,
    flexGrow: 1,
    overflow: 'hidden',
    position: 'relative',
    '&.editable': {
      cursor: 'text',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    // objectFit: 'contain',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '0.4in',
    lineHeight: '0.4in',
    opacity: 0.6,
  },
  logo: {
    '& img': {
      height: '10mm',
      verticalAlign: 'middle',
    },
  },
  pageNumber: {

  },
});

export interface OwnProps extends DocumentPageProps {
  zoom: number;
}

type Props = OwnProps & WithStyles<typeof styles>;

class DocumentPage extends React.Component<Props> {

  static defaultProps: OwnProps = {
    sectionName: '',
    document: null,
    pageName: '',
    actions: null,
    page: null,
    pageNumber: null,
    zoom: 1,
  }

  renderContent() {
    const { classes, container, page } = this.props;
    if (!page) {
      return null;
    }
    switch (page.type) {
      case 'html': {
        return html2react(page.content || '');
      }
      case 'pdf-page':
      case 'image': {
        if (page.file && page.file.urls) {
          const { urls } = page.file;
          const url = page.type === 'pdf-page' ? urls.original : urls.preview;
          return (
            <LazyloadImage
              src={url}
              className={classes.image}
              scrollContainer={container}
              data-pageid={page._id}
              draggable={false}
              crossOrigin="anonymous"
              onLoad={this.onloadImg}
            />
          );
        }
      }
    }
    return null;
  }

  isEditable() {
    const { document } = this.props;
    return document && ['empty', 'html'].includes(document.fileType);
  }

  onloadImg = (evt: any) => {
    const { clientHeight: parentHeight } = evt.target.parentNode;
    const { clientHeight: imgHeight } = evt.target;
    evt.target.style.position = 'relative';
    evt.target.style.top = ((parentHeight - imgHeight) / 2) + 'px';
  }

  handleEdit = (event: React.MouseEvent) => {
    const { onEdit } = this.props;
    if (this.isEditable() && onEdit) {
      onEdit();
    }
  }

  render() {
    const {
      classes,
      className,
      sectionName,
      document,
      page,
      pageName,
      actions,
      pageNumber,
      zoom,
      onDownload,
      onEdit,
    } = this.props;
    const editable = this.isEditable();
    const pageId = page ? page._id : document ? `${document._id}-default-page` : undefined;
    return (
      <div
        id={`page-${pageId}`}
        className={clsx(classes.root, className)}
        style={{
          width: PAPER_WIDTH * zoom,
          height: PAPER_HEIGHT * zoom,
        }}
      >
        <div
          className={classes.page}
          style={{
            width: PAPER_WIDTH,
            height: PAPER_HEIGHT,
            transform: `scale(${zoom})`
          }}
        >
          <div className={classes.header}>
            <div className={classes.sectionName}>{sectionName}</div>
            <div className={classes.pageName}>{pageName}</div>
          </div>
          <article
            className={clsx(`${classes.content} page-content`, { editable })}
            onDoubleClick={this.handleEdit}
          >
            {this.renderContent()}
          </article>
          <div className={classes.footer}>
            <div className={classes.logo}>
              <img src={`${process.env.PUBLIC_URL}/images/logo.png`} /> {__('app.productName')}
            </div>
            <div className={classes.pageNumber}>
              {pageNumber}
            </div>
          </div>
        </div>
        <div className={classes.actionsWrapper}>
          <div className={classes.actions}>
            {actions}
            {document && document.fileType === 'image-list' && page &&
              <Tooltip {...tooltipProps} title={__('document.downloadCurrentPage')}>
                <IconButton onClick={onDownload}>
                  <SimpleSvgIcon path={mdiFileDownloadOutline} />
                </IconButton>
              </Tooltip>
            }
          </div>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(DocumentPage);
