import _ from 'lodash';
import { ClaimData } from '../../types/claim';
import { ThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';

// Action Definition
export interface SetCurrentItem {
  type: '@@claim/SET_CURRENT_ITEM';
  item: ClaimData;
}

export interface ClearCurrentItem {
  type: '@@claim/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@claim/SET_LIST';
  list: ClaimData[];
}

export interface UpdateListItem {
  type: '@@claim/UPDATE_LIST_ITEM';
  item: ClaimData;
}

export interface DeleteListItem {
  type: '@@claim/REMOVE_LIST_ITEM';
  id: string;
}

// Union Action Types
export type Action = SetCurrentItem
  | ClearCurrentItem
  | SetList
  | UpdateListItem
  | DeleteListItem
  ;

// Action Creators
export const setItem = (item: ClaimData): SetCurrentItem => {
  return { type: '@@claim/SET_CURRENT_ITEM', item };
}

export const clearItem = (): ClearCurrentItem => {
  return { type: '@@claim/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: ClaimData[]): SetList => {
  return { type: '@@claim/SET_LIST', list };
}

export const updateListItem = (item: ClaimData): UpdateListItem => {
  return { type: '@@claim/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@claim/REMOVE_LIST_ITEM', id };
}

const getDossierIdFromState = (state: RootState): string => {
  const dossier = state.dossier.current;
  if (!dossier) {
    throw new Error('dossier not loaded');
  }
  return dossier._id;
}

export const fetchItemRemote = (claimId: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const dossierId = getDossierIdFromState(getState());
    const item = await client.get<ClaimData>('/dossiers/:dossierId/claims/:claimId', {
      params: { dossierId, claimId },
    });
    dispatch(setItem(item));
  }
}

export const updateItemRemote = (claimId: string, data: Partial<ClaimData>): ThunkActionType<ClaimData> => {
  return async (dispatch, getState) => {
    const dossierId = getDossierIdFromState(getState());
    const item = await client.put<ClaimData>('/dossiers/:dossierId/claims/:claimId', {
      params: { dossierId, claimId },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const removeItemRemote = (claimId: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const dossierId = getDossierIdFromState(getState());
    await client.delete('/dossiers/:dossierId/claims/:claimId', {
      params: { dossierId, claimId },
    });
    dispatch(removeListItem(claimId));
  }
}

export const fetchListRemote = (): ThunkActionType => {
  return async (dispatch, getState) => {
    const dossierId = getDossierIdFromState(getState());
    const list = await client.get<ClaimData[]>('/dossiers/:dossierId/claims', {
      params: { dossierId },
    });
    dispatch(setList(list));
  }
}

export const createItemRemote = (data: Partial<ClaimData>): ThunkActionType<ClaimData> => {
  return async (dispatch, getState) => {
    const dossierId = getDossierIdFromState(getState());
    const item = await client.post<ClaimData>('/dossiers/:dossierId/claims', {
      params: { dossierId },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}
