import 'moment/locale/zh-cn';

export default {
  'en': require('./en.json'),
  // 'es': require('./es.json'),
  // 'fr': require('./fr.json'),
  // 'it': require('./it.json'),
  // 'ja': require('./ja.json'),
  // 'ko': require('./ko.json'),
  // 'ru': require('./ru.json'),
  'zh-CN': require('./zh-CN.json'),
  // 'zh-HK': require('./zh-HK.json'),
}
