import React from 'react';
import { Avatar } from '@material-ui/core';
import UserIcon from '@material-ui/icons/AccountCircle';
import { AvatarProps } from '@material-ui/core/Avatar';
import { PublicUserInfo } from '../../types/user';

interface Props extends AvatarProps {
  user?: PublicUserInfo | null;
}

class UserAvatar extends React.Component<Props> {

  render() {
    const { user, ...avatarProps } = this.props;
    if (user) {
      if (user.avatar) {
        return (
          <Avatar {...avatarProps} src={user.avatar} />
        );
      } else if (user.profile && user.profile.lastName) {
        return (
          <Avatar {...avatarProps}>{user.profile.lastName.charAt(0)}</Avatar>
        );
      }
    }
    return (
      <Avatar {...avatarProps}>
        <UserIcon />
      </Avatar>
    )
  }
}

export default UserAvatar;
