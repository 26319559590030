import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Fab, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { FabProps } from '@material-ui/core/Fab';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomFitIcon from '@material-ui/icons/ZoomOutMap';

import { __ } from '../../utils/intl';
import { DocumentData } from '../../types/document';
import { TreeItem } from '../../types/tree';
import { findItemInTree, UITreeItem } from '../../utils/tree';
import { RootState } from '../../store/reducers';
import {
  PanelStatus,
  getContainerPosition,
} from '../../store/ui/reducers';
import { PAPER_WIDTH, MIN_PAPER_WIDTH, getPaperExtraMargin, ZOOM_LEVELS } from './constants';
import { ScrollViewContext, ScrollViewState } from './context';
import DocumentFragment from './DocumentFragment';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    overflow: 'auto',
    // backgroundColor: '#ccc',
    // backgroundImage: 'linear-gradient(to bottom, #f6f6f6 0%,#e8e8e8 100%)',
    flexGrow: 1,
  },
  wrapper: {
    margin: '0 auto',
  },
  actions: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    bottom: 96,
    right: theme.spacing(4),
    transformOrigin: 'right middle',
    transition: 'all 0.4s ease-in-out',
  },
  fab: {
    margin: theme.spacing(1, 0),
    backgroundColor: '#fff',
  },
});

interface StateProps {
  panelStatus: PanelStatus;
}

interface DispatchProps {
}

interface OwnProps {
  id: string;
  tree: TreeItem;
  onlyDocument?: boolean;
  selectedItem: TreeItem | null;
  onSelectedItemChange: (item: TreeItem) => void;
  initialDocumentId?: string;
}

type Props = StateProps & DispatchProps & OwnProps & WithWidth & WithStyles<typeof styles>;

interface State extends ScrollViewState {
}

const tooltipProps: Partial<TooltipProps> = {
  placement: 'left',
};

class ScrollView extends React.Component<Props, State> {

  private container: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.handleScroll = _.debounce(this.handleScroll.bind(this), 200);
    this.handleResize = _.debounce(this.handleResize.bind(this), 500);
    this.state = {
      ...this.getInitialState(props),
      changeZoom: (zoom) => this.setState({ zoom }),
    }
  }

  getInitialState(props: Props) {
    return {
      onlyDocument: props.onlyDocument || false,
      zoomMode: 'fit',
      zoom: this.calcZoom(),
    } as State;
  }

  componentDidMount() {
    const { selectedItem } = this.props;
    this.scrollToDocument(selectedItem)
    if (this.container) {
      this.container.addEventListener('scroll', this.handleScroll as any, { passive: true });
    }
    window.addEventListener('resize', this.handleResize, { passive: true });
  }

  componentWillUnmount() {
    if (this.container) {
      this.container.removeEventListener('scroll', this.handleScroll as any);
    }
    window.removeEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps: Props) {
    const position = getContainerPosition(this.props.panelStatus, true);
    const nextPosition = getContainerPosition(nextProps.panelStatus, true);
    if (position.left + position.right !== nextPosition.left + nextPosition.right) {
      this.handleResize();
    }
    // if (!this.props.initialDocumentId && nextProps.initialDocumentId) {
    //   this.scrollToDocument({ _id: nextProps.initialDocumentId })
    // }
  }
  
  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    return (
      nextProps.tree !== this.props.tree ||
      nextState.zoom !== this.state.zoom ||
      nextProps.selectedItem !== this.props.selectedItem ||
      nextProps.panelStatus !== this.props.panelStatus
    );
  }

  scrollToDocument = (selectedItem?: TreeItem | null) => {
    if (selectedItem) {
      const currentElement = document.getElementById(`document-${selectedItem._id}`);
      if (currentElement) {
        currentElement.scrollIntoView();
      }
    }
  }

  handleScroll() {
    // TODO: use IntersectionObserver for checking current scrolling position:
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const { tree, onSelectedItemChange } = this.props;
    const pageElements = document.querySelectorAll('.document-node');
    let currentElement: Element = pageElements[0];
    for (let i = 0; i < pageElements.length; i++ ) {
      const pageElement = pageElements[i];
      const box = pageElement.getBoundingClientRect();
      if (box.top <= 100 && box.bottom > 100) {
        currentElement = pageElement;
        break;
      }
    }
    const { itemId } = (currentElement as HTMLElement).dataset
    const pageItem = findItemInTree(tree, item => item._id === itemId);
    if (pageItem) {
      setTimeout(() => {
        onSelectedItemChange(pageItem);
      }, 0);
    }
  }

  calcZoom(): number {
    const { panelStatus } = this.props;
    let width = window.innerWidth - getPaperExtraMargin() * 2;
    const position = getContainerPosition(panelStatus);
    width -= position.left + position.right;
    if (width > PAPER_WIDTH) {
      return 1;
    } else {
      if (width < MIN_PAPER_WIDTH) {
        width = MIN_PAPER_WIDTH;
      }
      return width / PAPER_WIDTH;
    }
  }

  getScrollPosition(): number | null {
    const { container } = this;
    if (!container) return null;
    const scrollRange = container.scrollHeight - container.offsetHeight;
    if (scrollRange === 0) {
      return 0;
    } else {
      return container.scrollTop / scrollRange;
    }
  }

  setScrollPosition(position: number | null) {
    const { container } = this;
    if (!container || !position) return null;
    container.scrollTop = (container.scrollHeight - container.offsetHeight) * position;
  }

  handleResize() {
    const scrollPosition = this.getScrollPosition();
    const { zoomMode } = this.state;
    if (zoomMode === 'fit') {
      const zoom = this.calcZoom();
      this.setState({ zoom }, () => {
        this.setScrollPosition(scrollPosition);
      });
    }
  }

  handleZoomChange(diff: number) {
    const scrollPosition = this.getScrollPosition();
    const { zoom } = this.state;
    const levels = _.uniq([...ZOOM_LEVELS, this.calcZoom()]).sort();
    const index = _.sortedIndex(levels, zoom);
    const newIndex = index + diff;
    const newZoom = levels[newIndex];
    if (newZoom) {
      this.setState({
        zoomMode: 'free',
        zoom: newZoom,
      }, () => {
        this.setScrollPosition(scrollPosition);
      });
    }
  }

  handleZoomFit() {
    this.setState(this.getInitialState(this.props));
  }

  render() {
    const { id, panelStatus, classes, tree, width } = this.props;
    const { zoom, onlyDocument } = this.state;
    const paperMargin = getPaperExtraMargin();
    const isSmallScreen = isWidthDown('xs', width);
    const actionButtonSize: FabProps['size'] = isSmallScreen ? 'small' : 'medium';
    const position = getContainerPosition(panelStatus, true);
    return (
      <ScrollViewContext.Provider value={this.state}>
        <div
          id={id}
          className={classes.root}
          ref={ref => this.container = ref}
          onScroll={() => this.handleScroll()}
        >
          <div
            className={classes.wrapper}
            style={{
              width: PAPER_WIDTH * zoom + paperMargin * 2,
              padding: `0 ${paperMargin}px`,
            }}
          >
            <DocumentFragment
              container={`#${id}`}
              item={tree}
              path={[]}
            />
          </div>
          <div
            className={classes.actions}
            style={{
              bottom: onlyDocument ? 8 : 96,
              transform: onlyDocument ? undefined : `translateX(-${position.right}px)`,
            }}
          >
            <Tooltip {...tooltipProps} title={__('view.zoomIn')}>
              <Fab size={actionButtonSize} className={classes.fab} onClick={() => this.handleZoomChange(+1)}>
                <ZoomInIcon />
              </Fab>
            </Tooltip>
            <Tooltip {...tooltipProps} title={__('view.zoomFit')}>
              <Fab size={actionButtonSize} className={classes.fab} onClick={() => this.handleZoomFit()}>
                <ZoomFitIcon />
              </Fab>
            </Tooltip>
            <Tooltip {...tooltipProps} title={__('view.zoomOut')}>
              <Fab size={actionButtonSize} className={classes.fab} onClick={() => this.handleZoomChange(-1)}>
                <ZoomOutIcon />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </ScrollViewContext.Provider>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  panelStatus: states.ui.panelStatus,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(ScrollView);
