import Quill from 'quill';

const Embed = Quill.import('blots/embed');

export interface QuillVariableContext {
  value: string,
  fieldName: string,
  type: string,
}

class QuillVariable extends Embed {
  static blotName = 'variable';
  static className = 'quill-variable';
  static tagName = 'span';

  constructor(dom: HTMLElement, context: QuillVariableContext) {
    super(dom)
    dom.addEventListener('click', (event) => {
      event.preventDefault()
      this.scroll.emitter.emit('click-variable', {context, target: dom})
    })
  }

  static create(context: QuillVariableContext) {
    // console.log(context)
    const {value, fieldName, type} = context;
    let node:HTMLElement = super.create();
    node.innerText = `${context.value}`;
    node.dataset.fieldName = fieldName;
    node.dataset.type = type;
    if (fieldName !== value) {
      node.classList.add('filled')
    }
    return node;
  }

  static value(node: HTMLElement): QuillVariableContext {
    const value = node.innerText
    return {
      value: value,
      fieldName: node.dataset.fieldName || value,
      type: node.dataset.type || 'string',
    }
  }
}

// QuillVariable.blotName = 'variable'
// QuillVariable.className = 'quill-variable'
// QuillVariable.tagName = 'span'

// Quill.register({'formats/placeholder': Placeholder})

export default QuillVariable
// Quill.register('modules/variable', )