import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { compose } from 'recompose';
import { Tooltip, Fab } from '@material-ui/core';
import { FabProps } from '@material-ui/core/Fab';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { mdiFileTree } from '@mdi/js';

import './Dossier.scss';
import client from '../../store/client';
import { __ } from '../../utils/intl';
import { DocumentData } from '../../types/document';
import { DossierData, DossierTreeItem } from '../../types/dossier';
import { DossierTemplateData } from '../../types/dossier.template';
import { FileData } from '../../types/file';
import { MemberData } from '../../types/member';
import { TreeItem } from '../../types/tree';
import { ViewMode } from '../../types/view';
import { RootState } from '../../store/reducers';
import ShareThreadList from '../Team/ShareThreadList';
import qs from 'qs';
import {Location} from 'history'

import {
  PanelStatus,
  PanelType,
  PanelSide,
  RightPanelType,
  isPanelOpenAndPinned,
  getContainerPosition,
} from '../../store/ui/reducers';
import { getUploadUrl, createUploadedDocument, getAcceptString } from '../../utils/drive';
import { scrollIntoViewById } from '../../utils/ui';
import {
  togglePanel,
  togglePanelPin,
  setCurrentTreeItem,
  toggleSinglePanel,
  updatePanelWidth,
} from '../../store/ui/actions';
import { fetchItemRemote as fetchDossier } from '../../store/dossier/actions'
import {
  getCachedItem as getCachedDocument,
  setItem as setCurrentDocument,
  createItemRemote as createDocumentRemote,
  getBaseUrl,
} from '../../store/document/actions';
import { fetchItemRemote as fetchDossierTemplate } from '../../store/dossierTemplate/actions'
import {
  fetchListRemote as fetchDocumentList,
  updateItemRemote as updateDocumentRemote,
} from '../../store/document/actions'
import {
  fetchListRemote as fetchMemberList,
} from '../../store/member/actions'
import SimpleUploader, { PureSimpleUploader } from '../Common/SimpleUploader';
import DocumentEditorDialog from '../Editor/DocumentEditorDialog';
import AssistantPanel from '../Assistant/AssistantPanel'
import Timeline from '../Timeline/Timeline';
import MapComp from '../MapComp/MapComp';
import Inbox from '../Inbox/Inbox';
import Layout from '../Common/Layout';
import StacksView from '../StacksView/StacksView';
import StatusBar from './StatusBar';
import ScrollView from './ScrollView';
import EntityView from './EntityView';
import FactView from './FactView';
import EvidenceEditorDialog from '../Editor/EvidenceViewDialog';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import DocumentViewDialog from './DocumentViewDialog';
import { DossierContext, DossierState, DownloadDocumentOptions } from './dossierContext';
import DocCreator from './DocCreator';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import Sidebar from '../Common/Sidebar';
import Loading from '../Common/Loading';
import { TargetIdentity } from '../../types/notification';
import { history } from '../../store';

const styles = (theme: Theme) => createStyles({
  root: {
    '& a, a:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
  },
  treeButton: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(3),
    backgroundColor: '#fff',
    transition: 'transform 0.4s ease-in-out',
    [theme.breakpoints.down('xs')]: {
      left: theme.spacing(2),
      bottom: theme.spacing(3),
    },
  },
});

interface StateProps {
  panelStatus: PanelStatus;
  selectedTreeItem: DossierTreeItem | null;
  dossier: DossierData | null;
}

interface DispatchProps {
  togglePanel: (side: PanelSide, status?: boolean) => void;
  togglePanelPin: (side: PanelSide, status?: boolean) => void;
  toggleSinglePanel: (type: PanelType, status?: boolean) => void;
  updatePanelWidth: (type: PanelSide, width?: number) => void;
  setCurrentTreeItem: (item: TreeItem | null) => void;
  fetchDossier: (dossierId: string) => Promise<DossierData>;
  fetchDossierTemplate: (dossierId: string) => Promise<DossierTemplateData>;
  fetchDocumentList: (dossierId: string) => Promise<void>;
  updateDocumentRemote: (dossierId: string, data: Partial<DocumentData>) => Promise<DocumentData>;
  fetchMemberList: (dossierId: string) => Promise<MemberData[]>;
  getCachedDocument: (dossierId: string, documentId: string) => Promise<DocumentData | undefined>
  setCurrentDocument: (document: DocumentData) => void;
  createDocumentRemote: (dossierId: string, document: Partial<DocumentData>) => Promise<DocumentData | undefined>;
}

interface OwnProps {
}

interface Params {
  viewMode?: ViewMode;
  dossierId: string;
}

interface Query {
  document?: string
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithWidth & WithStyles<typeof styles>;

interface State extends DossierState {
  openDocCreator: boolean
  sharing: boolean
  target?: TargetIdentity
}

class Dossier extends React.Component<Props, State> {

  private uploader: PureSimpleUploader | null = null;
  private documentData: Partial<DocumentData> | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      openDocCreator: false,
      setCurrentItem: this.handleSelectedItemChange,
      routeToDocumentEditor: this.routeToDocumentEditor,
      routeToEvidenceEditor: this.routeToDocumentEditor,
      openDocumentPanel: this.openDocumentPanel,
      uploadDocument: this.handleOpenUploader,
      createFromTemplate: this.handleCreateFromTemplate,
      moveDocument: this.handleMoveDocument, 
      downloadDocument: this.handleDownload,
      shareInDossier: this.shareInDossier,
      sharing: false,
    }
  }

  componentDidMount() {
    this.loadData(this.props);
    this.focusDocument(this.props.location)
  }

  componentWillReceiveProps(nextProps: Readonly<Props>) {
    if (nextProps.match.params.dossierId !== this.props.match.params.dossierId) {
      this.loadData(nextProps);
    }
    this.focusDocument(nextProps.location)
  }

  componentWillUnmount() {
    togglePanel('side', true);
  }

  focusDocument = (location: Location<any>) => {
    const {search} = location;
    if (search && /^\?/.test(search)) {
      const {document: id} = qs.parse(search.substr(1));
      scrollIntoViewById(`document-${id}`, { block: 'start' }, { top: 16 });
      history.replace(location.pathname)
    }
  }

  shareInDossier = (target: TargetIdentity) => {
    if (!target) {
      return console.error('target not define')
    }
    this.setState({sharing: true, target})
  }

  routeToDocumentEditor = async (item?: DossierTreeItem | null) => {
    const { history, match: { params: { dossierId, viewMode } },
      getCachedDocument,
      setCurrentTreeItem,
      selectedTreeItem,
    } = this.props;
    if (!item) {
      item = selectedTreeItem;
    }
    if (!item) return;
    const document = await getCachedDocument(dossierId, item._id)
    if (!document) return;
    setCurrentTreeItem(item);
    if (_.get(document, 'flags.evidence')) {
      history.push(`/dossier/${dossierId}/${viewMode}/document/${document._id}/evi-editor`)
    } else {
      history.push(`/dossier/${dossierId}/${viewMode}/document/${document._id}/editor`)
    }
  }

  openDocumentPanel = async (item?: DossierTreeItem | null, type: RightPanelType = 'inspector') => {
    const { setCurrentTreeItem, toggleSinglePanel, selectedTreeItem } = this.props;
    if (item) {
      setCurrentTreeItem(item);
    } else {
      item = selectedTreeItem;
    }
    if (!item) return;
    setTimeout(() => {
      toggleSinglePanel(type, true);
    });
  }

  async loadData(props: Readonly<Props>) {
    const {
      fetchDossier,
      fetchDocumentList,
      fetchDossierTemplate,
      fetchMemberList,
      match: { params: { dossierId } },
    } = props;
    this.setState({dossierId})
    const dossier = await fetchDossier(dossierId);
    await fetchMemberList(dossier._id);
    await fetchDocumentList(dossier._id);
    await fetchDossierTemplate(dossier.dossier_template_id);
  }

  handleTreeItemSelect(item: TreeItem) {
    const { setCurrentTreeItem } = this.props;
    if (item) {
      setCurrentTreeItem(item);
      scrollIntoViewById(`document-${item._id}`, { block: 'start' }, { top: 16 });
    }
  }

  handleSelectedItemChange = (item: TreeItem) => {
    const { setCurrentTreeItem, match: { params } } = this.props;
    setCurrentTreeItem(item);
    if (params.viewMode === 'scroll') {
      scrollIntoViewById(`nav-tree-item-${item._id}`);
    }
  }

  handleOpenUploader = async (item?: DossierTreeItem | null, newVersion: boolean = false) => {
    const { uploader } = this;
    const { getCachedDocument, dossier, selectedTreeItem, setCurrentTreeItem } = this.props;
    if (item) {
      setCurrentTreeItem(item);
    } else {
      item = selectedTreeItem;
    }
    if (!dossier || !uploader || !item) return;
    const document: Partial<DocumentData> | undefined = await getCachedDocument(dossier._id, item._id)
    if (!document) return;
    if (!newVersion) {
      document._id = undefined;
    }
    this.documentData = document;
    uploader.openBrowse();
  }

  async handleFileUpload(file: FileData) {
    const { dossier, updateDocumentRemote } = this.props;
    const { documentData } = this;
    await client.post<FileData>('/drive/files', { data: file });
    if (!dossier || !documentData) return;
    const document = createUploadedDocument(file, documentData);
    const result = await updateDocumentRemote(dossier._id, document);
    this.handleSelectedItemChange(result as TreeItem)
  }

  handleCreateFromTemplate = async (item?: DossierTreeItem | null, empty: boolean = false) => {
    // treat with empty file creation later
    const { createDocumentRemote, dossier, setCurrentTreeItem, selectedTreeItem } = this.props;
    if (item) {
      setCurrentTreeItem(item);
    } else {
      item = selectedTreeItem;
    }
    if (!dossier || !item) return;
    const document = await createDocumentRemote(dossier._id, {
      document_template_id: item.document_template_id,
      parent_id: item.parent_id,
      type: item.type,
      childIndex: item.childIndex + 1,
      versions: [],
    })
    if (document) {
      setCurrentTreeItem(document as TreeItem)
    }
  }

  handleMoveDocument = async (itemId: string, movement: number) => {
    const { dossier } = this.props;
    if (!dossier) return;
    await client.post(`/dossiers/:dossierId/documents/:documentId/move-index`, {
      params: {
        dossierId: dossier._id,
        documentId: itemId,
      },
      data: { movement },
    });
  }

  handleDownload = async (item?: DossierTreeItem | null, options: DownloadDocumentOptions = {}) => {
    const { getCachedDocument, dossier, selectedTreeItem } = this.props;
    if (item) {
      setCurrentTreeItem(item);
    } else {
      item = selectedTreeItem;
    }
    if (!dossier || !item) return;
    const document = await getCachedDocument(dossier._id, item._id);
    if (!document) return;
    let url = `/dossiers/${dossier._id}/documents/${document._id}/download`;
    client.clientRedirect(url, options);
  }

  renderView() {
    const { panelStatus, dossier, match, selectedTreeItem } = this.props;
    const { viewMode, dossierId } = match.params;

    if (!dossier || !dossier.tree) {
      return null
    }

    switch (viewMode) {
      case ViewMode.Scroll:
        return (
          <ScrollView
            id={`scroll-view-${dossier._id}`}
            tree={dossier.tree}
            selectedItem={selectedTreeItem}
            onSelectedItemChange={this.handleSelectedItemChange}
          />
        );
      case ViewMode.Stacks:
        return (
          <StacksView
            id={`stacks-view-${dossier._id}`}
            tree={dossier.tree}
            selectedItem={selectedTreeItem}
            onSelectedItemChange ={this.handleSelectedItemChange}
            dossier={dossier}
          />
        );
      case ViewMode.Entities:
        return (
          <EntityView />
        )
      case ViewMode.Facts:
        return (
          <FactView {...this.props} />
        );
      case ViewMode.Timeline:
        return (
          <Timeline />
        )
      case ViewMode.Map:
        return (
          <MapComp />
        )
      default:
        return (
          <Redirect to={`/dossier/${dossierId}/scroll`} />
        );
    }
  }

  render() {
    const {
      classes,
      panelStatus,
      selectedTreeItem,
      dossier,
      width,
      togglePanel,
      togglePanelPin,
      toggleSinglePanel,
      updatePanelWidth,
    } = this.props;
    const { openDocCreator } = this.state;
    const isSmallScreen = isWidthDown('xs', width);
    const actionButtonSize: FabProps['size'] = isSmallScreen ? 'small' : 'medium';
    const position = getContainerPosition(panelStatus, true);
    return (
      <DossierContext.Provider value={this.state}>
        <Layout className="dossier">
          {!dossier ?
            <Loading />
            :
            <>
              {this.renderView()}
              <Sidebar
                align="left"
                open={panelStatus.left.open}
                autoClose={!panelStatus.left.pinned}
                onClose={() => togglePanel('left', false)}
                width={panelStatus.left.width}
                onWidthChange={(width) => updatePanelWidth('left', width)}
                style={{
                  left: isPanelOpenAndPinned(panelStatus.side) ? panelStatus.side.width : 0,
                }}
              >
                <LeftPanel
                  dossier={dossier}
                  status={panelStatus.left}
                  onPin={() => togglePanelPin('left')}
                  onChange={(panel) => toggleSinglePanel(panel, true)}
                  onClose={() => togglePanel('left', false)}
                  selectedItem={selectedTreeItem}
                  onSelect={item => this.handleTreeItemSelect(item)}
                />
              </Sidebar>

              <ShareThreadList target={this.state.target!} open={this.state.sharing} onClose={() => this.setState({sharing: false})} query={{ type: 'dossier', dossier_id: dossier._id}} />
              <Sidebar
                align="right"
                open={panelStatus.right.open}
                autoClose={!panelStatus.right.pinned}
                onClose={() => togglePanel('right', false)}
                width={panelStatus.right.width}
                onWidthChange={(width) => updatePanelWidth('right', width)}
                style={{
                  right: isPanelOpenAndPinned(panelStatus.chat) ? panelStatus.chat.width : 0,
                }}
              >
                <RightPanel
                  dossier={dossier}
                  status={panelStatus.right}
                  onPin={() => togglePanelPin('right')}
                  onChange={(panel) => toggleSinglePanel(panel, true)}
                  onClose={() => togglePanel('right', false)}
                  selectedItem={selectedTreeItem}
                  onSelect={item => this.handleTreeItemSelect(item)}
                />
              </Sidebar>
              <Tooltip title={__('nav.toggleLeftPanel')}>
                <Fab
                  size={actionButtonSize}
                  className={classes.treeButton}
                  style={{
                    transform: `translateX(-${position.right}px)`
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setTimeout(() => togglePanel('left', !panelStatus.left.open), 0);
                  }}
                >
                  <SimpleSvgIcon path={mdiFileTree} />
                </Fab>
              </Tooltip>
              <AssistantPanel />
              {/* <StatusBar /> */}
              <Route
                path={`/dossier/:dossierId/:viewMode/document/:documentId/editor`}
                exact
                component={DocumentEditorDialog}
              />
              <Route
                path={`/dossier/:dossierId/:viewMode/document/:documentId/evi-editor`}
                exact
                component={EvidenceEditorDialog}
              />
              <Route
                path={`/dossier/:dossierId/:viewMode/document/:documentId/view/version/:version`}
                exact
                component={DocumentViewDialog}
              />
              <SimpleUploader
                accept={getAcceptString('default', true)}
                getRef={ref => this.uploader = ref}
                getUploadUrl={getUploadUrl}
                onUploadFinish={(file) => this.handleFileUpload(file)}
              />
            </>
          }
          {/* <DocCreator current={selectedTreeItem} open={openDocCreator}/> */}
        </Layout>
      </DossierContext.Provider>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  panelStatus: states.ui.panelStatus,
  selectedTreeItem: states.ui.selectedTreeItem,
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  togglePanel: (side, status) => dispatch(togglePanel(side, status)),
  togglePanelPin: (side, status) => dispatch(togglePanelPin(side, status)),
  toggleSinglePanel: (panel, status) => dispatch(toggleSinglePanel(panel, status)),
  updatePanelWidth: (side, width) => dispatch(updatePanelWidth(side, width)),
  setCurrentTreeItem: (item) => dispatch(setCurrentTreeItem(item)),
  fetchDossier: (dossierId) => dispatch(fetchDossier(dossierId)),
  fetchDossierTemplate: (dossierId) => dispatch(fetchDossierTemplate(dossierId)),
  fetchDocumentList: (dossierId) => dispatch(fetchDocumentList(dossierId)),
  updateDocumentRemote: (dossierId, data) => dispatch(updateDocumentRemote(dossierId, data)),
  getCachedDocument: (dossierId, documentId) => dispatch(getCachedDocument(dossierId, documentId)),
  setCurrentDocument: (document) => dispatch(setCurrentDocument(document)),
  createDocumentRemote: (dossierId, data) => dispatch(createDocumentRemote(dossierId, data)),
  fetchMemberList: (dossierId) => dispatch(fetchMemberList({ type: 'dossier', dossier_id: dossierId })),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(Dossier);
