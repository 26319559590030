import qs from 'qs';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router';
import { Paper, Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import './Dashboard.scss';
import { RootState } from '../../store/reducers';
import Layout from '../Common/Layout';
import { __ } from '../../utils/intl';
import FileCreationWizard from './FileCreationWizard';
import DossierList from '../Dossier/DossierList';
import CenterBox from '../Common/CenterBox';

const styles = (theme: Theme) => createStyles({
  container: {
    width: '100%',
    maxWidth: 720,
    margin: '0 auto',
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithStyles<typeof styles>;

interface State {
}

class Dashboard extends React.Component<Props, State> {

  state: State = {
  }

  componentDidMount() {
  }

  render() {
    const { classes, location } = this.props;
    return (
      <Layout className="dashboard">
        <Switch>
          <Redirect exact from="/dashboard" to="/dashboard/list" />
          <Route path="/dashboard/create" component={FileCreationWizard} />
          <CenterBox paper innerProps={{ p: [2, 4] }}>
            <Route
              path="/dashboard/list"
              render={() => (
                <DossierList
                  query={qs.parse(location.search.slice(1))}
                  title={__('dossier.list.all')}
                />
              )}
            />
          </CenterBox>
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Dashboard);
