import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { mdiCircleSmall, mdiCircleMedium, mdiCircle } from '@mdi/js';

import SimpleSvgIcon from './SimpleSvgIcon';

export type IconSize = 'small' | 'large' | 'medium';

interface Props extends SvgIconProps {
  size?: IconSize;
}

const iconMap = {
  small: mdiCircleSmall,
  medium: mdiCircleMedium,
  large: mdiCircle,
}

const RedAlertIcon: React.SFC<Props> = (props) => {
  const path = iconMap[props.size || 'small'] || mdiCircleSmall;
  return (
    <SimpleSvgIcon
      color="secondary"
      path={path}
      {...props}
    />
  );
}

export default RedAlertIcon;
