import React from 'react';
import clsx from 'clsx';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import TemplateEditor from './TemplateEditor';

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

export interface OwnProps {
}

type Props = OwnProps & WithStyles<typeof styles>;

class Index extends React.Component<Props> {

  static defaultProps: OwnProps = {
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <TemplateEditor />
      </div>
    );
  }

}

export default withStyles(styles)(Index);
