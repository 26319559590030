import React from 'react';
import { IconButton, FormControl, InputLabel, Input, InputAdornment } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { __ } from '../../utils/intl';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

interface OwnProps {
  className?: string;
  placeholder?: string;
  onSearch: (value: string) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  filterValue: string;
}

class ListFilter extends React.Component<Props, State> {

  state = {
    filterValue: '',
  }

  handleKeyDown(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (event.key === 'Enter') {
      setTimeout(() => {
        this.handleSearch();
      }, 0);
    }
  }

  handleSearch() {
    const { onSearch } = this.props;
    const { filterValue } = this.state;
    onSearch(filterValue);
  }

  render() {
    const { classes, className, placeholder, onSearch } = this.props;
    const { filterValue } = this.state;
    return (
      <FormControl fullWidth className={className}>
        <InputLabel>{placeholder || __('common.search')}</InputLabel>
        <Input
          value={filterValue}
          onChange={event => this.setState({ filterValue: event.target.value })}
          onKeyDown={event => this.handleKeyDown(event)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Search"
                onClick={() => this.handleSearch()}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    )
  }
}

export default withStyles(styles)(ListFilter);
