import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { SelectOption } from '../../types/form';

const styles = (theme: Theme) => createStyles({
  control: {
    margin: theme.spacing(3),
  },
});

export type CheckboxOption = SelectOption;

interface OwnProps {
  className?: string;
  options: CheckboxOption[];
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class CheckboxGroup extends React.Component<Props> {

  handleChange(value: string, checked: boolean) {
    const { onChange, value: selectedValues } = this.props;
    if (checked) {
      onChange([...selectedValues, value]);
    } else {
      onChange(_.without(selectedValues, value));
    }
  }

  render() {
    const { classes, className, options, label, value: selectedValues } = this.props;
    return (
      <FormControl component="fieldset" className={classNames(classes.control, className)}>
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              value={value}
              checked={selectedValues.includes(value)}
              onChange={(event, checked) => this.handleChange(value, checked)}
              control={<Checkbox />}
              label={label}
            />
          ))}
        </FormGroup>
      </FormControl>
    )
  }
}

export default withStyles(styles)(CheckboxGroup);
