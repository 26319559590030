import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import {  List, ListSubheader, ListItemText, Button, Dialog, DialogTitle, ListItem } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { MemberData, checkPermission, MemberIdentity } from '../../types/member';
import { UserData } from '../../types/user';
import { DossierData } from '../../types/dossier';
import { RootState } from '../../store/reducers';
import {
  fetchListRemote as fetchMemberListRemote,
} from '../../store/member/actions';
import { getThreadRemote } from '../../store/chat/actions'
import MemberListItem from './MemberListItem';
import { ThreadOptions, ChatThreadData, ChatMessageOptions } from '../../types/chat';
import { socket } from '../../store/socket';
import { TargetIdentity } from '../../types/notification';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  formControl: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
  },
  memberList: {
    flexGrow: 1,
    overflow: 'auto',
    minWidth: 250,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    padding: theme.spacing(2),
  },
  placeholder: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
});

interface StateProps {
  user: UserData | null;
  memberList: MemberData[];
  dossier: DossierData | null;
}

interface DispatchProps {
  fetchMemberListRemote: (query: MemberIdentity) => Promise<MemberData[]>;
  getThreadRemote: (options: ThreadOptions) => Promise<ChatThreadData>
}

interface OwnProps {
  title?: string;
  query: MemberIdentity;
  open: boolean;
  onClose: () => void;
  target: TargetIdentity;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

interface State {
  selected: MemberData | null;
  inviteMemberOpen: boolean;
}

class MemberList extends React.Component<Props, State> {

  state: State = {
    selected: null,
    inviteMemberOpen: false,
  }

  async componentDidMount() {
    const { query, fetchMemberListRemote } = this.props;
    await fetchMemberListRemote(query);
  }

  renderTeam = () => {
    const { query, dossier, classes } = this.props;
    let children:React.ReactElement | null = null;
    switch (query.type) {
      case 'dossier':
        children = (
          <ListItem key={dossier!._id} component="div" onClick={this.handleSelectDossier} button>
            <ListItemText
              primary={`卷宗: ${dossier!.name}`}
              secondary={'共xxx人'}
            />
          </ListItem>
        )
        break;
    
      default:
        break;
    }
    return (
      <List className={classes.memberList}>
        {children}
      </List>
    )
  }

  handleSelectDossier = async () => {
    console.log('click select')
    const {getThreadRemote, dossier, onClose, user, target} = this.props;
    const thread = await getThreadRemote({to: {type: 'dossier', dossier_id: dossier!._id}})

    this.handleSelect(thread._id)
  }

  handleSelectMember = async (member: MemberData) => {
    const {getThreadRemote, dossier, onClose, user, target} = this.props;
    const thread = await getThreadRemote({to: {type: 'user', user_id: member.user_id}})
    this.handleSelect(thread._id)
  }
  
  handleSelect = (threadId: string) => {
    const { onClose, user, target } = this.props;

    const options: ChatMessageOptions = {
      from: {
        user_id: user!._id,
      },
      to: {
        type: 'thread',
        thread_id: threadId,
      },
      attachment: {
        type: 'target',
        target,
      }
    };
    setTimeout(() => {
      socket.emit('im:send', options)
    }, 0)
    onClose()
  }

  render() {
    const { classes, user, memberList, open } = this.props;
    const { selected } = this.state;
    if (!user) return null;
    const currentMember = _.find(memberList, { user_id: user._id });
    if (!currentMember) return null;
    const members = memberList//.filter((m) => m.user_id !== user._id);

    return (
      <Dialog open={open} onClose={this.props.onClose}>
        <DialogTitle>分享</DialogTitle>
        {this.renderTeam()}
        <List className={classes.memberList}>
          {members.length ? members.map(member => (
            <MemberListItem
              key={member._id}
              selected={selected ? selected.user_id === member._id : false}
              member={member}
              onClick={() => this.handleSelectMember(member)}
            />
          )) : <div className={classes.placeholder}>no members</div>}
        </List>
      </Dialog>
    );
  }

}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  user: states.user.current,
  memberList: states.member.list,
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchMemberListRemote: (query) => dispatch(fetchMemberListRemote(query)),
  getThreadRemote: (options) => dispatch(getThreadRemote(options))
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MemberList);
