import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { OrganizationFormItem, FormItem, GroupFormItem } from '../../types/form';
import { __ } from '../../utils/intl';
import GroupControl from './GroupControl';
import { BaseFormControlProps } from './types';

const fields: FormItem[] = [
  { key: 'name', label: __('organization.field.name'), type: 'text' },
  // { key: 'legalRep', label: __('organization.field.legalRep'), type: 'text' },
  { key: 'creditCode', label: __('organization.field.creditCode'), type: 'text' },
  { key: 'phone', label: __('organization.field.phone'), type: 'text' },
  { key: 'province', label: __('organization.field.province'), type: 'text' },
  { key: 'address', label: __('organization.field.address'), type: 'text' },
];

const styles = (theme: Theme) => createStyles({
});

interface OwnProps extends BaseFormControlProps {
  scopes: any[];
  item: OrganizationFormItem;
  value: any;
  readOnly?: boolean;
  onChange: (values: any) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class PersonControl extends React.Component<Props, State> {

  render() {
    const { classes, item, ...restProps } = this.props;
    let items = fields;
    if (item.items) {
      items = [...fields, ...item.items];
    }
    return (
      <GroupControl
        className="organization-control"
        item={{
          ...item,
          type: 'group',
          items,
        }}
        { ...restProps }
      />
    )
  }

}

export default withStyles(styles)(PersonControl);
