import { combineReducers, Reducer } from 'redux';
import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router';
import { History } from 'history';

import claimReducer, { ClaimState } from './claim/reducers';
import chatReducer, { ChatState } from './chat/reducers';
import dossierReducer, { DossierState } from './dossier/reducers';
import dossierTemplateReducer, { DossierTemplateState } from './dossierTemplate/reducers';
import documentReducer, { DocumentState } from './document/reducers';
import factReducer, { FactState } from './fact/reducers';
import infoReducer, { InfoState } from './info/reducers';
import invitationReducer, { InvitationState } from './invitation/reducers';
import memberReducer, { MemberState } from './member/reducers';
import notificationReducer, { NotificationState } from './notification/reducers';
import organizationReducer, { OrganizationState } from './organization/reducers';
import personReducer, { PersonState } from './person/reducers';
import teamReducer, { TeamState } from './team/reducers';
import uiReducer, { UIState } from './ui/reducers';
import userReducer, { UserState } from './user/reducers';
import { Action } from './types';

export interface RootState {
  router: RouterState;
  chat: ChatState;
  claim: ClaimState;
  document: DocumentState;
  dossier: DossierState;
  dossierTemplate: DossierTemplateState;
  fact: FactState;
  info: InfoState;
  invitation: InvitationState;
  member: MemberState;
  notification: NotificationState;
  organization: OrganizationState;
  person: PersonState;
  team: TeamState;
  ui: UIState;
  user: UserState;
}

const createRootReducer = (history: History) => combineReducers<RootState>({
  router: connectRouter(history),
  chat: chatReducer,
  claim: claimReducer,
  document: documentReducer,
  dossier: dossierReducer,
  dossierTemplate: dossierTemplateReducer,
  fact: factReducer,
  info: infoReducer,
  invitation: invitationReducer,
  member: memberReducer,
  notification: notificationReducer,
  organization: organizationReducer,
  person: personReducer,
  team: teamReducer,
  ui: uiReducer,
  user: userReducer,
});

export default createRootReducer;
