

import { FormItem } from '../../types/form'

export const partyFormItem: FormItem[] = [
  {
    key: 'isClient', label: '委托人', type: 'boolean',
  },
  {
    key: '类型', label: '类型', type: 'select',
    options: [
      { label: '自然人（完全民事行为能力）', value: '自然人（完全民事行为能力）' },
      { label: '自然人（无或限制民事行为能力）', value: '自然人（无或限制民事行为能力）' },
      { label: '法人', value: '法人' },
      { label: '非法人组织', value: '非法人组织' },
    ],
  },
  {
    key: '诉讼地位', label: '诉讼地位', type: 'select',
    options: [
      { label: '原告', value: '原告' },
      { label: '被告', value: '被告' },
    ],
    condition: {
      operator: '=',
      key: '阶段',
      value: '一审',
    },
  },
  {
    key: '诉讼地位', label: '诉讼地位', type: 'select',
    options: [
      { label: '上诉人', value: '上诉人' },
      { label: '被上诉人', value: '被上诉人' },
    ],
    condition: {
      operator: '=',
      key: '阶段',
      value: '二审',
    },
  },
  {
    key: '诉讼地位', label: '诉讼地位', type: 'select',
    options: [
      { label: '再审申请人', value: '再审申请人' },
      { label: '再审被申请人', value: '再审被申请人' },
    ],
    condition: {
      operator: '=',
      key: '阶段',
      value: '再审',
    },
  },
  {
    key: 'profile', label: '基本信息', type: 'person',
    condition: {
      key: '类型',
      operator: 'IN',
      value: ['自然人（完全民事行为能力）', '自然人（无或限制民事行为能力）']
    },
  },
  {
    key: 'profile', label: '基本信息', type: 'organization',
    condition: {
      key: '类型', operator: 'IN', value: ['法人', '非法人组织']
    },
  },
  {
    key: '法定监护人', label: '法定监护人', type: 'person',
    items: [
      {
        key: '与委托人的关系',
        label: '与委托人的关系',
        type: 'text',
      }
    ],
    condition: {
      key: '类型', operator: '=', value: '自然人（无或限制民事行为能力）',
    },
  },
  {
    key: '法定代表人', label: '法定代表人', type: 'person',
    condition: {
      key: '类型', operator: '=', value: '法人',
    },
  },
  {
    key: '负责人', label: '负责人', type: 'person',
    condition: {
      key: '类型', operator: '=', value: '非法人组织',
    },
  },
  {
    key: '联系人', label: '联系人', type: 'person',
    items: [
      {
        key: '与委托人的关系',
        label: '与委托人的关系',
        type: 'text',
      },
    ],
    optional: true,
  },
  {
    key: '其他诉讼代理人', label: '其他诉讼代理人', type: 'person',
    items: [
      {
        key: '与委托人的关系',
        label: '与委托人的关系',
        type: 'text',
      },
    ],
    optional: true,
  },
]

export const partyForm: FormItem = {
  label: '当事人信息', key: 'parties', type: 'array',
  primaryKey: 'profile.name',
  item: {
    key: '',
    label: '当事人',
    type: 'group',
    items: partyFormItem,
  },
}

export const basicForms: FormItem[] = [
  { key: '案由', label: '案由', type: 'text' },
  {
    key: '阶段', label: '阶段', type: 'select',
    options: [
      { label: '一审', value: '一审' },
      { label: '二审', value: '二审' },
      { label: '再审', value: '再审' },
    ],
  },
]

export const stepOneForm: FormItem = {
  key: '', label: '案件基本信息', type: 'group',
  condition: {
    key: '阶段', operator: '=', value: '一审',
  },
  items: [
    {
      key: '', label: '一审', type: 'group',
      items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        {
          key: '审判程序', label: '审判程序', type: 'select',
          options: [
            { label: '普通程序', value: '普通程序' },
            { label: '简易程序', value: '简易程序' },
          ]
        },
        { key: '审判长或独任审判员', label: '审判长或独任审判员', type: 'text' },
        { key: '审判员或陪审员1', label: '审判员或陪审员1', type: 'text' },
        { key: '审判员或陪审员2', label: '审判员或陪审员2', type: 'text' },
        { key: '开庭时间', label: '开庭时间', type: 'date' },
        { key: '开庭地点', label: '开庭地点', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ],
        },
        { key: '简要案情', label: '简要案情', type: 'text' },
        {
          key: '是否涉及特殊程序', label: '是否涉及特殊程序', type: 'select',
          options: [
            { label: '需要申请诉前财产保全/对方申请了诉前财产保全', value: '需要申请诉前财产保全/对方申请了诉前财产保全' },
            { label: '需要申请诉中财产保全/对方申请了诉中财产保全', value: '需要申请诉中财产保全/对方申请了诉中财产保全' },
            { label: '需要申请法院调取证据【对方申请了法院调取证据】', value: '需要申请法院调取证据【对方申请了法院调取证据】' },
            { label: '需要申请证据保全【对方申请了证据保全】', value: '需要申请证据保全【对方申请了证据保全】' },
            { label: '需要申请司法鉴定【对方申请了司法鉴定】', value: '需要申请司法鉴定【对方申请了司法鉴定】' },
            { label: '对方提出了反诉/需要提出反诉', value: '对方提出了反诉/需要提出反诉' },
            { label: '对方提出了管辖异议/需要提出管辖异议', value: '对方提出了管辖异议/需要提出管辖异议' },
            { label: '需要申请回避【对方申请回避】', value: '需要申请回避【对方申请回避】' },
            { label: '需要延长举证期限【对方申请延长举证期限】', value: '需要延长举证期限【对方申请延长举证期限】' },
            { label: '需要申请延期开庭【对方申请延期开庭】', value: '需要申请延期开庭【对方申请延期开庭】' },
            { label: '需要申请中止诉讼【对方申请中止诉讼】', value: '需要申请中止诉讼【对方申请中止诉讼】' },
            { label: '需要庭前调解【需要庭外和解】', value: '需要庭前调解【需要庭外和解】' },
          ],
          multiple: true,
        },
      ],
    },
  ],
}

export const stepTwoForm: FormItem = {
  key: '', label: '案件基本信息', type: 'group',
  condition: {
    key: '阶段', operator: '=', value: '二审',
  },
  items: [
    {
      key: '一审', label: '一审', type: 'group', optional: true,
      items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        { key: '审判长', label: '审判长', type: 'text' },
        { key: '审判员', label: '审判员', type: 'text' },
        { key: '审判员', label: '审判员', type: 'text' },
        { key: '原告姓名或名称', label: '原告姓名或名称', type: 'text' },
        { key: '被告姓名或名称', label: '被告姓名或名称', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ],
        },
      ]
    },
    {
      key: '', label: '二审', type: 'group',
      items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        { key: '审判长', label: '审判长', type: 'text' },
        { key: '审判员', label: '审判员', type: 'text' },
        { key: '审判员', label: '审判员', type: 'text' },
        { key: '开庭时间', label: '开庭时间', type: 'date' },
        { key: '开庭地点', label: '开庭地点', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ],
        },
        {
          key: '是否涉及特殊程序', label: '是否涉及特殊程序', type: 'select',
          options: [
            { label: '需要申请回避【对方申请回避】', value: '需要申请回避【对方申请回避】' },
            { label: '需要申请延期开庭【对方申请延期开庭】', value: '需要申请延期开庭【对方申请延期开庭】' },
            { label: '需要申请中止诉讼【对方申请中止诉讼】', value: '需要申请中止诉讼【对方申请中止诉讼】' },
            { label: '需要庭前调解【需要庭外和解】', value: '需要庭前调解【需要庭外和解】' },
          ],
          multiple: true,
        }
      ],
    },
  ],
}

export const stepThreeForm: FormItem = {
  key: '', label: '案件基本信息', type: 'group',
  condition: {
    key: '阶段',
    operator: '=',
    value: '再审',
  },
  items: [
    {
      key: '一审', label: '一审', type: 'group', optional: true,
      items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        { key: '原告姓名或名称', label: '原告姓名或名称', type: 'text' },
        { key: '被告姓名或名称', label: '被告姓名或名称', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ],
        },
      ]
    },
    {
      key: '二审', label: '二审', type: 'group', optional: true,
      items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        { key: '上诉人姓名或名称', label: '原告姓名或名称', type: 'text' },
        { key: '被上诉人姓名或名称', label: '被告姓名或名称', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ]
        },
        {
          key: '是否涉及特殊程序', label: '是否涉及特殊程序', type: 'select',
          options: [
            { label: '需要申请回避【对方申请回避】', value: '需要申请回避【对方申请回避】' },
            { label: '需要申请延期开庭【对方申请延期开庭】', value: '需要申请延期开庭【对方申请延期开庭】' },
            { label: '需要申请中止诉讼【对方申请中止诉讼】', value: '需要申请中止诉讼【对方申请中止诉讼】' },
            { label: '需要庭前调解【需要庭外和解】', value: '需要庭前调解【需要庭外和解】' },
          ],
          multiple: true,
        }]
    },
    {
      key: '再审', label: '再审', type: 'group', items: [
        { key: '法院名称', label: '法院名称', type: 'text' },
        { key: '法院案号', label: '法院案号', type: 'text' },
        { key: '开庭时间', label: '开庭时间', type: 'date' },
        { key: '开庭地点', label: '开庭地点', type: 'text' },
        { key: '裁判文书做出日期', label: '裁判文书做出日期', type: 'date' },
        {
          key: '裁判文书类型', label: '裁判文书类型', type: 'select',
          options: [
            { label: '《民事判决书》', value: '《民事判决书》' },
            { label: '《民事裁定书》', value: '《民事裁定书》' },
            { label: '《民事调解书》', value: '《民事调解书》' },
          ]
        },
        {
          key: '是否涉及特殊程序', label: '是否涉及特殊程序', type: 'select', options: [
            { label: '需要申请回避【对方申请回避】', value: '需要申请回避【对方申请回避】' },
            { label: '需要申请延期开庭【对方申请延期开庭】', value: '需要申请延期开庭【对方申请延期开庭】' },
            { label: '需要申请中止诉讼【对方申请中止诉讼】', value: '需要申请中止诉讼【对方申请中止诉讼】' },
            { label: '需要庭前调解【需要庭外和解】', value: '需要庭前调解【需要庭外和解】' },
          ],
          multiple: true,
        },
      ],
    },
  ],
}

export const lawFirmInfo: FormItem = {
  key: '律所', label: '律所内部信息', type: 'group', optional: true, items: [
    {
      key: '基本信息', label: '律所基本信息', type: 'group',
      items: [
        { key: '名称', label: '名称', type: 'text' },
        { key: '户名', label: '户名', type: 'text' },
        { key: '开户行', label: '开户行', type: 'text' },
        { key: '账号', label: '账号', type: 'text' },
      ],
    },
    {
      key: '收案信息', label: '律所收案信息', type: 'group',
      items: [
        { key: '案号', label: '案号', type: 'text' },
        { key: '收案日期', label: '收案日期', type: 'date' },
      ],
    },
    {
      key: '主办律师', label: '主办律师信息', type: 'group',
      items: [
        { key: '姓名', label: '姓名', type: 'text' },
        { key: '电话', label: '电话', type: 'text' },
        { key: '律师事务所', label: '律师事务所', type: 'text' },
      ],
    },
    {
      key: '协办律师', label: '协办律师信息', type: 'array', primaryKey: '姓名',
      item: {
        key: '', label: '', type: 'group',
        items: [
          { key: '姓名', label: '姓名', type: 'text' },
          { key: '电话', label: '电话', type: 'text' },
          { key: '律师事务所', label: '律师事务所', type: 'text' },
        ],
      },
    },
    {
      key: '律师助理', label: '律师助理信息', type: 'array', primaryKey: '姓名',
      item: {
        key: '', label: '', type: 'group',
        items: [
          { key: '姓名', label: '姓名', type: 'text' },
          { key: '电话', label: '电话', type: 'text' },
          { key: '律师事务所', label: '律师事务所', type: 'text' },
        ],
      },
    },
  ],
}

export const baseForm: FormItem[] = [
  ...basicForms,
  partyForm,
  stepOneForm,
  stepTwoForm,
  stepThreeForm,
  lawFirmInfo,
]
