import _ from 'lodash';
import { Action } from './actions';
import { UserData, PublicUserInfo } from '../../types/user';
import { KeyValueMap } from '../../types/common';

export interface UserState {
  current: UserData | null;
  list: PublicUserInfo[];
}

const initialState: UserState = {
  current: null,
  list: [],
}

const userReducer = (state: UserState = initialState, action: Action): UserState => {
  switch (action.type) {
    case '@@user/SET_CURRENT_ITEM': {
      return { ...state, current: action.user };
    }
    case '@@user/CLEAR_CURRENT_ITEM': {
      return { ...state, current: null };
    }
    case '@@user/SET_LIST': {
      return { ...state, list: action.list };
    }
    case '@@user/UPDATE_LIST_ITEM': {
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list };
    }
    default:
      return state;
  }
}

export default userReducer;
