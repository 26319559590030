import React from 'react';
import { Route } from 'react-router-dom'

import { TreeItem } from '../../types/tree';
import { DocumentData } from '../../types/document';
import Document from './Document';

interface Props {
  container?: string;
  item: TreeItem;
  path: TreeItem[];
}

interface State {
}

class DocumentFragment extends React.Component<Props, State> {

  shouldComponentUpdate(nextProps: Readonly<Props>){
    return nextProps.item !== this.props.item;
  }

  render() {
    const { container, item, path } = this.props;
    const newPath = [...path, item];
    if (item.type === 'file') {
      return (
        <Route
          key={item._id}
          render={() => (
            <Document
              container={container}
              document={null}
              item={item}
              path={path}
            />
          )}
        />
      );
    } else {
      return (
        <section
          className="document-fragment"
          id={`document-${item._id}`}
          data-item-id={item._id}
        >
          {item.children && item.children.map(child => (
            <DocumentFragment
              container={container}
              key={child._id}
              item={child}
              path={newPath}
            />
          ))}
        </section>
      );
    }
  }
}

export default DocumentFragment;
