import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import { IconButton, Typography, Button, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { __ } from '../../utils/intl';
import { DocumentData } from '../../types/document';
import { FormItem } from '../../types/form';
import { TreeItem } from '../../types/tree';
import { RootState } from '../../store/reducers';
import {
  updateItemRemote as updateDocumentRemote,
  removeItemRemote as removeDocumentRemote,
} from '../../store/document/actions';
import FormControl from '../Form/FormControl';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  actions: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontSize: '14px',
  },
  form: {
    flexGrow: 1,
    overflow: 'auto',
  },
  bottomActions: {
  },
});

interface OwnProps {
}

interface StateProps {
  selectedItem: TreeItem | null;
  document: DocumentData | null;
}

interface DispatchProps {
  updateDocumentRemote: (dossierId: string | null, data: Partial<DocumentData>) => Promise<void>;
  removeDocumentRemote: (dossierId: string | null, documentId: string) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type EditableValue = Partial<Pick<DocumentData, 'name'>>;

interface State {
  editing: boolean;
  value: EditableValue;
  deleteConfirmOpen: boolean;
}

const fields: FormItem[] = [
  { key: 'name', label: __('document.field.name'), type: 'text' },
  { key: 'dateCreated', label: __('common.field.dateCreated'), type: 'datetime', readOnly: true },
  { key: 'dateModified', label: __('common.field.dateModified'), type: 'datetime', readOnly: true },
];

class InspectorPanel extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  componentWillReceiveProps(nextProps: Props) {
    const { editing } = this.state;
    if (!editing && nextProps.document && nextProps.document !== this.props.document) {
      this.setState(this.getStateFromProps(nextProps));
    }
  }

  getStateFromProps(props: Props): State {
    return {
      value: _.pick(props.document, ['name', 'dateCreated', 'dateModified']),
      editing: false,
      deleteConfirmOpen: false,
    }
  }

  handleChange(value: EditableValue) {
    this.setState({ value });
  }

  handleCancel() {
    this.setState(this.getStateFromProps(this.props));
  }

  handleSave() {
    const { document, updateDocumentRemote } = this.props;
    const { value } = this.state;
    if (!document) return;
    this.setState({ editing: false });
    updateDocumentRemote(document.dossier_id || null, {
      _id: document._id,
      ...value,
    });
  }

  async handleRemove() {
    const { document, removeDocumentRemote } = this.props;
    if (document) {
      await removeDocumentRemote(document.dossier_id || null, document._id);
    }
    this.setState({ deleteConfirmOpen: false });
  }

  render() {
    const { classes } = this.props;
    const { editing, value, deleteConfirmOpen } = this.state;
    if (!document) {
      return null;
    }
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.title}>
            {__('panels.inspector.documentInfoTitle')}
          </Typography>
          {editing ?
            <>
              <Tooltip title={__('dialog.cancel')}>
                <IconButton onClick={() => this.handleCancel()}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={__('dialog.save')}>
                <IconButton onClick={() => this.handleSave()}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </>
            :
            <Tooltip title={__('dialog.edit')}>
              <IconButton onClick={() => this.setState({ editing: true })}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          }
        </div>
        <FormControl
          className={classes.form}
          readOnly={!editing}
          items={fields}
          value={value}
          onChange={value => this.handleChange(value)}
        />
        <div className={classes.bottomActions}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => this.setState({ deleteConfirmOpen: true })}
          >
            {__('document.delete')}
          </Button>
        </div>
        <Confirm
          open={deleteConfirmOpen}
          title={__('document.delete')}
          content={__('document.deleteConfirmHint')}
          confirmButtonText={__('document.delete')}
          confirmButtonProps={{ color: 'secondary' }}
          onClose={() => this.setState({ deleteConfirmOpen: false })}
          onConfirm={() => this.handleRemove()}
        />
      </div>
    )
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  selectedItem: states.ui.selectedTreeItem,
  document: states.document.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  updateDocumentRemote: (dossierId, data) => dispatch(updateDocumentRemote(dossierId, data)),
  removeDocumentRemote: (dossierId, documentId) => dispatch(removeDocumentRemote(dossierId, documentId)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(InspectorPanel);
