import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import EditEvidence from '@material-ui/icons/FormatShapesSharp';
import {
  mdiFolderDownload,
  mdiFileReplace,
  mdiFilePlus,
} from '@mdi/js';

import { __ } from '../../utils/intl';
import { DocumentData, hasFlag } from '../../types/document';
import { DossierData } from '../../types/dossier';
import { TreeItem } from '../../types/tree';
import { RootState } from '../../store/reducers';
import { ViewMode } from '../../types/view';
import {
  getCachedItem as getCachedDocument,
} from '../../store/document/actions';
import DocumentPageContainer from './DocumentPageContainer';
import { DossierContext } from './dossierContext'
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import { ScrollViewContext } from './context';
import { ButtonProps } from '@material-ui/core/Button';

const styles = (theme: Theme) => createStyles({
  page: {
    margin: theme.spacing(4, 0),
  },
});

interface StateProps {
  dossier: DossierData | null,
  document: DocumentData | null,
}

interface DispatchProps {
  getCachedDocument: (dossierId: string, documentId: string) => Promise<DocumentData | undefined>
}

interface OwnProps {
  container?: string;
  item: TreeItem;
  document: DocumentData | null,
  path: TreeItem[];
}

interface State {
  document: DocumentData | null,
  formDialogOpen: boolean;
  factDialogOpen: boolean
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

export const tooltipProps: Partial<TooltipProps> = {
  placement: 'right',
}

class Document extends React.Component<Props, State> {

  state: State = {
    document: null,
    formDialogOpen: false,
    factDialogOpen: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.loadData(this.props);
    }, 5000);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.item._id !== this.props.item._id) {
      this.loadData(nextProps);
    }
  }

  async loadData(props: Props) {
    const { dossier, item, document: doc, getCachedDocument } = props;
    if (!dossier || doc) return;
    const document = await getCachedDocument(dossier._id, item._id);
    this.setState({ document: document || null });
  }

  renderActions() {
    const { dossier, item } = this.props;
    const { document } = this.state;
    const isCatalog = hasFlag(document, 'catalog');
    const isEvidence = hasFlag(document, 'evidence');
    const isEditable = !dossier || !dossier.isArchived;
    return (
      <DossierContext.Consumer>
        {({
          routeToDocumentEditor,
          routeToEvidenceEditor,
          openDocumentPanel,
          uploadDocument,
          downloadDocument,
          shareInDossier
        }) => (
          <ScrollViewContext.Consumer>
            {({ onlyDocument }) => (
              <>
                {!onlyDocument && isEditable &&
                  <>
                    <Tooltip {...tooltipProps} title={__('document.viewInfo')}>
                      <IconButton onClick={() => openDocumentPanel(item, 'inspector')}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                    {dossier && !isEvidence &&
                      <Tooltip {...tooltipProps} title={__('document.edit')}>
                        <IconButton onClick={() => routeToDocumentEditor(item)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }
                    <Tooltip {...tooltipProps} title={__('document.uploadNewVersion')}>
                      <IconButton onClick={() => uploadDocument(item, true)}>
                        <SimpleSvgIcon path={mdiFileReplace} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip {...tooltipProps} title={__('document.uploadSameClass')}>
                      <IconButton onClick={() => uploadDocument(item)}>
                        <SimpleSvgIcon path={mdiFilePlus} />
                      </IconButton>
                    </Tooltip>
                    {document && hasFlag(document, 'evidence') &&
                      <Tooltip {...tooltipProps} title={__('document.openEvidenceEditor')}>
                        <IconButton onClick={() => routeToEvidenceEditor(item)}>
                          <EditEvidence />
                        </IconButton>
                      </Tooltip>
                    }
                    {document &&
                      <Tooltip {...tooltipProps} title={__('document.share')}>
                        <IconButton onClick={() => shareInDossier({type: 'document', document_id: document._id})}>
                          <ShareIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </>
                }
                {document && document.fileType !== 'empty' &&
                  <Tooltip {...tooltipProps} title={__('document.download')}>
                    <IconButton onClick={() => downloadDocument(item)}>
                      <SimpleSvgIcon path={mdiFolderDownload} />
                    </IconButton>
                  </Tooltip>
                }
              </>
            )}
          </ScrollViewContext.Consumer>
        )}
      </DossierContext.Consumer>
    )
  }

  render() {
    const { document: _document } = this.state;
    const { classes, container, item, path, document = _document } = this.props;
    const sectionName = _.map(path.slice(1), 'name').join(' - ');
    const actions = this.renderActions();
    return (
      <section
        className="document document-node"
        id={`document-${item._id}`}
        data-item-id={item._id}
      >
        <DossierContext.Consumer>
          {({
            downloadDocument,
            routeToDocumentEditor,
          }) => (
            <>
              {document && document.pages ?
                document.pages.map((page, index, pages) => (
                  <DocumentPageContainer
                    key={page._id}
                    className={classes.page}
                    container={container}
                    document={document}
                    sectionName={sectionName}
                    pageName={`${item.name}${pages.length > 1 ? ` ${index + 1} / ${pages.length}` : ''}`}
                    actions={actions}
                    page={page}
                    pageNumber={(item.pageStart || 1) + index}
                    onDownload={() => downloadDocument(item, { page: index + 1 })}
                    onEdit={() => routeToDocumentEditor(item)}
                  />
                ))
                :
                <DocumentPageContainer
                  className={classes.page}
                  container={container}
                  document={document}
                  sectionName={sectionName}
                  pageName={item.name}
                  actions={actions}
                  page={null}
                  pageNumber={(item.pageStart || 1)}
                  onEdit={() => routeToDocumentEditor(item)}
                />
              }
            </>
          )}
        </DossierContext.Consumer>
      </section>
    );
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  dossier: states.dossier.current,
  document: props.document || _.find(states.document.list, { _id: props.item._id }) || null,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  getCachedDocument: (dossierId, documentId) => dispatch(getCachedDocument(dossierId, documentId)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Document);
