import _ from 'lodash';
import { TreeItem } from '../../types/tree';
import { PanelType, PanelSide, SinglePanelStatus } from './reducers';
import { ThunkActionType } from '../types';
import { DocumentData } from '../../types/document';
import { setItem } from '../document/actions';

// Action Definition
export interface UpdatePanelStatus {
  type: '@@ui/UPDATE_PANEL_STATUS';
  side: PanelSide;
  status: Partial<SinglePanelStatus>;
}

export interface ToggleSidePanel {
  type: '@@ui/TOGGLE_SIDE_PANEL';
  side: PanelSide;
  status?: boolean;
}

export interface ToggleSidePanelPin {
  type: '@@ui/TOGGLE_SIDE_PANEL_PIN';
  side: PanelSide;
  status?: boolean;
}

export interface ToggleSidePanelMinified {
  type: '@@ui/TOGGLE_SIDE_PANEL_MINIFIED';
  side: PanelSide;
  status?: boolean;
}

export interface ToggleSinglePanel {
  type: '@@ui/TOGGLE_SINGLE_PANEL';
  panel: PanelType;
  status?: boolean;
}

export interface UpdatePanelWidth {
  type: '@@ui/UPDATE_PANEL_WIDTH';
  side: PanelSide;
  width?: number;
}

export interface SetSelectedTreeItem {
  type: '@@ui/SET_SELECTED_TREE_ITEM';
  item: TreeItem | null;
}

// Union Action Types
export type Action = (
  UpdatePanelStatus |
  ToggleSidePanel |
  ToggleSidePanelPin |
  ToggleSidePanelMinified |
  ToggleSinglePanel |
  SetSelectedTreeItem |
  UpdatePanelWidth
);

// Action Creators
export const updatePanelStatus = (side: PanelSide, status: Partial<SinglePanelStatus>): UpdatePanelStatus => ({
  type: '@@ui/UPDATE_PANEL_STATUS',
  side,
  status,
});

export const togglePanel = (side: PanelSide, status?: boolean): ToggleSidePanel => ({
  type: '@@ui/TOGGLE_SIDE_PANEL',
  side,
  status,
});

export const togglePanelPin = (side: PanelSide, status?: boolean): ToggleSidePanelPin => ({
  type: '@@ui/TOGGLE_SIDE_PANEL_PIN',
  side,
  status,
});

export const togglePanelMinified = (side: PanelSide, status?: boolean): ToggleSidePanelMinified => ({
  type: '@@ui/TOGGLE_SIDE_PANEL_MINIFIED',
  side,
  status,
});

export const toggleSinglePanel = (panel: PanelType, status?: boolean): ToggleSinglePanel => ({
  type: '@@ui/TOGGLE_SINGLE_PANEL',
  panel,
  status,
});

export const updatePanelWidth = (side: PanelSide, width?: number): UpdatePanelWidth => ({
  type: '@@ui/UPDATE_PANEL_WIDTH',
  side,
  width,
});

export const setSelectedTreeItem = (item: TreeItem | null): SetSelectedTreeItem => ({
  type: '@@ui/SET_SELECTED_TREE_ITEM',
  item,
});

export const setCurrentTreeItem = (item: TreeItem | null): ThunkActionType => {
  return async (dispatch, getState): Promise<void> => {
    const { document: { list: documentList }} = getState();
    let document: DocumentData | null;
    if (!item) {
      document = null;
    } else {
      document = _.find(documentList, { _id: item._id }) || null;
    }
    dispatch(setSelectedTreeItem(item));
    if (document) {
      dispatch(setItem(document));
    }
  };
};
