import { FactData } from '../../types/fact';
import { ThunkActionType, ThunkDispatchType } from '../types';
import client from '../client';
import { history } from '../configureStore';

// Action Definition
export interface SetList {
  type: '@@fact/SET_LIST';
  list: FactData[];
}


// Union Action Types
export type Action = SetList;

export const setList = (list: FactData[]): SetList => {
  return {type: '@@fact/SET_LIST', list}
}

export const fetchListRemote = (dossierId: string): ThunkActionType => {  
  return async (dispatch): Promise<void> => {
    const facts = await client.get<FactData[]>(`/dossiers/${dossierId}/facts`);
    dispatch(setList(facts));
  }
}
