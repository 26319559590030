import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import classNames from 'classnames';
import { Typography, IconButton } from '@material-ui/core';
import { grey, blue } from '@material-ui/core/colors';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveLeftIcon from '@material-ui/icons/ArrowBack';
import MoveRightIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/CheckCircle';

import { TreeItem } from '../../types/tree';
import { Point2D } from '../../types/drawing';
import { DossierData } from '../../types/dossier';
import { findFirstLeaf } from '../../utils/tree';
import { history } from '../../store/configureStore';
import { RootState } from '../../store/reducers';
import { setCurrentTreeItem } from '../../store/ui/actions';
import { getCachedItem as getCachedDocument } from '../../store/document/actions';
import {
  STACK_EXPLODING_DURATION,
  PAPER_WIDTH,
  STACK_ITEM_DEFAULT_WIDTH,
  STACK_ITEM_DEFAULT_HEIGHT,
  STACK_ITEM_PADDING,
} from '../Dossier/constants';
import { DocumentData, PageContent } from '../../types/document';
import DocumentPage from '../Dossier/DocumentPage';

interface StackTransform {
  rotate: number;
  translate: Point2D;
}

// faking shaking of stack of paper
const stackTransformSerial: StackTransform[] = [
  { rotate: 0, translate: { x: 0, y: 0 } },
  { rotate: -2, translate: { x: -1, y: 1 } },
  { rotate: 2, translate: { x: 1, y: 2 } },
];

// const stackWidth = paperWidth + STACK_ITEM_PADDING * 2;
const stackHeight = 340;
const maxScale = 4;

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    // width: stackWidth,
    // height: stackHeight,
    cursor: 'pointer',
    borderRadius: 4,
    '&.fading': {
      transition: `opacity ease-out ${STACK_EXPLODING_DURATION}s`,
      opacity: 0,
    },
    '&.sorting': {
      transition: 'none',
    },
  },
  paperStack: {
  },
  paper: {
    position: 'absolute',
    top: STACK_ITEM_PADDING,
    left: STACK_ITEM_PADDING,
    width: STACK_ITEM_DEFAULT_WIDTH,
    height: STACK_ITEM_DEFAULT_HEIGHT,
    background: '#fff',
    transition: 'all cubic-bezier(.43,-0.34,.64,1.38) 0.1s',
  },
  paperShadow: {
    boxShadow: '0 2px 4px rgba(0,0,0,0.29)',
  },
  exploding: {
    transitionDuration: `${STACK_EXPLODING_DURATION}s`
  },
  paperStackItem: {
  },
  cover: {
  },
  caption: {
    position: 'absolute',
    width: STACK_ITEM_DEFAULT_WIDTH,
    left: 0,
    bottom: 0,
    height: '2em',
    lineHeight: '2em',
    textAlign: 'center',
    color: grey[500],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    verticalAlign: 'middle',
    marginRight: 4,
  },
  preview: {
    display: 'flex',
    boxSizing: 'border-box',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  thumb: {
    width: '100%',
    objectFit: 'cover',
  },
});

export interface OwnProps {
  container?: string;
  item: TreeItem;
  dossier?: DossierData;
  editing?: boolean;
  fading?: boolean;
  sorting?: boolean;
  selected?: boolean;
  zoom: number;
  onClick?: () => void;
  onSelectionChange?: (selected: boolean) => void;
  onMove?: (movement: number) => void;
  onDelete?: () => void;
}

interface StateProps {
  documentList: DocumentData[],
}

interface DispatchProps {
  setCurrentTreeItem: (item: TreeItem | null) => void;
  getCachedDocument: (id: string) => Promise<DocumentData | undefined>;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps & WithStyles<typeof styles>;

interface State {
  hover: boolean;
  transforms?: StackTransform[];
  exploding: boolean;
  document?: DocumentData;
}

const getTransformStyle = (t?: StackTransform, tScale?: number) => {
  if (!t || !tScale) return undefined;
  return `rotate(${t.rotate}deg) translate(${t.translate.x * tScale}px, ${t.translate.y * tScale}px)`
}

class StackItem extends React.Component<Props, State> {

  static defaultProps: Partial<OwnProps> = {
    fading: false,
    onClick: () => {},
  }

  state: State = {
    hover: false,
    transforms: undefined,
    exploding: false,
    document: undefined,
  }

  componentDidMount() {
    this.fetchDetail();
  }

  async fetchDetail() {
    const { item, getCachedDocument } = this.props;
    const firstLeaf = findFirstLeaf(item);
    if (!firstLeaf) return;
    try {
      const document = await getCachedDocument(firstLeaf._id);
      if (document) {
        this.setState({ document });
      }
    } catch (err) {
      console.error('load data failed');
    }
  }

  handleHover(event: React.MouseEvent<HTMLElement>, hover: boolean) {
    event.stopPropagation();
    this.setState({ hover });
  }

  handleSelectionChange(event?: React.MouseEvent) {
    const { selected, onSelectionChange } = this.props;
    onSelectionChange && onSelectionChange(!selected);
  }

  handleMove(event: React.MouseEvent, movement: number) {
    const { onMove } = this.props;
    event.stopPropagation();
    onMove && onMove(movement);
  }

  handleDelete(event: React.MouseEvent) {
    const { onDelete } = this.props;
    event.stopPropagation();
    onDelete && onDelete();
  }

  handleClick(event: React.MouseEvent<HTMLElement>) {
    const { dossier, item, sorting, editing, onClick, setCurrentTreeItem } = this.props;
    if (editing) {
      this.handleSelectionChange();
      return;
    } else if (item.type === 'file' || sorting) {
      if (dossier) {
        setCurrentTreeItem(item);
        history.push(`/dossier/${dossier._id}/scroll`);
      }
      return;
    }
    const childCount = item.type === 'folder' && item.children ? item.children.length : 1;
    const stackElement = event.currentTarget;
    const itemBox = stackElement.getBoundingClientRect();
    const ulElement = stackElement.parentElement as HTMLElement;
    const scrollElement = document.querySelector('main.main') as HTMLElement;
    const box = ulElement.getBoundingClientRect();
    const gridGap = 48;
    const itemsPerRow = Math.floor((box.width + gridGap) / (itemBox.width + gridGap));
    const gridGapX = (box.width - itemBox.width * itemsPerRow) / (itemsPerRow - 1);
    const gridGapY = gridGap;
    const offsetX = box.left - itemBox.left;
    const offsetY = box.top - itemBox.top + scrollElement.scrollTop;
    const transforms: StackTransform[] = [];
    for (let row = 0, col = 0, i = 0; i < childCount; i++, col++) {
      if (col === itemsPerRow) {
        col = 0;
        row++;
      }
      transforms.push({
        rotate: 0,
        translate: {
          x: offsetX + (itemBox.width + gridGapX) * col,
          y: offsetY + (itemBox.height + gridGapY) * row,
        },
      });
    }
    this.setState({
      transforms,
      exploding: true,
    }, () => {
      onClick && onClick();
    });
  }

  render() {
    const { classes, container, sorting, fading, editing, selected, item, zoom } = this.props;
    const { hover, transforms = stackTransformSerial, exploding, document } = this.state;
    const childCount = item.type === 'folder' ? (item.children ? item.children.length : 0) : 0;
    const tScale = (selected || (!exploding && hover)) ? maxScale : 1;
    const coverPage: PageContent | null = _.get(document, 'pages[0]', null);
    const paperWidth = STACK_ITEM_DEFAULT_WIDTH * zoom;
    const paperHeight = STACK_ITEM_DEFAULT_HEIGHT * zoom;
    const paperStyle = {
      width: paperWidth,
      height: paperHeight,
    };
    const captionFontSize = Math.max(16 * zoom, 10);
    const iconProps: SvgIconProps = {
      className: classes.icon,
      fontSize: captionFontSize < 12 ? 'small' : 'default',
    };
    return (
      <li
        className={classNames(classes.root, { fading, sorting, selected })}
        style={{
          width: paperWidth + STACK_ITEM_PADDING * 2,
          height: paperHeight + 16 + captionFontSize * 2,
        }}
        onClick={event => this.handleClick(event)}
        onMouseOverCapture={(event) => this.handleHover(event, true)}
        onMouseOutCapture={(event) => this.handleHover(event, false)}
      >
        {item.type === 'folder' &&
          <div className={classes.paperStack}>
            {_.range(1, childCount).map(i => {
              const t = transforms[i];
              return (
                <div
                  className={classNames(classes.paper, classes.paperStackItem, {
                    [classes.paperShadow]: exploding || i < stackTransformSerial.length,
                    [classes.exploding]: exploding,
                  })}
                  key={`${item._id}-${item.name}-${i}`}
                  style={{
                    ...paperStyle,
                    ...(t && {
                      transform: getTransformStyle(t, tScale),
                    }),
                  }}
                />
              );
            })}
          </div>
        }
        <div
          className={classNames(classes.paper, classes.cover, {
            [classes.exploding]: exploding,
          })}
          style={{
            ...paperStyle,
            boxShadow: selected ?
              `0 ${2 * tScale}px ${4 * tScale}px rgba(33,150,243.0.65)`:
              `0 ${2 * tScale}px ${4 * tScale}px rgba(0,0,0,0.29)`,
            transform: getTransformStyle(transforms[0], tScale),
          }}
        >
          <div className={classes.preview}>
            <DocumentPage
              container={container}
              zoom={STACK_ITEM_DEFAULT_WIDTH / PAPER_WIDTH * zoom}
              page={coverPage}
            />
          </div>
          {editing &&
            <div className={classNames(classes.actions)}>
              <IconButton onClick={(event) => this.handleDelete(event)}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={(event) => this.handleMove(event, -1)}>
                <MoveLeftIcon />
              </IconButton>
              <IconButton onClick={(event) => this.handleMove(event, 1)}>
                <MoveRightIcon />
              </IconButton>
              <IconButton
                onClick={() => this.handleSelectionChange()}
                color={selected ? 'secondary' : undefined}
              >
                <CheckIcon />
              </IconButton>
            </div>
          }
        </div>
        <div
          className={classes.caption}
          title={item.name}
          style={{
            width: paperWidth,
            fontSize: captionFontSize,
          }}
        >
          {item.type === 'folder' ?
            <FolderIcon {...iconProps} />
            :
            <FileIcon {...iconProps} />
          }
          {item.name}
        </div>
      </li>
    );
  }

}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  documentList: states.document.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>, props: OwnProps): DispatchProps => ({
  setCurrentTreeItem: (item) => dispatch(setCurrentTreeItem(item)),
  getCachedDocument: (id) => dispatch(getCachedDocument(props.dossier ? props.dossier._id : null, id)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(StackItem);
