import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { compose } from 'recompose';
import { grey } from '@material-ui/core/colors';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { __ } from '../../utils/intl';
import { ChatContact } from '../../types/chat';
import ContactListItem from './ContactListItem';

const styles = (theme: Theme) => createStyles({
  root: {
    color: grey[700],
    padding: theme.spacing(1),
  },
  minified: {
    padding: theme.spacing(1, 0),
  },
});

interface OwnProps {
  className?: string;
  minified: boolean;
  recentContactList: ChatContact[];
  contactList: ChatContact[];
  onOpenChatWindow: (event: React.MouseEvent, item: ChatContact) => void;
}

interface OwnProps {
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class ContactList extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  getRecentContactItem(): ChatContact {
    const { recentContactList } = this.props;
    return {
      _id: 'recent',
      type: 'group',
      // INTL:template __('chat.contacts.recent')
      name: 'chat.contacts.recent',
      children: recentContactList,
    };
  }

  renderMinified() {
    const { classes, className, recentContactList, onOpenChatWindow } = this.props;
    return (
      <div className={clsx(classes.root, classes.minified, className)}>
        {recentContactList.map(item => (
          <ContactListItem
            key={item._id}
            minified
            item={item}
            onClick={onOpenChatWindow}
          />
        ))}
      </div>
    );
  }

  render() {
    const { classes, className, minified, contactList, onOpenChatWindow } = this.props;
    if (minified) {
      return this.renderMinified();
    }
    return (
      <TreeView
        className={clsx(classes.root, className)}
        defaultExpanded={['recent']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
      >
        <ContactListItem
          key={'recent'}
          item={this.getRecentContactItem()}
          onClick={onOpenChatWindow}
        />
        {contactList.map(item => (
          <ContactListItem
            key={item._id}
            item={item}
            onClick={onOpenChatWindow}
          />
        ))}
      </TreeView>
    );
  }

}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(ContactList);
