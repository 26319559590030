import _ from 'lodash';
import { } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import {  List, ListSubheader, Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { MemberData, checkPermission, MemberIdentity } from '../../types/member';
import { DossierData } from '../../types/dossier';
import { InvitationOptions } from '../../types/invitation';
import { UserData, PublicUserInfo } from '../../types/user';
import { RootState } from '../../store/reducers';
import {
  inviteMemberRemote,
  fetchListRemote as fetchMemberListRemote,
  updateItemRemote as updateMemberRemote,
  removeItemRemote as removeMemberRemote,
  loadCollaboratorsRemote,
} from '../../store/member/actions';
import MemberListItem from './MemberListItem';
import MemberProfile from './MemberProfile';
import InviteMember from './InviteMember';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  formControl: {
    marginTop: theme.spacing(2),
    padding: `0 ${theme.spacing(2)}px`,
  },
  memberList: {
    flexGrow: 1,
    overflow: 'auto',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
  actions: {
    margin: theme.spacing(2),
  },
});

interface StateProps {
  user: UserData | null;
  memberList: MemberData[];
}

interface DispatchProps {
  fetchMemberListRemote: (query: MemberIdentity) => Promise<MemberData[]>;
  inviteMemberRemote: (query: MemberIdentity, options: InvitationOptions) => Promise<MemberData>;
  updateMemberRemote: (query: MemberIdentity, memberId: string, data: Partial<MemberData>) => Promise<MemberData>;
  removeMemberRemote: (query: MemberIdentity, memberId: string) => Promise<void>;
  loadCollaboratorsRemote: (query: MemberIdentity) => Promise<PublicUserInfo[]>;
}

interface OwnProps {
  title?: string;
  query: MemberIdentity;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

interface State {
  selected: MemberData | null;
  inviteMemberOpen: boolean;
}

class MemberList extends React.Component<Props, State> {

  state: State = {
    selected: null,
    inviteMemberOpen: false,
  }

  async componentDidMount() {
    const { query, fetchMemberListRemote } = this.props;
    await fetchMemberListRemote(query);
  }
  
  handleSelect(member: MemberData) {
    this.setState({ selected: member });
  }

  handleLoadCollaborators = () => {
    const { query, loadCollaboratorsRemote } = this.props;
    return loadCollaboratorsRemote(query);
  }

  handleInvite = (options: InvitationOptions) => {
    const { query, inviteMemberRemote } = this.props;
    return inviteMemberRemote(query, options);
  }

  handleUpdate = (data: Partial<MemberData>) => {
    const { query, updateMemberRemote } = this.props;
    const { selected } = this.state;
    if (selected) {
      return updateMemberRemote(query, selected._id, data);
    }
  }

  handleDelete = () => {
    const { query, removeMemberRemote } = this.props;
    const { selected } = this.state;
    if (selected) {
      return removeMemberRemote(query, selected._id);
    }
  }

  render() {
    const { classes, title, user, memberList } = this.props;
    const { selected, inviteMemberOpen } = this.state;
    if (!user) return null;
    const currentMember = _.find(memberList, { user_id: user._id });
    if (!currentMember) return null;
    const editable = checkPermission(currentMember.role, 'admin');
    return (
      <>
        {editable &&
          <div className={classes.actions}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => this.setState({ inviteMemberOpen: true })}
            >
              {__('member.invite')}
              <PersonAddIcon className={classes.buttonIcon} />
            </Button>
          </div>
        }
        <List className={classes.memberList} subheader={<ListSubheader>{title}</ListSubheader>}>
          {memberList.map(member => (
            <MemberListItem
              key={member._id}
              selected={selected ? selected.user_id === member._id : false}
              member={member}
              onClick={() => this.handleSelect(member)}
            />
          ))}
        </List>
        {selected &&
          <MemberProfile
            open
            editable={editable}
            member={selected}
            onClose={() => this.setState({ selected: null })}
            onUpdate={this.handleUpdate}
            onDelete={this.handleDelete}
          />
        }
        {editable && inviteMemberOpen &&
          <InviteMember
            open
            onClose={() => this.setState({ inviteMemberOpen: false })}
            onLoadCollaborators={this.handleLoadCollaborators}
            onInvite={this.handleInvite}
          />
        }
      </>
    );
  }

}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  user: states.user.current,
  memberList: states.member.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchMemberListRemote: (query) => dispatch(fetchMemberListRemote(query)),
  inviteMemberRemote: (query, options) => dispatch(inviteMemberRemote(query, options)),
  updateMemberRemote: (query, memberId, data) => dispatch(updateMemberRemote(query, memberId, data)),
  removeMemberRemote: (query, memberId) => dispatch(removeMemberRemote(query, memberId)),
  loadCollaboratorsRemote: (query) => dispatch(loadCollaboratorsRemote(query)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MemberList);
