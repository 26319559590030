import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    zIndex: 600,
    bottom: 0,
    transition: 'all 0.2s ease-in-out',
    background: '#f0f0f0',
    boxShadow: theme.shadows[3],
    top: 64,
    [theme.breakpoints.down('xs')]: {
      top: 56,
    },
  },
  draggingHandler: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: 10,
    cursor: 'ew-resize',
  },
  content: {
    width: '100%',
    height: '100%',
    bottom: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export interface ResizeBounds {
  left: number;
  right: number;
}

export interface OwnProps {
  className: string;
  open: boolean;
  autoClose?: boolean;
  onClose?: () => void;
  width: number;
  onWidthChange: (width: number) => void;
  align: 'left' | 'right';
  resizable?: boolean;
  resizeBounds?: ResizeBounds;
  style?: React.CSSProperties;
}

interface State {
  initialWidth: number;
  width: number;
}

type Props = OwnProps & WithStyles<typeof styles>;

class Sidebar extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    className: '',
    align: 'left',
    width: 300,
    resizable: true,
    resizeBounds: {
      left: -100,
      right: 100,
    },
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      initialWidth: props.width,
      width: props.width,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.width !== this.props.width) {
      this.setState({ width: nextProps.width });
    }
  }

  handleDrag: DraggableEventHandler = _.throttle((event, data) => {
    const { align } = this.props;
    const { initialWidth } = this.state;
    this.setState({
      width: initialWidth + data.x * (align === 'left' ? 1 : -1),
    });
  }, 100);

  handleStop: DraggableEventHandler = (event, data) => {
    const { onWidthChange } = this.props;
    const { width } = this.state;
    onWidthChange && onWidthChange(width);
  }

  handleClickAway = () => {
    const { open, autoClose, onClose } = this.props;
    if (open && autoClose && onClose) {
      onClose();
    }
  }

  render() {
    const { classes, className, open, align, children, resizable, resizeBounds, style } = this.props;
    const { initialWidth, width } = this.state;
    const direction = align === 'left' ? 1 : -1;
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <aside
          className={classNames(classes.root, className, { open: open })}
          style={{
            [align]: 0,
            width,
            transform: `translateX(${open ? 0 : (width + 10) * -direction}px)`,
            ...style,
          }}
        >
          <main className={classes.content}>
            {children}
          </main>
          {resizable &&
            <Draggable
              axis="x"
              onDrag={this.handleDrag}
              onStop={this.handleStop}
              bounds={resizeBounds && {
                ...resizeBounds,
                top: 0,
                bottom: 0,
              }}
            >
              <div className={classes.draggingHandler} style={{ [align]: initialWidth }} />
            </Draggable>
          }
        </aside>
      </ClickAwayListener>
    )
  }
}

export default withStyles(styles)(Sidebar);
