import pink from '@material-ui/core/colors/pink';
import cyan from '@material-ui/core/colors/cyan';
import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#38445e',
    },
    secondary: {
      main: pink[500],
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    text: {
    },
  },
});

export default theme;
