import { TreeItem } from '../../types/tree';
import { IStage } from '../../types/document.template';
import { DossierData } from '../../types/dossier';
import { DemoData } from '../types';

import { documentTemplates } from './documents';

const demoData: DemoData = {
  users: [],
  chat: [],
  documentTemplates,
  templateList: [
    { childIndex: 0, _id: 'f1t1', type: 'file', name: '民事诉讼（一审）' },
    { childIndex: 0, _id: 'f1t2', type: 'file', name: '民事诉讼（二审）' },
    { childIndex: 0, _id: 'f1t3', type: 'file', name: '民事诉讼（再审）' },
    { childIndex: 0, _id: 'f1t4', type: 'file', name: '民事诉讼（执行）' },
    { childIndex: 0, _id: 'f2t1', type: 'file', name: '刑事诉讼（侦查）' },
    { childIndex: 0, _id: 'f2t2', type: 'file', name: '刑事诉讼（审查起诉）' },
    { childIndex: 0, _id: 'f2t3', type: 'file', name: '刑事诉讼（一审）' },
    { childIndex: 0, _id: 'f2t4', type: 'file', name: '刑事诉讼（二审）' },
    { childIndex: 0, _id: 'f2t5', type: 'file', name: '刑事诉讼（再审）' },
    { childIndex: 0, _id: 'f3t1', type: 'file', name: '行政诉讼（一审）' },
    { childIndex: 0, _id: 'f3t2', type: 'file', name: '行政诉讼（二审）' },
    { childIndex: 0, _id: 'f3t3', type: 'file', name: '行政诉讼（再审）' },
    { childIndex: 0, _id: 't1', type: 'file', name: '商事仲裁' },
    { childIndex: 0, _id: 't2', type: 'file', name: '劳动争议仲裁' },
  ],

  basicForm: [
    '案件名称',
    '案由',
    '简要案情',
    '律师事务所+名称',
    '律师事务所+户名',
    '律师事务所+开户行',
    '律师事务所+账号',
    '律所案号',
    '收案日期',
    '主办律师+姓名',
    '主办律师+电话',
    '律师助理',
  ],

  rolesData: {
    "民事": {
      stage: [{
        name: "一审",
        roles: ["原告", "被告", "第三人"]
      }, {
        name: "二审",
        roles: ["上诉人", "被上诉人", "第三人"]
      }, {
        name: "再审",
        roles: ["申请人", "被申请人", "第三人"]
      }]
    },
  },

  files: [
    { childIndex: 0, _id: 'f1', type: 'file', name: '夏洛诉马冬梅离婚案（一审）' },
    { childIndex: 0, _id: 'f2', type: 'file', name: '马冬梅诉夏洛离婚案（二审）' },
    { childIndex: 0, _id: 'f3', type: 'file', name: '王五诉XX房地产开发有限公司房屋所有权纠纷案' },
    { childIndex: 0, _id: 'f4', type: 'file', name: '赵六诉大成街道办事处排水纠纷案' },
  ],

  documentTree: {
    _id: '',
    childIndex: 0,
    type: 'folder',
    name: '卷宗总目录',
    children: [
      { _id: '', childIndex: 0, type: 'file', name: '民事卷宗封面' },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: '律所内部资料',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: '民事案件审批表' },
          { _id: '', childIndex: 0, type: 'file', name: '民事案件委托代理合同' },
          { _id: '', childIndex: 0, type: 'file', name: '代理费票据' },
          { _id: '', childIndex: 0, type: 'file', name: '预收办案费用收据' },
          { _id: '', childIndex: 0, type: 'file', name: '预收办案费用结算清单' },
          { _id: '', childIndex: 0, type: 'file', name: '民事案件结案报告' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: '律师工作记录',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: '一审立案前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '收案沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '二审上诉前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '再审申请前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '一审答辩前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '二审答辩前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '再审答辩前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '开庭前沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '开庭后沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '判决后沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '日常沟通记录' },
          { _id: '', childIndex: 0, type: 'file', name: '接收资料清单' },
          { _id: '', childIndex: 0, type: 'file', name: '返还资料清单' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: '当事人案件资料（多个）',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: '当事人身份证复印件' },
          { _id: '', childIndex: 0, type: 'file', name: '当事人法定代理人身份证复印件' },
          { _id: '', childIndex: 0, type: 'file', name: '当事人法人资格证明' },
          { _id: '', childIndex: 0, type: 'file', name: '法定代表人身份证明书' },
          { _id: '', childIndex: 0, type: 'file', name: '当事人法定代表人身份证明复印件' },
          { _id: '', childIndex: 0, type: 'file', name: '当事人其他组织资格证明' },
          { _id: '', childIndex: 0, type: 'file', name: '主要负责人身份证明书' },
          { _id: '', childIndex: 0, type: 'file', name: '当事人其他组织负责人身份证复印件' },
          { _id: '', childIndex: 0, type: 'file', name: '授权委托书(公民委托诉讼代理人用)' },
          { _id: '', childIndex: 0, type: 'file', name: '授权委托书(法人或者其他组织委托诉讼代理人用)' },
          { _id: '', childIndex: 0, type: 'file', name: '律师事务所出庭函' },
          { _id: '', childIndex: 0, type: 'file', name: '代理律师执业证复印件' },
          { _id: '', childIndex: 0, type: 'file', name: '证据目录' },
          { _id: '', childIndex: 0, type: 'file', name: '证据' },
          { _id: '', childIndex: 0, type: 'file', name: '申请书(申请人民法院调查收集证据用)' },
          { _id: '', childIndex: 0, type: 'file', name: '申请书(申请书证提出命令用)' },
          { _id: '', childIndex: 0, type: 'file', name: '申请书(申请通知证人出庭作证用)' },
          { _id: '', childIndex: 0, type: 'file', name: '申请书(申请通知有专门知识的人出庭用)' },
          { _id: '', childIndex: 0, type: 'file', name: '质证意见' },
          { _id: '', childIndex: 0, type: 'file', name: '民事代理词' },
          { _id: '', childIndex: 0, type: 'file', name: '民事起诉状' },
          { _id: '', childIndex: 0, type: 'file', name: '民事答辩状' },
          { _id: '', childIndex: 0, type: 'file', name: '民事上诉状' },
          { _id: '', childIndex: 0, type: 'file', name: '民事二审答辩状' },
          { _id: '', childIndex: 0, type: 'file', name: '民事再审申请书' },
          { _id: '', childIndex: 0, type: 'file', name: '民事再审申请答辩状' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: '法院司法文书',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: '案件受理通知' },
          { _id: '', childIndex: 0, type: 'file', name: '诉讼费缴费通知' },
          { _id: '', childIndex: 0, type: 'file', name: '举证通知书' },
          { _id: '', childIndex: 0, type: 'file', name: '开庭传票' },
          { _id: '', childIndex: 0, type: 'file', name: '证据交换笔录' },
          { _id: '', childIndex: 0, type: 'file', name: '庭审笔录' },
          { _id: '', childIndex: 0, type: 'file', name: '民事判决书' },
          { _id: '', childIndex: 0, type: 'file', name: '民事调解书' },
          { _id: '', childIndex: 0, type: 'file', name: '民事裁定书' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: '案件研究资料',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: '案件事实摘要' },
          { _id: '', childIndex: 0, type: 'file', name: '法律研究报告' },
          { _id: '', childIndex: 0, type: 'file', name: '案例检索报告' },
          { _id: '', childIndex: 0, type: 'file', name: '案情分析报告' },
          { _id: '', childIndex: 0, type: 'file', name: '模拟法庭记录' },
        ]
      }
    ],
  },

  similarCaseData: [],
  relevantLawData: [],
  
  entities: [

  ],
  facts: [],

} 

export default demoData;
