import _ from 'lodash';
import clsx from 'clsx';
import classNames from 'classnames';
import React from 'react';
import uuid from 'uuid';
import { FormLabel, IconButton, Paper, Badge, Tooltip, Button } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

import { __ } from '../../utils/intl';
import { ArrayFormItem } from '../../types/form';
import FormControlItem from './FormControlItem';
import { BaseFormControlProps } from './types';
import { shouldFormItemShow, parseReference } from './utils';
import arrayMove from 'array-move';

const styles = (theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  content: {
  },
  badgeRoot: {
    marginRight: theme.spacing(3),
  },
  badge: {
    transform: 'scale(1) translate(100%, -50%)',
  },
  paper: {
    position: 'relative',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0.01, 2),
  },
  singleText: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    paddingRight: 0,
  },
  headerActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1, 1),
    whiteSpace: 'nowrap',
  },
  singleTextActions: {
    position: 'static',
    paddingTop: theme.spacing(1.5),
    marginRight: 0,
  },
  actions: {

  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

interface ArrayItemValue {
  _id: string;
  [key: string]: any;
}

interface OwnProps extends BaseFormControlProps {
  item: ArrayFormItem;
  value: ArrayItemValue[];
  readOnly?: boolean;
  onChange: (values: ArrayItemValue[]) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  errors: any;
}

class ArrayControl extends React.Component<Props, State> {

  handleItemAdd() {
    const { value, onChange } = this.props;
    const newItem: ArrayItemValue = {
      _id: uuid.v4(),
    };
    onChange([...value || [], newItem]);
  }

  handleItemMove(index: number, offset: number) {
    const { value, onChange } = this.props;
    let newValue = _.isArray(value) ? value : [];
    const targetIndex = index + offset;
    if (targetIndex < 0 || targetIndex > newValue.length - 1) {
      return;
    }
    newValue = arrayMove(newValue, index, targetIndex);
    onChange(newValue);
  }

  handleItemRemove(index: number) {
    const { value, onChange } = this.props;
    const item = value[index];
    onChange(_.without(value, item));
  }

  handleItemChange(index: number, itemValue: any) {
    const { value, onChange } = this.props;
    let newValue = _.isArray(value) ? value : [];
    newValue = newValue.map((item, i) => index === i ? itemValue : item);
    onChange(newValue);
  }

  getPrimaryText(itemValue: any) {
    const { item } = this.props;
    let primaryText = '';
    if (_.isString(itemValue)) {
      primaryText = itemValue;
    }
    if (item.primaryKey) {
      primaryText = _.get(itemValue, item.primaryKey);
    }
    return primaryText || ' ';
  }

  render() {
    const {
      classes,
      definitions,
      scopes,
      item,
      value:
      _value,
      path:
      _path,
      pathFilters,
      readOnly,
      onChange,
    } = this.props;
    let value = _value;
    if (!_.isArray(value)) {
      value = [];
    }
    let subItem = item.item;
    if (subItem.type === 'reference') {
      subItem = parseReference(definitions, subItem);
    }
    const isSingleText = subItem.type === 'text';
    return (
      <div className={classNames('array-form-item', classes.root)}>
        <FormLabel filled>{item.label}</FormLabel>
        <div className={classes.content}>
          {value.map((itemValue, index) => {
            const path = `${_path}.${index}`;
            if (!shouldFormItemShow(pathFilters, path, item.item)) {
              return null;
            }
            return (
              <div key={itemValue._id || `${subItem.type}-${subItem.label}-${index}`}>
                <Paper
                  className={clsx(classes.paper, { [classes.singleText]: isSingleText })}
                  elevation={isSingleText ? 0 : undefined}
                >
                  <div className={clsx(classes.headerActions, { [classes.singleTextActions]: isSingleText })}>
                    <Tooltip title={__('form.arrayControl.itemIndex')}>
                      <Badge
                        badgeContent={index + 1}
                        color="primary"
                        classes={{
                          root: classes.badgeRoot,
                          badge: classes.badge,
                        }}
                      >
                        {!isSingleText && this.getPrimaryText(itemValue)}
                      </Badge>
                    </Tooltip>
                    {!readOnly &&
                      <>
                        <Tooltip title={__('form.arrayControl.moveUp')}>
                          <IconButton
                            tabIndex={-1}
                            size="small"
                            onClick={() => this.handleItemMove(index, -1)}
                          >
                            <UpIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={__('form.arrayControl.moveDown')}>
                          <IconButton
                            tabIndex={-1}
                            size="small"
                            onClick={() => this.handleItemMove(index, 1)}
                          >
                            <DownIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={__('form.arrayControl.remove', { label: subItem.label })}>
                          <IconButton
                            tabIndex={-1}
                            size="small"
                            color="secondary"
                            onClick={() => this.handleItemRemove(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  </div>
                  <FormControlItem
                    noIndent
                    definitions={definitions}
                    scopes={[...scopes, itemValue]}
                    readOnly={readOnly}
                    item={subItem}
                    value={itemValue}
                    path={path}
                    pathFilters={pathFilters}
                    onChange={newValue => this.handleItemChange(index, newValue)}
                  />
                </Paper>
              </div>
            )
          })}
          {!readOnly && !pathFilters &&
            <div className={classes.actions}>
              <Button onClick={() => this.handleItemAdd()}>
                <AddIcon className={classes.leftIcon} />
                {__('form.arrayControl.addItem', { label: subItem.label })}
              </Button>
            </div>
          }
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(ArrayControl);
