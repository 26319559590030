import _ from 'lodash';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import CopyrightIcon from '@material-ui/icons/Copyright';
import ProductIcon from '@material-ui/icons/ImportantDevices';
import LockIcon from '@material-ui/icons/Lock';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { RouteComponentProps } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { fetchServerInfoRemote } from '../../store/info/actions';
import { getPublicUrl } from '../../utils/url';
import { ServerInfo } from '../../types/info';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import TermsPopup, { TermsType } from './TermsPopup';
import BuildInfoDialog from './BuildInfoDialog';

const styles = (theme: Theme) => createStyles({
  logoContainer: {
    width: '100%',
    textAlign: 'center',
  },
  logo: {
    width: 128,
    height: 128,
  },
});

interface StateProps {
  info: ServerInfo | null;
}

interface DispatchProps {
  fetchServerInfoRemote: () => void;
}

interface OwnProps { };

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  termsPageType: TermsType;
  buildInfoOpen: boolean;
}

class About extends React.Component<Props, State> {

  state: State = {
    termsPageType: undefined,
    buildInfoOpen: false,
  }

  componentDidMount() {
    const { fetchServerInfoRemote } = this.props;
    fetchServerInfoRemote();
  }

  render() {
    const { classes, history, info } = this.props;
    const { termsPageType, buildInfoOpen } = this.state;
    return (
      <ResponsiveDialog
        open
        withoutContentContainer
        title={__('nav.about')}
        actions={[]}
        onClose={() => history.push('/account')}
      >
        <List>
          <ListItem>
            <div className={classes.logoContainer}>
              <img className={classes.logo} src={getPublicUrl('/images/logo@2x.png')} />
            </div>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ProductIcon />
            </ListItemIcon>
            <ListItemText
              primary={__('app.productName')}
            />
          </ListItem>
          {info &&
            <ListItem>
              <ListItemIcon onClick={() => this.setState({ buildInfoOpen: true })}>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                primary={`${__('info.version')} ${info.version}`}
              />
            </ListItem>
          }
          <ListItem>
            <ListItemIcon>
              <CopyrightIcon />
            </ListItemIcon>
            <ListItemText
              primary={__('app.copyrightText')}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.setState({ termsPageType: 'privacy-policy' })}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText
              primary={__('account.privacyPolicy')}
            />
          </ListItem>
          <ListItem
            button
            onClick={() => this.setState({ termsPageType: 'terms-of-service' })}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={__('account.termsOfService')}
            />
          </ListItem>
        </List>
        <TermsPopup
          type={termsPageType}
          onClose={() => this.setState({ termsPageType: undefined })}
        />
        <BuildInfoDialog
          open={buildInfoOpen}
          onClose={() => this.setState({ buildInfoOpen: false })}
        />
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  info: states.info.info,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchServerInfoRemote: () => dispatch(fetchServerInfoRemote())
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(About);
