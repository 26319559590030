import _ from 'lodash';
import { Action } from './actions';
import { ChatThreadData, ChatMessageData, ChatContact } from '../../types/chat';
import { KeyValueMap } from '../../types/common';

export interface ChatState {
  thread: ChatThreadData | null;
  threadList: ChatThreadData[];
  messages: KeyValueMap<ChatMessageData[]>;
  contactList: ChatContact[];
}

const initialState: ChatState = {
  thread: null,
  threadList: [],
  messages: {},
  contactList: [],
}

export const getUnreadMessageCount = (list: ChatThreadData[]): number => {
  return _.sumBy(list, item => (threadHasUnreadMessage(item) ? 1 : 0));
}

export const threadHasUnreadMessage = (thread: ChatThreadData): boolean => {
  if (!thread.dateLastRead) return false;
  return thread.dateUpdated > thread.dateLastRead; 
}

const chatReducer = (state: ChatState = initialState, action: Action): ChatState => {
  switch (action.type) {

    case '@@chat/SET_THREAD': 
      return { ...state, thread: action.item };

    case '@@chat/CLEAR_THREAD':
      return { ...state, thread: null };

    case '@@chat/SET_THREAD_LIST':
      return { ...state, threadList: action.list };

    case '@@chat/UPDATE_THREAD': {
      const index = _.findIndex(state.threadList, { _id: action.item._id });
      const list = [...state.threadList];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return {
        ...state,
        threadList: list,
        thread: (state.thread && state.thread._id === action.item._id) ? action.item : state.thread,
      };
    }

    case '@@chat/REMOVE_THREAD':
      return { ...state, threadList: _.reject(state.threadList, { _id: action.id }) };

    case '@@chat/SET_THREAD_MESSAGE_LIST':
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.id]: action.list,
        },
      };

    case '@@chat/PREPEND_THREAD_MESSAGE_LIST':
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.id]: [
            ...action.list,
            ...(state.messages[action.id] || []),
          ],
        },
      };

    case '@@chat/SET_INCOMING_MESSAGE':
      const threadId = action.item.thread_id;
      return {
        ...state,
        messages: {
          ...state.messages,
          [threadId]: [
            ...(state.messages[threadId] || []),
            action.item,
          ],
        },
      };

    case '@@chat/SET_CONTACT_LIST':
      return { ...state, contactList: action.list };

    default:
      return state;
  }
}

export default chatReducer;
