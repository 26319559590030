import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import React from 'react';
import { TextField } from '@material-ui/core';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { DatePicker, TimePicker, DateTimePicker } from '@material-ui/pickers';
import { BasePickerProps } from '@material-ui/pickers/typings/BasePicker';

import { DateFormItem, TimeFormItem, DateTimeFormItem } from '../../types/form';
import { SimpleFormControlProps } from './types';
import DelayedTextField from './DelayedTextField';

const styles = (theme: Theme) => createStyles({
  
});

export type PickerFormItem = DateFormItem | TimeFormItem | DateTimeFormItem;

export interface OwnProps extends SimpleFormControlProps {
  item: PickerFormItem;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  errors: any;
}

class DateControl extends React.Component<Props, State> {

  handleChange(value: any) {
    const { item, onChange } = this.props;
    if (item.type !== 'time') {
      value = moment(value).toDate();
    }
    onChange(value);
  }

  renderReadOnly() {
    const { item, className } = this.props;
    let { value } = this.props;
    if (item.type === 'date') {
      value = moment(value).format('YYYY/MM/DD');
    } else if (item.type === 'time') {
      value = moment(value).format('hh:mm:ss A');
    } else if (item.type === 'datetime') {
      value = moment(value).format('YYYY/MM/DD hh:mm A');
    }
    return (
      <TextField
        fullWidth
        className={className}
        value={value}
        name={item.key}
        label={item.label}
        type="text"
        inputProps={{
          readOnly: true,
        }}
      />
    );
  }

  renderNativeControl() {
    const { item, className } = this.props;
    let { value } = this.props;
    if (item.native) {
      if (item.type === 'date') {
        value = moment(value).format('YYYY-MM-DD');
      } else if (item.type === 'time') {
        value = moment(value).format('HH:mm');
      } else if (item.type === 'datetime') {
        value = moment(value).format('YYYY-MM-DDTHH:mm');
      }
    }
    return (
      <DelayedTextField
        fullWidth
        className={className}
        name={item.key}
        type={item.type}
        label={item.label}
        value={value}
        onChange={value => this.handleChange(value)}
      />
    )
  }

  renderPicker() {
    const { item, className } = this.props;
    let { value } = this.props;
    if (!value) {
      value = null;
    }
    const commonProps: BasePickerProps & Pick<BaseTextFieldProps, 'label' | 'fullWidth' | 'className'> = {
      value,
      className,
      fullWidth: true,
      variant: 'inline',
      label: item.label,
      onChange: date => this.handleChange(date),
    }
    switch (item.type) {
      case 'date':
        return (
          <DatePicker
            autoOk
            format="YYYY/MM/DD"
            {...commonProps}
          />
        );
      case 'time':
        return (
          <TimePicker
            autoOk
            {...commonProps}
          />
        );
      case 'datetime':
        return (
          <DateTimePicker
            format="YYYY/MM/DD hh:mm A"
            {...commonProps}
          />
        );
    }
  }

  render() {
    const { item, readOnly } = this.props;
    if (readOnly) {
      return this.renderReadOnly();
    } else if (item.native) {
      return this.renderNativeControl();
    } else {
      return this.renderPicker();
    }
  }

}

export default withStyles(styles)(DateControl);
