import _ from 'lodash';
import React from 'react';
import Layout from '../Common/Layout';
import qs from 'qs';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Button } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { PersonProfile, ContactPersonData } from '../../types/contact.person';
import { ContactOrganizationData } from '../../types/contact.organization';
import { SelectOption } from '../../types/form';
import {
  fetchItemRemote,
  updateItemRemote,
  createItemRemote,
  removeItemRemote,
} from '../../store/person/actions';
import {
  fetchListRemote as fetchOrganizationListRemote
} from '../../store/organization/actions';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import MultipleSelect from '../Form/MultipleSelect';
import OrganizationListPartial from './OrganizationListPartial';
import PersonForm from './PersonForm';

const styles = (theme: Theme) => createStyles({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  multipleSelect: {
    marginTop: theme.spacing(2),
  },
  relatedList: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
});

interface StateProps {
  current: ContactPersonData | null;
  organizationList: ContactOrganizationData[];
}

interface DispatchProps {
  fetchOrganizationListRemote: () => Promise<void>,
  fetchItemRemote: (id: string) => Promise<void>,
  updateItemRemote: (id: string, data: Partial<ContactPersonData>) => Promise<void>,
  createItemRemote: (data: Partial<ContactPersonData>) => Promise<void>,
  removeItemRemote: (id: string) => Promise<void>,
}

interface OwnProps {};

interface Params {
  personId?: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  editing: boolean;
  data: Partial<ContactPersonData>;
}

class PersonEditor extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      editing: !props.match.params.personId,
      data: this.updateData(props),
    };
  }

  componentDidMount() {
    const { fetchItemRemote, fetchOrganizationListRemote , match: { params: { personId }} } = this.props;
    if (!personId) return;
    fetchItemRemote(personId);
    fetchOrganizationListRemote();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.current !== this.props.current) {
      this.setState({
        editing: !nextProps.match.params.personId,
        data: this.updateData(nextProps),
      });
    }
  }

  updateData(props: Props) {
    if (!props.match.params.personId) {
      return {};
    }
    return props.current || {};
  }

  handleEdit() {
    this.setState({
      editing: true,
    });
  }

  handleCancel() {
    this.setState({
      editing: false,
      data: this.updateData(this.props),
    });
  }

  handleDelete() {
    const { removeItemRemote, history, match: { params: { personId }} } = this.props;
    if (!personId) return;
    this.setState({
      editing: false,
    }, async () => {
      await removeItemRemote(personId);
      history.push('/contacts/person');
    });
  }

  handleSave() {
    const {
      updateItemRemote,
      createItemRemote,
      history,
      match: { params: { personId } },
    } = this.props;
    const { data } = this.state;
    this.setState({
      editing: false,
    }, async () => {
      if (personId) {
        updateItemRemote(personId, data);
      } else {
        await createItemRemote(data);
        history.push('/contacts/person');
      }
    });
  }

  handleProfileChange(profile: PersonProfile) {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        profile,
      },
    });
  }

  handleOrganizationChange(values: SelectOption[]) {
    this.setState({
      data: {
        ...this.state.data,
        organization_ids: values.map(option => option.value),
      },
    });
  }

  render() {
    const { classes, organizationList, history, match: { params: { personId }} } = this.props;
    const { editing, data } = this.state;
    const organizationOptions: SelectOption[] = organizationList.map(item => ({
      label: item.profile.name || '',
      value: item._id,
    }));
    let organizationValue: SelectOption[] = [];
    let relatedOrganizations: ContactOrganizationData[] = [];
    const { organization_ids } = data;
    if (data && organization_ids) {
      organizationValue = organizationOptions.filter(item => organization_ids.includes(item.value));
      relatedOrganizations = organizationList.filter(item => organization_ids.includes(item._id));
    }
    return (
      <ResponsiveDialog
        open
        hasBackButton
        title={personId ? __('contacts.person.detail') : __('contacts.person.create')}
        actions={editing?
          <>
            {personId &&
              <>
                <Button
                  color="inherit"
                  onClick={() => this.handleCancel()}
                >
                  {__('dialog.cancel')}
                </Button>
                <Button
                  color="inherit"
                  onClick={() => this.handleDelete()}
                >
                  {__('dialog.delete')}
                </Button>
              </>
            }
            <Button
              color="inherit"
              onClick={() => this.handleSave()}
            >
              {__('dialog.save')}
            </Button>
          </>
          :
          <Button
            color="inherit"
            onClick={() => this.handleEdit()}
          >
            {__('dialog.edit')}
          </Button>
        }
        onClose={() => history.push('/contacts/person')}
      >
        <PersonForm
          readOnly={!editing}
          data={data.profile}
          onChange={data => this.handleProfileChange(data)}
        />
        {editing ?
          <MultipleSelect
            label={__('contacts.person.relatedOrganizations')}
            className={classes.formControl}
            readOnly={!editing}
            options={organizationOptions}
            value={organizationValue}
            onChange={value => this.handleOrganizationChange(value)}
          />
        :
          <OrganizationListPartial
            className={classes.relatedList}
            title={__('contacts.person.relatedOrganizations')}
            list={relatedOrganizations}
          />
        }
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  current: states.person.current,
  organizationList: states.organization.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchOrganizationListRemote: () => dispatch(fetchOrganizationListRemote()),
  fetchItemRemote: (id: string) => dispatch(fetchItemRemote(id)),
  updateItemRemote: (id: string, data: Partial<ContactPersonData>) => dispatch(updateItemRemote(id, data)),
  createItemRemote: (data: Partial<ContactPersonData>) => dispatch(createItemRemote(data)),
  removeItemRemote: (id: string) => dispatch(removeItemRemote(id)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(PersonEditor);
