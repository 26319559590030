import _ from 'lodash';
import qs from 'qs';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

import { SelectFormItem, SelectOption } from '../../types/form';
import { getJSON, getSelectOptions } from '../../utils/ajax';
import client from '../../store/client';
import { SimpleFormControlProps } from './types';
import { components, styles } from './MultipleSelect';
import { CSSProperties } from '@material-ui/styles';
import { ValueType } from 'react-select/src/types';

interface OwnProps extends SimpleFormControlProps {
  className?: string;
  readOnly?: boolean;
  item: SelectFormItem;
  value: any;
  onChange: (value: string) => void;
}

type Props = OwnProps & WithStyles<typeof styles>

interface State {
  value: ValueType<SelectOption>;
}

class AutocompleteSelect extends React.Component<Props, State> {

  static getDerivedStateFromProps(props: Props) {
    const { value } = props;
    if (!value) {
      return { value: null };
    } else {
      return {
        value: {
          label: value,
          value,
        },
      };
    }
  }

  loadOptions = async (value: string) => {
    const { item } = this.props;
    let { remoteUrl: url, query = {} } = item;
    if (!url) {
      throw new Error('AutocompleteSelect: remote url not found!');
    }
    query = {
      ..._.isString(query) ? qs.parse(query) : query,
      q: value,
    };
    if (!/^https?:\/\//.test(url)) {
      url = client.getUrl(url);
    }
    url += '?' + qs.stringify(query);
    const result = await getJSON(url);
    return getSelectOptions(result);
  }

  handleChange = (value: ValueType<SelectOption>) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(_.get(value, 'value', null));
  }

  render() {
    const { classes, item } = this.props;
    const { value } = this.state;
    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        isClearable
        classes={classes}
        components={components}
        loadOptions={this.loadOptions}
        value={value}
        placeholder={item.label}
        onChange={this.handleChange}
      />
    );
  }

}

export default withStyles(styles)(AutocompleteSelect);
