import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { BooleanFormItem } from '../../types/form';

const styles = (theme: Theme) => createStyles({
  
});

interface OwnProps {
  className?: string;
  readOnly?: boolean;
  item: BooleanFormItem;
  value: any;
  onChange: (value: boolean) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  errors: any;
}

class BooleanControl extends React.Component<Props, State> {

  render() {
    const { classes, className, item, value, readOnly, onChange } = this.props;
    return (
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            disabled={readOnly}
            checked={!!value}
            onChange={(event, checked) => onChange(checked)}
            indeterminate={_.isUndefined(value)}
          />
        }
        label={item.label}
      />
    )
  }

}

export default withStyles(styles)(BooleanControl);
