import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import {
  Typography,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Box,
  ListItem,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  Button,
  TextField,
} from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import { mdiAccountMultiple, mdiChatProcessing } from '@mdi/js';

import { __ } from '../../utils/intl';
import { ThreadOptions, ChatThreadData } from '../../types/chat';
import { DossierListQuery, DossierData } from '../../types/dossier';
import { MemberIdentity, MemberData, checkPermission, MemberRole } from '../../types/member';
import { TeamData } from '../../types/team';
import { RootState } from '../../store/reducers';
import { getThreadRemote } from '../../store/chat/actions';
import {
  fetchItemRemote as fetchTeam,
  updateItemRemote as updateTeamRemote,
  removeItemRemote as removeTeamRemote,
  transferTeamRemote,
} from '../../store/team/actions';
import {
  fetchListRemote as fetchDossierList,
} from '../../store/dossier/actions';
import Loading from '../Common/Loading';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import TeamEditDialog from './TeamEditDialog';
import TeamList from './TeamList';
import MemberList from './MemberList';
import Tips from '../Common/Tips';
import DossierList from '../Dossier/DossierList';
import TransferDialog, { TransferOptionType } from './TransferDialog';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  paperWrapper: {
    padding: theme.spacing(4),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  sidebar: {
    width: 200,
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  description: {
    flexGrow: 1,
    fontSize: 14,
    color: '#999',
    paddingTop: theme.spacing(2),
  },
  actions: {

  },
  actionButton: {
  },
  tabs: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tab: {

  },
  settingSection: {
    paddingRight: theme.spacing(20),
  },
  bottomNav: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});


interface StateProps {
  team: TeamData | null;
  teamList: TeamData[];
  dossierList: DossierData[];
  memberList: MemberData[];
}

interface DispatchProps {
  fetchTeam: (id: string) => Promise<TeamData>;
  updateTeamRemote: (id: string, data: Partial<TeamData>) => Promise<TeamData>;
  removeTeamRemote: (id: string, password: string) => Promise<void>;
  transferTeamRemote: (id: string, userId: string, password: string) => Promise<void>; 
  fetchDossierList: (query: DossierListQuery) => Promise<DossierData[]>;
  getThreadRemote: (options: ThreadOptions) => Promise<ChatThreadData>;
}

interface OwnProps {
}

interface Params {
  teamId: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithWidth & WithStyles<typeof styles>;

interface State {
  loading: boolean;
  tab: 'dossiers' | 'members' | 'settings';
  editDialogOpen: boolean;
  transferDialogOpen: boolean;
  deleteConfirmOpen: boolean;
  password: string;
}

class TeamView extends React.Component<Props> {

  state: State = this.getDefaultState();

  getDefaultState(): State {
    return {
      loading: true,
      tab: 'dossiers',
      editDialogOpen: false,
      transferDialogOpen: false,
      deleteConfirmOpen: false,
      password: '',
    }
  }

  async componentDidMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.match.params.teamId !== this.props.match.params.teamId) {
      this.setState(this.getDefaultState());
      this.loadData(nextProps);
    }
  }

  async loadData(props: Props) {
    const { fetchTeam, fetchDossierList, match: { params: { teamId } } } = props;
    await fetchTeam(teamId);
    await fetchDossierList({ type: 'all', team_id: teamId });
    this.setState({ loading: false });
  }

  getTeamId() {
    const { team } = this.props;
    return team ? team._id : '';
  }

  getMemberQuery(): MemberIdentity {
    return {
      type: 'team',
      team_id: this.getTeamId(),
    };
  }

  checkPermission(required: MemberRole) {
    const { team } = this.props;
    return team && team.member && checkPermission(team.member.role, required) || false;
  }

  onOpenChatWindow = async () => {
    const { team, getThreadRemote } = this.props;
    if (!team) return;
    await getThreadRemote({
      to: {
        type: 'team',
        team_id: team._id,
      },
    });
  }

  onUpdateTeam = async (data: Partial<TeamData>) => {
    const { updateTeamRemote } = this.props;
    await updateTeamRemote(this.getTeamId(), data);
  }

  onTransferTeam = async (type: TransferOptionType['type'], userId: string, password: string) => {
    const { transferTeamRemote } = this.props;
    const teamId = this.getTeamId();
    if (!teamId) return;
    await transferTeamRemote(teamId, userId, password);
  }

  onDeleteTeam = async () => {
    const { history, removeTeamRemote } = this.props;
    const teamId = this.getTeamId();
    if (!teamId) return;
    try {
      await removeTeamRemote(teamId, this.state.password);
      history.push('/team');
    } catch(err) {
      console.error(err);
    }
  }

  renderDossiers() {
    const { dossierList } = this.props;
    return dossierList.length === 0 ? (
      <Tips message={__('dossier.list.empty')} />
    ) : (
      <DossierList
        query={{ type: 'all', team_id: this.getTeamId() }}
      />
    );
  }

  renderMembers() {
    const { memberList } = this.props;
    return (
      <MemberList query={this.getMemberQuery()} />
    );
  }

  renderSettings() {
    const { classes } = this.props;
    if (!this.checkPermission('owner')) {
      return (
        <Tips message={__('settings.empty')}/>
      );
    }
    return (
      <List>
        <ListItem className={classes.settingSection}>
          <ListItemText
            primary={__('team.transfer')}
            secondary={__('team.transfer.tips')}
          />
          <ListItemSecondaryAction>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => this.setState({ transferDialogOpen: true })}
            >
              {__('team.transfer.button')}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem className={classes.settingSection}>
          <ListItemText
            primary={__('team.delete')}
            secondary={__('team.delete.tips')}
          />
          <ListItemSecondaryAction>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => this.setState({ deleteConfirmOpen: true })}
            >
              {__('team.delete.button')}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    )
  }

  renderTabContent() {
    const { tab } = this.state;
    switch (tab) {
      case 'dossiers':
        return this.renderDossiers();
      case 'members':
        return this.renderMembers();
      case 'settings':
        return this.renderSettings();
    }
  }

  renderDetail() {
    const { classes, team, memberList, history } = this.props;
    const { loading, tab, editDialogOpen, transferDialogOpen, deleteConfirmOpen, password } = this.state;
    if (!team || loading) {
      return (
        <Box flexGrow={1}><Loading /></Box>
      );
    }
    return (
      <ResponsiveDialog
        open
        onClose={() => history.push('/team')}
        title={team.name || __('team.view')}
      >
        <div className={classes.header}>
          <Typography className={classes.description}>
            {team.description}
          </Typography>
          <div>
            <Tooltip title={__('im.openChatWindow')}>
              <IconButton
                className={classes.actionButton}
                onClick={this.onOpenChatWindow}
              >
                <SimpleSvgIcon path={mdiChatProcessing} />
              </IconButton>
            </Tooltip>
            {this.checkPermission('admin') &&
              <Tooltip title={__('dialog.edit')}>
                <IconButton
                  className={classes.actionButton}
                  onClick={() => this.setState({ editDialogOpen: true })}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            }
          </div>
        </div>
        <Tabs
          className={classes.tabs}
          value={tab}
          onChange={(event, tab) => this.setState({ tab })}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab className={classes.tab} value="dossiers" label={__('dossier.dossiers')} />
          <Tab className={classes.tab} value="members" label={__('member.members')} />
          <Tab className={classes.tab} value="settings" label={__('nav.settings')} />
        </Tabs>
        <Box marginY={2} flexGrow={1} overflow="auto">
          {this.renderTabContent()}
        </Box>
        <Paper
          square
          className={classes.bottomNav}
        >
          <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, tab) => this.setState({ tab })}
          >
            <BottomNavigationAction
              value="dossiers"
              label={__('dossier.dossiers')}
              icon={<ListIcon />}
            />
            <BottomNavigationAction
              value="members"
              label={__('member.members')}
              icon={<SimpleSvgIcon path={mdiAccountMultiple} />}
            />
            <BottomNavigationAction
              value="settings"
              label={__('nav.settings')}
              icon={<SettingsIcon />}
            />
          </BottomNavigation>
          <TeamEditDialog
            mode="edit"
            open={editDialogOpen}
            data={team}
            onSave={this.onUpdateTeam}
            onClose={() => this.setState({ editDialogOpen: false })}
          />
          <TransferDialog
            open={transferDialogOpen}
            members={memberList.filter(member => team.member && member._id !== team.member._id)}
            onClose={() => this.setState({ transferDialogOpen: false })}
            onSave={this.onTransferTeam}
          />
          <Confirm
            open={deleteConfirmOpen}
            content={
              <>
                {__('team.delete.confirm')}
                <TextField
                  fullWidth
                  type="password"
                  label={__('dossier.deleteConfirmPasswordLabel')}
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </>
            }
            confirmButtonText={__('team.delete.button')}
            confirmButtonProps={{ variant: 'contained', color: 'secondary' }}
            onClose={() => this.setState({ deleteConfirmOpen: false })}
            onConfirm={this.onDeleteTeam}
          />
        </Paper>
      </ResponsiveDialog>
    );
  }

  render() {
    const { classes, history, team, teamList } = this.props;
    return (
      <>
        <div className={classes.sidebar}>
          <TeamList
            list={teamList}
            onCreateTeam={() => history.push('/team/create')}
          />
        </div>
        {this.renderDetail()}
      </>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  team: states.team.current,
  teamList: states.team.list,
  dossierList: states.dossier.list,
  memberList: states.member.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchTeam: (id) => dispatch(fetchTeam(id)),
  updateTeamRemote: (id, data) => dispatch(updateTeamRemote(id, data)),
  removeTeamRemote: (id, password) => dispatch(removeTeamRemote(id, password)),
  transferTeamRemote: (id, userId, password) => dispatch(transferTeamRemote(id, userId, password)),
  fetchDossierList: (query) => dispatch(fetchDossierList(query)),
  getThreadRemote: (options) => dispatch(getThreadRemote(options)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(TeamView);
