import _ from 'lodash';
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { compose } from 'recompose';
import { Popover, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Button, Tooltip, Typography, IconButton, Box } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import OpenIcon from '@material-ui/icons/OpenInNew';
import { mdiEmailOpen } from '@mdi/js';

import { __ } from '../../utils/intl';
import { NotificationData } from '../../types/notification';
import { openTarget } from '../../utils/notification';
import { RootState } from '../../store/reducers';
import {
  switchTab,
  updateReadRemote,
  fetchUnreadCount,
  fetchListRemote,
  fetchMoreRemote,
} from '../../store/notification/actions';
import { NotificationState, TabType } from '../../store/notification/reducers';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import Loading from '../Common/Loading';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 400,
    maxWidth: '100%',
    height: 360,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    margin: '0 auto',
  },
  list: {
    flexGrow: 1,
    overflow: 'auto',
  },
  readItem: {
    opacity: 0.6,
  },
  unreadIcon: {
    color: '#fff176',
  },
  messageText: {
    fontSize: 14,
  },
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10,
  },
});

export interface StateProps {
  notification: NotificationState;
}

export interface DispatchProps {
  switchTab: (tab: TabType) => Promise<void>;
  updateReadRemote: (id: string, read: boolean) => Promise<void>;
  fetchUnreadCount: () => Promise<void>;
  fetchListRemote: () => Promise<NotificationData[]>;
  fetchMoreRemote: () => Promise<NotificationData[]>;
}

export interface OwnProps {
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

class NotificationPopup extends React.Component<Props> {

  componentDidMount() {
    const { fetchUnreadCount, fetchListRemote } = this.props;
    fetchUnreadCount();
    fetchListRemote();
  }

  componentWillReceiveProps(nextProps: Props) {
    const { switchTab } = this.props;
    if (nextProps.open && !this.props.open) {
      switchTab('unread');
    }
  }

  handleClick(item: NotificationData) {
    const { updateReadRemote } = this.props;
    updateReadRemote(item._id, true);
    if (item.target) {
      openTarget(item.target);
    }
  }

  render() {
    const {
      classes,
      open,
      anchorEl,
      onClose,
      notification,
      switchTab,
      updateReadRemote,
      fetchMoreRemote,
    } = this.props;
    const { tab, loading, list: _list, noMore } = notification;
    let list = tab === 'all' ? _list : _.filter(_list, { read: false });
    list = _.orderBy(list, 'dateCreated', 'desc');
    return (
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={0.2}
      >
        <div className={classes.root}>
          <Tabs
            className={classes.tabs}
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => switchTab(value)}
          >
            <Tab label={__('notification.filter.unread')} value="unread" />
            <Tab label={__('notification.filter.all')} value="all" />
          </Tabs>
          <List component="div" className={classes.list}>
            {list.map(item => (
              <ListItem
                key={item._id}
                button
                className={clsx({ [classes.readItem]: item.read})}
                onClick={() => this.handleClick(item)}
              >
                <ListItemIcon>
                  <Tooltip title={item.read ? __('notification.markAsUnread') : __('notification.markAsRead')}>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        updateReadRemote(item._id, !item.read);
                      }}
                    >
                      {item.read ?
                        <SimpleSvgIcon path={mdiEmailOpen} />
                        :
                        <EmailIcon className={classes.unreadIcon} />
                      }
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={<Typography className={classes.messageText}>{item.message}</Typography>}
                  secondary={moment(item.dateCreated).format('YYYY/MM/DD HH:mm')}
                />
                {item.target &&
                  <ListItemSecondaryAction>
                    <Tooltip title={__('notification.viewDetails')}>
                      <IconButton>
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            ))}
            <Box marginTop={2} textAlign="center">
              {noMore?
                <Box color="text.hint" fontSize={12}>{__('list.noMore')}</Box>
                :
                <Button onClick={() => fetchMoreRemote()}>
                  {__('list.fetchMore')}
                </Button>
              }
            </Box>
          </List>
          {loading &&
            <Loading className={classes.loading} />
          }
        </div>
      </Popover>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  notification: states.notification,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  switchTab: (tab) => dispatch(switchTab(tab)),
  updateReadRemote: (id, read) => dispatch(updateReadRemote(id, read)),
  fetchUnreadCount: () => dispatch(fetchUnreadCount()),
  fetchListRemote: () => dispatch(fetchListRemote()),
  fetchMoreRemote: () => dispatch(fetchMoreRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(NotificationPopup);
