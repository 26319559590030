import _ from 'lodash';
import { NotificationData, StatusQuery, SingleReadStatus } from '../../types/notification';
import { ThunkActionType, SimpleThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';
import { KeyValueMap } from '../../types/common';
import { TabType, NOTIFICATION_LIST_SIZE } from './reducers';

// Action Definition
export interface SetUnreadCount {
  type: '@@notification/SET_UNREAD_COUNT';
  count: number;
}

export interface SetLoading {
  type: '@@notification/SET_LOADING';
  loading: boolean;
}

export interface SetTab {
  type: '@@notification/SET_TAB';
  tab: TabType;
}

export interface SetCurrentItem {
  type: '@@notification/SET_CURRENT_ITEM';
  item: NotificationData;
}

export interface ClearCurrentItem {
  type: '@@notification/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@notification/SET_LIST';
  list: NotificationData[];
}

export interface AppendList {
  type: '@@notification/APPEND_LIST';
  list: NotificationData[];
}

export interface UpdateListItem {
  type: '@@notification/UPDATE_LIST_ITEM';
  item: NotificationData;
}

// Union Action Types
export type Action = (
  SetUnreadCount |
  SetLoading |
  SetTab |
  SetCurrentItem |
  ClearCurrentItem |
  SetList |
  AppendList |
  UpdateListItem
)

// Action Creators
export const setUnreadCount = (count: number): SetUnreadCount => ({
  type: '@@notification/SET_UNREAD_COUNT',
  count,
})

export const setLoading = (loading: boolean): SetLoading => ({
  type: '@@notification/SET_LOADING',
  loading,
});

export const setTab = (tab: TabType): SetTab => ({
  type: '@@notification/SET_TAB',
  tab,
});

export const setItem = (item: NotificationData): SetCurrentItem => ({
  type: '@@notification/SET_CURRENT_ITEM',
  item,
})

export const clearItem = (): ClearCurrentItem => ({
  type: '@@notification/CLEAR_CURRENT_ITEM',
})

export const setList = (list: NotificationData[]): SetList => ({
  type: '@@notification/SET_LIST',
  list,
})

export const appendList = (list: NotificationData[]): AppendList => ({
  type: '@@notification/APPEND_LIST',
  list,
})

export const updateListItem = (item: NotificationData): UpdateListItem => ({
  type: '@@notification/UPDATE_LIST_ITEM',
  item,
})

export const fetchUnreadCount = (): ThunkActionType => {
  return async (dispatch, getState) => {
    const { count } = await client.get<{ count: number }>('/notification/unread-count');
    dispatch(setUnreadCount(count));
  }
}

export const switchTab = (tab: TabType): ThunkActionType => {
  return async (dispatch, getState) => {
    const { tab: oldTab } = getState().notification;
    if (oldTab === tab) return;
    dispatch(setTab(tab));
    dispatch(fetchListRemote());
  }
}

export const fetchItemRemote = (notificationId: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const item = await client.get<NotificationData>('/notification/items/:notificationId', {
      params: { notificationId },
    });
    dispatch(setItem(item));
  }
}

export const updateReadRemote = (notificationId: string, read: boolean): ThunkActionType => {
  return async (dispatch, getState) => {
    await client.put('/notification/items/:notificationId/read', {
      params: { notificationId },
      data: { read },
    });
    const { list, current } = getState().notification;
    const item = _.find(list, { _id: notificationId });
    if (item && read !== item.read) {
      dispatch(updateListItem({ ...item, read }));
    }
    if (current && current._id === notificationId && read !== current.read) {
      dispatch(setItem({ ...current, read }));
    }
  }
}

export const fetchListRemote = (): ThunkActionType<NotificationData[]> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { tab } = getState().notification;
    const query: KeyValueMap = {
      size: NOTIFICATION_LIST_SIZE,
    };
    if (tab === 'unread') {
      query.read = 0;
    }
    const list = await client.get<NotificationData[]>('/notification/items', {
      query,
    });
    dispatch(setList(list));
    return list;
  }
}

export const fetchMoreRemote = (): ThunkActionType<NotificationData[]> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { list: oldList, tab } = getState().notification;
    const query: KeyValueMap = {
      size: NOTIFICATION_LIST_SIZE,
    };
    if (tab === 'unread') {
      query.read = 0;
    }
    if (oldList.length) {
      const lastItem = _.last(oldList);
      if (lastItem) {
        query.last = lastItem.dateCreated.valueOf();
      }
    }
    const list = await client.get<NotificationData[]>('/notification/items', {
      query,
    });
    dispatch(appendList(list));
    return list;
  }
}
