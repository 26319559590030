import _ from 'lodash';
import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { passwordPattern } from '../../utils/user';
import { parseValidationError } from '../../utils/error-parser';
import client, { ClientError } from '../../store/client';
import ResponsiveDialog from '../Common/ResponsiveDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    maxWidth: 500,
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
});

interface FormValues {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

type FormErrors = Partial<FormValues>;

interface OwnProps {
  open: boolean;
  onClose: (state?: string) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State extends FormValues {
  errors: FormErrors;
}

class ChangePasswordDialog extends React.Component<Props, State> {
  
  changeLanguageTimer: any = null;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  getInitialState(): State {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      errors: {},
    };
  }

  getErrorsFromValues(values: Partial<FormValues>): FormErrors {
    const { state } = this;
    const errors: FormErrors = {};
    _.each(values, (value, key) => {
      switch (key) {
        case 'oldPassword':
          errors[key] = value ? undefined
            : __('errors.changePassword.noOldPassword');
          break;
        case 'newPassword':
          errors[key] = value && passwordPattern.test(value) ? undefined
            : __('errors.changePassword.invalidPasswordFormat');
          break;
        case 'newPasswordConfirm':
          errors[key] = (state.newPassword === value) ? undefined
            : __('errors.changePassword.newPasswordNotSame');
          break;
      }
    });
    return errors;
  }

  handleChange(key: keyof FormValues, value: string) {
    const state: Partial<FormValues> = {
      [key]: value,
    };
    this.setState({
      ...(state as any),
      errors: {
        ...this.state.errors,
        ...this.getErrorsFromValues(state),
      },
    });
  }

  async handleSubmit() {
    const { onClose } = this.props;
    const { newPasswordConfirm, errors: _errors, ...values } = this.state;
    const errors = this.getErrorsFromValues(this.state);
    if (_.some(_.values(errors))) {
      this.setState({ errors });
      return;
    }
    try {
      await client.post('/account/change-pass', { data: values });
      onClose();
    } catch (err) {
      console.error(err);
      if (err instanceof ClientError && err.code === 'validation_error') {
        this.setState({
          errors: {
            ...errors,
            ...parseValidationError(err),
          },
        });
      }
    }
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { oldPassword, newPassword, newPasswordConfirm, errors } = this.state;
    return (
      <ResponsiveDialog
        modal
        open={open}
        title={__('settings.changePassword')}
        onClose={() => onClose()}
        actions={
          <>
            <Button
              color="inherit"
              onClick={() => onClose()}
            >
              {__('dialog.cancel')}
            </Button>
            <Button
              color="inherit"
              onClick={() => this.handleSubmit()}
            >
              {__('dialog.ok')}
            </Button>
          </>
        }
      >
        <div className={classes.root}>
          <TextField
            fullWidth
            className={classes.formControl}
            error={!!errors.oldPassword}
            label={__('changePassword.oldPassword')}
            value={oldPassword}
            onChange={event => this.handleChange('oldPassword', event.target.value)}
            helperText={errors.oldPassword}
          />
          <TextField
            fullWidth
            className={classes.formControl}
            error={!!errors.newPassword}
            label={__('changePassword.newPassword')}
            value={newPassword}
            onChange={event => this.handleChange('newPassword', event.target.value)}
            helperText={errors.newPassword}
          />
          <TextField
            fullWidth
            className={classes.formControl}
            error={!!errors.newPasswordConfirm}
            label={__('changePassword.newPasswordConfirm')}
            value={newPasswordConfirm}
            onChange={event => this.handleChange('newPasswordConfirm', event.target.value)}
            helperText={errors.newPasswordConfirm}
          />
        </div>
      </ResponsiveDialog>
    );
  }
}

export default withStyles(styles)(ChangePasswordDialog);
