import React from 'react'
import { observable, toJS, autorun } from 'mobx'
import { observer } from 'mobx-react'
import { StoreXState } from '../storex';

export type GenericComponentClass = { new(...args: any[]): React.Component<any, any> }

let _propName = 'store';
let _initialValue: any = undefined;

const store: StoreXState = {} as any;

export const initReaktor = function (initialValue, propName: string) {
  if (_initialValue) throw new Error('already initialized')
  if (propName) _propName = propName
  _initialValue = initialValue
  return _initialValue
}

export const withReaktor = function (propName: keyof StoreXState) {
  return function<T extends GenericComponentClass>(Component: T) {
    @observer
    class WrapperComponent extends Component {
      [propName || _propName] = _initialValue
    }
    return WrapperComponent
  }
}

export function initLocalReaktor<K extends keyof StoreXState>(initialValue: StoreXState[K], propName: K) {
  if (!initialValue) throw new Error('initial value required');
  if (!propName) throw new Error('property name required');
  if (store[propName]) throw new Error('already initialized');
  store[propName] = initialValue;
  return store[propName];
}

export function withLocalReaktor(propName: keyof StoreXState) {
  if (!propName) throw new Error('property name required');
  return function <T extends { new(...args: any[]): React.Component<any, any> }>(ComponentClass: T) {
    @observer
    class WrapperComponent extends ComponentClass {
      static displayName = `WithLocalReaktor(${(ComponentClass as React.ComponentClass).displayName || ComponentClass.name})`;
      [propName] = store[propName]
    }
    return WrapperComponent
  }
}
