import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { ClaimData } from '../../types/claim';
import { __ } from '../../utils/intl';
import ClaimListItem from './ClaimListItem';

const styles = (theme: Theme) => createStyles({
  content: {
    minWidth: 600,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

interface OwnProps {
  open: boolean;
  onClose: () => void;
  claimList: ClaimData[];
  createClaim: (data: Partial<ClaimData>) => Promise<ClaimData>;
  updateClaim: (claimId: string, data: Partial<ClaimData>) => Promise<ClaimData>;
  removeClaim: (claimId: string) => Promise<void>;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class ClaimListDialog extends React.Component<Props, State> {

  handleChange(id: string, value: string) {
    const { updateClaim } = this.props;
    updateClaim(id, { content: value });
  }

  handleDelete(id: string) {
    const { removeClaim } = this.props;
    removeClaim(id);
  }

  handleCreate() {
    const { createClaim } = this.props;
    createClaim({
      content: '',
    });
  }

  render() {
    const { classes, open, onClose, claimList } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{__('claimListDialog.title')}</DialogTitle>
        <DialogContent className={classes.content}>
          {claimList.map(item => (
            <ClaimListItem
              key={item._id}
              value={item.content}
              onChange={value => this.handleChange(item._id, value)}
              onDelete={() => this.handleDelete(item._id)}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCreate()}>
            {__('claimList.addItem')}
          </Button>
          <Button onClick={onClose}>
            {__('dialog.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(ClaimListDialog);
