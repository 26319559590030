import _ from 'lodash';
import uuid from 'uuid';
import React from 'react';
import classNames from 'classnames';
import { compose } from 'recompose';
import { Paper, FormControlLabel, Switch, IconButton, Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import ResizeObserver from 'react-resize-observer';
import VoiceIcon from '@material-ui/icons/KeyboardVoice';
import CameraIcon from '@material-ui/icons/CameraAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CreateNewFileIcon from '@material-ui/icons/InsertDriveFile';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { __ } from '../../utils/intl';
import { FileData } from '../../types/file';
import { DocumentData } from '../../types/document';
import { DossierData } from '../../types/dossier';
import { TreeItem } from '../../types/tree';
import { isFeatureEnabled } from '../../constants/experiment';
import { getUploadUrl } from '../../utils/drive';
import { findItemAndPath } from '../../utils/tree';
import client from '../../store/client';
import FolderBreadcrumbs from '../Common/FolderBreadcrumbs';
import SimpleUploader, { PureSimpleUploader } from '../Common/SimpleUploader';
import CombinedFab from '../Common/CombinedFab';
import {
  STACK_EXPLODING_DURATION,
  STACK_IMPLODING_DURATION,
  getPaperExtraMargin,
  STACK_ITEM_DEFAULT_WIDTH,
  STACK_MIN_ITEMS_PER_ROW,
  STACK_ITEM_PADDING,
} from '../Dossier/constants';
// import ResumableUploader from '../Common/ResumableUploader';
import Confirm from '../Common/Confirm';
import StackItem, { OwnProps as StackItemProps } from './StackItem';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  toolbar: {
    position: 'sticky',
    zIndex: 500,
    top: 0,
    margin: theme.spacing(4),
    padding: theme.spacing(1),
    height: 56,
    display: 'flex',
    boxShadow: '0 2px 4px rgba(0,0,0,0.29)',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginBottom: theme.spacing(2),
      borderRadius: 0,
    },
  },
  grow: {
    flexGrow: 1,
  },
  stacks: {
    flex: '1 0 auto',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(14),
    transition: `opacity ease ${STACK_IMPLODING_DURATION}s`,
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    '&.imploding': {
      transform: 'scale(0.25)',
      transition: `all ease ${STACK_IMPLODING_DURATION}s`,
      opacity: 0,
    },
    '& ul': {
      listStyle: 'none',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, 218px)',
      gridGap: 48,
      justifyContent: 'space-between',
      margin: 0,
      padding: 0,
      '& li': {
        margin: 0,
      },
    },
  },
  fab: {
    position: 'fixed',
    left: '50%',
    bottom: theme.spacing(4),
    width: 160,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: cyan[500],
  },
  fabButton: {
    color: '#fff',
  },
});

interface OwnProps {
  id: string;
  tree: TreeItem;
  selectedItem: TreeItem | null;
  onSelectedItemChange: (item: TreeItem) => void;
  dossier?: DossierData;
  onUpdate?: (path: TreeItem[], item: TreeItem) => void;
  onDelete?: (items: TreeItem[]) => void;
  onMove?: (path: TreeItem[], item: TreeItem, movement: number) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  item: TreeItem;
  path: TreeItem[];
  sorting: boolean;
  editing: boolean;
  selectedItems: TreeItem[];
  explodingItem: TreeItem | null;
  imploding: boolean;
  stackWidth: number;
  deleteConfirmOpen: boolean;
  zoom: number;
}

interface ListProps {
  items: TreeItem[];
  zoom: number;
  onClick?: (item: TreeItem) => void;
}

const SortableItem = SortableElement<StackItemProps>((itemProps: StackItemProps) => <StackItem {...itemProps} />);
const SortableList = SortableContainer<ListProps>((listProps: ListProps) => {
  const { items, zoom, onClick } = listProps;
  return (
    <ul>
      {items.map((item, index) => (
        <SortableItem
          sorting
          key={`${item._id}-${item.name}-${index}`}
          index={index}
          item={item}
          onClick={() => onClick && onClick(item)}
          zoom={zoom}
        />
      ))}
    </ul>
  );
});

class StacksView extends React.Component<Props, State> {

  rootElement: HTMLElement | null = null;
  uploader: PureSimpleUploader | null = null;

  static getDerivedStateFromProps(props: Props): Partial<State> {
    const { tree, selectedItem } = props;
    const defaultResult = {
      item: tree,
      path: [tree],
    };
    if (!selectedItem) {
      return defaultResult;
    } else {
      let { item, path } = findItemAndPath(tree, { _id: selectedItem._id });
      if (item) {
        while (item && item.type === 'file') {
          item = path.pop() || null;
        }
      }
      if (!item) {
        return defaultResult;
      } else {
        return { item, path: [...path, item] };
      }
    }
  }
  
  constructor(props: Readonly<Props>) {
    super(props);
    const { tree } = props;
    this.handleResize = _.debounce(this.handleResize.bind(this), 500);
    this.state = {
      ...this.getInitialState(),
      item: tree,
      path: [tree],
    }
  }

  getInitialState() {
    return {
      sorting: false,
      editing: false,
      selectedItems: [],
      explodingItem: null,
      imploding: false,
      stackWidth: window.innerWidth,
      deleteConfirmOpen: false,
      zoom: this.calcZoom(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  calcZoom(): number {
    const paperMargin = getPaperExtraMargin();
    let width = window.innerWidth;
    const stackItemWidth = STACK_ITEM_DEFAULT_WIDTH + STACK_ITEM_PADDING * 2;
    if (width < stackItemWidth * STACK_MIN_ITEMS_PER_ROW + paperMargin * (STACK_MIN_ITEMS_PER_ROW + 1)) {
      return (width - paperMargin * (STACK_MIN_ITEMS_PER_ROW + 2)) / STACK_MIN_ITEMS_PER_ROW / stackItemWidth;
    } else {
      return 1;
    }
  }

  handleResize() {
    const zoom = this.calcZoom();
    this.setState({ zoom });
  }

  handleExplode(item: TreeItem) {
    this.setState({
      explodingItem: item,
    }, () => {
      setTimeout(() => {
        this.setState({
          explodingItem: null,
        }, () => {
          this.handleSelectedItemChange(item);
        });
        if (this.rootElement) {
          const mainElement = this.rootElement.parentElement as HTMLElement;
          mainElement.scrollTop = 0;
        }
      }, STACK_EXPLODING_DURATION * 1000);
    });
  }

  handleGoUp(item: TreeItem) {
    this.setState({
      imploding: true,
    });
    setTimeout(() => {
      this.setState({
        imploding: false,
      }, () => {
        this.handleSelectedItemChange(item);
      });
    }, STACK_IMPLODING_DURATION * 1000);
  }

  getCurrentFolder() {
    const { path } = this.state;
    return path[path.length - 1];
  }

  handleUpdateItem(item: TreeItem | DocumentData) {
    const { onUpdate } = this.props;
    const { path } = this.state;
    onUpdate && onUpdate(path, _.pick(item, ['_id', 'type', 'name', 'childIndex']));
  }

  handleEnterEditing() {
    this.setState({ editing: true });
  }

  handleExitEditing() {
    this.setState({ editing: false });
  }

  async handleUploadedFile(data: Partial<FileData>) {
    const file = await client.post<FileData>('/drive/files', { data: data });
    const parent = this.getCurrentFolder();
    const document: Partial<DocumentData> = {
      type: 'file',
      fileType: 'image-list',
      name: file.filename,
      parent_id: parent._id,
      pages: [{
        _id: uuid.v4(),
        type: 'image',
        file_id: file._id,
      }],
    }
    const result = await client.post<DocumentData>('/documents', { data: document });
    this.handleUpdateItem(result);
  }

  async createFolder() {
    const parent = this.getCurrentFolder();
    const document: Partial<DocumentData> = {
      type: 'folder',
      name: __('stacksView.newFolderName'),
      parent_id: parent._id,
    };
    const result = await client.post<DocumentData>('/documents', { data: document });
    this.handleUpdateItem(result);
  }

  openUploader() {
    if (this.uploader) {
      this.uploader.openBrowse();
    }
  }

  handleSelectedItemChange(item: TreeItem) {
    const { onSelectedItemChange } = this.props;
    onSelectedItemChange(item);
  }

  handleSelectionChange(item: TreeItem, selected: boolean) {
    let { selectedItems } = this.state;
    if (selected) {
      selectedItems = [...selectedItems, item];
    } else {
      selectedItems = _.without(selectedItems, item);
    }
    this.setState({ selectedItems });
  }

  async handleMove(item: TreeItem, movement: number) {
    const { onMove } = this.props;
    const { path } = this.state;
    onMove && onMove(path, item, movement);
    await client.post(`/documents/${item._id}/move-index`, { data: { movement }});
  }

  async handleDelete() {
    const { onDelete } = this.props;
    const { selectedItems } = this.state;
    const ids = _.map(selectedItems, '_id');
    await client.delete('/documents', { data: { ids } });
    this.setState({ deleteConfirmOpen: false }, () => {
      onDelete && onDelete(selectedItems);
    });
  }

  render() {
    const { id, classes, dossier } = this.props;
    const {
      item,
      path,
      sorting,
      explodingItem,
      selectedItems,
      imploding,
      editing,
      deleteConfirmOpen,
      zoom,
    } = this.state;
    const children: TreeItem[] = _.sortBy(item.children || [], 'childIndex');
    const stackItemWidth = STACK_ITEM_DEFAULT_WIDTH * zoom + STACK_ITEM_PADDING * 2;
    return (
      <div
        id={`#${id}`}
        ref={ref => this.rootElement = ref}
        className={classes.root}
      >
        <Paper elevation={0} className={classes.toolbar}>
          {!editing ?
            <FolderBreadcrumbs
              path={path}
              onItemClick={(pathItem, i) => this.handleGoUp(pathItem)}
            />
            :
            <>
              <Button>
                {__('stacksView.move')}
              </Button>
              <Button>
                {__('stacksView.delete')}
              </Button>
              <Button>
                {__('stacksView.explode')}
              </Button>
              <Button>
                {__('stacksView.combine')}
              </Button>
            </>
          }
          {/* <FormControlLabel
            control={
              <Switch
                checked={sorting}
                onChange={event => this.setState({ sorting: event.target.checked })}
              />
            }
            label="Sort"
          /> */}
          <div className={classes.grow} />
          {isFeatureEnabled('stackViewManagement') &&
            <Button
              color="primary"
              onClick={() => this.setState({ editing: !editing })}
            >
              {editing ? __('dialog.cancel') : __('stacksView.manage')}
            </Button>
          }
        </Paper>
        <section className={classNames(classes.stacks, { imploding })}>
          {/* <ResizeObserver
            onResize={rect => console.log(rect)}
          /> */}
          {item.type === 'folder' ?
            sorting ?
              <SortableList
                axis="xy"
                zoom={zoom}
                items={children}
              />
              :
              <ul
                style={{
                  gridTemplateColumns: `repeat(auto-fill, ${stackItemWidth}px)`,
                  gridGap: getPaperExtraMargin(),
                }}
              >
                {children.map((child, i) => (
                  <StackItem
                    key={`${child._id}-${child.name}-${i}`}
                    item={child}
                    dossier={dossier}
                    editing={editing}
                    selected={selectedItems.includes(child)}
                    fading={explodingItem ? explodingItem !== child : false}
                    zoom={zoom}
                    onClick={() => this.handleExplode(child)}
                    onDelete={() => this.setState({ selectedItems: [child], deleteConfirmOpen: true })}
                    onSelectionChange={(selected) => this.handleSelectionChange(child, selected)}
                    onMove={(movement) => this.handleMove(child, movement)}
                  />
                ))}
              </ul>
            :
            <div>Content of file</div>
          }
        </section>
        {isFeatureEnabled('stackViewManagement') &&
          <CombinedFab className={classes.fab}>
            {/* <IconButton className={classes.fabButton}>
              <VoiceIcon />
            </IconButton> */}
            {/* <IconButton
              className={classes.fabButton}
              onClick={() => {
                // @ts-ignore
                if (window.cordova) {
                  cordovaWescan()
                } else {
                  this.uploader && this.uploader.openCapture()
                }
              }}
            >
              <CameraIcon />
            </IconButton> */}
            <IconButton
              className={classes.fabButton}
              onClick={() => this.uploader && this.uploader.openBrowse()}
            >
              <CloudUploadIcon />
            </IconButton>
            <IconButton
              className={classes.fabButton}
              onClick={() => this.uploader && this.uploader.openBrowse()}
            >
              <CreateNewFileIcon />
            </IconButton>
            <IconButton
              className={classes.fabButton}
              onClick={() => this.createFolder()}
            >
              <CreateNewFolderIcon />
            </IconButton>
            {/* <IconButton className={classes.fabButton}>
              <EditIcon />
            </IconButton> */}
          </CombinedFab>
        }
        <SimpleUploader
          getRef={ref => this.uploader = ref}
          getUploadUrl={getUploadUrl}
          onUploadFinish={(file) => this.handleUploadedFile(file)}
        />
        <Confirm
          open={deleteConfirmOpen}
          title="Delete"
          content="Please confirm deleting this item"
          confirmButtonText="Delete"
          onClose={() => this.setState({ deleteConfirmOpen: false })}
          onConfirm={() => this.handleDelete()}
        />
      </div>
    )
  }

}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(StacksView);
