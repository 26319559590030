import React from 'react';

import { __ } from '../../utils/intl';
import { getPublicUrl } from '../../utils/url';
import PopupPage from '../Common/PopupPage';

const pageTitles = {
  'privacy-policy': __('account.privacyPolicy'),
  'terms-of-service': __('account.termsOfService'),
};

export type TermsType = 'privacy-policy' | 'terms-of-service' | undefined;

interface Props {
  type: TermsType;
  onClose: () => void;
}

interface State {
}

class TermsPopup extends React.Component<Props, State> {

  render() {
    const { type, onClose } = this.props;
    const title = type ? pageTitles[type] : '';
    const url = getPublicUrl(`/terms/${type}-zh-CN.html`);
    return (
      <PopupPage
        open={!!type}
        title={title}
        url={url}
        onClose={onClose}
      />
    )
  }
}

export default TermsPopup;
