import { DocData } from '../types/base';
import { JSONParseDate } from '../utils/json';
import { store } from '.';
import { processIncomingMessage } from './chat/actions';

export function syncDoc(data: DocData) {
  const doc: DocData = JSONParseDate(JSON.stringify(data));
  switch (doc.__type) {
    case 'Notification':
      store.dispatch({ type: '@@notification/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'Dossier':
      store.dispatch({ type: '@@dossier/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'Document':
      store.dispatch({ type: '@@document/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'User':
      store.dispatch({ type: '@@user/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'ChatThread':
      store.dispatch({ type: '@@chat/UPDATE_THREAD', item: doc });
      break;
    case 'ChatMessage':
      store.dispatch(processIncomingMessage(doc) as any);
      // store.dispatch({ type: '@@chat/SET_INCOMING_MESSAGE', item: doc });
      break;
    case 'Member':
      store.dispatch({ type: '@@member/UPDATE_LIST_ITEM', item: doc })
      break;
  }
}

export function syncRemove(data: DocData) {
  const doc: DocData = JSONParseDate(JSON.stringify(data));
  switch (doc.__type) {
    case 'Notification':
      // store.dispatch({ type: '@@notification/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'Dossier':
      store.dispatch({ type: '@@dossier/REMOVE_LIST_ITEM', id: doc._id });
      break;
    case 'Document':
      // store.dispatch({ type: '@@document/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'User':
      // store.dispatch({ type: '@@user/UPDATE_LIST_ITEM', item: doc });
      break;
    case 'ChatThread':
      store.dispatch({ type: '@@chat/REMOVE_THREAD', id: doc._id });
      break;
    case 'ChatMessage':
      // store.dispatch(processIncomingMessage(doc) as any);
      // store.dispatch({ type: '@@chat/SET_INCOMING_MESSAGE', item: doc });
      break;
    case 'Member':
      store.dispatch({ type: '@@member/REMOVE_LIST_ITEM', id: doc._id })
      break;
  }
}
