import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import './TabView.scss';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { KeyValueMap } from '../../types/common';

export type ColumnRenderer<T = any, V = any> = (value: V, record: T) => React.ReactNode

export interface Column<T = any> {
  field: keyof T;
  label: string;
  renderer?: ColumnRenderer<T, T[keyof T]>;
}

export type Record = KeyValueMap;

function formatValue(value: any): string {
  if (_.isUndefined(value)) {
    return '';
  } else if (_.isDate(value)) {
    return value.toLocaleDateString();
  }
  if (!_.isString(value)) {
    return _.isFunction(value.toString) ? value.toString() : '';
  }
  return value;
}

const styles = (theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});

interface OwnProps {
  columns: Column[];
  data: any[];
}

type Props = OwnProps & WithStyles<typeof styles>;

class DataTable extends React.Component<Props> {

  render() {
    const { classes, columns, data } = this.props;
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.field as string}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((column, colIndex) => {
                  const value = _.get(record, column.field);
                  return (
                    <TableCell key={column.field as string} component="th" scope="row">
                      {_.isFunction(column.renderer) ? column.renderer(value, record) : formatValue(value)}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(styles)(DataTable);
