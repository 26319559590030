import { DossierTreeItem } from '../../../types/dossier';

const demoTree: DossierTreeItem = {
  _id: '866967d2-526d-4caf-ba58-f336d8d76cb1',
  type: 'folder',
  childIndex: 0,
  name: '民事案件',
  flags: {},
  children: [
    {
      _id: '18966750-2e80-43ea-8255-e286eabb8a4a',
      type: 'folder',
      childIndex: 0,
      name: '律所内部资料',
      flags: {},
      children: [
        {
          _id: '95894f11-2704-4e93-8d78-6fb6460e2133',
          type: 'file',
          childIndex: 0,
          name: '民事案件审批表',
          flags: {}
        },
        {
          _id: 'ef3c0fd8-0e80-4b7c-b8c6-0bc5d2e0dd92',
          type: 'file',
          childIndex: 1,
          name: '案件委托代理合同',
          flags: {}
        },
        {
          _id: '66ab32dc-0185-415a-9f12-fb042c8f83bd',
          type: 'file',
          childIndex: 2,
          name: '代理费票据',
          flags: {}
        },
        {
          _id: '02ed3cb3-f14f-4c68-a304-c597061ddce4',
          type: 'file',
          childIndex: 3,
          name: '结案报告',
          flags: {}
        }
      ]
    },
    {
      _id: 'c91ad50a-82bb-4056-be82-cf58c81dbbf1',
      type: 'folder',
      childIndex: 1,
      name: '律师工作记录',
      flags: {},
      children: [
        {
          _id: '9c4d7e62-d6fe-4435-8934-1b4f3d618d8d',
          type: 'file',
          childIndex: 0,
          name: '收案沟通记录',
          flags: {}
        },
        {
          _id: 'fb6fa1c7-a515-4c97-930b-a223aaa727f7',
          type: 'file',
          childIndex: 1,
          name: '一审立案前沟通记录',
          flags: {}
        },
        {
          _id: 'b3c10671-7318-4e8a-a343-81e4f872fcb5',
          type: 'file',
          childIndex: 2,
          name: '二审上诉前沟通记录',
          flags: {}
        },
        {
          _id: '610e8435-e027-4e99-994f-ea8ee0232333',
          type: 'file',
          childIndex: 3,
          name: '再审申请前沟通记录',
          flags: {}
        },
        {
          _id: 'c157d693-bf48-4e4a-9b18-a4e552c14838',
          type: 'file',
          childIndex: 4,
          name: '一审答辩前沟通记录',
          flags: {}
        },
        {
          _id: '4468e08c-43bb-4983-8be1-afeb674f83d8',
          type: 'file',
          childIndex: 5,
          name: '二审答辩前沟通记录',
          flags: {}
        },
        {
          _id: '0f212d68-ce6b-46e4-8346-9de39e61e712',
          type: 'file',
          childIndex: 6,
          name: '再审答辩前沟通记录',
          flags: {}
        },
        {
          _id: 'd4b32b83-e428-4135-bf02-456c68cec8fe',
          type: 'file',
          childIndex: 7,
          name: '开庭前沟通记录',
          flags: {}
        },
        {
          _id: '38b11489-eaa7-4dd0-8f15-5d2f24b6797a',
          type: 'file',
          childIndex: 8,
          name: '开庭后沟通记录',
          flags: {}
        },
        {
          _id: 'b7856ff4-3cdf-4591-8e52-036f54858f1b',
          type: 'file',
          childIndex: 9,
          name: '判决后沟通记录',
          flags: {}
        },
        {
          _id: 'f4dc60f7-1b87-47d0-87fc-549bd8b9dc23',
          type: 'file',
          childIndex: 10,
          name: '日常沟通记录',
          flags: {}
        },
        {
          _id: '093f8452-e82a-46c9-9d4e-f6f15277065a',
          type: 'file',
          childIndex: 11,
          name: '接收资料清单',
          flags: {}
        },
        {
          _id: 'b89822e2-7f80-48ee-801a-c87030ce3032',
          type: 'file',
          childIndex: 12,
          name: '返还资料清单',
          flags: {}
        }
      ]
    },
    {
      _id: 'a8e76b53-93e0-45ab-8020-70bfb9e8f8df',
      type: 'folder',
      childIndex: 2,
      name: '当事人案件资料',
      flags: {
        party: true
      },
      children: [
        {
          _id: '661813fc-52b4-4c60-a0e5-c0851efb27d5',
          type: 'file',
          childIndex: 0,
          name: '当事人身份证复印件',
          flags: {}
        },
        {
          _id: 'e2dffcb0-8534-4ea0-aba3-e46dc4f078c5',
          type: 'file',
          childIndex: 1,
          name: '当事人法定代理人身份证复印件',
          flags: {}
        },
        {
          _id: '0f3df262-f1cd-439f-895d-00bc75dab0f4',
          type: 'file',
          childIndex: 2,
          name: '当事人法人资格证明',
          flags: {}
        },
        {
          _id: 'a915022b-a9c8-4dee-9338-6d0f8e83e439',
          type: 'file',
          childIndex: 3,
          name: '法定代表人身份证明书',
          flags: {}
        },
        {
          _id: '1a6a4fb6-01df-4798-9094-152bdf3834b4',
          type: 'file',
          childIndex: 4,
          name: '当事人法定代表人身份证明复印件',
          flags: {}
        },
        {
          _id: '6c5573ee-a70d-42c7-95e2-e03799290ebb',
          type: 'file',
          childIndex: 5,
          name: '当事人其他组织资格证明',
          flags: {}
        },
        {
          _id: '21a6b3d9-0264-4998-9ba3-aa228e8a0233',
          type: 'file',
          childIndex: 6,
          name: '主要负责人身份证明书',
          flags: {}
        },
        {
          _id: 'c3ff95ac-36ee-4f1f-b190-bab109cffe8e',
          type: 'file',
          childIndex: 7,
          name: '当事人负责人身份证复印件',
          flags: {}
        },
        {
          _id: '6ea18001-696e-4978-9fd5-07d4286a25f7',
          type: 'file',
          childIndex: 8,
          name: '授权委托书(公民代理用)',
          flags: {}
        },
        {
          _id: '10f73faa-e6bd-4f57-8879-dec79111ed3a',
          type: 'file',
          childIndex: 9,
          name: '授权委托书(律师代理用)',
          flags: {}
        },
        {
          _id: '70c3c63b-4b2f-4b9f-83a2-ffc2bc5760a8',
          type: 'file',
          childIndex: 10,
          name: '律师事务所出庭函',
          flags: {}
        },
        {
          _id: 'f1260c96-1c33-4759-b73a-b4dee53afdb8',
          type: 'file',
          childIndex: 11,
          name: '律师执业证复印件',
          flags: {}
        },
        {
          _id: 'a3fde2c0-65fe-46d4-893d-af13f0e95b5d',
          type: 'folder',
          childIndex: 12,
          name: '证据',
          flags: {
            evidence: true
          },
          children: [
            {
              _id: '5238ec1f-498a-45b6-8e01-08098a1d3b4d',
              type: 'file',
              childIndex: 0,
              name: '证据目录',
              flags: {
                catalog: true
              }
            },
            {
              _id: 'b9711b88-c47a-4b29-846f-f230935021ee',
              type: 'file',
              childIndex: 1,
              name: '证据',
              flags: {
                evidence: true
              }
            }
          ]
        },
        {
          _id: '8aba0104-a149-4b09-a2ad-377ee7c89c69',
          type: 'file',
          childIndex: 13,
          name: '质证意见',
          flags: {}
        },
        {
          _id: 'b3e2e443-4678-415e-9a55-8da8dbb5075b',
          type: 'file',
          childIndex: 14,
          name: '民事代理词',
          flags: {}
        },
        {
          _id: 'a31ca383-d161-4030-97a8-1e9f0fd71e3d',
          type: 'file',
          childIndex: 15,
          name: '民事起诉状',
          flags: {}
        },
        {
          _id: '85bbef99-e0c1-4a7d-b1ce-1473045e5d68',
          type: 'file',
          childIndex: 16,
          name: '民事答辩状',
          flags: {}
        },
        {
          _id: 'ae1d9cfe-b16f-432d-9741-232800d59f47',
          type: 'file',
          childIndex: 17,
          name: '民事上诉状',
          flags: {}
        },
        {
          _id: '65c0899f-8de5-4a51-8d5d-472e9cc1cdc5',
          type: 'file',
          childIndex: 18,
          name: '民事二审答辩状',
          flags: {}
        },
        {
          _id: '96009bd9-827b-46d0-8e9f-6b31393e56cb',
          type: 'file',
          childIndex: 19,
          name: '民事再审申请书',
          flags: {}
        },
        {
          _id: '33159524-9952-49e9-9a09-e72661cce099',
          type: 'file',
          childIndex: 20,
          name: '再审答辩状',
          flags: {}
        }
      ]
    },
    {
      _id: '1cc009a2-9030-4689-9721-6bc6bda316c4',
      type: 'folder',
      childIndex: 3,
      name: '法院司法文书',
      flags: {},
      children: [
        {
          _id: '54640320-ea09-4a8b-94e1-98ba8c70c8ef',
          type: 'file',
          childIndex: 0,
          name: '案件受理通知',
          flags: {}
        },
        {
          _id: '1669d26f-f308-4d00-a2b0-ef84843ba691',
          type: 'file',
          childIndex: 1,
          name: '诉讼费缴费通知',
          flags: {}
        },
        {
          _id: '0fc1fc38-733f-4e35-80fd-a1f6c807174a',
          type: 'file',
          childIndex: 2,
          name: '举证通知书',
          flags: {}
        },
        {
          _id: 'a220bbf5-9e93-40b1-9d90-b692191219c5',
          type: 'file',
          childIndex: 3,
          name: '开庭传票',
          flags: {}
        },
        {
          _id: '8d931f3c-4a14-425c-838d-4028799dd4fc',
          type: 'file',
          childIndex: 4,
          name: '证据交换笔录',
          flags: {}
        },
        {
          _id: '3957877c-5f08-488e-8195-338bfcb9adba',
          type: 'file',
          childIndex: 5,
          name: '庭审笔录',
          flags: {}
        },
        {
          _id: '90f99296-6a53-4b04-b9a5-461f2c42448c',
          type: 'file',
          childIndex: 6,
          name: '民事判决书',
          flags: {}
        },
        {
          _id: '6b6706ac-cccf-4539-80b4-771747de539f',
          type: 'file',
          childIndex: 7,
          name: '民事调解书',
          flags: {}
        },
        {
          _id: '3d68e007-af64-419d-b58f-68c39016c175',
          type: 'file',
          childIndex: 8,
          name: '民事裁定书',
          flags: {}
        }
      ]
    },
    {
      _id: 'b07376ae-4af3-419b-865e-ac027bf54235',
      type: 'folder',
      childIndex: 4,
      name: '案件研究资料',
      flags: {},
      children: [
        {
          _id: '35363d96-26aa-45f5-ace6-a4af91857cbc',
          type: 'file',
          childIndex: 0,
          name: '案件事实摘要',
          flags: {}
        },
        {
          _id: '12ce31c8-a044-424c-812e-5e9be1b37035',
          type: 'file',
          childIndex: 1,
          name: '法律研究报告',
          flags: {}
        },
        {
          _id: '5241952c-05f4-441c-b30b-e6ba4ff5b02e',
          type: 'file',
          childIndex: 2,
          name: '案例检索报告',
          flags: {}
        },
        {
          _id: 'e02a143e-46d5-4fbb-96e2-adab4270a90d',
          type: 'file',
          childIndex: 3,
          name: '案情分析报告',
          flags: {}
        },
        {
          _id: '089a19bb-8d33-499a-837e-08ed814d6596',
          type: 'file',
          childIndex: 4,
          name: '模拟法庭记录',
          flags: {}
        }
      ]
    }
  ]
}

export default demoTree;
