import _ from 'lodash';
import clsx from 'clsx';
import uuid from 'uuid/v4';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { IconButton, Paper, InputBase, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router';
import CropImageIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import ResetIcon from '@material-ui/icons/SettingsBackupRestore';
import CropDoneImageIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Selection from '@simonwep/selection-js';

import { DossierData } from '../../types/dossier';
import { DocumentData } from '../../types/document';
import { __ } from '../../utils/intl';
import client from '../../store/client';
import { RootState } from '../../store/reducers';
import { withLocalReaktor } from '../../utils/reaktor';
import { scrollIntoViewById } from '../../utils/ui';
import { AnnotationStore } from '../../storex/annotation';
import { AnnotationData } from '../../types/annotation';
import { EvidenceReference } from '../../types/fact';
import { IconButtonProps } from '@material-ui/core/IconButton';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  selected: {
    position: 'relative',
    boxShadow: '0px 0px 6px 6px rgba(0,0,255,0.4)',
  },
  inputRoot: {
    padding: 0,
  },
  input: {
    padding: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(-1),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
  dossier: DossierData;
  document: DocumentData;
  annotation: AnnotationData;
  reference: EvidenceReference;
  referenceIndex: number;
  resetInstances: () => void;
  getCropperInstance: (selectedTarget?: HTMLImageElement | HTMLCanvasElement, options?: Cropper.Options) => Cropper;
  getSelectionInstance: () => Selection;
}

interface State {
}


interface Params {
  documentId: string;
  dossierId: string;
}

export type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

@withLocalReaktor('annotationsData')
@observer
class AnnotationReference extends React.Component<Props, State> {

  annotationsData!: AnnotationStore;

  getCroppedData = async () => {
    const {
      getSelectionInstance,
      getCropperInstance,
      resetInstances,
      reference,
    } = this.props;
    const selection = getSelectionInstance()
    const cropperInstance = getCropperInstance();
    const box = cropperInstance.getData();
    const ocrText = await this.getOCRResult();
    const { x: left, y: top, width, height } = box;
    reference.box = { left, top, width, height };
    if (reference.comment === reference.ocrText) {
      reference.comment = ocrText;     
    }
    reference.ocrText = ocrText;
    if (!reference.page_id) {
      reference.page_id = cropperInstance.element.dataset.pageid;
    }
    resetInstances();
    this.unsetCurrentReferenceId();
    this.updateReference();
  }

  setCurrentReferenceId = () => {
    const { annotationsData } = this;
    const {
      annotation,
      reference,
      resetInstances,
      getCropperInstance,
      getSelectionInstance,
    } = this.props;
    const currentAnnotationId = annotation._id
    const currentReferenceId = reference._id
    annotationsData.setCurrentId({ currentAnnotationId, currentReferenceId });
    if (!reference.box) {
      resetInstances();
      getSelectionInstance();
    } else {
      const { left: x, top: y, width, height } = reference.box;
      const target = document.querySelector(`#annotation-area [data-pageid="${reference.page_id}"]`);
      if (!target) return;
      getCropperInstance(target, {
        data: { x, y, width, height }
      });
      target.scrollIntoView();
    }
  }

  unsetCurrentReferenceId = () => {
    const { annotationsData } = this;
    annotationsData.currentReferenceId = null;
  }

  getOCRResult = async () => {
    const { getCropperInstance } = this.props;
    const canvas: HTMLCanvasElement = getCropperInstance().getCroppedCanvas();
    const result: any = await new Promise((resolve, reject) => {
      canvas.toBlob(async blob => {
        if (!blob) {
          return reject();
        }
        const formData = new FormData();
        formData.append('file', blob);
        const result = await client.post('/tools/ocr', {
          data: formData,
        });
        resolve(result)
      });
    });
    return _.map(result, 'text').join('')
  }

  getApiUrl() {
    const { dossier, document, annotation } = this.props;
    return `/dossiers/${dossier._id}/documents/${document._id}/annotations/${annotation._id}`
  }

  delayedUpdate = _.debounce(() => {
    this.updateReference();
  }, 1000)

  updateReference = async () => {
    const { annotation } = this.props;
    await client.put(this.getApiUrl(), {
      data: annotation,
    });
  }

  deleteReference = async () => {
    const { annotation, referenceIndex } = this.props;
    annotation.references.splice(referenceIndex, 1);
    await client.put(this.getApiUrl(), {
      data: annotation,
    });
  }

  handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { reference } = this.props;
    reference.comment = event.target.value;
    this.delayedUpdate();
  }

  handleResetComment = () => {
    const { reference } = this.props;
    reference.comment = reference.ocrText;
    this.updateReference();
  }

  resetCropping = () => {
    const { annotationsData } = this;
    const { resetInstances } = this.props;
    annotationsData.resetCropping();
    resetInstances();
  }

  render() {
    const { annotationsData } = this;
    const { classes, reference, annotation } = this.props;
    const isCurrentAnnotationId = annotationsData.currentAnnotationId === annotation._id;
    const isCurrentReferenceId = annotationsData.currentReferenceId === reference._id;
    const isCropping = isCurrentAnnotationId && isCurrentReferenceId
    // __('annotationEditor.reference.ocrText')
    const iconButtonProps: Partial<IconButtonProps> = {
      size: 'small',
      className: classes.iconButton,
    }
    return (
      <Paper square className={isCurrentReferenceId ? classes.selected : classes.root}>
        <InputBase
          title={__('annotationEditor.reference.ocrText', { text: reference.ocrText || '' })}
          multiline
          fullWidth
          className={classes.inputRoot}
          classes={{
            input: classes.input,
          }}
          placeholder={__('annotationEditor.reference.content')}
          value={reference.comment}
          onChange={this.handleTextChange}
          onBlur={this.delayedUpdate}
        />
        <div className={classes.actions}>
          {isCropping ?
            <>
              <Tooltip title={__('dialog.cancel')}>
                <IconButton {...iconButtonProps} onClick={this.resetCropping}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={__('annotationEditor.reference.setReferenceAreaDone')}>
                <IconButton {...iconButtonProps} onClick={this.getCroppedData}>
                  <CropDoneImageIcon />
                </IconButton>
              </Tooltip>
            </>
            :
            <>
              <Tooltip title={__('annotationEditor.reference.setReferenceArea')}>
                <IconButton {...iconButtonProps} onClick={this.setCurrentReferenceId}>
                  <CropImageIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={__('annotationEditor.reference.resetOcrText')}>
                <IconButton {...iconButtonProps} onClick={this.handleResetComment}>
                  <ResetIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={__('annotationEditor.reference.removeReference')}>
                <IconButton {...iconButtonProps} onClick={this.deleteReference}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          }
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AnnotationReference);
