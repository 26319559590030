import React from 'react'
import { List, ListSubheader } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { InvitationData, InvitationTypeFilter } from '../../types/invitation';
import InvitationListItem from './InvitationListItem';
import Tips from '../Common/Tips';

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

interface OwnProps {
  loading?: boolean;
  type: InvitationTypeFilter;
  title: string;
  invitations: InvitationData[];
  onAccept?: (id: string) => Promise<void>;
  onReject?: (id: string) => Promise<void>;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class InvitationList extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    type: 'received',
    title: '',
    invitations: [],
    onAccept: undefined,
    onReject: undefined,
  }

  state: State = {
  }

  render() {
    const { classes, loading, type, title, invitations, onAccept, onReject } = this.props;
    if (!loading && invitations.length === 0) {
      return (
        <Tips message={__('invitation.list.empty')} />
      );
    }
    return (
      <List className={classes.root} subheader={<ListSubheader>{title}</ListSubheader>}>
        {invitations.map(item => (
          <InvitationListItem
            key={item._id}
            type={type}
            invitation={item}
            onAccept={() => onAccept && onAccept(item._id)}
            onReject={() => onReject && onReject(item._id)}
          />
        ))}
      </List>
    );
  }

}

export default withStyles(styles)(InvitationList);
