import _ from 'lodash';
import { Action } from './actions';
import { NotificationData, SingleReadStatus } from '../../types/notification';

export const NOTIFICATION_LIST_SIZE = 20;
export type TabType = 'unread' | 'all'

export interface NotificationState {
  loading: boolean;
  tab: TabType;
  current: NotificationData | null;
  list: NotificationData[];
  unreadCount: number;
  noMore: boolean;
}

const initialState: NotificationState = {
  loading: true,
  tab: 'unread',
  current: null,
  list: [],
  unreadCount: 0,
  noMore: false,
}

const dossierReducer = (state: NotificationState = initialState, action: Action): NotificationState => {
  switch (action.type) {
    case '@@notification/SET_UNREAD_COUNT':
      return { ...state, unreadCount: action.count };

    case '@@notification/SET_LOADING':
      return { ...state, loading: action.loading };

    case '@@notification/SET_TAB': {
      if (action.tab === state.tab) {
        return state;
      }
      return { ...state, tab: action.tab, list: [], noMore: false };
    }

    case '@@notification/SET_CURRENT_ITEM':
      return { ...state, current: action.item };

    case '@@notification/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@notification/SET_LIST':
      return {
        ...state,
        loading: false,
        list: action.list,
        noMore: action.list.length < NOTIFICATION_LIST_SIZE,
      };

    case '@@notification/APPEND_LIST':
      return {
        ...state,
        loading: false,
        list: [...state.list, ...action.list],
        noMore: action.list.length < NOTIFICATION_LIST_SIZE,
      };

    case '@@notification/UPDATE_LIST_ITEM': {
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      let { unreadCount } = state;
      if (index === -1 || action.item.read !== list[index].read) {
        unreadCount += action.item.read ? -1 : 1;
      }
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.unshift(action.item);
      }
      return { ...state, list, unreadCount };
    }
    
    default:
      return state;
  }
}

export default dossierReducer;
