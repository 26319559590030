import _ from 'lodash';
import { InvitationData, InvitationStatusQuery } from '../../types/invitation';
import { ThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';
import { INVITATION_LIST_SIZE } from './reducers';
import { KeyValueMap } from '../../types/common';

// Action Definition
export interface SetPendingCount {
  type: '@@invitation/SET_PENDING_COUNT';
  count: number;
}

export interface SetLoading {
  type: '@@invitation/SET_LOADING';
  loading: boolean;
}

export interface SetCurrentItem {
  type: '@@invitation/SET_CURRENT_ITEM';
  item: InvitationData;
}

export interface ClearCurrentItem {
  type: '@@invitation/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@invitation/SET_LIST';
  list: InvitationData[];
}

export interface AppendList {
  type: '@@invitation/APPEND_LIST';
  list: InvitationData[];
}

export interface UpdateListItem {
  type: '@@invitation/UPDATE_LIST_ITEM';
  item: InvitationData;
}

export interface DeleteListItem {
  type: '@@invitation/REMOVE_LIST_ITEM';
  id: string;
}

// Union Action Types
export type Action = (
  SetPendingCount |
  SetLoading |
  SetCurrentItem |
  ClearCurrentItem |
  SetList |
  AppendList |
  UpdateListItem |
  DeleteListItem
);

// Action Creators
export const setPendingCount = (count: number): SetPendingCount => ({
  type: '@@invitation/SET_PENDING_COUNT',
  count,
});

export const setLoading = (loading: boolean): SetLoading => ({
  type: '@@invitation/SET_LOADING',
  loading,
});

export const setItem = (item: InvitationData): SetCurrentItem => ({
  type: '@@invitation/SET_CURRENT_ITEM',
  item,
});

export const clearItem = (): ClearCurrentItem => ({
  type: '@@invitation/CLEAR_CURRENT_ITEM',
});

export const setList = (list: InvitationData[]): SetList => ({
  type: '@@invitation/SET_LIST',
  list,
});

export const appendList = (list: InvitationData[]): AppendList => ({
  type: '@@invitation/APPEND_LIST',
  list,
});

export const updateListItem = (item: InvitationData): UpdateListItem => ({
  type: '@@invitation/UPDATE_LIST_ITEM',
  item,
});

export const removeListItem = (id: string): DeleteListItem => ({
  type: '@@invitation/REMOVE_LIST_ITEM',
  id,
});

export const fetchPendingCountRemote = (): ThunkActionType => {
  return async (dispatch) => {
    const { count } = await client.get<{ count: number }>('/invitation/pending-count');
    dispatch(setPendingCount(count));
  }
}

export const fetchItemRemote = (invitationId: string): ThunkActionType<InvitationData> => {
  return async (dispatch) => {
    const item = await client.get<InvitationData>('/invitation/items/:invitationId', {
      params: { invitationId },
    });
    dispatch(setItem(item));
    return item;
  }
}

export const updateItemRemote = (invitationId: string, data: Partial<InvitationData>): ThunkActionType<InvitationData> => {
  return async (dispatch) => {
    const item = await client.put<InvitationData>('/invitation/items/:invitationId', {
      params: { invitationId },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const acceptRemote = (invitationId: string): ThunkActionType<InvitationData> => {
  return async (dispatch, getState) => {
    const result = await client.post<InvitationData>('/invitation/items/:invitationId/accept', {
      params: { invitationId },
    });
    const { pendingCount } = getState().invitation;
    dispatch(setPendingCount(pendingCount - 1));
    dispatch(updateListItem(result));
    return result;
  }
}

export const rejectRemote = (invitationId: string): ThunkActionType<InvitationData> => {
  return async (dispatch, getState) => {
    const result = await client.post<InvitationData>('/invitation/items/:invitationId/reject', {
      params: { invitationId },
    });
    const { pendingCount } = getState().invitation;
    dispatch(setPendingCount(pendingCount - 1));
    dispatch(updateListItem(result));
    return result;
  }
}

export const fetchListRemote = (query: InvitationStatusQuery): ThunkActionType<InvitationData[]> => {
  return async (dispatch) => {
    dispatch(setList([]));
    dispatch(setLoading(true));
    const list = await client.get<InvitationData[]>('/invitation/items', {
      query: { ...query, size: INVITATION_LIST_SIZE },
    });
    dispatch(setList(list));
    return list;
  }
}

export const fetchMoreRemote = (query: InvitationStatusQuery): ThunkActionType<InvitationData[]> => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { list: oldList } = getState().invitation;
    query.size = INVITATION_LIST_SIZE;
    if (oldList.length) {
      const lastItem = _.last(oldList);
      if (lastItem) {
        query.last = lastItem.dateCreated.valueOf();
      }
    }
    const list = await client.get<InvitationData[]>('/invitation/items', {
      query: query,
    });
    dispatch(appendList(list));
    return list;
  }
}
