import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';

import { __, intl } from '../../utils/intl';
import { history } from '../../store';
import { DossierData } from '../../types/dossier';
import { getUsername } from '../../utils/data';

const styles = (theme: Theme) => createStyles({
});

interface OwnProps {
  item: DossierData;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class DossierListItem extends React.Component<Props, State> {

  handleClick() {
    const { item } = this.props;
    history.push(`/dossier/${item._id}`);
  }

  render() {
    const { item } = this.props;
    return (
      <ListItem
        button
        onClick={() => this.handleClick()}
      >
        <ListItemAvatar>
          <FolderIcon htmlColor="#ffe082" />
        </ListItemAvatar>
        <ListItemText
          primary={item.name}
          secondary={
            <>
              {item.ownership.type === 'user' ?
                __('dossier.ownership.fromUser', { name: getUsername(item.ownership.user) })
                :
                __('dossier.ownership.fromTeam', { name: _.get(item.ownership, 'team.name', '') })
              }
              {' '}
              {intl.formatDate(item.dateUpdated, 'lll')}
            </>
          }
        />
      </ListItem>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(DossierListItem);
