import { ServerInfo } from '../../types/info';
import { ThunkActionType } from '../types';
import client from '../client';

// Action Definition
export interface SetServerInfo {
  type: '@@document/SET_SERVER_INFO';
  info: ServerInfo;
}

// Union Action Types
export type Action = SetServerInfo;

// Action Creators
export const setServerInfo = (info: ServerInfo): SetServerInfo => {
  return { type: '@@document/SET_SERVER_INFO', info };
}

export const fetchServerInfoRemote = (): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const info = await client.get<ServerInfo>('/');
    dispatch(setServerInfo(info));
  }
}
