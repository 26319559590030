import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { Tabs, Tab, Box, Button, Badge } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { mdiCircleSmall } from '@mdi/js';

import { __ } from '../../utils/intl';
import {
  InvitationData,
  InvitationStatusQuery,
  InvitationTypeFilter,
  InvitationStatusFilter,
  getDefaultQuery,
} from '../../types/invitation';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import {
  fetchPendingCountRemote,
  fetchListRemote,
  fetchMoreRemote,
  acceptRemote,
  rejectRemote,
} from '../../store/invitation/actions';
import Layout from '../Common/Layout';
import CenterBox from '../Common/CenterBox';
import Loading from '../Common/Loading';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import InvitationList from './InvitationList';

const styles = (theme: Theme) => createStyles({
  layout: {
    flexDirection: 'row',
  },
  paperWrapper: {
    padding: theme.spacing(4),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    maxWidth: 750,
    margin: '0 auto',
    padding: theme.spacing(2),
  },
});

interface StateProps {
  currentUser: UserData | null;
  pendingCount: number;
  invitationList: InvitationData[];
  noMore: boolean;
  loading: boolean;
}

interface DispatchProps {
  fetchPendingCountRemote: () => Promise<void>;
  fetchListRemote: (query: InvitationStatusQuery) => Promise<InvitationData[]>;
  fetchMoreRemote: (query: InvitationStatusQuery) => Promise<InvitationData[]>;
  acceptRemote: (id: string) => Promise<InvitationData>;
  rejectRemote: (id: string) => Promise<InvitationData>;
}

interface OwnProps { }

interface OwnProps {
  onCreateInvitation: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithWidth & WithStyles<typeof styles>;

interface State {
}

class InvitationIndex extends React.Component<Props, State> {

  state: State = {
  }

  componentDidMount() {
    const { type, status } = this.parseQuery(this.props);
    if (!type || !status) {
      const query = getDefaultQuery({ type, status });
      this.handleFilterChange(query, true);
    } else {
      this.loadData(this.props);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { type: nextType, status: nextStatus } = this.parseQuery(nextProps);
    const { type, status } = this.parseQuery(this.props);
    if (!nextType || !nextStatus) {
      const query = getDefaultQuery({ type: nextType, status: nextStatus });
      this.handleFilterChange(query, true);
    } else if (nextType !== type || nextStatus !== status) {
      this.loadData(nextProps);
    }
  }

  parseQuery(props: Props): InvitationStatusQuery {
    return qs.parse(props.location.search.slice(1));
  }

  getList(query: InvitationStatusQuery): InvitationData[] {
    const { currentUser, invitationList } = this.props;
    const filter: Partial<InvitationData> = {};
    if (!currentUser) return invitationList;
    if (query.status && query.status !== 'all') {
      filter.status = query.status;
    }
    if (query.type === 'received') {
      filter.to = currentUser._id;
    } else if (query.type === 'sent') {
      filter.from = currentUser._id;
    }
    return _.filter(invitationList, filter as any);
  }

  async loadData(props: Props) {
    const { fetchListRemote, fetchPendingCountRemote } = this.props;
    const query = this.parseQuery(props);
    await fetchListRemote(query);
    await fetchPendingCountRemote();
  }

  async fetchMore() {
    const { fetchMoreRemote } = this.props;
    const query = this.parseQuery(this.props);
    await fetchMoreRemote(query);
  }

  handleTabChange = (tab: string) => {
    const { history } = this.props;
    if (!_.isString(tab)) return;
    const [type, status] = tab.split(':') as [InvitationTypeFilter, InvitationStatusFilter];
    this.handleFilterChange({ type, status });
  }

  handleFilterChange = (query: InvitationStatusQuery, replace = false) => {
    const { history } = this.props;
    let url = '/invitation' + '?' + qs.stringify(query);
    if (replace) {
      history.replace(url);
    } else {
      history.push(url);
    }
  }

  handleAccept = async (id: string) => {
    const { acceptRemote } = this.props;
    await acceptRemote(id);
  }

  handleReject = async (id: string) => {
    const { rejectRemote } = this.props;
    await rejectRemote(id);
  }

  render() {
    const { classes, loading, noMore, pendingCount } = this.props;
    const { type = 'received', status = 'pending' } = this.parseQuery(this.props);
    const query = { type, status };
    const list = this.getList(query);
    return (
      <Layout mainClassName={classes.layout}>
        <CenterBox paper>
          <Tabs
            value={`${type}:${status}`}
            onChange={(event, tab) => this.handleTabChange(tab)}
            indicatorColor="primary"
            textColor="primary"
          >

            <Tab
              value="received:pending"
              label={
                <Badge
                  badgeContent={pendingCount > 0 &&
                    <SimpleSvgIcon color="secondary" path={mdiCircleSmall} />
                  }
                >
                  {__('invitation.status.pending')}
                </Badge>
              }
            />
            <Tab value="received:accepted" label={__('invitation.status.accepted')} />
            <Tab value="received:all" label={__('invitation.list.all')} />
            <Tab value="sent:all" label={__('invitation.list.sent')} />
          </Tabs>
          <Box flexGrow={1} overflow="auto">
            <InvitationList
              loading={loading}
              type={type}
              invitations={list}
              onAccept={this.handleAccept}
              onReject={this.handleReject}
            />
            {loading ?
              <Box height={64} position="relative">
                <Loading />
              </Box>
              :
              <Box marginTop={2} textAlign="center">
                {noMore ?
                  <Box color="text.hint" fontSize={12}>{__('list.noMore')}</Box>
                  :
                  <Button onClick={() => this.fetchMore()}>
                    {__('list.fetchMore')}
                  </Button>
                }
              </Box>
            }
          </Box>
        </CenterBox>
      </Layout >
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
  pendingCount: states.invitation.pendingCount,
  invitationList: states.invitation.list,
  noMore: states.invitation.noMore,
  loading: states.invitation.loading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchPendingCountRemote: () => dispatch(fetchPendingCountRemote()),
  fetchListRemote: (query) => dispatch(fetchListRemote(query)),
  fetchMoreRemote: (query) => dispatch(fetchMoreRemote(query)),
  acceptRemote: (id) => dispatch(acceptRemote(id)),
  rejectRemote: (id) => dispatch(rejectRemote(id)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(InvitationIndex);
