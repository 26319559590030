import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  ListItemIcon,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { DossierTemplateData } from '../../types/dossier.template';
import { DossierData, DossierOwnership } from '../../types/dossier';
import { TeamData } from '../../types/team';
import { UserData } from '../../types/user';
import {
  createItemRemote as createDossierRemote,
} from '../../store/dossier/actions'
import {
  fetchListRemote as fetchDossierTemplateListRemote,
} from '../../store/dossierTemplate/actions'
import {
  fetchListRemote as fetchTeamListRemote,
} from '../../store/team/actions'
import ResponsiveDialog from '../Common/ResponsiveDialog';
import FormControl from '../Form/FormControl';
import Tips from '../Common/Tips';
import Loading from '../Common/Loading';
import MemberList from '../Team/MemberList';
import OwnershipForm from '../Dossier/OwnershipForm';

const styles = (theme: Theme) => createStyles({
  button: {
    marginLeft: theme.spacing(1),
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 0),
    }
  },
  content: {
    padding: theme.spacing(3),
    flexGrow: 1,
    overflow: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
  },
});

interface FileWizardStep {
  key: 'ownership' | 'template' | 'form' | 'collaborators';
  label: string;
  disabled?: boolean;
};

const fileWizardSteps: FileWizardStep[] = [
  {
    key: 'ownership',
    label: __('fileWizard.steps.ownership'),
  },
  {
    key: 'template',
    label: __('fileWizard.steps.template'),
  },
  {
    key: 'form',
    label: __('fileWizard.steps.form'),
  },
  {
    key: 'collaborators',
    label: __('fileWizard.steps.collaborators'),
  },
];

interface StateProps {
  currentUser: UserData | null;
  teamList: TeamData[];
  templateList: DossierTemplateData[];
  dossier: DossierData | null;
}

interface DispatchProps {
  fetchDossierTemplateListRemote: () => Promise<DossierTemplateData[]>;
  fetchTeamListRemote: () => Promise<TeamData[]>;
  createDossierRemote: (data: Partial<DossierData>) => Promise<DossierData>;
}

interface OwnProps {
  onOk: (item?: DossierData) => void;
}

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
  activeStep: number;
  selectedTemplate: DossierTemplateData | null;
  dossier: Pick<DossierData, 'ownership' | 'context' | 'dossier_template_id' | 'name'>;
  isCreating: boolean;
}

class FileCreationWizard extends React.Component<Props, State> {

  static defaultProps: Partial<Props> = {
    templateList: [],
  }

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState(props: Props): State {
    const { currentUser } = props;
    return {
      activeStep: 0,
      selectedTemplate: null,
      dossier: {
        ownership: {
          type: 'user',
          user_id: currentUser ? currentUser._id : '',
        },
        context: {},
        dossier_template_id: '',
        name: '',
      },
      isCreating: false,
    };
  }

  async componentDidMount() {
    const { fetchDossierTemplateListRemote, fetchTeamListRemote } = this.props;
    await fetchDossierTemplateListRemote();
    await fetchTeamListRemote();
    this.setState(this.getInitialState(this.props));
  }

  handleClose() {
    const { history } = this.props;
    this.setState({
      activeStep: 0,
    }, () => history.goBack());
  }

  handleContextChange(context: any) {
    this.setState({
      dossier: {
        ...this.state.dossier,
        context,
      },
    });
  }

  setCurrentTemplate(template: DossierTemplateData) {
    this.setState({
      selectedTemplate: template === this.state.selectedTemplate ? null : template,
    });
  }

  handleOwnershipChange = (value: DossierOwnership) => {
    const { dossier } = this.state;
    this.setState({
      dossier: {
        ...dossier,
        ownership: value,
      },
    });
  }

  renderOwnership() {
    const { dossier } = this.state;
    return (
      <OwnershipForm
        value={dossier.ownership}
        onChange={this.handleOwnershipChange}
      />
    );
  }
  
  renderTemplate() {
    const { templateList } = this.props;
    const { selectedTemplate } = this.state;
    return (
      <div className="body">
        <List>
          {templateList.map((template) => {
            const selected = template === selectedTemplate;
            return (
              <ListItem
                key={template._id}
                button
                selected={selected}
                onClick={() => this.setCurrentTemplate(template)}
              >
                <ListItemIcon>
                  {selected ?
                    <CheckIcon />
                  :
                    <span></span>
                  }
                </ListItemIcon>
                <ListItemText primary={template.name} />
              </ListItem>
            )
          })}
        </List>
      </div>
    );
  }

  renderForm() {
    const { classes } = this.props;
    const { selectedTemplate, dossier } = this.state;
    return (
      <div className="body">
        <Paper className={classes.formContainer} elevation={5}>
          {selectedTemplate && selectedTemplate.form &&
            <FormControl
              items={selectedTemplate.form}
              value={dossier.context}
              onChange={context => this.handleContextChange(context)}
            />
          }
        </Paper>
      </div>
    )
  }

  renderCollaborators() {
    const { dossier } = this.props;
    if (!dossier) return <Loading />;
    return (
      <MemberList query={{ type: 'dossier', dossier_id: dossier._id }} />
    );
  }

  renderCreating() {
    return (
      <Tips
        icon={<Loading />}
        message={__('fileWizard.creating')}
      />
    );
  }

  renderStepContent() {
    const { activeStep, isCreating } = this.state;
    const step = fileWizardSteps[activeStep];
    if (isCreating) {
      return this.renderCreating();
    }
    switch (step && step.key) {
      case 'ownership':
        return this.renderOwnership();
      case 'template':
        return this.renderTemplate();
      case 'form':
        return this.renderForm();
      case 'collaborators':
        return this.renderCollaborators();
      default:
        return null;
    }
  }

  getSteps() {
    const { dossier } = this.state;
    const steps = [...fileWizardSteps];
    if (dossier.ownership.type === 'user') {
      steps.pop();
    }
    return steps;
  }

  renderSteps() {
    const { activeStep } = this.state;
    const steps = this.getSteps();
    return (
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step) => (
          <Step key={step.key}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  handleBack() {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
  }

  async handleNext() {
    const { activeStep } = this.state;
    const step = fileWizardSteps[activeStep];
    if (step.key === 'form') {
      await this.handleCreateDossier();
    }
    if (this.isFinalStep()) {
      const { history, dossier } = this.props;
      if (dossier) {
        history.push(`/dossier/${dossier._id}`);
      }
    }
    this.setState({ activeStep: activeStep + 1 });
  }

  async handleCreateDossier() {
    const { createDossierRemote } = this.props;
    const { selectedTemplate, dossier } = this.state;
    if (!selectedTemplate) return;
    const data = { ...dossier }
    data.dossier_template_id = selectedTemplate._id;
    console.log('# creating dossier', dossier);
    this.setState({ isCreating: true });
    try {
      const result = await createDossierRemote(data);
      console.log('# dossier created', result);
    } catch (error) {
      console.error(error);
    }
    this.setState({ isCreating: false });
  }

  isFinalStep() {
    const { activeStep } = this.state;
    const steps = this.getSteps();
    return activeStep === steps.length - 1;
  }

  renderActions() {
    const { classes } = this.props;
    const { activeStep, selectedTemplate, isCreating } = this.state;
    const isFinalStep = this.isFinalStep();
    return (
      <div className={classes.actions}>
        <Button
          className={classes.button}
          size="large"
          color="inherit"
          disabled={activeStep === 0 || isCreating}
          onClick={() => this.handleBack()}
        >
          {__('dialog.back')}
        </Button>
        <Button
          className={classes.button}
          size="large"
          color="primary"
          variant="contained"
          onClick={() => this.handleNext()}
          disabled={(activeStep === 1 && !selectedTemplate) || isCreating}
        >
          {isFinalStep ? __('dialog.finish') : __('dialog.next')}
        </Button>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <ResponsiveDialog
        open
        title={__('fileExplorer.newCase')}
        className="file-creation-wizard"
        onClose={() => this.handleClose()}
      >
        <div className={classes.dialogContainer}>
          {this.renderSteps()}
          <div className={classes.content}>
            {this.renderStepContent()}
          </div>
          {this.renderActions()}
        </div>
      </ResponsiveDialog>
    )
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
  teamList: states.team.list,
  templateList: states.dossierTemplate.list,
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchDossierTemplateListRemote: () => dispatch(fetchDossierTemplateListRemote()),
  fetchTeamListRemote: () => dispatch(fetchTeamListRemote()),
  createDossierRemote: (data) => dispatch(createDossierRemote(data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FileCreationWizard);
