import React from 'react';
import { Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import PrintIcon from '@material-ui/icons/Print';

import { __ } from '../../utils/intl';
import { DossierData } from '../../types/dossier';
import client from '../../store/client';
import ResponsiveDialog, { OwnProps as ResponsiveDialogProps } from '../Common/ResponsiveDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    
  },
  previewFrame: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});


interface OwnProps extends Pick<ResponsiveDialogProps, 'open' | 'onClose'> {
  dossier: DossierData;
  cacheKey: string;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class ExportPreviewDialog extends React.Component<Props, State> {

  private iframeElement: HTMLIFrameElement | null = null;

  getUrl(suffix: string = '') {
    const { dossier, cacheKey } = this.props;
    return `${client.serverUrl}/print/dossier/${dossier._id}/cache/${cacheKey}${suffix}`;
  }

  handlePrint() {
    // const { iframeElement } = this;
    // if (iframeElement && iframeElement.contentWindow) {
    //   iframeElement.contentWindow.print();
    // }
    (window as any).frames['preview'].focus();
    (window as any).frames['preview'].print();
  }

  handleDownloadPdf() {
    const url = this.getUrl('/pdf')
    window.open(url);
  }

  render() {
    const { classes, dossier, cacheKey, ...dialogProps } = this.props;
    return (
      <ResponsiveDialog
        fullScreen
        withoutContentContainer
        title={dossier.name}
        actions={
          <>
            <Button
              color="inherit"
              onClick={() => this.handlePrint()}
            >
              {__('exportPreviewDialog.printButton')}
              <PrintIcon className={classes.buttonIcon} />
            </Button>
            <Button
              color="inherit"
              onClick={() => this.handleDownloadPdf()}
            >
              {__('exportPreviewDialog.downloadButton')}
              <DownloadIcon className={classes.buttonIcon} />
            </Button>
          </>
        }
        {...dialogProps}
      >
        <iframe
          sandbox="allow-same-origin allow-scripts allow-modals"
          ref={ref => this.iframeElement = ref}
          name="preview"
          className={classes.previewFrame}
          src={this.getUrl()}
        />
      </ResponsiveDialog>
    )
  }
}

export default withStyles(styles)(ExportPreviewDialog);
