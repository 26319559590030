import { Theme, createStyles } from '@material-ui/core/styles';

export const partialListStyles = (theme: Theme) => createStyles({
  listHeader: {
    background: 'rgba(255,255,255,0.8)',
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    textAlign: 'center',
  },
  filter: {
    maxWidth: 320,
  },
});
