import _ from 'lodash';
import React from 'react';
import qs from 'qs';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch, Redirect } from "react-router-dom";
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { List, ListItem, Divider, ListItemText, ListItemIcon, Paper } from '@material-ui/core';
import UserIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import AboutIcon from '@material-ui/icons/Info';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { UserData } from '../../types/user';
import { fetchCurrentUser, logoutCurrentUser } from '../../store/user/actions';
import UserAvatar from '../Common/UserAvatar';
import Layout from '../Common/Layout';
import Profile from './Profile';
import Settings from './Settings';
import About from './About';

// DO NOT remove the following lines, these are for translation template
// __('errors.login.invalid_username')
// __('errors.login.user_exists')
// __('errors.login.invalid_login')
// __('errors.login.account_disabled')

const styles = (theme: Theme) => createStyles({
  nav: {
    
  },
  avatar: {
    display: 'block',
    margin: '20px auto',
    width: 128,
    height: 128,
  },
  container: {
    width: '100%',
    maxWidth: 720,
    margin: '0 auto',
  },
  paper: {
    minHeight: 400,
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
});

interface StateProps {
  currentUser: UserData | null;
}

interface DispatchProps {
  fetchCurrentUser: () => void,
  logoutCurrentUser: () => void,
}

interface OwnProps {};

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
}

class Account extends React.Component<Props, State> {

  handleLogout() {
    const { logoutCurrentUser } = this.props;
    logoutCurrentUser();
  }

  renderIndex() {
    const { classes, history, location, currentUser } = this.props;
    const subRoute = location.pathname.split('/').pop();
    return (
      <Paper component="nav" className={classes.nav}>
        <List>
          <div>
            <UserAvatar className={classes.avatar} user={currentUser} />
          </div>
        </List>
        <List>
          <ListItem
            button
            selected={subRoute === 'profile'}
            onClick={() => history.push('/account/profile')}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText primary={__('nav.profile')} />
          </ListItem>
          <ListItem
            button
            selected={subRoute === 'settings'}
            onClick={() => history.push('/account/settings')}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={__('nav.settings')} />
          </ListItem>
          <ListItem
            button
            selected={subRoute === 'about'}
            onClick={() => history.push('/account/about')}
          >
            <ListItemIcon>
              <AboutIcon />
            </ListItemIcon>
            <ListItemText primary={__('nav.about')} />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => this.handleLogout()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={__('nav.logout')} />
          </ListItem>
        </List>
      </Paper>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <Layout className="account-layout">
        <Switch>
          <Route path="/account" exact render={() => this.renderIndex()} />
          <Route path="/account/profile" component={Profile} />
          <Route path="/account/settings" component={Settings} />
          <Route path="/account/about" component={About} />
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  logoutCurrentUser: () => dispatch(logoutCurrentUser()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Account);
