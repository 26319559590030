import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';

import { __, intl } from '../../utils/intl';
import { TeamData } from '../../types/team';
import { RootState } from '../../store/reducers';
import client, { ClientError } from '../../store/client';
import { ListQueryResult } from '../../types/base';
import { getUsername } from '../../utils/data';
import { parseListQuery, parseListQueryResult, localization } from '../../utils/material-table';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import {
  mdiPauseCircle,
  mdiCheckCircle,
  mdiShieldCheck,
  mdiShieldOffOutline,
  mdiAccountMultiple,
} from '@mdi/js';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithSnackbarProps &
  WithStyles<typeof styles>;

interface State {
  selectedItem: TeamData | null;
  updateData: Partial<TeamData> | null;
  updateTip: string | null;
}

const columns: Column<TeamData>[] = [
  {
    field: 'verified',
    title: __('member.status'),
    render: (team) => team.verified ? __('team.status.verified') : __('team.status.pending'),
  },
  {
    field: 'owner',
    title: __('team.field.owner'),
    render: (team) => getUsername(team.member ? team.member.user : null),
  },
  {
    field: 'name',
    title: __('team.field.name'),
  },
  {
    field: 'description',
    title: __('team.field.description'),
  },
  {
    field: 'dateCreated',
    title: __('common.field.dateCreated'),
    render: (team) => intl.formatTime(team.dateCreated, 'L LTS'),
  },
]

const tableOptions: Options = {
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  exportButton: true,
}

class AdminTeams extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<TeamData>>();
  state: State = {
    selectedItem: null,
    updateData: null,
    updateTip: null,
  }

  fetchData = async (query: Query<TeamData>): Promise<QueryResult<TeamData>> => {
    const result = await client.get<ListQueryResult<TeamData>>('/admin/teams', {
      query: {
        ...parseListQuery(query),
      },
    });
    return parseListQueryResult(result);
  }

  async handleUpdate(team: TeamData, data: Partial<TeamData>) {
    let updateTip = '';
    const name = team.name;
    if (!_.isUndefined(data.verified)) {
      updateTip = data.verified
        ? __('admin.teams.setVerified.tip', { name })
        : __('admin.teams.removeVerified.tip', { name });
    } else if (!_.isUndefined(data.isDisabled)) {
      updateTip = data.isDisabled
        ? __('admin.teams.setDisabled.tip', { name })
        : __('admin.teams.setEnabled.tip', { name });
    } else {
      return;
    }
    this.setState({
      selectedItem: team,
      updateData: data,
      updateTip,
    });
  }

  handleUpdateConfirm = async () => {
    const { enqueueSnackbar } = this.props;
    const { selectedItem, updateData } = this.state;
    if (!selectedItem) return;
    try {
      await client.put('/admin/teams/:teamId', {
        params: { teamId: selectedItem._id },
        data: updateData,
      });
      this.refreshData();
    } catch (err) {
      if (err instanceof ClientError && err.status >= 400) {
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      }
    }
    this.setState({ selectedItem: null });
  }

  handleViewDetail = async (event: React.MouseEvent, team: TeamData | TeamData[]) => {
    const { history } = this.props;
    if (!_.isArray(team)) {
      history.push(`/admin/teams/view/${team._id}`);
    }
  }
  refreshData() {
    const { current: table } = this.tableRef;
    if (table) {
      (table as any).onQueryChange();
    }
  }

  render() {
    const { classes, history } = this.props;
    const { selectedItem, updateTip } = this.state;
    const title = __('admin.nav.teams');
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
        </div>
        <MaterialTable<TeamData>
          tableRef={this.tableRef}
          localization={localization}
          title={title}
          columns={columns}
          data={this.fetchData}
          options={tableOptions}
          actions={[
            {
              icon: 'refresh',
              tooltip: __('common.refresh'),
              isFreeAction: true,
              onClick: () => this.refreshData(),
            },
            (team) => ({
              icon: () => <SimpleSvgIcon color="primary" path={mdiAccountMultiple} />,
              tooltip: __('admin.teams.viewDetail'),
              onClick: this.handleViewDetail,
            }),
            (team) => ({
              icon: () => (
                <SimpleSvgIcon
                  color={team.verified ? 'primary' : 'disabled'}
                  path={team.verified ? mdiShieldCheck : mdiShieldOffOutline}
                />
              ),
              tooltip: team.verified ? __('admin.teams.removeVerified') : __('admin.teams.setVerified'),
              onClick: () => this.handleUpdate(team, { verified: !team.verified }),
            }),
            (team) => ({
              icon: () => (
                <SimpleSvgIcon
                  color={team.isDisabled ? 'disabled' : 'primary'}
                  path={team.isDisabled ? mdiPauseCircle : mdiCheckCircle}
                />
              ),
              tooltip: team.isDisabled ? __('common.resume') : __('common.disable'),
              onClick: () => this.handleUpdate(team, { isDisabled: !team.isDisabled }),
            }),
          ]}
        />
        <Confirm
          open={Boolean(selectedItem)}
          content={updateTip}
          onConfirm={this.handleUpdateConfirm}
          onClose={() => this.setState({ selectedItem: null })}
        />
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminTeams);
