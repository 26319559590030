import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { DossierTemplateData } from '../../types/dossier.template';
import { ThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';

// Action Definition
export interface SetCurrentItem {
  type: '@@dossierTemplate/SET_CURRENT_ITEM';
  item: DossierTemplateData;
}

export interface ClearCurrentItem {
  type: '@@dossierTemplate/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@dossierTemplate/SET_LIST';
  list: DossierTemplateData[];
}

export interface UpdateListItem {
  type: '@@dossierTemplate/UPDATE_LIST_ITEM';
  item: DossierTemplateData;
}

export interface DeleteListItem {
  type: '@@dossierTemplate/REMOVE_LIST_ITEM';
  id: string;
}

// Union Action Types
export type Action = SetCurrentItem | ClearCurrentItem | SetList | UpdateListItem | DeleteListItem;

// Action Creators
export const setItem = (item: DossierTemplateData): SetCurrentItem => {
  return { type: '@@dossierTemplate/SET_CURRENT_ITEM', item };
}

export const clearUser = (): ClearCurrentItem => {
  return { type: '@@dossierTemplate/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: DossierTemplateData[]): SetList => {
  return { type: '@@dossierTemplate/SET_LIST', list };
}

export const updateListItem = (item: DossierTemplateData): UpdateListItem => {
  return { type: '@@dossierTemplate/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@dossierTemplate/REMOVE_LIST_ITEM', id };
}

export const fetchItemRemote = (id: string): ThunkActionType<DossierTemplateData> => {
  return async (dispatch) => {
    const item = await client.get<DossierTemplateData>(`/dossiers.template/${id}`, { cached: true });
    dispatch(setItem(item));
    return item;
  }
}

export const removeItemRemote = (id: string): ThunkActionType => {
  return async (dispatch) => {
    await client.delete(`/dossiers.template/${id}`);
    dispatch(removeListItem(id));
  }
}

export const fetchListRemote = (): ThunkActionType<DossierTemplateData[]> => {
  return async (dispatch) => {
    const list = await client.get<DossierTemplateData[]>('/dossiers.template');
    dispatch(setList(list));
    return list;
  }
}
