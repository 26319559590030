import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { FormControlLabel, Checkbox, Paper, Switch } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { __ } from '../../utils/intl';
import { GroupFormItem } from '../../types/form';
import FormControl from './FormControl';
import { BaseFormControlProps } from './types';

const styles = (theme: Theme) => createStyles({
  root: {
    '&.indent': {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(1, 2),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  expandedIcon: {
    transform: 'rotate(45deg)',
  },
  content: {
    marginBottom: theme.spacing(1),
    '&.hidden': {
      display: 'none',
    },
  },
});

interface OwnProps extends BaseFormControlProps {
  className?: string;
  item: GroupFormItem;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  expanded: boolean;
}

class GroupControl extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    const { item: { optional, defaultExpanded = false } } = props;
    this.state = {
      expanded: !props.readOnly && !optional && defaultExpanded,
    };
  }

  render() {
    const { classes, noIndent, className, item, onChange, ...props } = this.props;
    let { value } = this.props;
    const { expanded } = this.state;
    if (_.isUndefined(value)) {
      value = item.optional ? null : {};
    }
    return (
      <Paper
        className={clsx('group-control', className, classes.root, { indent: !noIndent })}
        elevation={noIndent ? 0 : undefined}
      >
        <div className={classes.header}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={item.optional && value === null}
                icon={<ArrowRightIcon />}
                checkedIcon={<ArrowRightIcon className={classes.expandedIcon} />}
                checked={expanded}
                onChange={(event, checked) => this.setState({ expanded: checked })}
              />
            }
            label={item.label}
            title={expanded ? __('form.groupControl.foldTip') : __('form.groupControl.expandTip')}
          />
          {item.optional &&
            <FormControlLabel
              control={
                <Switch
                  checked={!_.isNull(value)}
                  onChange={(event, checked) => onChange(_.isNull(value) ? {} : null)}
                />
              }
              label={__('form.groupControl.included')}
            />
          }
        </div>
        {!_.isNull(value) &&
          <div className={clsx(classes.content, { hidden: !expanded, indent: !noIndent })}>
            <FormControl
              items={item.items}
              value={value}
              onChange={onChange}
              {...props}
            />
          </div>
        }
      </Paper>
    )
  }

}

export default withStyles(styles)(GroupControl);
