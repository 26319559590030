import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { TextField } from '@material-ui/core';

interface Props {
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  name?: string;
  label?: string;
  type?: string;
  value: string;
  onChange: (value: string) => void;
}

interface State {
  value: string;
}

class DelayedTextField extends React.Component<Props, State> {

  compositionStarted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
    this.submitValue = _.debounce(this.submitValue.bind(this), 1000);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  submitValue() {
    const { onChange } = this.props;
    const { value } = this.state;
    console.log('submitValue()', value, this.compositionStarted);
    if (!this.compositionStarted && onChange) {
       onChange(value);
    }
  }

  handleChange(value: string) {
    this.setState({ value }, () => {
      this.submitValue();
    });
  }

  render() {
    const { onChange, ...restProps } = this.props;
    const { value } = this.state;
    return (
      <TextField
        {...restProps}
        value={value}
        onChange={event => this.handleChange(event.target.value)}
        onCompositionStart={event => this.compositionStarted = true}
        onCompositionEnd={event => this.compositionStarted = false}
      />
    )
  }

}

export default DelayedTextField;
