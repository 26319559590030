import _ from 'lodash'
import React, { Component } from 'react'
import Layout from '../Common/Layout'
import d3comp from './d3comp'
import data from './data'

export default class Timeline extends Component {
  componentDidMount() {
    const { container } = this.refs
    const startAt = _.minBy(data, 'startAt').startAt
    const endAt = _.maxBy(data, 'endAt').endAt
    d3comp(container, data, { startAt, endAt })
  }

  render() {
    return (
      <Layout>
        <div ref='container' style={{ height: '100%' }}></div>
      </Layout>
    )
  }
}
