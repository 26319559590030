import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import {
  red,
  teal,
  lightBlue,
  green,
  orange,
  grey,
} from '@material-ui/core/colors';
import { 
  mdiFilePdf,
  mdiImage,
  mdiFileWord,
  mdiFileExcel,
  mdiFilePowerpoint,
  mdiFile,
} from '@mdi/js';

import SimpleSvgIcon from './SimpleSvgIcon';
import { KeyValueMap } from '../../types/common';
import { FileData } from '../../types/file';
import { getFileType } from '../../utils/drive';

interface Props extends SvgIconProps {
  file: FileData;
}

interface IconProps {
  path: string;
  color: string;
}

const iconMap: KeyValueMap<IconProps> = {
  pdf: { path: mdiFilePdf, color: red[700] },
  image: { path: mdiImage, color: teal[200] },
  word: { path: mdiFileWord, color: lightBlue[700] },
  excel: { path: mdiFileExcel, color: green[500] },
  powerpoint: { path: mdiFilePowerpoint, color: orange[700] },
  bin: { path: mdiFile, color: grey[500] },
}

const FileIcon: React.SFC<Props> = (props) => {
  const { file, ...restProps } = props;
  const type = getFileType(file.filename);
  const icon = iconMap[type] || iconMap.bin;
  return (
    <SimpleSvgIcon
      htmlColor={icon.color}
      path={icon.path}
      {...restProps}
    />
  );
}

export default FileIcon;
