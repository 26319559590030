import _ from 'lodash';
import { KeyValueMap } from './common';
import { IBaseData } from './base';
import { UserIdentity, PublicUserInfo } from './user';
import { TeamPublicData } from './team';
import { DossierPublicData } from './dossier';
import { DossierTemplatePublicData } from './dossier.template';
import { DocumentPublicData } from './document';
import { InvitationData } from './invitation';
import { ChatThreadData } from './chat';

export interface TargetIdentity {
  __attached?: true;
  type: 'user' | 'team' | 'dossier' | 'dossier-template' | 'document' | 'invitation' | 'thread';
  user_id?: string;
  user?: PublicUserInfo;
  team_id?: string;
  team?: TeamPublicData;
  dossier_id?: string;
  dossier?: DossierPublicData;
  dossier_template_id?: string;
  dossierTemplate?: DossierTemplatePublicData;
  document_id?: string;
  document?: DocumentPublicData;
  invitation_id?: string;
  invitation?: InvitationData;
  thread_id?: string;
  thread?: ChatThreadData;
}

export const TargetTypes = [
  'user',
  'team',
  'dossier',
  'dossierTemplate',
  'document',
  'invitation',
  'thread',
];

export function simplifyTarget(target: TargetIdentity): TargetIdentity {
  return _.omit(target, TargetTypes) as any;
}

export interface UserReadStatus extends UserIdentity {
  read: boolean;
}

export type SingleReadStatus = boolean | undefined;

export interface StatusQuery {
  read?: SingleReadStatus;
}

export interface NotificationData extends IBaseData {
  __type?: 'Notification';
  from: string;
  to: string[];
  status: UserReadStatus[];
  read?: SingleReadStatus;
  msgId: string;
  message: string;
  variables: KeyValueMap<string>;
  target?: TargetIdentity;
  dateCreated: Date;
  dateUpdated: Date;
  user?: PublicUserInfo;
  nonPersistent?: boolean;
}
