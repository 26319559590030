import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { DossierOwnership } from '../../types/dossier';
import { FormItem } from '../../types/form';
import { TeamData } from '../../types/team';
import { UserData } from '../../types/user';
import {
  fetchListRemote as fetchTeamListRemote,
} from '../../store/team/actions'
import FormControl from '../Form/FormControl';

interface StateProps {
  user: UserData | null;
  teamList: TeamData[];
}

interface DispatchProps {
  fetchTeamListRemote: () => Promise<TeamData[]>;
}

interface OwnProps {
  value: DossierOwnership;
  onChange: (value: DossierOwnership) => void;
}

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params>;

interface State {
}

class OwnershipForm extends React.Component<Props, State> {

  handleChange = (value: DossierOwnership) => {
    const { user, onChange } = this.props;
    if (!user) return;
    const _value = { ...value };
    if (_value.type === 'user') {
      _value.user_id = user._id;
      delete _value.team_id;
    } else {
      delete _value.user_id;
    }
    onChange(_value);
  }

  getFormItems(): FormItem[] {
    const { teamList } = this.props;
    return [
      {
        key: 'type',
        label: __('fileWizard.ownership'),
        type: 'select',
        control: 'radio',
        options: [
          { label: __('fileWizard.ownership.user'), value: 'user' },
          { label: __('fileWizard.ownership.team'), value: 'team' },
        ],
      },
      {
        key: 'team_id',
        label: __('fileWizard.team'),
        type: 'select',
        options: teamList.map(team => ({ label: team.name, value: team._id })),
        condition: { key: 'type', operator: '=', value: 'team' },
      },
    ];
  }

  render() {
    const { value } = this.props;
    return (
      <FormControl
        items={this.getFormItems()}
        value={value}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  user: states.user.current,
  teamList: states.team.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchTeamListRemote: () => dispatch(fetchTeamListRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(OwnershipForm);
