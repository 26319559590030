import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, Avatar, Box, Typography, Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __, intl } from '../../utils/intl';
import { InvitationData, invitationStatusLabels, InvitationTypeFilter } from '../../types/invitation'
import { getUsername } from '../../utils/data';
import { openTarget } from '../../utils/notification';
import UserAvatar from '../Common/UserAvatar';
import InvitationStatusIcon from './InvitationStatusIcon';
import { history } from '../../store';

const styles = (theme: Theme) => createStyles({
  avatar: {
    backgroundColor: '#ccc',
  },
  button: {
    marginRight: theme.spacing(2),
  },
});

interface OwnProps {
  type: InvitationTypeFilter;
  invitation: InvitationData;
  onAccept?: () => void;
  onReject?: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

class InvitationListItem extends React.Component<Props> {

  handleOpenTarget = () => {
    const { invitation } = this.props;
    openTarget(invitation);
  }

  renderMessage(): string {
    const { type, invitation } = this.props;
    const invitationType = invitation.type === 'dossier'
      ? __('dossier.dossier').toLowerCase()
      : __('team.team').toLowerCase();
    const invitationTarget = invitation.type === 'team'
      ? (invitation.team ? invitation.team.name : '')
      : (invitation.dossier ? invitation.dossier.name : '');
    switch (type) {
      case 'received':
        // INTL:template __('invitation.message.received')
        return __('invitation.message.received', {
          from: getUsername(invitation.from_user) || '',
          type: invitationType,
          target: invitationTarget,
        });
      case 'sent':
        // INTL:template __('invitation.message.sent')
        return __('invitation.message.sent', {
          to: getUsername(invitation.to_user) || '',
          type: invitationType,
          target: invitationTarget,
        });
      default:
        return '';
    }
  }

  renderActions() {
    const { classes, type, invitation, onAccept, onReject } = this.props;
    // INTL:template __('target.open')
    if (type === 'sent' || invitation.status === 'accepted') {
      return (
        <Button
          color="primary"
          className={classes.button}
          onClick={this.handleOpenTarget}
        >
          {__('target.open', {
            target: invitation.type === 'dossier'
              ? __('dossier.dossier')
              : __('team.team')
          })}
        </Button>
      );
    } else if (invitation.status === 'pending') {
      return (
        <>
          {}
          <Button
            color="secondary"
            className={classes.button}
            onClick={onReject}
          >
            {__('invitation.reject')}
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={onAccept}
          >
            {__('invitation.accept')}
          </Button>
        </>
      );
    }
    return null;
  }

  render() {
    const { classes, invitation, onAccept, onReject } = this.props;
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <InvitationStatusIcon status={invitation.status} fontSize="small" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={this.renderMessage()}
          secondary={
            <Box display="flex" lineHeight="40px" color="text.hint">
              <Box display="inline" flexGrow={1}>
                {intl.formatDate(invitation.dateCreated, 'lll')}
                {' '}
                {invitationStatusLabels[invitation.status]}
              </Box>
              {this.renderActions()}
            </Box>
          }
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(InvitationListItem);
