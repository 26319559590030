import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Paper } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';

import { __ } from '../../utils/intl';
import { TeamData } from '../../types/team';
import { RootState } from '../../store/reducers';
import { fetchListRemote, createItemRemote } from '../../store/team/actions';
import Layout from '../Common/Layout';
import CenterBox from '../Common/CenterBox';
import TeamList from './TeamList';
import TeamEditDialog from './TeamEditDialog';
import TeamView from './TeamView';

const styles = (theme: Theme) => createStyles({
  layout: {
    flexDirection: 'row',
  },
  paperWrapper: {
    padding: theme.spacing(4),
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  paper: {
    maxWidth: 750,
    margin: '0 auto',
    padding: theme.spacing(2),
  },
});

interface StateProps {
  teamList: TeamData[];
}

interface DispatchProps {
  fetchListRemote: () => Promise<TeamData[]>;
  createItemRemote: (data: Partial<TeamData>) => Promise<TeamData>;
}

interface OwnProps { }

interface OwnProps {
  onCreateTeam: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithWidth & WithStyles<typeof styles>;

interface State {
}

class TeamIndex extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  componentDidMount() {
    const { fetchListRemote } = this.props;
    fetchListRemote();
  }

  componentWillReceiveProps(nextProps: Props) {
  }

  async handleCreate(data: Partial<TeamData>) {
    const { createItemRemote } = this.props;
    await createItemRemote(data);
  }

  render() {
    const { classes, history, teamList } = this.props;
    return (
      <Layout mainClassName={classes.layout}>
        <Switch>
          <Route path="/team/view/:teamId" component={TeamView}
          />
          <Route
            path="/team"
            render={() => (
              <CenterBox paper>
                <TeamList
                  list={teamList}
                  onCreateTeam={() => history.push('/team/create')}
                />
              </CenterBox>
            )}
          />
        </Switch>
        <Route
          path="/team/create"
          exact
          render={() => (
            <TeamEditDialog
              open
              mode="create"
              onClose={() => history.push('/team')}
              onSave={data => this.handleCreate(data)}
            />
          )}
        />
      </Layout >
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  teamList: states.team.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchListRemote: () => dispatch(fetchListRemote()),
  createItemRemote: (data) => dispatch(createItemRemote(data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(TeamIndex);
