import { Intl } from 'intl-message';
import localeConfig from '../locales/@';
import moment from 'moment';

export const intl = new Intl(localeConfig);
export const __: typeof intl.formatMessage = intl.formatMessage.bind(intl);

// INTL: do not remove the following lines
// __('intl.autoDetect')
// __('intl.autoDetectWithLanguage')
// __('intl.language')

(window as any).intl = intl; // export global object
