import { __ } from '../utils/intl';
import { IBaseData } from './base';
import { UserIdentity, PublicUserInfo } from './user';

export type MemberRole = 'owner' | 'admin' | 'normal' | 'viewer';
export type MemberStatus = 'pending' | 'canceled' | 'normal' | 'removed';

export const memberRoles = ['owner', 'admin', 'normal', 'viewer'];
export const memberStatus = ['pending', 'canceled', 'normal', 'removed'];

export const memberRoleLabels = {
  owner: __('member.role.owner'),
  admin: __('member.role.admin'),
  normal: __('member.role.normal'),
  viewer: __('member.role.viewer'),
}

export const memberStatusLabels = {
  pending: __('member.status.pending'),
  canceled: __('member.status.canceled'),
  normal: __('member.status.normal'),
  removed: __('member.status.removed'),
}

export interface MemberIdentity {
  type: 'team' | 'dossier';
  team_id?: string;
  dossier_id?: string;
}

export interface MemberData extends IBaseData, UserIdentity, MemberIdentity {
  __type?: 'Member';
  role: MemberRole;
  status: MemberStatus;
  dateCreated: Date;
  dateUpdated: Date;
  user?: PublicUserInfo;
}

export function checkPermission(role: MemberRole, required: MemberRole) {
  return memberRoles.indexOf(role) <= memberRoles.indexOf(required);
}
