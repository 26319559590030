import _ from 'lodash';
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/AccountCircle';

import { __ } from '../../utils/intl';
import { history } from '../../store/configureStore';
import { getFullName } from '../../utils/data';
import { ContactPersonData } from '../../types/contact.person';
import ListFilter from '../Common/ListFilter';
import { partialListStyles } from './common';

const styles = partialListStyles;

interface OwnProps {
  className?: string;
  title?: string;
  hasFilter?: boolean;
  list: ContactPersonData[];
};

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  filterValue: string;
}

class OrganizationListPartial extends React.Component<Props, State> {

  state: State = {
    filterValue: '',
  }

  render() {
    const { classes, hasFilter, className, title, list: _list } = this.props;
    const { filterValue } = this.state;
    let list = _list;
    if (hasFilter) {
      const keyword = filterValue.toLowerCase();
      list = _list.filter(item => {
        const name = getFullName(item.profile);
        return name ? name.toLowerCase().includes(filterValue) : false;
      });
    }
    return (
      <List
        className={className}
        subheader={
          <ListSubheader className={classes.listHeader}>
            <div>{title || __('contacts.personList')}</div>
            {hasFilter &&
              <div className={classes.filterContainer}>
                <ListFilter
                  className={classes.filter}
                  onSearch={value => this.setState({ filterValue: value })}
                />
              </div>
            }
          </ListSubheader>
        }
      >
        {list.map(item => (
          <ListItem
            button
            key={item._id}
            onClick={() => history.push(`/contacts/person/item/${item._id}`)}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={getFullName(item.profile)}
            />
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(OrganizationListPartial);
