import _ from 'lodash';
import { Action } from './actions';
import { InvitationData } from '../../types/invitation';
import { number } from 'prop-types';

export const INVITATION_LIST_SIZE = 10;

export interface InvitationState {
  current: InvitationData | null;
  list: InvitationData[];
  loading: boolean;
  noMore: boolean;
  pendingCount: number;
}

const initialState: InvitationState = {
  loading: true,
  current: null,
  list: [],
  noMore: false,
  pendingCount: 0,
}

const dossierReducer = (state: InvitationState = initialState, action: Action): InvitationState => {
  switch (action.type) {
    case '@@invitation/SET_PENDING_COUNT': 
      return { ...state, pendingCount: action.count };

    case '@@invitation/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@invitation/SET_LOADING': 
      return { ...state, loading: action.loading };

    case '@@invitation/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@invitation/SET_LIST':
      return {
        ...state,
        list: action.list,
        loading: false,
        noMore: action.list.length < INVITATION_LIST_SIZE,
      };

    case '@@invitation/APPEND_LIST':
      return {
        ...state,
        list: [...state.list, ...action.list],
        loading: false,
        noMore: action.list.length < INVITATION_LIST_SIZE,
      };

    case '@@invitation/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      let { pendingCount } = state;
      const newStatus = action.item.status;
      if (index === -1) {
        pendingCount += newStatus === 'pending' ? 1 : -1;
        list.push(action.item);
      } else {
        const { status: oldStatus } = action.item;
        if (oldStatus !== newStatus && (oldStatus === 'pending' || newStatus === 'pending')) {
          pendingCount += newStatus === 'pending' ? 1 : -1;
        }
        list[index] = action.item;
      }
      return { ...state, list };

    case '@@invitation/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };

    default:
      return state;
  }
}

export default dossierReducer;
