import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List } from '@material-ui/core';

import { __ } from '../../utils/intl';
import { FormItem } from '../../types/form';
import { MemberData, memberRoleLabels, memberStatusLabels } from '../../types/member'
import { FormControl } from '../Form';
import { buildOptionsFromMap } from '../Form/utils';
import MemberListItem from './MemberListItem';
import Confirm from '../Common/Confirm';

export const formItems: FormItem[] = [
  {
    key: 'role', label: __('member.role'), type: 'select',
    options: buildOptionsFromMap(memberRoleLabels).filter(item => item.value !== 'owner'),
  },
  {
    key: 'status', label: __('member.status'), type: 'select', readOnly: true,
    options: buildOptionsFromMap(memberStatusLabels),
  },
]

interface Props {
  open: boolean;
  onClose: () => void;
  editable?: boolean;
  member: MemberData;
  onUpdate?: (data: Partial<MemberData>) => void;
  onDelete?: () => void;
}

interface State {
  editing: boolean;
  data: MemberData;
  deleteConfirmOpen: boolean;
}

class MemberProfile extends React.Component<Props> {

  state: State = {
    editing: false,
    data: this.props.member,
    deleteConfirmOpen: false,
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.member !== this.props.member) {
      this.setState({ data: nextProps.member });
    }
  }

  handleCancel = () => {
    this.setState({ data: this.props.member, editing: false });
  }

  handleUpdate = () => {
    const { onUpdate } = this.props;
    const { data } = this.state;
    if (!data) return;
    this.setState({ editing: false }, () => {
      onUpdate && onUpdate(data);
    });
  }

  handleDelete = () =>  {
    const { onDelete, onClose } = this.props;
    if (onDelete) {
      onDelete();
      onClose();
    }
  }

  render() {
    const { open, onClose, editable, member, onDelete } = this.props;
    const { editing, data, deleteConfirmOpen } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{__('member.profile')}</DialogTitle>
        <DialogContent>
          <List>
            <MemberListItem member={member} />
          </List>
          <FormControl
            readOnly={!editable || !editing}
            items={formItems}
            value={data}
            onChange={(data) => this.setState({ data })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{__('dialog.close')}</Button>
          {editable && (editing ?
            <>
              {onDelete &&
                <Button
                  color="secondary"
                  onClick={() => this.setState({ deleteConfirmOpen: true })}
                >
                  {__('dialog.remove')}
                </Button>
              }
              <Button onClick={this.handleCancel}>{__('dialog.cancel')}</Button>
              <Button color="primary" onClick={this.handleUpdate}>{__('dialog.save')}</Button>
            </>
            :
            <Button
              color="primary"
              disabled={member.role === 'owner'}
              onClick={() => this.setState({ editing: true })}
            >
              {__('dialog.edit')}
            </Button>
          )}
        </DialogActions>
        <Confirm
          open={deleteConfirmOpen}
          title={__('member.deleteConfirm.title')}
          content={__('member.deleteConfirm.content')}
          confirmButtonText={__('dialog.remove')}
          confirmButtonProps={{ color: 'secondary', variant: 'contained' }}
          onConfirm={this.handleDelete}
          onClose={() => this.setState({ deleteConfirmOpen: false })}
        />
      </Dialog>
    );
  }
}

export default MemberProfile;
