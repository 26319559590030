import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { InputBase, Paper, IconButton, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import { __ } from '../../utils/intl';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  inputRoot: {
    padding: 0,
  },
  input: {
    padding: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(-1),
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
})

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
  onDelete: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  value: string;
}

class ClaimListItem extends React.Component<Props, State> {

  state = {
    value: this.props.value,
  }

  componentWillReceiveProps(nextProps: Props) {
    console.log('newProps:',nextProps);
    if (nextProps.value !== this.props.value)
    this.setState({
      value: nextProps.value,
    });
  }

  handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ value: event.target.value });
  }

  handleUpdate = () => {
    const { onChange, value: oldValue } = this.props;
    const { value } = this.state;
    if (value !== oldValue) {
      onChange(value);
    }
  }

  render() {
    const { classes, onDelete } = this.props;
    const { value } = this.state;
    return (
      <Paper square>
        <InputBase
          multiline
          fullWidth
          className={classes.inputRoot}
          classes={{
            input: classes.input,
          }}
          placeholder={__('claimList.inputPlaceHolder')}
          value={value}
          onChange={this.handleTextChange}
          onBlur={this.handleUpdate}
        />
        <div className={classes.actions}>
          <Tooltip title={__('claimList.removeItem')}>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Paper>
    );
  }
}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(ClaimListItem);
