export function getScrollParent(element: Element): Element | null {
  if (element == null) {
    return null;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  } else if (element.parentElement) {
    return getScrollParent(element.parentElement);
  } else {
    return null;
  }
}

export interface ScrollOffset {
  top?: number,
  left?: number,
}

export function scrollIntoViewById(id: string, options?: ScrollIntoViewOptions, offset?: ScrollOffset) {
  const element = document.getElementById(id);
  if (!element) return;
  if (!options && (element as any).scrollIntoViewIfNeeded) {
    (element as any).scrollIntoViewIfNeeded();
    return;
  }
  if (element && !isInsideViewport(element)) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'nearest',
      ...options,
    });
  }
  if (offset) {
    const {
      top = 0,
      left = 0
    } = offset;
    const scrollParent = getScrollParent(element);
    if (!scrollParent) return;
    scrollParent.scrollTo({
      top: scrollParent.scrollTop + top,
      left: scrollParent.scrollLeft + left,
    });
  }
}

export function isInsideViewport(element: Element) {
  const box = element.getBoundingClientRect();
  return (
    box.top >= 0 &&
    box.left >= 0 &&
    box.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    box.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}
