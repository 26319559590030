import React from 'react';
import { List, ListSubheader } from '@material-ui/core';
import {
  mdiIframe,
  mdiAccountMultiple,
  mdiShieldAccount,
  mdiAccount,
  mdiFormatListBulleted,
} from '@mdi/js';

import { __ } from '../../utils/intl';
import SimpleListItem from '../Common/SimpleListItem';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

class AdminNav extends React.Component {
  render() {
    return (
      <List>
        <SimpleListItem
          title={__('admin.nav.users')}
          subTitle={__('admin.nav.users.tip')}
          link="/admin/users"
          icon={<SimpleSvgIcon path={mdiAccount} />}
        />
        <SimpleListItem
          title={__('admin.nav.teams')}
          subTitle={__('admin.nav.teams.tip')}
          link="/admin/teams"
          icon={<SimpleSvgIcon path={mdiAccountMultiple} />}
        />
        <SimpleListItem
          title={__('admin.nav.templateEditor')}
          subTitle={__('admin.nav.templateEditor.tip')}
          link="/admin/dossier-templates"
          icon={<SimpleSvgIcon path={mdiIframe} />}
        />
        <SimpleListItem
          title={__('admin.nav.administrators')}
          subTitle={__('admin.nav.administrators.tip')}
          link="/admin/administrators"
          icon={<SimpleSvgIcon path={mdiShieldAccount} />}
        />
        <SimpleListItem
          title={__('admin.nav.logs')}
          subTitle={__('admin.nav.logs.tip')}
          link="/admin/logs"
          icon={<SimpleSvgIcon path={mdiFormatListBulleted} />}
        />
      </List>
    )
  }
}

export default AdminNav;
