import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid';
import { IconButton, Tooltip } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { mdiFileDownloadOutline } from '@mdi/js';

import { DossierTreeItem } from '../../types/dossier';
import { __ } from '../../utils/intl';
import exampleTree from './example/tree';
import DocumentTree from '../Dossier/DocumentTree';

const styles = (theme: Theme) => createStyles({
  root: {
    background: 'rgb(240, 240, 240)',
  },
});

export interface OwnProps {
  className?: string;
  tree: DossierTreeItem
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  tree: DossierTreeItem;
}

class TreeEditor extends React.Component<Props, State> {

  static getDerivedStateFromProps(props: Props, state: State) {
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      tree: props.tree,
    };
  }

  componentDidMount() {
    this.setState({
      tree: exampleTree,
    });
  }

  render() {
    const { classes, className } = this.props;
    return (
      <div className={clsx(classes.root, className)}>
        <DocumentTree
          tree={exampleTree}
        />
      </div>
    );
  }

}

export default withStyles(styles)(TreeEditor);
