import React from 'react';
import { Box } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface Props {
  icon?: React.ReactElement;
  message?: string;
}

class Tips extends React.Component<Props> {
  render() {
    const { icon = <InfoIcon fontSize="large" />, message = '' } = this.props;
    return (
      <Box width="100%" bgcolor="#fff" marginX="auto" marginY={8} textAlign="center">
        <Box color="text.hint" fontSize={24}>{icon}</Box>
        <Box color="text.hint">{message}</Box>
      </Box>
    )
  }
}

export default Tips;
