import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { 
  mdiFolder,
  mdiAccountMultiple,
  mdiHexagonSlice3,
} from '@mdi/js';

import SimpleSvgIcon from './SimpleSvgIcon';
import { KeyValueMap } from '../../types/common';
import { TargetIdentity } from '../../types/notification';
import DocumentIcon from '../Dossier/DocumentIcon';
import { amber, cyan, lightGreen } from '@material-ui/core/colors';

interface Props extends SvgIconProps {
  item: TargetIdentity;
}

interface IconProps {
  path: string;
  color: string;
}

const iconMap: KeyValueMap<IconProps> = {
  dossier: { path: mdiFolder, color: amber[200] },
  group: { path: mdiAccountMultiple, color: lightGreen[200] },
  default: { path: mdiHexagonSlice3, color: cyan[200] },
}

const TargetIcon: React.SFC<Props> = (props) => {
  const { item, ...restProps } = props;
  if (item.type === 'document' && item.document) {
    return (
      <DocumentIcon document={item.document} {...restProps} />
    );
  }
  const icon = iconMap[item.type] || iconMap.default;
  return (
    <SimpleSvgIcon htmlColor={icon.color} path={icon.path} {...restProps} />
  );
}

export default TargetIcon;
