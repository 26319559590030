import _ from 'lodash';
import React from 'react';
import arrayMove from 'array-move';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { List } from '@material-ui/core';

import { TreeItem } from '../../types/tree';
import {
  UITreeItem,
  buildCheckboxTree,
  rebuildCheckboxTree,
  onCheckChange,
  getTreeItemPath,
  walk,
  findAll,
  find,
} from '../../utils/tree';
import DocumentTreeNode from './DocumentTreeNode';
import { DossierData } from '../../types/dossier';

interface StateProps {
  dossier: DossierData | null
}

interface DispatchProps {
  fetchDossier: (dossierId: string) => Promise<DossierData>
  setCurrentTreeItem: (item: TreeItem) => Promise<void>
}

interface OwnProps {
  tree: TreeItem;
  checkbox?: boolean;
  sortable?: boolean;
  selectedItem: TreeItem | null;
  checkedItems: string[];
  onMoveIndex: (itemId: string, movement: number) => void;
  onSelect: (item: TreeItem) => void;
  onCheckChange: (items: string[]) => void;
  onContextMenu: (event: React.MouseEvent, item: TreeItem) => void;
}


type Props = OwnProps & DispatchProps & StateProps;


interface State {
}

class DocumentTree extends React.Component<Props, State> {

  private tree!: UITreeItem;
  private isCheckedChanging = false;

  static defaultProps: Partial<Props> = {
    checkbox: false,
    selectedItem: null,
    checkedItems: [],
    onSelect: () => {},
    onCheckChange: () => {},
  }

  constructor(props: Readonly<Props>) {
    super(props);
    this.tree = buildCheckboxTree(props.tree);
    this.state = {
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { selectedItem } = this.props;
    if (selectedItem && selectedItem !== prevProps.selectedItem) {
      const path = getTreeItemPath(this.tree, selectedItem);
      if (path) {
        let changed = false;
        path.forEach(item => {
          if (!item.expanded) {
            changed = true;
          }
          item.expanded = true;
        });
        if (changed) {
          this.forceUpdate();
        }
      }
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.checkedItems !== this.props.checkedItems &&
      !_.isEqual(nextProps.checkedItems, this.props.checkedItems)
    ) {
      this.handleCheckedPropsChange(nextProps);
    } else if (nextProps.tree !== this.props.tree) {
      this.handleUpdateTree(nextProps.tree);
      this.handleCheckedPropsChange(nextProps);
    }
  }

  handleUpdateTree(tree: TreeItem) {
    if (tree._id === this.props.tree._id) {
      const expandedItems = findAll(this.tree, item => item.expanded === true).map(i => i._id)
      this.tree = buildCheckboxTree(tree, { initialExpandedIds: expandedItems });
    } else {
      this.tree = buildCheckboxTree(tree);
    }
  }

  getCheckedIdsFromProps(props: Props) {
    return _.uniq(_.map(props.checkedItems, '_id')).sort();
  }

  getCheckedItems() {
    const checkedItems: string[] = [];
    walk(this.tree, (item, path) => {
      if (item.checked) {
        checkedItems.push(item._id);
      }
    });
    return checkedItems;
  }

  handleCheckedPropsChange(props: Props) {
    if (this.isCheckedChanging) return;
    rebuildCheckboxTree(this.tree, props.checkedItems);
    this.forceUpdate();
  }

  handleExpandChange(item: UITreeItem, status?: boolean) {
    item.expanded = !item.expanded;
    this.forceUpdate();
  }

  handleCheckChange(item: UITreeItem, status?: boolean) {
    this.isCheckedChanging = true;
    onCheckChange(this.tree, item);
    this.props.onCheckChange(this.getCheckedItems());
    this.forceUpdate(() => {
      this.isCheckedChanging = false;
    });
  }

  handleSelect(item: UITreeItem) {
    const { onSelect } = this.props;
    onSelect(item);
  }

  onBeforeDragStart = () => {
    /*...*/
  }

  onDragStart = () => {
    /*...*/
  }

  onDragUpdate = () => {
    /*...*/
  }

  onDragEnd = async (move: DropResult, reason: ResponderProvided) => {
    const { onMoveIndex } = this.props;
    if (!move.destination) {
      return;
    }
    const movement = move.destination.index - move.source.index;
    if (movement === 0) {
      return;
    }
    const { tree } = this;
    const parent = find(tree, (item) => item._id === move.source.droppableId)
    if (parent && parent.children) {
      arrayMove.mutate(parent.children, move.source.index, move.destination.index);
      parent.children.forEach((child, i) => child.childIndex = i);
      this.tree = tree;
      this.forceUpdate();
    }
    const itemId = move.draggableId;
    onMoveIndex(itemId, movement);
    // await this.props.fetchDossier(this.props.dossier._id)
  }

  render() {
    const { sortable, checkbox, selectedItem, onContextMenu } = this.props;
    return (
      <div className="document-tree">
        <DragDropContext
          onBeforeDragStart={this.onBeforeDragStart}
          onDragStart={this.onDragStart}
          onDragUpdate={this.onDragUpdate}
          onDragEnd={this.onDragEnd}
        >
          <Droppable
            droppableId={this.tree._id}
            isDropDisabled={!sortable}
          >
            {(provided, snapshot) => (
              <List component="nav"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <DocumentTreeNode
                  sortable={sortable}
                  checkbox={checkbox}
                  item={this.tree}
                  level={1}
                  selectedItem={selectedItem}
                  onSelect={(item) => this.handleSelect(item)}
                  onExpandChange={(item) => this.handleExpandChange(item)}
                  onCheckChange={(item) => this.handleCheckChange(item)}
                  onContextMenu={onContextMenu}
                />
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

export default DocumentTree;
