import _ from 'lodash';
import React from 'react';
import { TreeItem } from '../../types/tree';
import { Action } from './actions';

import {AnnotationData} from '../../types/annotation'
import { Rect } from '../../types/entity';

export type LeftPanelType = 'tree' | 'team' | 'info';
export type RightPanelType = 'inspector' | 'versions' | 'assistant' | 'chat';
export type SidePanelType = 'sidenav';
export type ChatPanelType = 'chat';
export type PanelType = LeftPanelType | RightPanelType | SidePanelType | ChatPanelType;

export const LeftPanelTypes = ['tree', 'task', 'info', 'export', 'team'];
export const RightPanelTypes = ['inspector', 'versions', 'assistant', 'chat'];
export const SidePanelTypes = ['sidenav'];
export const ChatPanelTypes = ['chat'];
export const PanelTypes = [...LeftPanelTypes, ...RightPanelTypes, ...SidePanelTypes, , ...ChatPanelTypes];

const isWideScreen = window.innerWidth >= 1280;
const sidePanelWidth = 200;
const sidePanelMinifiedWidth = 56

export interface SinglePanelStatus<T = any> {
  open: boolean;
  pinned: boolean;
  minified?: boolean;
  key: T;
  width: number;
}

export interface PanelStatus {
  left: SinglePanelStatus<LeftPanelType>;
  right: SinglePanelStatus<RightPanelType>;
  side: SinglePanelStatus<SidePanelType>;
  chat: SinglePanelStatus<ChatPanelType>;
}

export type PanelSide = keyof PanelStatus;

export interface UIState {
  panelStatus: PanelStatus;
  selectedTreeItem: TreeItem | null;
}

const initialState: UIState = {
  panelStatus: {
    left: {
      open: isWideScreen,
      pinned: isWideScreen,
      key: 'tree',
      width: 300,
      minified: false,
    },
    right: {
      open: false,
      pinned: false,
      key: 'inspector',
      width: 300,
      minified: false,
    },
    side: {
      open: false,
      pinned: false,
      key: 'sidenav',
      width: 200,
      minified: false,
    },
    chat: {
      open: false,
      pinned: false,
      key: 'chat',
      width: 200,
      minified: false,
    },
  },
  selectedTreeItem: null,
}

export function isPanelOpenAndPinned(status: SinglePanelStatus<any>) {
  return status.open && status.pinned;
}

export interface ContainerPosition {
  left: number,
  right: number,
}

export function getContainerPosition(panelStatus: PanelStatus, inDossierRoute = false): ContainerPosition {
  let left = 0;
  let right = 0;
  if (isPanelOpenAndPinned(panelStatus.side)) {
    left += panelStatus.side.width;
  }
  if (isPanelOpenAndPinned(panelStatus.chat)) {
    right += panelStatus.chat.width;
  }
  if (inDossierRoute) {
    if (isPanelOpenAndPinned(panelStatus.left)) {
      left += panelStatus.left.width;
    }
    if (isPanelOpenAndPinned(panelStatus.right)) {
      right += panelStatus.right.width;
    }
  }
  return {
    left,
    right,
  }
}

const projectReducer = (state: UIState = initialState, action: Action): UIState => {
  switch (action.type) {

    case '@@ui/UPDATE_PANEL_STATUS': {
      const newStatus = {
        ...state.panelStatus[action.side],
        ...action.status,
      };
      if (action.side === 'side' || action.side === 'chat' && _.has(action.status, 'minified')) {
        newStatus.width = newStatus.minified ? sidePanelMinifiedWidth : sidePanelWidth;
      }
      return {
        ...state,
        panelStatus: {
          ...state.panelStatus,
          [action.side]: newStatus,
        }
      }
    }
    
    case '@@ui/TOGGLE_SIDE_PANEL':
    case '@@ui/TOGGLE_SIDE_PANEL_PIN':
    case '@@ui/TOGGLE_SIDE_PANEL_MINIFIED': {
      let prop: 'open' | 'pinned' | 'minified' = 'open';
      switch (action.type) {
        case '@@ui/TOGGLE_SIDE_PANEL':
          prop = 'open';
          break;
        case '@@ui/TOGGLE_SIDE_PANEL_PIN':
          prop = 'pinned';
          break;
        case '@@ui/TOGGLE_SIDE_PANEL_MINIFIED':
          prop = 'minified';
          break;
      }
      const oldPanelStatus = state.panelStatus[action.side];
      if (oldPanelStatus[prop] === action.status) {
        return state;
      } else {
        const status = _.isUndefined(action.status) ? !oldPanelStatus[prop] : action.status;
        const newPanelStatus = {
          ...oldPanelStatus,
          [prop]: status,
        };
        if (['side', 'chat'].includes(action.side) && prop === 'minified') {
          newPanelStatus.width = status ? sidePanelMinifiedWidth : sidePanelWidth;
        }
        return {
          ...state,
          panelStatus: {
            ...state.panelStatus,
            [action.side]: newPanelStatus,
          }
        };
      }
    }

    case '@@ui/TOGGLE_SINGLE_PANEL': {
      let side: PanelSide;
      if (LeftPanelTypes.includes(action.panel)) {
        side = 'left';
      } else {
        side = 'right';
      }
      const oldPanelStatus = state.panelStatus[side];
      const newPanelStatus = {
        ...oldPanelStatus,
        key: action.panel,
      };
      if (_.isUndefined(action.status)) {
        newPanelStatus.open = oldPanelStatus.key === action.panel ? !oldPanelStatus.open : true;
      } else {
        newPanelStatus.open = action.status;
      }
      return {
        ...state,
        panelStatus: {
          ...state.panelStatus,
          [side]: newPanelStatus,
        },
      };
    }

    case '@@ui/UPDATE_PANEL_WIDTH': {
      if (state.panelStatus[action.side].width === action.width) {
        return state;
      } else {
        return {
          ...state,
          panelStatus: {
            ...state.panelStatus,
            [action.side]: {
              ...state.panelStatus[action.side],
              width: action.width,
            },
          }
        };
      }
    }

    case '@@ui/SET_SELECTED_TREE_ITEM':
      return { ...state, selectedTreeItem: action.item};

    default:
      return state;
  }
}

export default projectReducer;
