import _ from 'lodash';
import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { __ } from '../../utils/intl';
import { getFullName } from '../../utils/data';
import { UserProfile } from '../../types/user';
import { FormItem } from '../../types/form';
import FormControl from '../Form/FormControl';

const fields: FormItem[] = [
  { key: 'firstName', label: __('profile.field.firstName'), type: 'text' },
  { key: 'lastName', label: __('profile.field.lastName'), type: 'text' },
  { key: 'sex', label: __('profile.field.sex'), type: 'select', options: [
    { label: __('profile.sex.male'), value: 'M' },
    { label: __('profile.sex.female'), value: 'F' },
  ] },
  { key: 'mobilePhone', label: __('profile.field.mobilePhone'), type: 'text', required: true },
  { key: 'officePhone', label: __('profile.field.officePhone'), type: 'text', required: true },
  { key: 'organization', label: __('profile.field.organization'), type: 'text', required: true },
  { key: 'jobTitle', label: __('profile.field.jobTitle'), type: 'text', required: true },
  { key: 'province', label: __('profile.field.province'), type: 'text', required: true },
  { key: 'city', label: __('profile.field.city'), type: 'text', required: true },
  { key: 'address', label: __('profile.field.address'), type: 'text', required: true },
];

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

interface OwnProps {
  title?: string;
  data: UserProfile | null;
  readOnly: boolean;
  onChange: (data: UserProfile) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class PersonForm extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    data: {},
    readOnly: false,
    onChange: () => {},
  }

  render() {
    const { classes, title, data, readOnly, onChange } = this.props;
    let name = title || getFullName(data);
    return (
      <div className={classes.root}>
        <Typography variant="h6">{name}</Typography>
        {data &&
          <FormControl
            readOnly={readOnly}
            items={fields}
            value={data}
            onChange={onChange}
          />
        }
      </div>
    );
  }
}

export default withStyles(styles)(PersonForm);
