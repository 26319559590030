import _ from 'lodash';
import { Action } from './actions';
import { FactData } from '../../types/fact';

export interface FactState {
  current: FactData | null;
  list: FactData[] | null;
}

const initialState: FactState = {
  current: null,
  list: null,
}

const factReducer = (state: FactState = initialState, action: Action): FactState => {
  switch (action.type) {
    case '@@fact/SET_LIST': 
      return { ...state, list: action.list };

    default:
      return state;
  }
}

export default factReducer;
