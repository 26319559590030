import React from 'react';
import clsx from 'clsx';
import { AppBar, Toolbar, IconButton, Color, PropTypes, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { mdiFileTree, mdiTableEdit } from '@mdi/js';

import { __ } from '../../utils/intl';
import FormEditor from './FormEditor';
import TreeEditor from './TreeEditor';

import demoForm from './example/form';
import demoTree from './example/tree';
import Sidebar from '../Common/Sidebar';
import { SinglePanelStatus, isPanelOpenAndPinned } from '../../store/ui/reducers';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // flexDirection: 'row',
  },
  appBar: {

  },
  topButton: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  selectedIconButton: {
    background: 'rgba(255, 255, 255, 0.36)',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.18)',
    }
  },
  sidebar: {

  },
  main: {
    position: 'fixed',
    top: 64,
    [theme.breakpoints.down('xs')]: {
      top: 56,
    },
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
  },
});

export interface OwnProps {
}

type Props = OwnProps & WithStyles<typeof styles>;

type LeftPanelTypes = 'tree';
type RightPanelTypes = 'props';
interface PanelStatus {
  left: SinglePanelStatus<LeftPanelTypes>;
  right: SinglePanelStatus<RightPanelTypes>;
}
type PanelSide = keyof PanelStatus;

interface State {
  panelStatus: PanelStatus;
  formEditorOpen: boolean;
}

function iPanelOpen<K, T extends K> (status: SinglePanelStatus<T>, key: K) {
  return status.open && status.key === key;
}

class TemplateEditor extends React.Component<Props, State> {

  state: State = {
    panelStatus: {
      left: {
        open: true,
        key: 'tree',
        pinned: true,
        width: 300,
      },
      right: {
        open: false,
        key: 'props',
        pinned: false,
        width: 300,
      },
    },
    formEditorOpen: false,
  }

  handlePanelStatusChange<T extends PanelSide>(side: T, status: Partial<PanelStatus[T]>) {
    const { panelStatus } = this.state;
    this.setState({
      panelStatus: {
        ...panelStatus,
        [side]: {
          ...panelStatus[side],
          ...status,
        },
      },
    });
  }

  render() {
    const { classes } = this.props;
    const { panelStatus, formEditorOpen } = this.state;
    const isTreePanelOpen = iPanelOpen(panelStatus.left, 'tree');
    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Tooltip title={__('template.tree')}>
              <IconButton
                color="inherit"
                className={clsx(classes.topButton, { [classes.selectedIconButton]: isTreePanelOpen })}
                onClick={() => this.handlePanelStatusChange('left', {
                  key: 'tree',
                  open: !panelStatus.left.open,
                })}
              >
                <SimpleSvgIcon path={mdiFileTree} />
              </IconButton>
            </Tooltip>
            <Tooltip title={__('template.form')}>
              <IconButton
                color="inherit"
                className={classes.topButton}
                onClick={() => this.setState({ formEditorOpen: true })}
              >
                <SimpleSvgIcon path={mdiTableEdit} />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Sidebar
          open={panelStatus.left.open}
          align="left"
          className={classes.sidebar}
          width={panelStatus.left.width}
          onWidthChange={(width) => this.handlePanelStatusChange('left', { width })}
          resizeBounds={{
            left: -100,
            right: 200,
          }}
        >
          <TreeEditor
            tree={demoTree}
          />
        </Sidebar>
        <div
          className={classes.main}
          style={{
            left: isPanelOpenAndPinned(panelStatus.left) ? panelStatus.left.width : 0,
            right: isPanelOpenAndPinned(panelStatus.right) ? panelStatus.right.width : 0,
          }}
        >
        </div>
        <FormEditor
          open={formEditorOpen}
          schema={demoForm}
          onClose={() => this.setState({ formEditorOpen: false })}
          onUpdate={() => {}}
        />
      </div>
    );
  }

}

export default withStyles(styles)(TemplateEditor);
