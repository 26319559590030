import _ from 'lodash';
import { TeamData } from '../../types/team';
import { ThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';
import { updateListItem as updateMemberListItem } from '../member/actions';

// Action Definition
export interface SetCurrentItem {
  type: '@@team/SET_CURRENT_ITEM';
  item: TeamData;
}

export interface ClearCurrentItem {
  type: '@@team/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@team/SET_LIST';
  list: TeamData[];
}

export interface UpdateListItem {
  type: '@@team/UPDATE_LIST_ITEM';
  item: TeamData;
}

export interface DeleteListItem {
  type: '@@team/REMOVE_LIST_ITEM';
  id: string;
}

// Union Action Types
export type Action = (
  SetCurrentItem |
  ClearCurrentItem |
  SetList |
  UpdateListItem |
  DeleteListItem
);

// Action Creators
export const setItem = (item: TeamData): SetCurrentItem => {
  return { type: '@@team/SET_CURRENT_ITEM', item };
}

export const clearItem = (): ClearCurrentItem => {
  return { type: '@@team/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: TeamData[]): SetList => {
  return { type: '@@team/SET_LIST', list };
}

export const updateListItem = (item: TeamData): UpdateListItem => {
  return { type: '@@team/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@team/REMOVE_LIST_ITEM', id };
}

export const fetchItemRemote = (teamId: string): ThunkActionType<TeamData> => {
  return async (dispatch) => {
    const item = await client.get<TeamData>('/teams/:teamId', {
      params: { teamId },
    });
    dispatch(setItem(item));
    return item;
  }
}

export const updateItemRemote = (teamId: string, data: Partial<TeamData>): ThunkActionType<TeamData> => {
  return async (dispatch) => {
    const item = await client.put<TeamData>('/teams/:teamId', {
      params: { teamId },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const removeItemRemote = (teamId: string, password: string): ThunkActionType => {
  return async (dispatch) => {
    await client.delete('/teams/:teamId', {
      params: { teamId },
      data: { password }
    });
    dispatch(removeListItem(teamId));
  }
}

export const transferTeamRemote = (teamId: string, userId: string, password: string): ThunkActionType => {
  return async (dispatch, getState) => {
    await client.post('/teams/:teamId/transfer', {
      params: { teamId },
      data: { owner: userId, password: password },
    });
    // TODO move data side affect into subscription
    const { user, team, member } = getState();
    if (team.current) {
      dispatch(setItem({
        ...team.current,
        owner: userId,
        member: team.current.member ? { ...team.current.member, role: 'admin' } : undefined,
      }));
    }
    if (user.current) {
      const currentMember = _.find(member.list, { type: 'team', team_id: teamId, user_id: user.current._id });
      if (currentMember) {
        dispatch(updateMemberListItem({ ...currentMember, role: 'admin' }));
      }
    }
    const newOwner = _.find(member.list, { type: 'team', team_id: teamId, user_id: userId });
    if (newOwner) {
      dispatch(updateMemberListItem({ ...newOwner, role: 'owner' }));
    }
    dispatch(removeListItem(teamId));
  }
}

export const fetchListRemote = (): ThunkActionType<TeamData[]> => {
  return async (dispatch) => {
    const list = await client.get<TeamData[]>('/teams');
    dispatch(setList(list));
    return list;
  }
}

export const createItemRemote = (data: Partial<TeamData>): ThunkActionType<TeamData> => {
  return async (dispatch) => {
    const item = await client.post<TeamData>('/teams', {
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}
