import _ from 'lodash';
import { ThunkActionType } from '../types';
import client from '../client';
import { ContactOrganizationData } from '../../types/contact.organization';

// Action Definition
export interface SetCurrentItem {
  type: '@@organization/SET_CURRENT_ITEM';
  item: ContactOrganizationData;
}

export interface ClearCurrentItem {
  type: '@@organization/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@organization/SET_LIST';
  list: ContactOrganizationData[];
}

export interface UpdateListItem {
  type: '@@organization/UPDATE_LIST_ITEM';
  item: ContactOrganizationData;
}

export interface DeleteListItem {
  type: '@@organization/REMOVE_LIST_ITEM';
  id: string;
}

export interface CreateItem {
  type: '@@organization/CREATE_ITEM_START',
}

export interface CreateItemFailure {
  type: '@@organization/CREATE_ITEM_FAILURE',
  error: Error;
}

export interface CreateItemSuccess {
  type: '@@organization/CREATE_ITEM_SUCCESS',
  item: ContactOrganizationData,
}

// Union Action Types
export type Action = SetCurrentItem
  | ClearCurrentItem
  | SetList
  | UpdateListItem
  | DeleteListItem
  | CreateItem
  | CreateItemSuccess
  | CreateItemFailure;

// Action Creators
export const setItem = (item: ContactOrganizationData): SetCurrentItem => {
  return { type: '@@organization/SET_CURRENT_ITEM', item };
}

export const clearItem = (): ClearCurrentItem => {
  return { type: '@@organization/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: ContactOrganizationData[]): SetList => {
  return { type: '@@organization/SET_LIST', list };
}

export const updateListItem = (item: ContactOrganizationData): UpdateListItem => {
  return { type: '@@organization/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@organization/REMOVE_LIST_ITEM', id };
}

export const fetchItemRemote = (id: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<ContactOrganizationData>(`/contacts.organization/${id}`);
    dispatch(setItem(item));
  }
}

export const updateItemRemote = (id: string, data: Partial<ContactOrganizationData>): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.put<ContactOrganizationData>(`/contacts.organization/${id}`, { data });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
  }
}

export const removeItemRemote = (id: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    await client.delete(`/contacts.organization/${id}`);
    dispatch(removeListItem(id));
  }
}

export const fetchListRemote = (): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<ContactOrganizationData[]>('/contacts.organization');
    dispatch(setList(item));
  }
}

export const createItemRemote = (data: Partial<ContactOrganizationData>): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch({ type: '@@organization/CREATE_ITEM_START' });
      const organization = await client.post<ContactOrganizationData>(`/contacts.organization`, { data });
      dispatch({
        type: '@@organization/CREATE_ITEM_SUCCESS',
        item: organization,
      })
    } catch (error) {
      dispatch({
        type: '@@organization/CREATE_ITEM_FAILURE',
        error: error,
      });
    }
  }
}
