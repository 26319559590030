import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link, Paper, List, ListItem, ListSubheader, ListItemText, ListItemAvatar, ListItemIcon } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';
import { mdiAccountMultiple } from '@mdi/js';

import { UserData } from '../../types/user';
import { TeamData } from '../../types/team';
import { memberRoleLabels, MemberData } from '../../types/member';
import { __, intl } from '../../utils/intl';
import { getUsername } from '../../utils/data';
import { RootState } from '../../store/reducers';
import client, { ClientRequestOptions } from '../../store/client';
import UserAvatar from '../Common/UserAvatar';
import Loading from '../Common/Loading';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
    },
  },
  infoBox: {
    [theme.breakpoints.up('md')]: {
      width: 280,
      borderRight: '1px solid #eee',
    },
  },
  secondaryList: {
    flexGrow: 1,
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
}

interface Params {
  teamId: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params>
  & WithSnackbarProps & WithStyles<typeof styles>;

interface State {
  team: TeamData | null;
  memberList: MemberData[];
  dossierCount: number;
}

class AdminUserDetail extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<UserData>>();
  state: State = {
    team: null,
    memberList: [],
    dossierCount: 0,
  }

  componentDidMount() {
    const { teamId } = this.props.match.params;
    this.fetchData(teamId);
  }

  componentWillReceiveProps(nextProps: Props) {
    const { teamId } = nextProps.match.params;
    if (teamId !== this.props.match.params.teamId) {
      this.fetchData(teamId);
    }
  }

  async fetchData(teamId: string) {
    const options: ClientRequestOptions = { params: { teamId } };
    const team = await client.get<TeamData>('/admin/teams/:teamId', options);
    const memberList = await client.get<MemberData[]>('/admin/teams/:teamId/members', options);
    const { count: dossierCount } = await client.get<{ count: number }>('/admin/teams/:teamId/dossier-count', options);
    this.setState({ team, memberList, dossierCount });
  }

  renderDetail() {
    const { classes, history } = this.props;
    const { team, memberList, dossierCount } = this.state;
    if (!team) return <Loading />;
    return (
      <Paper className={classes.content}>
        <div className={classes.infoBox}>
          <List>
            <ListItem>
              <ListItemIcon><SimpleSvgIcon path={mdiAccountMultiple} /></ListItemIcon>
              <ListItemText primary={team.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('team.field.description')}
                secondary={team.description}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('member.role.owner')}
                secondary={team.member && getUsername(team.member.user)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('dossier.totalCount')}
                secondary={dossierCount}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={__('common.field.dateCreated')}
                secondary={intl.formatTime(team.dateCreated, 'L LTS')}
              />
            </ListItem>
          </List>
        </div>
        <div className={classes.secondaryList}>
          <List subheader={<ListSubheader>{__('member.members')}</ListSubheader>}>
            {memberList.map(member => (
              <ListItem
                button
                key={member._id}
                onClick={() => history.push(`/admin/users/view/${member.user_id}`)}
              >
                <ListItemAvatar>
                  <UserAvatar user={member.user} />
                </ListItemAvatar>
                <ListItemText
                  primary={getUsername(member.user)}
                  secondary={memberRoleLabels[member.role]}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Paper>
    );
  }
  render() {
    const { classes, history } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Link color="inherit" onClick={() => history.push('/admin/teams')}>{__('admin.nav.teams')}</Link>
            <Typography color="textPrimary">{__('team.view')}</Typography>
          </Breadcrumbs>
          {this.renderDetail()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminUserDetail);
