import socketIOClient from 'socket.io-client';
import { SOCKET_URL, SOCKET_PATH } from '../constants';
import { DocData } from '../types/base';
import { syncDoc, syncRemove } from './utils';
import client from './client';

export let socket: SocketIOClient.Socket;

export function initSocket() {

  socket = socketIOClient(SOCKET_URL, {
    path: SOCKET_PATH,
    transportOptions: {
      polling: {
        extraHeaders: client.getRequestHeaders(),
      },
    },
  });
  
  socket.on('error', (error: any) => {
    // console.error('socket<error>', error);
  });
  
  socket.on('connect_error', (error: any) => {
    // console.error('socket<connect error>', error);
  });

  socket.on('sync:update', (doc: DocData) => {
    // console.log('sync:update', doc)
    syncDoc(doc);
  });

  socket.on('sync:remove', (doc: DocData) => {
    // console.log('sync:remove', doc)
    syncRemove(doc)
  });

  // export io for debugging
  (window as any).io = socket;

}
