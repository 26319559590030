import _ from 'lodash';

import { ChatThreadData, ChatMessageData, ThreadOptions, ChatContact } from '../../types/chat';
import { ThunkActionType } from '../types';
import client from '../client';
import { simplifyTarget } from '../../types/notification';

// Action Definition
export interface SetThread {
  type: '@@chat/SET_THREAD';
  item: ChatThreadData | null;
}

export interface ClearTread {
  type: '@@chat/CLEAR_THREAD';
}

export interface SetThreadList {
  type: '@@chat/SET_THREAD_LIST';
  list: ChatThreadData[];
}

export interface UpdateThread {
  type: '@@chat/UPDATE_THREAD';
  item: ChatThreadData;
}

export interface RemoveThread {
  type: '@@chat/REMOVE_THREAD';
  id: string;
}

export interface CreateThread {
  type: '@@chat/CREATE_THREAD';
}

export interface SetThreadMessageList {
  type: '@@chat/SET_THREAD_MESSAGE_LIST';
  id: string; 
  list: ChatMessageData[];
}

export interface PrependThreadMessageList {
  type: '@@chat/PREPEND_THREAD_MESSAGE_LIST';
  id: string; 
  list: ChatMessageData[];
}

export interface SetIncomingMessage {
  type: '@@chat/SET_INCOMING_MESSAGE';
  item: ChatMessageData;
}

export interface SetContactList {
  type: '@@chat/SET_CONTACT_LIST';
  list: ChatContact[];
}

// Union Action Types
export type Action = (
  SetThread | 
  ClearTread | 
  SetThreadList | 
  UpdateThread | 
  RemoveThread | 
  CreateThread |
  SetThreadMessageList |
  PrependThreadMessageList |
  SetIncomingMessage |
  SetContactList
);

// Action Creators
export const setThread = (thread: ChatThreadData | null): SetThread => ({
  type: '@@chat/SET_THREAD',
  item: thread,
});

export const clearThread = (): ClearTread => ({
  type: '@@chat/CLEAR_THREAD',
});

export const setThreadList = (list: ChatThreadData[]): SetThreadList => ({
  type: '@@chat/SET_THREAD_LIST',
  list,
});

export const updateThread = (thread: ChatThreadData): UpdateThread => ({
  type: '@@chat/UPDATE_THREAD',
  item: thread,
});

export const removeThread = (id: string): RemoveThread => ({
  type: '@@chat/REMOVE_THREAD',
  id,
});

export const setThreadMessageList = (threadId: string, list: ChatMessageData[]): SetThreadMessageList => ({
  type: '@@chat/SET_THREAD_MESSAGE_LIST',
  id: threadId,
  list: list,
});

export const prependThreadMessageList = (threadId: string, list: ChatMessageData[]): PrependThreadMessageList => ({
  type: '@@chat/PREPEND_THREAD_MESSAGE_LIST',
  id: threadId,
  list: list,
});

export const setIncomingMessage = (item: ChatMessageData): SetIncomingMessage => ({
  type: '@@chat/SET_INCOMING_MESSAGE',
  item: item,
});

export const setContactList = (list: ChatContact[]): SetContactList => ({
  type: '@@chat/SET_CONTACT_LIST',
  list: list,
});

export const fetchThreadRemote = (threadId: string): ThunkActionType<ChatThreadData> => {
  return async (dispatch) => {
    const thread = await client.get<ChatThreadData>('/im/threads/:threadId', {
      params: { threadId },
    });
    dispatch(setThread(thread));
    return thread;
  }
}

export const updateThreadRemote = (data: Partial<ChatThreadData>): ThunkActionType<ChatThreadData> => {
  return async (dispatch) => {
    const thread = await client.put<ChatThreadData>('/im/threads/:threadId', {
      params: { threadId: data._id },
      data,
    });
    dispatch(setThread(thread));
    dispatch(updateThread(thread));
    return thread;
  }
}

export const setThreadReadRemote = (threadId: string, date: Date): ThunkActionType => {
  return async (dispatch, getState) => {
    await client.post('/im/threads/:threadId/read', {
      params: { threadId },
      data: { date },
    });
    const { thread, threadList } = getState().chat;
    if (thread && thread._id === threadId) {
      dispatch(setThread({ ...thread, dateLastRead: date }));
    }
    const item = _.find(threadList, { _id: threadId });
    if (item) {
      dispatch(updateThread({ ...item, dateLastRead: date }));
    }
  }
}

export const fetchThreadListRemote = (): ThunkActionType<ChatThreadData[]> => {
  return async (dispatch) => {
    const list = await client.get<ChatThreadData[]>('/im/threads');
    dispatch(setThreadList(list));
    return list;
  }
}

export const getThreadRemote = (options: ThreadOptions): ThunkActionType<ChatThreadData> => {
  return async (dispatch) => {
    const thread = await client.post<ChatThreadData>(`/im/threads`, { data: {
      ...options,
      to: simplifyTarget(options.to),
    } });
    dispatch(setThread(thread));
    return thread;
  }
}

export const fetchMessageListRemote = (threadId: string): ThunkActionType<ChatMessageData[]> => {
  return async (dispatch) => {
    const list = await client.get<ChatMessageData[]>('/im/threads/:threadId/messages', {
      params: { threadId },
    });
    dispatch(setThreadMessageList(threadId, list));
    return list;
  }
}

export const fetchMoreMessageListRemote = (threadId: string): ThunkActionType<ChatMessageData[]> => {
  return async (dispatch) => {
    const list = await client.get<ChatMessageData[]>('/im/threads/:threadId/messages', {
      params: { threadId },
    });
    dispatch(prependThreadMessageList(threadId, list));
    return list;
  }
}

export const processIncomingMessage = (message: ChatMessageData): ThunkActionType => {
  return async (dispatch, getState) => {
    dispatch(setIncomingMessage(message));
    const { threadList } = getState().chat;
    let thread = _.find(threadList, { _id: message.thread_id });
    if (!thread) {
      thread = await client.get<ChatThreadData>('/im/threads/:threadId', {
        params: { threadId: message.thread_id },
      });
    }
    dispatch(updateThread({
      ...thread,
      dateUpdated: message.dateCreated,
    }));
  }
}

export const fetchContactListRemote = (): ThunkActionType<ChatContact[]> => {
  return async (dispatch) => {
    const list = await client.get<ChatContact[]>('/im/contacts');
    dispatch(setContactList(list));
    return list;
  }
}
