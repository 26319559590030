import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { RouteComponentProps, Route, Switch } from 'react-router';
import { Typography, Breadcrumbs, Link } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import MaterialTable, { Column, Query, QueryResult, Options } from 'material-table';

import { __, intl } from '../../utils/intl';
import { DossierTemplateData } from '../../types/dossier.template';
import { RootState } from '../../store/reducers';
import client, { ClientError } from '../../store/client';
import { ListQueryResult } from '../../types/base';
import { parseListQuery, parseListQueryResult, localization } from '../../utils/material-table';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import {
  mdiPauseCircle,
  mdiCheckCircle,
  mdiAccountMultiple,
  mdiFolderOpen,
} from '@mdi/js';
import Confirm from '../Common/Confirm';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    }
  },
  actions: {
    marginBottom: theme.spacing(1),
  },
});

interface StateProps {
}

interface DispatchProps {
}

interface OwnProps {
}

interface OwnProps {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithSnackbarProps &
  WithStyles<typeof styles>;

interface State {
  selectedItem: DossierTemplateData | null;
  updateData: Partial<DossierTemplateData> | null;
  updateTip: string | null;
}

const columns: Column<DossierTemplateData>[] = [
  {
    field: 'name',
    title: __('dossierTemplate.field.name'),
  },
  {
    field: 'description',
    title: __('dossierTemplate.field.description'),
  },
  {
    field: 'dateCreated',
    title: __('common.field.dateCreated'),
    render: (doc) => intl.formatTime(doc.dateCreated, 'L LTS'),
  },
  {
    field: 'datePublished',
    title: __('dossierTemplate.field.datePublished'),
    render: (doc) => intl.formatTime(doc.datePublished, 'L LTS'),
  },
]

const tableOptions: Options = {
  pageSize: 10,
  pageSizeOptions: [10, 20, 50],
  exportButton: true,
}

class AdminDossierTemplates extends React.Component<Props, State> {

  tableRef = React.createRef<MaterialTable<DossierTemplateData>>();
  state: State = {
    selectedItem: null,
    updateData: null,
    updateTip: null,
  }

  fetchData = async (query: Query<DossierTemplateData>): Promise<QueryResult<DossierTemplateData>> => {
    const result = await client.get<ListQueryResult<DossierTemplateData>>('/admin/dossier-templates', {
      query: {
        ...parseListQuery(query),
      },
    });
    return parseListQueryResult(result);
  }

  async handleUpdate(dossierTemplate: DossierTemplateData, data: Partial<DossierTemplateData>) {
    let updateTip = '';
    const name = dossierTemplate.name;
    if (!_.isUndefined(data.isPublished)) {
      updateTip = data.isPublished
        ? __('admin.dossierTemplates.setPublished.tip', { name })
        : __('admin.dossierTemplates.setUnpublished.tip', { name });
    } else {
      return;
    }
    this.setState({
      selectedItem: dossierTemplate,
      updateData: data,
      updateTip,
    });
  }

  handleUpdateConfirm = async () => {
    const { enqueueSnackbar } = this.props;
    const { selectedItem, updateData } = this.state;
    if (!selectedItem) return;
    try {
      await client.put('/admin/dossier-templates/:dossierTemplateId', {
        params: { dossierTemplateId: selectedItem._id },
        data: updateData,
      });
      this.refreshData();
    } catch (err) {
      if (err instanceof ClientError && err.status >= 400) {
        enqueueSnackbar(err.message, { variant: 'error', autoHideDuration: 5000 });
      }
    }
    this.setState({ selectedItem: null });
  }

  handleViewDetail = async (event: React.MouseEvent, dossierTemplate: DossierTemplateData | DossierTemplateData[]) => {
    alert(__('lab.comingSoon'));
  }

  refreshData() {
    const { current: table } = this.tableRef;
    if (table) {
      (table as any).onQueryChange();
    }
  }

  render() {
    const { classes, history } = this.props;
    const { selectedItem, updateTip } = this.state;
    const title = __('admin.nav.dossierTemplates');
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Breadcrumbs>
            <Link color="inherit" onClick={() => history.push('/admin')}>{__('nav.adminPanel')}</Link>
            <Typography color="textPrimary">{title}</Typography>
          </Breadcrumbs>
        </div>
        <MaterialTable<DossierTemplateData>
          tableRef={this.tableRef}
          localization={localization}
          title={title}
          columns={columns}
          data={this.fetchData}
          options={tableOptions}
          actions={[
            {
              icon: 'refresh',
              tooltip: __('common.refresh'),
              isFreeAction: true,
              onClick: () => this.refreshData(),
            },
            (dossierTemplate) => ({
              icon: () => <SimpleSvgIcon color="primary" path={mdiFolderOpen} />,
              tooltip: __('admin.dossierTemplates.viewDetail'),
              onClick: this.handleViewDetail,
            }),
            (dossierTemplate) => ({
              icon: () => (
                <SimpleSvgIcon
                  color={dossierTemplate.isPublished ? 'primary' : 'disabled'}
                  path={dossierTemplate.isPublished ? mdiCheckCircle : mdiPauseCircle}
                />
              ),
              tooltip: dossierTemplate.isPublished ? __('admin.dossierTemplates.setUnpublished') : __('admin.dossierTemplates.setPublished'),
              onClick: () => this.handleUpdate(dossierTemplate, { isPublished: !dossierTemplate.isPublished }),
            }),
          ]}
        />
        <Confirm
          open={Boolean(selectedItem)}
          content={updateTip}
          onConfirm={this.handleUpdateConfirm}
          onClose={() => this.setState({ selectedItem: null })}
        />
      </div>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withSnackbar,
)(AdminDossierTemplates);
