import { DocumentTemplateData } from '../../../types/document.template';
import { KeyValueMap } from '../../../types/common';

export const documentTemplates: KeyValueMap<DocumentTemplateData> = {
  'Cover': require('./cover.json'),
  'Power of Attorney': require('./powerOfAttorney.json'),
  'Application for Arbitration': require('./applicationForArbitration.json'),
  'Evidence: Loan Receipt': require('./evidence1.json'),
  'Contracts': require('./contract.json'),
};
