import _ from 'lodash';
import { IBaseData } from './base';
import { FileData } from './file';
import { KeyValueMap } from './common';
import { DocumentFlags } from './document.template';

export interface PageContent {
  _id: string;
  type: 'html' | 'image' | 'pdf-page';
  content?: string;
  file_id?: string;
  pageNumber?: number;
  file?: FileData;
}

export interface DocumentBase {
  fileType: 'empty' | 'html' | 'docx' | 'pdf' | 'image-list';
  name: string;
  file_id?: string;
  content?: string;
  template?: string;
  pages?: PageContent[];
}

export interface DocVersion extends DocumentBase  {
  version: number;
  date: Date;
}

export interface DocMeta {
  source?: string;
  date?: Date;
  origin?: boolean;
}

export interface DocumentData extends IBaseData, DocumentBase {
  __type?: 'Document';
  user_id?: string;
  dossier_id?: string;
  document_template_id?: string;
  parent_id: string | null;
  type: 'file' | 'folder';
  isRoot?: boolean;
  childIndex: number;
  map?: KeyValueMap;
  version?: number;
  versions?: DocVersion[];
  dateCreated?: Date;
  dateModified?: Date;
  metadata?: DocMeta;
  flags?: DocumentFlags;
}

export interface DocumentPublicData extends IBaseData {
  name: string;
  dossier_id: string;
}

export interface WithFlags {
  flags?: DocumentFlags
}

export const hasFlag = (item: WithFlags | null | undefined, flag: keyof DocumentFlags): boolean => {
  return _.get(item, ['flags', flag]) || false;
}

export const hasFlagIteratee = (flag: keyof DocumentFlags) => (item: WithFlags) => hasFlag(item, flag);
