import _ from 'lodash';
import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { OrganizationProfile } from '../../types/contact.organization';
import { FormItem } from '../../types/form';
import Form from '../Form/FormControl';

const fields: FormItem[] = [
  { key: 'name', label: __('organization.field.name'), type: 'text', required: true },
  { key: 'legalRep', label: __('organization.field.legalRep'), type: 'text' },
  { key: 'creditCode', label: __('organization.field.creditCode'), type: 'text' },
  { key: 'province', label: __('organization.field.province'), type: 'text' },
  { key: 'address', label: __('organization.field.address'), type: 'text' },
];

const styles = (theme: Theme) => createStyles({
  root: {
  },
});

interface OwnProps {
  title?: string;
  data: OrganizationProfile | null;
  readOnly: boolean;
  onChange: (data: OrganizationProfile) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class OrganizationForm extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    data: {},
    readOnly: false,
    onChange: () => {},
  }

  render() {
    const { classes, data, readOnly, onChange } = this.props;
    return (
      <div className={classes.root}>
        {data &&
          <Form
            readOnly={readOnly}
            items={fields}
            value={data}
            onChange={onChange}
          />
        }
      </div>
    );
  }
}

export default withStyles(styles)(OrganizationForm);
