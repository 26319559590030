import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { DocumentData } from '../../types/document';
import { DossierData } from '../../types/dossier';
import { TreeItem } from '../../types/tree';
import client from '../../store/client';
import { RootState } from '../../store/reducers';
import { PanelStatus } from '../../store/ui/reducers';
import { updateListItem } from '../../store/document/actions';
import ResponsiveDialog from '../Common/ResponsiveDialog';
import Loading from '../Common/Loading';
import Document from './Document';
import ScrollView from './ScrollView';

const styles = (theme: Theme) => createStyles({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialogContent: {
    padding: 0,
  },
});

interface StateProps {
  dossier: DossierData | null;
  documentList: DocumentData[];
}

interface DispatchProps {
  updateListItem: (data: DocumentData) => void;
}

interface OwnProps {

}

interface Params {
  documentId: string;
  version?: string;
}

export type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

interface State {
  tree: TreeItem | null;
}

class DocumentViewDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      tree: null,
    };
    this.loadData(props);
  }
  
  async loadData(props: Props) {
    const { dossier, updateListItem, match: { params: { documentId, version } } } = props;
    const dossierUrl = dossier ? `/dossiers/${dossier._id}` : ''
    const versionUrl = version ? `/versions/${version}` : '';
    const data = await client.get<DocumentData>(`${dossierUrl}/documents/${documentId}${versionUrl}`);
    updateListItem(data);
    const tree: TreeItem = {
      _id: data._id,
      name: data.name,
      childIndex: 0,
      type: 'file',
    }
    this.setState({ tree });
  }

  render() {
    const { history, documentList } = this.props;
    const { tree } = this.state;
    const document = tree ? _.find(documentList, { _id: tree._id }) : null;
    return (
      <ResponsiveDialog
        open
        fullScreen
        withoutContentContainer
        title={`${__('documentViewDialog.title')} - ${__('document.version')} ${document ? document.version : '...'}`}
        onClose={() => history.goBack()}
      >
        {tree && document ?
          <ScrollView
            onlyDocument
            id={`document-preview`}
            tree={tree}
            selectedItem={null}
            onSelectedItemChange={() => {}}
          />
          :
          <Loading />
        }
      </ResponsiveDialog>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
  dossier: states.dossier.current,
  documentList: states.document.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  updateListItem: (data) => dispatch(updateListItem(data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DocumentViewDialog);
