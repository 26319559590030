import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

import { __ } from '../../utils/intl';
import UserForm from './UserForm';
import client, { ClientError } from '../../store/client';
import { parseValidationError } from '../../utils/error-parser';
import { RootState } from '../../store/reducers';
import { UserData } from '../../types/user';
import { setCurrentUser } from '../../store/user/actions';

interface StateProps {
}

interface DispatchProps {
  setCurrentUser: (user: UserData) => void;
}

interface OwnProps {};

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

interface FormFields {
  username: string;
  password: string;
  rememberChecked: boolean;
}

interface FieldError {
  username: string | null;
  password: string | null;
}

interface State extends FormFields {
  errors: FieldError,
}

class Login extends React.Component<Props, State> {

  state: State = {
    username: '',
    password: '',
    rememberChecked: false,
    errors: {
      username: null,
      password: null,
    },
  }

  setErrors<K extends keyof FieldError>(errors: Pick<FieldError, K>) {
    this.setState(({ errors: _errors }) => ({
      errors: {
        ..._errors,
        ...errors,
      },
    }));
  }

  clearErrors(fields: (keyof FieldError)[]) {
    const { errors } = this.state;
    const newErrors: Partial<FieldError> = {};
    fields.forEach(field => {
      newErrors[field] = null;
    });
    this.setState({
      errors: {
        ...errors,
        ...newErrors,
      },
    })
  }

  handleChange<K extends keyof State>(newState: Pick<State, K>) {
    if ('username' in newState) {
      this.clearErrors(['username']);
    }
    if ('password' in newState) {
      this.clearErrors(['password']);
    }
    this.setState(newState);
    
  }

  async handleSubmit() {
    const { history, setCurrentUser } = this.props;
    const { username, password, rememberChecked } = this.state;
    const data = {
      username,
      password,
      client_id: process.env.REACT_APP_CLIENT_ID,
    };
    try {
      const { token, user } = await client.post('/account/login', { data });
      client.setToken(token.token, rememberChecked);
      setCurrentUser(user);
      history.push('/dashboard');
    } catch (err) {
      if (err instanceof ClientError) {
        const result = parseValidationError(err);
        this.setErrors(result as any);
      }
    }
  }

  render() {
    const { username, password, rememberChecked, errors } = this.state;
    return (
      <UserForm
        className="account-register"
        title={__('account.login')}
      >
        <main className="login-form">
          <TextField
            error={!!errors.username}
            autoComplete="username email mobile"
            className="form-control"
            label={__('account.username')}
            onChange={event => this.handleChange({ username: event.target.value })}
            value={username}
            helperText={errors.username}
          />
          <TextField
            error={!!errors.password}
            autoComplete="current-password"
            className="form-control"
            type="password"
            label={__('account.password')}
            onChange={event => this.handleChange({ password: event.target.value })}
            value={password}
            helperText={errors.password}
          />
          <FormControl
            className="form-control"
          >
            <FormControlLabel
              label={__('account.rememberMe')}
              control={
                <Checkbox
                  color="primary"
                  checked={rememberChecked}
                  onChange={event => this.handleChange({ rememberChecked: event.target.checked })}
                />
              }
            />
            <FormHelperText>{__('account.rememberMeTip')}</FormHelperText>
          </FormControl>
          <Button
            className="form-control"
            variant="contained"
            color="primary"
            onClick={() => this.handleSubmit()}
          >
            {__('account.login')}
          </Button>
          <div className="tips">
            <p>
              {__('account.doNotHaveAccount')}
              {' '}
              <Link to="/sign-up">
                {__('account.signUp')}
              </Link>
            </p>
            <p>
              {__('account.troubleLogin')}
              {' '}
              <Link to="/retrieve-account">
                {__('account.retrieveAccount')}
              </Link>
            </p>
          </div>
        </main>
      </UserForm>
    )
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(Login);
