import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  TextField,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import UserForm from './UserForm';
import client, { ClientError } from '../../store/client';
import { parseValidationError } from '../../utils/error-parser';
import { RootState } from '../../store/reducers';
import { UserData } from '../../types/user';
import { setCurrentUser } from '../../store/user/actions';
import PrivacyPolicy from '../Common/PopupPage'
import TermsPopup, { TermsType } from './TermsPopup';

const styles = (theme: Theme) => createStyles({
  smsBox: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  smsInput: {
    flexGrow: 1,
    height: 40
  },
  smsBtn: {
    flexShrink: 0,
    marginLeft: 5,
  },
  privacyPolicy: {
    marginTop: 10
  }
})
interface StateProps {
}

interface DispatchProps {
  setCurrentUser: (user: UserData) => void;
}

interface OwnProps {};

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithStyles<typeof styles>;


interface FormFields {
  username: string;
  password: string;
  smsCode: string;
  agreementChecked: boolean;
}

interface FieldError {
  username: string | null;
  password: string | null;
  sms: string | null;
  agreementChecked: string | null;
}

interface State extends FormFields {
  termsPageType: TermsType;
  errors: FieldError,
  smsSentTimeout: number;
}


class SignUp extends React.Component<Props, State> {
  interval?: number = 0
  state: State = {
    termsPageType: undefined,
    username: '',
    password: '',
    smsCode: '',
    agreementChecked: false,
    smsSentTimeout: 0,
    errors: {
      username: null,
      password: null,
      sms: null,
      agreementChecked: null,
    },
  }

  setErrors<K extends keyof FieldError>(errors: Pick<FieldError, K>) {
    this.setState(({ errors: _errors }) => ({
      errors: {
        ..._errors,
        ...errors,
      },
    }));
  }

  clearErrors(fields: (keyof FieldError)[]) {
    const { errors } = this.state;
    const newErrors: Partial<FieldError> = {};
    fields.forEach(field => {
      newErrors[field] = null;
    });
    this.setState({
      errors: {
        ...errors,
        ...newErrors,
      },
    })
  }

  handleChange<K extends keyof State>(newState: Pick<State, K>) {
    if ('username' in newState) {
      this.clearErrors(['username']);
    }
    if ('password' in newState) {
      this.clearErrors(['password']);
    }
    if ('agreementChecked' in newState) {
      this.clearErrors(['agreementChecked']);
    }
    this.setState(newState);
    
  }

  async handleSubmit() {
    const { history, setCurrentUser } = this.props;
    const { username, password, agreementChecked, smsCode } = this.state;
    if (!agreementChecked) {
      this.setErrors({ agreementChecked: __('account.agreementTip') });
      return;
    }
    const data = {
      username,
      password,
      smsCode,
      client_id: process.env.REACT_APP_CLIENT_ID,
    };
    try {
      const { token, user } = await client.post('/account/sign-up', { data });
      client.setToken(token.token);
      setCurrentUser(user);
      history.push('/dashboard');
    } catch (err) {
      if (err instanceof ClientError) {
        const result = parseValidationError(err);
        this.setErrors(result as any);
      }
    }
  }

  handleOpenTermsPage(event: React.MouseEvent<HTMLAnchorElement>, type: TermsType) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ termsPageType: type });
  }

  handleSendSmsVerifyCode = async () => {
    try {
      
      await client.post('/account/sms/request', {
        data: {
          username: this.state.username
        }
      });
      this.setState({ smsSentTimeout: 59 })
      this.interval = window.setInterval(() => {
        if (this.state.smsSentTimeout === 0) {
          return window.clearInterval(this.interval)
        }
        this.setState({ smsSentTimeout: this.state.smsSentTimeout - 1 })
      }, 1000);
    } catch (error) {
      console.log('send sms error')
      console.error(error)
    }
  }

  render() {
    const {
      termsPageType,
      username,
      password,
      smsCode,
      agreementChecked,
      errors,
      smsSentTimeout
    } = this.state;
    const { classes } = this.props;
    return (
      <UserForm
        termsPageType={termsPageType}
        onTermsPageClose={() => this.setState({ termsPageType: undefined })}
        className="account-sign-up"
        title={__('account.signUp')}
      >
        <main className="sign-up-form">
          <TextField
            error={!!errors.username}
            autoComplete="username mobile"
            className="form-control"
            label={__('account.username')}
            onChange={event => this.handleChange({ username: event.target.value })}
            value={username}
            helperText={errors.username}
          />
          <TextField
            error={!!errors.password}
            autoComplete="new-password"
            className="form-control"
            type="password"
            label={__('account.password')}
            onChange={event => this.handleChange({ password: event.target.value })}
            value={password}
            helperText={errors.password}
          />
          <div className={classes.smsBox}>
            <TextField
              error={!!errors.sms}
              className={`${classes.smsInput} form-control`}
              label={__('account.smsCode')}
              onChange={event => this.handleChange({ smsCode: event.target.value })}
              value={smsCode}
              helperText={errors.sms}
            />
            <Button
              color="secondary"
              className={classes.smsBtn}
              onClick={this.handleSendSmsVerifyCode}
              variant="outlined"
              disabled={!!(smsSentTimeout || !username)}
            >
              {__('account.sendSmsToVerify') + (smsSentTimeout !== 0 ? `(${smsSentTimeout})` : '')}
            </Button>
          </div>
          <FormControl
            className="form-control"
            error={!!errors.agreementChecked}
          >
            <FormControlLabel
              className={classes.privacyPolicy}
              label={
                <div className="tips">
                  {__('account.agreement')}
                  <a
                    onClick={(event) => this.handleOpenTermsPage(event, 'privacy-policy')}
                  >
                    {__('account.privacyPolicy')}
                  </a>
                  ,{' '}
                  <a
                    onClick={(event) => this.handleOpenTermsPage(event, 'terms-of-service')}
                  >
                    {__('account.termsOfService')}
                  </a>
                </div>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={agreementChecked}
                  onChange={event => this.handleChange({ agreementChecked: event.target.checked })}
                />
              }
            />
            <FormHelperText>{errors.agreementChecked}</FormHelperText>
          </FormControl>
          <Button
            className="form-control"
            variant="contained"
            color="primary"
            onClick={() => this.handleSubmit()}
          >
            {__('account.signUp')}
          </Button>
          <div className="tips">
            <p>
              {__('account.alreadyHaveAccount')}
              {' '}
              <Link to="/login">
                {__('account.login')}
              </Link>
            </p>
          </div>
        </main>
      </UserForm>
    )
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SignUp);
