import _ from 'lodash';
import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v4';
import { observer } from 'mobx-react';
import Cropper from 'cropperjs';
import { RouteComponentProps } from 'react-router';
import { Paper, IconButton, Checkbox, FormControlLabel, InputBase, Tooltip, Typography, TextField } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { IconButtonProps } from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Selection from '@simonwep/selection-js';

import { AnnotationData } from '../../types/annotation';
import { DossierData } from '../../types/dossier';
import { DocumentData } from '../../types/document';
import { EvidenceReference } from '../../types/fact';
import client from '../../store/client';
import { __ } from '../../utils/intl';
import { withLocalReaktor } from '../../utils/reaktor';
import { AnnotationStore } from '../../storex/annotation';
import AnnotationReference from './AnnotationReference';
import ClaimList from './ClaimList';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(2, 1),
    background: '#f9f9f9',
    padding: theme.spacing(1, 2),
  },
  topActions: {
    display: 'flex',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  inTocCheckbox: {
    flexGrow: 1,
  },
  selected: {
    background: '#fff',
  },
  label: {
    fontSize: 14,
    color: '#999',
  },
  referenceList: {
    margin: theme.spacing(1, 0),
  },
  referenceListActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  claimList: {

  },
});

interface OwnProps {
  dossier: DossierData;
  document: DocumentData;
  annotation: AnnotationData;
  annotationIndex: number;
  resetInstances: () => void;
  getCropperInstance: (selectedTarget?: HTMLImageElement | HTMLCanvasElement, options?: Cropper.Options) => Cropper;
  getSelectionInstance: () => Selection;
}

interface State extends AnnotationData {
}

interface Params {
  documentId: string;
  dossierId: string;
}

export type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & WithStyles<typeof styles>;

@withLocalReaktor('annotationsData')
@observer
class AnnotationEditor extends React.Component<Props, State> {

  state = this.props.annotation;
  annotationsData!: AnnotationStore;
  
  componentWillReceiveProps(nextProps: Props) {
    const { annotation } = nextProps;
    if (annotation._id !== this.state._id) {
      this.state = annotation;
    }
  }

  dataReady() {
    const { dossier, document } = this.props;
    return (dossier && document);
  }

  getApiUrl(creating = false) {
    const { dossier, document, annotation } = this.props;
    if (dossier && document) {
      return `/dossiers/${dossier._id}/documents/${document._id}/annotations${creating ? '' : `/${annotation._id}`}`;
    } else {
      return '';
    }
  }

  createAnnotation = async (data: Partial<AnnotationData>) => {
    await client.post(this.getApiUrl(true), { data });
  }

  updateAnnotation = async (data: Partial<AnnotationData>) => {
    await client.put(this.getApiUrl(), { data });
  }

  removeAnnotation = async () => {
    const { annotationsData } = this;
    const { annotationIndex } = this.props;
    if (!this.dataReady()) return;
    annotationsData.annotations.splice(annotationIndex, 1);
    await client.delete(this.getApiUrl());
  }

  setCurrentAnnotationId = (annotationId: string | null) => {
    const { annotationsData } = this;
    annotationsData.currentAnnotationId = annotationId;
  }

  toggleInToc = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.dataReady()) return;
    const inToc = event.target.checked;
    this.state.inToc = event.target.checked;
    await client.put(this.getApiUrl(), {
      data: { inToc },
    });
  }

  renderInToc() {
    const { classes } = this.props;
    const { inToc } = this.state;
    return (
      <FormControlLabel
        className={classes.inTocCheckbox}
        control={<Checkbox size="small" onChange={this.toggleInToc} checked={inToc} />}
        label={__('annotationEditor.includeInToc')}
      />
    )
  }

  createReference = async () => {
    const { annotation } = this.props;
    await this.createAnnotation(annotation);
  }
  
  handleCreateReference = () => {
    const { annotation } = this.props;
    const data: EvidenceReference = {
      _id: uuid(),
      inToc: false,
      type: 'selection',
      ocrText: '',
      comment: '',
    }
    annotation.references.push(data);
    this.createReference();
  }

  handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { annotation } = this.props;
    annotation.comment = event.target.value;
  }

  handleCommentSave = () => {
    const { annotation } = this.props;
    this.setCurrentAnnotationId(null);
    this.updateAnnotation({
      comment: annotation.comment,
    });
  }

  handleClaimChange = (claimIds: string[]) => {
    const { annotation } = this.props;
    annotation.claim_ids = claimIds;
    this.updateAnnotation({
      claim_ids: claimIds,
    });
  }

  render() {
    const { annotationsData } = this;
    const {
      classes,
      dossier,
      document,
      annotation,
      getSelectionInstance,
      getCropperInstance,
      resetInstances,
    } = this.props;
    const selected = annotationsData.currentAnnotationId === annotation._id;
    const iconButtonProps: Partial<IconButtonProps> = {
      size: 'small',
      className: classes.iconButton,
    }
    return (
      <Paper className={clsx(classes.root, { [classes.selected]: selected})}>
        {annotation.type === 'simple' ?
          <>
            {this.renderInToc()}
            <InputBase
              placeholder={__('annotationEditor.simpleCommentPlaceholder')}
              onFocus={() => this.setCurrentAnnotationId(this.props.annotation._id)}
              value={annotation.comment}
              onChange={this.handleCommentChange}
              onBlur={this.handleCommentSave}
            />
          </>
          :
          <>
            <div className={classes.topActions}>
              {this.renderInToc()}
              <Tooltip title={__('annotationEditor.removeAnnotation')}>
                <IconButton {...iconButtonProps} onClick={this.removeAnnotation}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Typography className={classes.label}>{__('annotationEditor.evidenceContents')}</Typography>
            <div className={classes.referenceList}>
              {annotation.references.map((reference, referenceIndex) => (
                <AnnotationReference
                  key={reference._id}
                  dossier={dossier}
                  document={document}
                  annotation={annotation}
                  reference={reference}
                  referenceIndex={referenceIndex}
                  getSelectionInstance={getSelectionInstance}
                  getCropperInstance={getCropperInstance}
                  resetInstances={resetInstances}
                />
              ))}
            </div>
            <div className={classes.referenceListActions}>
              <Tooltip title={__('annotationEditor.addReference')}>
                <IconButton {...iconButtonProps} onClick={this.handleCreateReference}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.claimList}>
              <Typography className={classes.label}>{__('annotationEditor.claims')}</Typography>
            </div>
            <ClaimList
              claimIds={annotation.claim_ids}
              onChange={this.handleClaimChange}
            />
          </>
        }
      </Paper>
    );
  }
}

export default withStyles(styles)(AnnotationEditor);
