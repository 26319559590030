import _ from 'lodash';
import { Action } from './actions';
import { DossierData } from '../../types/dossier';

export interface DossierState {
  current: DossierData | null;
  list: DossierData[];
  loading: boolean;
  error?: Error;
}

const initialState: DossierState = {
  current: null,
  list: [],
  loading: false,
}

const dossierReducer = (state: DossierState = initialState, action: Action): DossierState => {
  switch (action.type) {
    case '@@dossier/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@dossier/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@dossier/SET_LIST':
      return { ...state, list: action.list };

    case '@@dossier/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }
    case '@@dossier/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    case '@@dossier/CREATE_ITEM_START':
      return { ...state, loading: true }
    case '@@dossier/CREATE_ITEM_SUCCESS':
      return { ...state, loading: false, current: action.item }
    case '@@dossier/CREATE_ITEM_FAILURE':
      return { ...state, loading: false, error: action.error}
    default:
      return state;
  }
}

export default dossierReducer;
