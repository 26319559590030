import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { List, Divider, IconButton, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';

import {
  mdiUnfoldLessVertical,
  mdiUnfoldMoreVertical,
} from '@mdi/js';

import { __ } from '../../utils/intl';
import { ChatContact, ThreadOptions, ChatThreadData } from '../../types/chat';
import { RootState } from '../../store/reducers';
import { togglePanel, togglePanelMinified } from '../../store/ui/actions';
import { PanelStatus, PanelSide } from '../../store/ui/reducers';
import { threadHasUnreadMessage } from '../../store/chat/reducers';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import Sidebar from '../Common/Sidebar';
import ContactList from './ContactList';
import {
  getThreadRemote,
  fetchContactListRemote,
  fetchThreadListRemote,
} from '../../store/chat/actions';
import { UserData } from '../../types/user';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flexGrow: 1,
    color: grey[700],
    fontSize: '14px',
    padding: '19px 16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease-in-out',
  },
  minifiedTitle: {
    flexShrink: 1,
    padding: '19px 0',
  },
  minifyButton: {
    margin: 4,
  },
  contactList: {
    flexGrow: 1,
    overflow: 'auto',
  },
  actions: {
    margin: theme.spacing(1),
  },
});

interface StateProps {
  currentUser: UserData | null;
  panelStatus: PanelStatus;
  threadList: ChatThreadData[];
  contactList: ChatContact[];
}

interface DispatchProps {
  togglePanel: (side: PanelSide, status?: boolean) => void;
  togglePanelMinified: (side: PanelSide, status?: boolean) => void;
  getThreadRemote: (options: ThreadOptions) => Promise<ChatThreadData>;
  fetchThreadListRemote: () => Promise<ChatThreadData[]>;
  fetchContactListRemote: () => Promise<ChatContact[]>;
}

interface OwnProps { }

interface OwnProps {
}

type Props = StateProps & DispatchProps & OwnProps & WithWidth & WithStyles<typeof styles>;

interface State {
  recentContactList: ChatContact[];
}

class ChatSidebar extends React.Component<Props, State> {

  state: State = {
    recentContactList: [],
  }

  async componentDidMount() {
    const { fetchThreadListRemote, fetchContactListRemote } = this.props;
    await fetchThreadListRemote();
    await fetchContactListRemote();
    this.updateRecentContact(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.threadList !== this.props.threadList) {
      this.updateRecentContact(nextProps);
    }
  }

  autoClose = () => {
    const { panelStatus, togglePanel } = this.props;
    if (!panelStatus.chat.pinned && panelStatus.chat.open) {
      togglePanel('chat', false);
    }
  }

  handleOpenChatWindow = async (event: React.MouseEvent, item: ChatContact) => {
    const { getThreadRemote } = this.props;
    if (!item.target) return;
    try {
      await getThreadRemote({
        to: item.target,
      });
    } catch (error) {
      console.log(item)
      console.error(error)
    }
  }

  updateRecentContact(props: Props) {
    this.setState({
      recentContactList: this.getRecentContactList(props),
    });
  }

  getRecentContactList(props: Props): ChatContact[] {
    const { currentUser, threadList } = props;
    if (!currentUser) return [];
    return _.sortBy(threadList, item => -item.dateUpdated.getTime()).map(thread => {
      const contact: ChatContact = {
        _id: thread._id,
        type: 'target',
      };
      // TODO Calculate the exact number of unread messages
      contact.unreadMessageCount = threadHasUnreadMessage(thread) ? 1 : 0;
      if (thread.type === 'target') {
        contact.target = thread.target;
      } else if (thread.type === 'pair') {
        const participant = thread.participants.find(item => item.user_id !== currentUser._id);
        if (participant) {
          contact.target = {
            type: 'user',
            user_id: participant.user_id,
            user: participant.user,
          };
        }
      }
      return contact;
    });
  }

  render() {
    const {
      classes,
      panelStatus,
      contactList,
      togglePanel,
      togglePanelMinified,
    } = this.props;
    const { recentContactList } = this.state;
    const status = panelStatus.chat;
    return (
      <Sidebar
        align="right"
        open={status.open}
        width={status.width}
        autoClose={!status.pinned}
        onClose={() => togglePanel('chat', false)}
        resizable={false}
      >
        <div className={classes.root}>
          <header className={classes.header}>
            <div className={clsx(classes.title, { [classes.minifiedTitle]: status.minified })}>
              {__('nav.chat')}
            </div>
            <IconButton
              className={classes.minifyButton}
              onClick={() => togglePanelMinified('chat')}
            >
              {status.minified ?
                <SimpleSvgIcon path={mdiUnfoldMoreVertical} />
                :
                <SimpleSvgIcon path={mdiUnfoldLessVertical} />
              }
            </IconButton>
          </header>
          <Divider />
          <ContactList
            className={classes.contactList}
            minified={status.minified || false}
            recentContactList={recentContactList}
            contactList={contactList}
            onOpenChatWindow={this.handleOpenChatWindow}
          />
        </div>
      </Sidebar>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
  panelStatus: states.ui.panelStatus,
  threadList: states.chat.threadList,
  contactList: states.chat.contactList,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  togglePanel: (side, status) => dispatch(togglePanel(side, status)),
  togglePanelMinified: (side, status) => dispatch(togglePanelMinified(side, status)),
  getThreadRemote: (options) => dispatch(getThreadRemote(options)),
  fetchContactListRemote: () => dispatch(fetchContactListRemote()),
  fetchThreadListRemote: () => dispatch(fetchThreadListRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ChatSidebar);
