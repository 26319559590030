import { UserData, UserProfile } from '../../types/user';
import { ThunkActionType, ThunkDispatchType } from '../types';
import client, { ClientError } from '../client';
import { history } from '../configureStore';

// Action Definition
export interface SetCurrentItem {
  type: '@@user/SET_CURRENT_ITEM';
  user: UserData;
}

export interface ClearCurrentItem {
  type: '@@user/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@user/SET_LIST';
  list: UserData[];
}

export interface UpdateListItem {
  type: '@@user/UPDATE_LIST_ITEM';
  item: UserData;
}

// Union Action Types
export type Action = SetCurrentItem | ClearCurrentItem | SetList | UpdateListItem;

// Action Creators
export const setCurrentUser = (item: UserData): SetCurrentItem => {
  return { type: '@@user/SET_CURRENT_ITEM', user: item };
}

export const clearUser = (): ClearCurrentItem => {
  return { type: '@@user/CLEAR_CURRENT_ITEM' };
}

export const updateUserList = (list: UserData[]): SetList => {
  return { type: '@@user/SET_LIST', list };
}

export const updateUser = (item: UserData): UpdateListItem => {
  return { type: '@@user/UPDATE_LIST_ITEM', item };
}

export const fetchCurrentUser = (): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const { user } = await client.get('/account/user');
    dispatch(setCurrentUser(user));
  }
}

export const logoutCurrentUser = (): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    await client.post('/account/logout');
    client.removeToken();
    history.replace('/login');
    dispatch(clearUser());
  }
}

export const updateUserProfileRemote = (data: UserProfile): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const user = await client.put<UserData>('/account/profile', { data });
    dispatch(setCurrentUser(user));
  }
}
