import _ from 'lodash';
import { MemberData, MemberIdentity } from '../../types/member';
import { ThunkActionType } from '../types';
import client from '../client';
import { RootState } from '../reducers';
import { InvitationOptions } from '../../types/invitation';
import { PublicUserInfo } from '../../types/user';

// Action Definition
export interface SetCurrentItem {
  type: '@@member/SET_CURRENT_ITEM';
  item: MemberData;
}

export interface ClearCurrentItem {
  type: '@@member/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@member/SET_LIST';
  list: MemberData[];
}

export interface UpdateListItem {
  type: '@@member/UPDATE_LIST_ITEM';
  item: MemberData;
}

export interface DeleteListItem {
  type: '@@member/REMOVE_LIST_ITEM';
  id: string;
}

// Union Action Types
export type Action = SetCurrentItem
  | ClearCurrentItem
  | SetList
  | UpdateListItem
  | DeleteListItem
  ;

// Action Creators
export const setItem = (item: MemberData): SetCurrentItem => {
  return { type: '@@member/SET_CURRENT_ITEM', item };
}

export const clearItem = (): ClearCurrentItem => {
  return { type: '@@member/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: MemberData[]): SetList => {
  return { type: '@@member/SET_LIST', list };
}

export const updateListItem = (item: MemberData): UpdateListItem => {
  return { type: '@@member/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@member/REMOVE_LIST_ITEM', id };
}

export function getBaseUrl(query: MemberIdentity) {
  switch (query.type) {
    case 'dossier':
      return `/dossiers/${query.dossier_id}`;
    case 'team':
      return `/teams/${query.team_id}`;
    default:
      throw new TypeError('invalid member type');
  }
}

export const fetchItemRemote = (query: MemberIdentity, memberId: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    const item = await client.get<MemberData>(`${baseUrl}/members/:memberId`, {
      params: { memberId },
    });
    dispatch(setItem(item));
  }
}

export const inviteMemberRemote = (query: MemberIdentity, options: InvitationOptions): ThunkActionType<MemberData> => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    const item = await client.post<MemberData>(`${baseUrl}/invite`, {
      data: options,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const updateItemRemote = (query: MemberIdentity, memberId: string, data: Partial<MemberData>): ThunkActionType<MemberData> => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    const item = await client.put<MemberData>(`${baseUrl}/members/:memberId`, {
      params: { memberId },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const removeItemRemote = (query: MemberIdentity, memberId: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    await client.delete(`${baseUrl}/members/:memberId`, {
      params: { memberId },
    });
    dispatch(removeListItem(memberId));
  }
}

export const fetchListRemote = (query: MemberIdentity): ThunkActionType<MemberData[]> => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    const list = await client.get<MemberData[]>(`${baseUrl}/members`);
    dispatch(setList(list));
    return list;
  }
}

export const createItemRemote = (query: MemberIdentity, data: Partial<MemberData>): ThunkActionType<MemberData> => {
  return async (dispatch, getState) => {
    const baseUrl = getBaseUrl(query);
    const item = await client.post<MemberData>(`${baseUrl}/members`, {
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const loadCollaboratorsRemote = (query: MemberIdentity): ThunkActionType<PublicUserInfo[]> => {
  return async () => {
    const result = await client.get<PublicUserInfo[]>('/account/collaborators', {
      query: query,
    });
    return result;
  }
}
