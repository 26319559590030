import uuid from 'uuid';
import _ from 'lodash';
import mime from 'mime';

import { KeyValueMap } from '../types/common';
import { DocumentData } from '../types/document';
import { FileData } from '../types/file';
import client from '../store/client';
import { __ } from './intl';

export async function getUploadUrl() {
  const { url } = await client.get('/drive/upload-url');
  return url;
}

export const fileTypeMap: KeyValueMap<string[]> = {
  pdf: [
    '.pdf',
  ],
  image: [
    '.jpg',
    '.png',
    '.gif',
  ],
  word2000: [
    '.doc',
    '.dot',
  ],
  wordx: [
    '.docx',
    '.dotx',
  ],
  word: [
    'word2000',
    'wordx',
  ],
  excel2000: [
    '.xls',
    '.xlt',
  ],
  excelx: [
    '.xlsx',
    '.xltx',
  ],
  excel: [
    'excel2000',
    'excelx',
  ],
  powerpoint2000: [
    '.ppt',
    '.ppa',
    '.pot',
  ],
  powerpointx: [
    '.pptx',
    '.potx',
    '.ppsx',
  ],
  powerpoint: [
    'powerpoint2000',
    'powerpointx',
  ],
  office2000: [
    'word2000',
    'excel2000',
    'powerpoint2000',
  ],
  officex: [
    'wordx',
    'excelx',
    'powerpointx',
  ],
  office: [
    'office2000',
    'officex',
  ],
  all: [
    'pdf',
    'image',
    'office',
  ],
  default: [
    'pdf',
    'image',
    'officex',
  ]
}

export const fileTypeTitles: KeyValueMap<string> = {
  pdf: __('file.types.pdf'),
  image: __('file.types.image'),
  word: __('file.types.word'),
  excel: __('file.types.excel'),
  powerpoint: __('file.types.powerpoint'),
  bin: __('file.types.bin'),
}

export function getExtension(filename: string): string {
  return filename.replace(/^.+?(\.\w+)$/, '$1');
}

export function getType(type: string): string | undefined {
  return _.findKey(fileTypeMap, types => types.includes(type));
}

export function getFileType(filename: string): string {
  const ext = getExtension(filename);
  let type = getType(ext);
  if (type) {
    if (type === 'image' || type === 'pdf') {
      return type;
    }
    type = getType(type);
  }
  return type || 'bin';
}

export function getFileTypeTitle(filename: string): string {
  const type = getFileType(filename);
  return fileTypeTitles[type] || fileTypeTitles.bin;
}

export function getExtensions(type: string, list: string[] = []): string[] {
  if (type.charAt(0) === '.') {
    list.push(type);
  } else {
    const found = fileTypeMap[type];
    if (found) {
      found.forEach(t => getExtensions(t, list));
    }
  }
  return list;
}

export function lookupTypes(type: string, toMime?: boolean): string[] {
  const list = getExtensions(type);
  if (toMime) {
    return list.map(item => mime.getType(item)).filter(item => item !== null) as string[];
  } else {
    return list;
  }
}

export function addAccept(list: string[], accept: string, toMime?: boolean) {
  if (accept.includes(',')) {
    accept.split(',').forEach(a => {
      addAccept(list, a);
    });
  } else {
    const types = lookupTypes(accept, toMime);
    types.forEach(type => list.push(type));
  }
}

export function getAcceptString(accept?: string | string[], toMime?: boolean) {
  const list: string[] = [];
  if (!accept) {
    return '*/*';
  } else if (_.isString(accept)) {
    addAccept(list, accept, toMime);
  } else if (_.isArray(accept)) {
    accept.forEach(item => {
      addAccept(list, item, toMime);
    })
  } else {
    throw new TypeError('invalid accept param');
  }
  return list.join(',');
}

export function getBasename(filename: string): string {
  if (!filename) return '';
  const part = filename.split('/');
  const last = _.last(part);
  return last ? last.replace(/\.\w+$/, '') : '';
}

export function createEditableDocument(data: Partial<DocumentData>) {
  return data;
}

export function createUploadedDocument(file: FileData, data: Partial<DocumentData>) {
  let document: Partial<DocumentData> = {
    _id: data._id,
    type: 'file',
    fileType: 'empty',
    // Name would be same as document template  (#116)
    // name: getBasename(file.filename),
    file_id: file._id,
    childIndex: data.childIndex || 0,
    document_template_id: data.document_template_id || undefined,
    parent_id: data.parent_id || null,
    flags: data.flags,
  }
  switch (file.contentType) {
    case 'image/png':
    case 'image/jpeg':
      document = {
        ...document,
        fileType: 'image-list',
        file_id: undefined,
        pages: [{
          _id: uuid.v4(),
          type: 'image',
          file_id: file._id,
        }],
      };
      break;
    case 'application/pdf':
      document = {
        ...document,
        fileType: 'pdf',
      };
      break;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    // case 'application/msword':
      document = {
        ...document,
        fileType: 'docx',
      };
      break;
    default:
      console.error('unsupported file type');
  }
  return document;
}
