import React from 'react';
import clsx from 'clsx';
import { Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { FormDefine } from '../../types/form';
import FormControl from '../Form/FormControl';
import { formItemSchema } from '../Form/schema';

import exampleSchema from './example/form';

const styles = (theme: Theme) => createStyles({
  dialogContent: {
    width: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 320,
    }
  },
  container: {
    padding: theme.spacing(2),
  }
});

interface OwnProps {
  className?: string;
  open: boolean;
  schema: FormDefine;
  onClose: () => void;
  onUpdate: (schema: FormDefine) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  schema: FormDefine;
}

class TemplateEditor extends React.Component<Props> {

  state: State = {
    schema: {},
  }

  handleUpdate() {
    const { onUpdate } = this.props;
    const { schema } = this.state;
    onUpdate(schema);
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { schema } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{__('template.formDialog.title')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl
            definitions={formItemSchema.definitions}
            items={formItemSchema.items}
            value={schema}
            onChange={value => this.setState({ schema: value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {__('dialog.cancel')}
          </Button>
          <Button color="primary" variant="contained" onClick={() => this.handleUpdate()}>
            {__('dialog.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

export default withStyles(styles)(TemplateEditor);
