import _ from 'lodash';
import React from 'react';
import uuid from 'uuid/v4';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, compose } from 'redux';
import { connect } from 'react-redux';
import mezr from 'mezr';
import { observer } from 'mobx-react';
import Cropper from 'cropperjs';
import { Button, Fab, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Selection from '@simonwep/selection-js';

import { AnnotationData } from '../../types/annotation';
import { DocumentData, DocMeta } from '../../types/document';
import { DossierData } from '../../types/dossier';
import { FormItem } from '../../types/form';
import { __ } from '../../utils/intl';
import { withLocalReaktor } from '../../utils/reaktor';
import client from '../../store/client';
import { RootState } from '../../store/reducers';
import { updateItemRemote } from '../../store/document/actions';
import { AnnotationStore } from '../../storex/annotation';
import Sidebar, { Props as SidebarProps } from '../Common/Sidebar';
import AnnotationEditor from './AnnotationEditor';
import FormControl from '../Form/FormControl';

import 'cropperjs/dist/cropper.min.css';
import '../Editor/EvidenceView.scss';

const styles = (theme: Theme) => createStyles({
  container: {
    display: 'flex',
    padding: '0 48px',
  },
  page: {
    margin: theme.spacing(4, 0),
  },
  fabAdd: {
    position: 'absolute',
    zIndex: 10,
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  annotations: {
    paddingBottom: theme.spacing(10),
  },
  overlayContainer: {
    transform: 'translate(-100%)',
  }
})

interface OwnProps extends SidebarProps {
  dossier: DossierData;
  document: DocumentData;
}

interface DispatchProps {
  updateDocumentRemote: (id: string, data: Partial<DocumentData>) => void
}

interface StateProps {

}

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

interface FlattenMetadata extends DocMeta {
  name: string;
}

interface State {
  value: FlattenMetadata;
}

const form: FormItem[] = [
  {
    key: 'name',
    label: __('evidence.name'),
    type: 'text',
  },
  {
    key: 'source',
    label: __('evidence.source'),
    type: 'text',
  },
  {
    key: 'date',
    label: __('evidence.collectionTime'),
    type: 'date',
  },
  {
    key: 'origin',
    label: __('evidence.isOriginal'),
    type: 'boolean',
  },
]

@withLocalReaktor('annotationsData')
@observer
class EvidenceSidebar extends React.Component<Props, State> {

  selection?: Selection;
  cropper?: Cropper;
  annotationsData!: AnnotationStore;

  state = {
    value: {
      name: this.props.document.name,
      ...this.props.document.metadata,
    },
  }

  updateRemote = async () => {
    const { dossier, document, updateDocumentRemote } = this.props;
    const { value: { name, ...metadata} } = this.state;
    const data: Partial<DocumentData> = {
      _id: document._id,
      name,
      metadata,
    };
    await updateDocumentRemote(dossier._id, data);
  }

  handleFormChange = (value: FlattenMetadata) => {
    this.setState({ value });
  }

  async componentDidMount() {
    const { annotationsData } = this;
    const { dossier, document } = this.props;
    const url = `/dossiers/${dossier._id}/documents/${document._id}/annotations`;
    const data = await client.get(url);
    annotationsData.annotations = data;
    if (!_.isEmpty(data)) return;
    this.handleCreateSimpleAnnotation();
  }

  componentWillUnmount() {
    const { annotationsData } = this;
    this.resetInstances();
    annotationsData.reset();
  }

  resetInstances = () => {
    if (this.selection) {
      this.selection.destroy();
      delete this.selection;
    }

    if (this.cropper) {
      this.cropper.destroy();
      delete this.cropper;
    }
  }

  preventRightClickEvent = (evt: MouseEvent | TouchEvent) => {
    return !(evt instanceof MouseEvent && evt.button === 2);
  }

  onSelectionStart = (evt: Selection.SelectionEvent) => {
    console.log('onSelectionStart', evt);
  }

  onSelectionStop = (evt: Selection.SelectionEvent) => {
    console.log('onSelectionStop', evt);
    const selectedTarget = evt.selectedElements[0] as HTMLImageElement;
    if (!selectedTarget) return;
    let [extraSpace] = getComputedStyle(selectedTarget.parentNode.parentNode.parentNode).margin.split('px');
    extraSpace = parseInt(extraSpace);

    const { _areaX1, _areaX2, _areaY1, _areaY2 } = evt.selection as any;
    const selectionX = Math.min(_areaX1, _areaX2);
    const selectionY = Math.min(_areaY1, _areaY2);
    const selectionWidth = Math.abs(_areaX1 - _areaX2);
    const selectionHeight = Math.abs(_areaY1 - _areaY2);

    const paper = (evt.selection as any)._targetContainer as HTMLImageElement;
    const overlay = document.createElement('div');
    overlay.style.position = 'absolute';
    overlay.style.zIndex = '999';
    overlay.style.left = Math.abs(paper.getBoundingClientRect().left - selectionX) + 'px';
    overlay.style.top = Math.abs(paper.getBoundingClientRect().top - (selectionY + extraSpace)) + 'px';
    overlay.style.width = selectionWidth + 'px';
    overlay.style.height = selectionHeight + 'px';
    overlay.style.background = 'rgba(0, 0, 255, .4)';
    // overlay.style.opacity = 0
    paper.appendChild(overlay);

    const { naturalWidth, naturalHeight, clientWidth, clientHeight } = selectedTarget;
    const ratioX = naturalWidth / clientWidth;
    const ratioY = naturalHeight / clientHeight;

    const intersection = mezr.intersection(selectedTarget, overlay);
    const croppedWidth = intersection.width * ratioX;
    const croppedHeight = intersection.height * ratioY;

    overlay.remove();

    // const canvas = document.createElement('canvas')
    // canvas.width = selectionWidth * ratioX
    // canvas.height = selectionHeight * ratioY
    // const ctx = canvas.getContext('2d')
    // ctx.drawImage(
    //   selectedTarget,
    //   Math.abs(selectedTarget.x * ratioX - intersection.left * ratioX),
    //   Math.abs((selectedTarget.y + window.scrollY) * ratioY - intersection.top * ratioY),
    //   croppedWidth,
    //   croppedHeight,
    //   0,
    //   0,
    //   croppedWidth,
    //   croppedHeight,
    // )
    // this.refs.preview.appendChild(canvas)
    this.getCropperInstance(selectedTarget, {
      data: {
        x: Math.abs(selectedTarget.x * ratioX - intersection.left * ratioX),
        y: Math.abs((selectedTarget.y + window.scrollY) * ratioY - intersection.top * ratioY),
        width: croppedWidth,
        height: croppedHeight,
        rotate: 0,
        scaleX: 1,
        scaleY: 1,
      },
      ready: () => {
        this.selection && this.selection.disable();
      },
    });
    // console.log(canvas.toDataURL())
  }

  getSelectionInstance = () => {
    return this.selection = this.selection || new Selection({
      class: 'selection-area',
      selectables: ['.paper1 img'],
      boundaries: ['.paper1'],
      selectionAreaContainer: '.fullscreen',
      validateStart: this.preventRightClickEvent,
      onStart: this.onSelectionStart,
      onStop: this.onSelectionStop,
    });
  }

  getCropperInstance = (selectedTarget?: HTMLImageElement | HTMLCanvasElement, options?: Cropper.Options) => {
    if (!this.cropper) {
      if (!selectedTarget) {
        throw new TypeError('cannot init cropper without target');
      }
      this.cropper = new Cropper(selectedTarget, {
        ...options,
        toggleDragModeOnDblclick: false,
        zoomable: false,
      });
    } else if (selectedTarget && options && options.data) {
      this.cropper.setData(options.data);
    }
    return this.cropper;
  }

  handleCreateAnnotation = () => {
    const { annotationsData } = this;
    const { dossier, document } = this.props;
    this.resetInstances();
    const currentAnnotationId = uuid();
    const annotation = {
      _id: currentAnnotationId,
      claim_ids: [],
      references: [],
      inToc: true,
      dossier_id: dossier._id,
      document_id: document._id,
      comment: '',
    }
    annotationsData.createAnnotation({ currentAnnotationId, annotation });
    this.createAnnotation(annotation);
  }

  handleCreateSimpleAnnotation = () => {
    const { annotationsData } = this;
    const { dossier, document } = this.props;
    this.resetInstances();
    const currentAnnotationId = uuid();
    const annotation: AnnotationData = {
      _id: currentAnnotationId,
      type: 'simple',
      claim_ids: [],
      references: [],
      inToc: false,
      dossier_id: dossier._id,
      document_id: document._id,
      comment: '',
    }
    annotationsData.createAnnotation({ currentAnnotationId, annotation });
    this.createAnnotation(annotation);
  }

  async createAnnotation(annotation: AnnotationData) {
    const { dossier, document } = this.props;
    const url = `/dossiers/${dossier._id}/documents/${document._id}/annotations`;
    await client.post(url, {
      data: annotation,
    });
  }

  render() {
    const { annotationsData } = this;
    const { classes, dossier, document, ...props } = this.props;
    const { value } = this.state;
    return (
      <Sidebar
        align="right"
        {...props}
      >
        <div style={{ padding: '12px' }}>
          <FormControl
            items={form}
            value={value}
            onChange={this.handleFormChange}
          />
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={this.updateRemote}
          >
            {__('dialog.save')}
          </Button>
        </div>
        <div className={classes.annotations}>
          <Tooltip title={__('annotationEditor.addAnnotation')}>
            <Fab color="secondary" className={classes.fabAdd} onClick={this.handleCreateAnnotation}>
              <AddIcon />
            </Fab>
          </Tooltip>
          {annotationsData.annotations.map((annotation, annotationIndex) => (
            <AnnotationEditor
              key={annotation._id}
              dossier={dossier}
              document={document}
              annotation={annotation}
              annotationIndex={annotationIndex}
              getSelectionInstance={this.getSelectionInstance}
              getCropperInstance={this.getCropperInstance}
              resetInstances={this.resetInstances}
             />
          ))}
        </div>
      </Sidebar>
    )
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  updateDocumentRemote: (id: string | null, data: Partial<DocumentData>) => dispatch(updateItemRemote(id, data))
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(EvidenceSidebar);
