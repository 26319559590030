import _ from 'lodash';
import { Action } from './actions';
import { ClaimData } from '../../types/claim';

export interface ClaimState {
  current: ClaimData | null;
  list: ClaimData[];
  loading: boolean;
  error?: Error;
}

const initialState: ClaimState = {
  current: null,
  list: [],
  loading: false,
}

const dossierReducer = (state: ClaimState = initialState, action: Action): ClaimState => {
  switch (action.type) {
    case '@@claim/SET_CURRENT_ITEM': 
      return { ...state, current: action.item };

    case '@@claim/CLEAR_CURRENT_ITEM':
      return { ...state, current: null };

    case '@@claim/SET_LIST':
      return { ...state, list: action.list };

    case '@@claim/UPDATE_LIST_ITEM':
      const index = _.findIndex(state.list, { _id: action.item._id });
      const list = [...state.list];
      if (index >= 0) {
        list[index] = action.item;
      } else {
        list.push(action.item);
      }
      return { ...state, list }
    case '@@claim/REMOVE_LIST_ITEM':
      return { ...state, list: _.reject(state.list, { _id: action.id }) };
    default:
      return state;
  }
}

export default dossierReducer;
