import _ from 'lodash';
import React from 'react';
import qs from 'qs';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Paper, Fab } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp, WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/Add';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { fetchListRemote } from '../../store/organization/actions';
import { ContactOrganizationData } from '../../types/contact.organization';
import OrganizationEditor from './OrganizationEditor';
import OrganizationListPartial from './OrganizationListPartial';

const styles = (theme: Theme) => createStyles({
  root: {
    // minHeight: 400,
    paddingBottom: 64,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 56px - 56px)',
    },
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
});

interface StateProps {
  current: ContactOrganizationData | null;
  list: ContactOrganizationData[];
}

interface DispatchProps {
  fetchListRemote: () => Promise<void>,
}

interface OwnProps {};

interface Params {
  organizationId: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithWidth & WithStyles<typeof styles>;

interface State {
}

class OrganizationList extends React.Component<Props, State> {

  componentDidMount() {
    const { fetchListRemote } = this.props;
    fetchListRemote();
  }

  render() {
    const { classes, width, history, list, location } = this.props;
    return (
      <Switch>
        <Route
          exact
          path="/contacts/organization"
          render={() => (
            <Paper
              className={classNames(classes.root, { hidden: location.pathname !== '/contacts/organization' })}
              square={isWidthDown('xs', width)}
            >
              <OrganizationListPartial
                hasFilter
                title={__('contacts.organizationList')}
                list={list}
              />
              <Fab
                className={classes.fab}
                color="primary"
                onClick={() => history.push('/contacts/organization/create')}
              >
                <AddIcon />
              </Fab>
            </Paper>
          )}
        />
        <Route path="/contacts/organization/create" component={OrganizationEditor} />
        <Route path="/contacts/organization/item/:organizationId" component={OrganizationEditor} />
      </Switch>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  current: states.organization.current,
  list: _.sortBy(states.organization.list, 'name'),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchListRemote: () => dispatch(fetchListRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(OrganizationList);
