import { DocumentTemplateData } from '../../../types/document.template';
import { KeyValueMap } from '../../../types/common';

export const documentTemplates: KeyValueMap<DocumentTemplateData> = {
  '民事卷宗封面': require('./民事卷宗封面.json'),
  '接收资料清单': require('./接收资料清单.json'),
  '开庭前沟通记录': require('./开庭前沟通记录.json'),
  '再审申请前沟通记录': require('./再审申请前沟通记录.json'),
  '收案沟通记录': require('./收案沟通记录.json'),
  '开庭后沟通记录': require('./开庭后沟通记录.json'),
  '主要负责人身份证明书': require('./主要负责人身份证明书.json'),
  '日常沟通记录': require('./日常沟通记录.json'),
  '一审立案前沟通记录': require('./一审立案前沟通记录.json'),
  '民事案件委托代理合同': require('./民事案件委托代理合同.json'),
  '返还资料清单': require('./返还资料清单.json'),
  '一审答辩前沟通记录': require('./一审答辩前沟通记录.json'),
  '法定代表人身份证明书': require('./法定代表人身份证明书.json'),
  '判决后沟通记录': require('./判决后沟通记录.json'),
  '二审上诉前沟通记录': require('./二审上诉前沟通记录.json'),
};
