import { FormDefine } from '../../types/form';
import { __ } from '../../utils/intl';


export const formItemSchema: FormDefine = {
  definitions: {
    formItem: {
      label: __('formSchema.item'), key: '', type: 'group', defaultExpanded: false,
      items: [
        {
          label: __('formSchema.condition'), key: 'condition', type: 'reference', optional: true,
          $ref: '#/definitions/conditions',
        },
        {
          label: __('formSchema.type'), key: 'type', type: 'select', defaultValue: 'text',
          options: [
            { label: __('formSchema.control.text'), value: 'text' },
            { label: __('formSchema.control.textarea'), value: 'textarea' },
            { label: __('formSchema.control.number'), value: 'number' },
            { label: __('formSchema.control.date'), value: 'date' },
            { label: __('formSchema.control.datetime'), value: 'datetime' },
            { label: __('formSchema.control.boolean'), value: 'boolean' },
            { label: __('formSchema.control.select'), value: 'select' },
            { label: __('formSchema.control.group'), value: 'group' },
            { label: __('formSchema.control.array'), value: 'array' },
            { label: __('formSchema.control.person'), value: 'person' },
            { label: __('formSchema.control.organization'), value: 'organization' },
          ]
        },
        { label: __('formSchema.label'), key: 'label', type: 'text', syncValueTo: 'key' },
        { label: __('formSchema.key'), key: 'key', type: 'text' },
        {
          label: __('formSchema.optionsType'), key: 'optionsType', type: 'select', defaultValue: 'pair',
          condition: { key: 'type', operator: '=', value: 'select' },
          options: [
            { label: __('formSchema.optionsType.pair'), value: 'pair' },
            { label: __('formSchema.optionsType.simple'), value: 'simple' },
            { label: __('formSchema.optionsType.remote'), value: 'remote' },
          ],
        },
        {
          label: __('formSchema.options'), key: 'options', type: 'reference',
          condition: {
            operator: 'AND', value: [
              { key: 'type', operator: '=', value: 'select' },
              { key: 'optionsType', operator: 'IN', value: [undefined, 'pair'] },
            ]
          },
          $ref: '#/definitions/pairSelectOptions',
        },
        {
          label: __('formSchema.options'), key: 'options', type: 'reference',
          condition: {
            operator: 'AND', value: [
              { key: 'type', operator: '=', value: 'select' },
              { key: 'optionsType', operator: '=', value: 'simple' },
            ]
          },
          $ref: '#/definitions/simpleSelectOptions',
        },
        {
          label: __('formSchema.remoteSettings'), key: '', type: 'reference',
          condition: {
            operator: 'AND', value: [
              { key: 'type', operator: '=', value: 'select' },
              {
                operator: 'OR', value: [
                  { key: 'optionsType', operator: '=', value: 'remote' },
                  { key: 'control', operator: '=', value: 'autocomplete' },
                ]
              },
            ]
          },
          $ref: '#/definitions/remoteSelectOptions',
        },
        {
          label: __('formSchema.listItem'), key: 'item', type: 'reference',
          condition: { key: 'type', operator: '=', value: 'array' },
          $ref: '#/definitions/formItem',
        },
        {
          label: __('formSchema.properties'), key: 'items', type: 'reference',
          condition: { key: 'type', operator: '=', value: 'group' },
          $ref: '#/definitions/formItemArray',
        },
      ],
    },
    formItemArray: {
      label: __('formSchema.items'), key: 'items', type: 'array', primaryKey: 'label',
      item: {
        label: __('formSchema.item'), key: '', type: 'reference',
        $ref: '#/definitions/formItem',
      },
    },
    simpleSelectOptions: {
      label: __('formSchema.options'), key: 'options', type: 'array',
      item: {
        label: __('formSchema.option'), key: '', type: 'text',
      },
    },
    pairSelectOptions: {
      label: __('formSchema.options'), key: 'options', type: 'array', primaryKey: 'label',
      item: {
        label: __('formSchema.option'), key: '', type: 'group',
        items: [
          { label: __('formSchema.option.label'), key: 'label', type: 'text', syncValueTo: 'value' },
          { label: __('formSchema.option.value'), key: 'value', type: 'text' },
        ],
      },
    },
    remoteSelectOptions: {
      label: __('formSchema.remoteSettings'), key: '', type: 'group',
      items: [
        { label: __('formSchema.remoteSettings.remoteUrl'), key: 'remoteUrl', type: 'text' },
        { label: __('formSchema.remoteSettings.query'), key: 'query', type: 'text' },
      ],
    },
    conditions: {
      label: __('formSchema.condition'), key: 'condition', type: 'group',
      items: [
        {
          label: __('formSchema.operator'), key: 'operator', type: 'select', defaultValue: '=',
          options: [
            { label: __('formSchema.operator.equals'), value: '=' },
            { label: __('formSchema.operator.and'), value: 'AND' },
            { label: __('formSchema.operator.or'), value: 'OR' },
            { label: __('formSchema.operator.not'), value: 'NOT' },
            { label: __('formSchema.operator.in'), value: 'IN' },
            { label: __('formSchema.operator.has'), value: 'HAS' },
            { label: __('formSchema.operator.truthy'), value: 'TRUTHY' },
          ],
        },
        {
          label: __('formSchema.variable'), key: 'key', type: 'text',
          condition: {
            operator: 'NOT', value: {
            key: 'operator', operator: 'IN', value: ['AND', 'OR', null] },
          },
        },
        {
          label: __('formSchema.subConditions'), key: 'value', type: 'array',
          condition: { key: 'operator', operator: 'IN', value: ['AND', 'OR'] },
          item: {
            label: 'Condition', key: 'condition', type: 'reference',
            $ref: '#/definitions/conditions',
          },
        },
        {
          label: __('formSchema.value'), key: 'value', type: 'reference',
          condition: { key: 'operator', operator: '=', value: 'NOT' },
          $ref: '#/definitions/conditions',
        },
        {
          label: __('formSchema.value'), key: 'value', type: 'text',
          condition: { key: 'operator', operator: '=', value: '=' },
        },
        {
          label: __('formSchema.values'), key: 'value', type: 'array',
          condition: { key: 'operator', operator: '=', value: 'IN' },
          item: {
            label: __('formSchema.value'), key: '', type: 'text',
          },
        },
      ]
    }
  },
  items: [
    {
      label: __('formSchema.items'), key: 'items', type: 'reference',
      $ref: '#/definitions/formItemArray',
    },
  ],
}
