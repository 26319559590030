import { observable, action } from 'mobx'
import { initLocalReaktor } from '../utils/reaktor'
import { AnnotationData } from '../types/annotation';

export interface SetCurrentIdOptions {
  currentAnnotationId: string;
  currentReferenceId: string;
}

export interface CreateAnnotationOptions {
  currentAnnotationId: string;
  annotation: AnnotationData;
}

export class AnnotationStore {
  @observable currentAnnotationId: string | null = null;
  @observable currentReferenceId: string | null = null;
  @observable annotations: AnnotationData[] = [];

  @action setCurrentId(options: SetCurrentIdOptions) {
    this.currentAnnotationId = options.currentAnnotationId;
    this.currentReferenceId = options.currentReferenceId;
  }

  @action createAnnotation(options: CreateAnnotationOptions) {
    this.currentAnnotationId = options.currentAnnotationId
    this.currentReferenceId = null
    this.annotations.push(options.annotation);
  }

  @action resetCropping() {
    this.currentAnnotationId = null
    this.currentReferenceId = null
  }

  @action reset() {
    this.currentAnnotationId = null
    this.currentReferenceId = null
    this.annotations = []
  }
}

export const initAnnotationStore = () => initLocalReaktor(new AnnotationStore, 'annotationsData');
