import parseUnit from 'parse-unit';

// extracted from https://github.com/mikolalysenko/to-px/blob/master/index.js

export const PIXELS_PER_INCH = 96;
export const MM_PER_INCH = 25.4;

const  defaults: { [key: string]: number } = {
  'ch': 8,
  'ex': 7.15625,
  'em': 16,
  'rem': 16,
  'in': PIXELS_PER_INCH,
  'cm': PIXELS_PER_INCH / 2.54,
  'mm': PIXELS_PER_INCH / 25.4,
  'pt': PIXELS_PER_INCH / 72,
  'pc': PIXELS_PER_INCH / 6,
  'px': 1,
}

export function toPx(str: string | number): number {
  if (!str) return 0;
  if (defaults[str]) return defaults[str];
  // detect number of units
  const parts = parseUnit(str)
  if (!isNaN(parts[0]) && parts[1]) {
    const px = toPx(parts[1]);
    return typeof px === 'number' ? parts[0] * px : 0;
  }
  return 0;
}
