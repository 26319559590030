import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';

import './UserForm.scss';
import { __, intl } from '../../utils/intl';
import Layout from '../Common/Layout';
import TermsPopup, { TermsType } from './TermsPopup';
import LanguagePicker from './LanguagePicker';

interface Props {
  title: string;
  className?: string;
  termsPageType?: TermsType;
  onTermsPageClose?: () => void;
}

interface State {
  termsPageType: TermsType;
  languagePickerOpen: boolean;
}

class UserForm extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      termsPageType: props.termsPageType,
      languagePickerOpen: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.termsPageType !== this.props.termsPageType) {
      this.setState({
        termsPageType: nextProps.termsPageType,
      });
    }
  }

  handleTermsPageOpen(type: TermsType) {
    this.setState({
      termsPageType: type,
    });
  }

  handleTermsPageClose() {
    const { onTermsPageClose } = this.props;
    this.setState({ termsPageType: undefined });
    onTermsPageClose && onTermsPageClose();
  }

  render() {
    const { children, className, title } = this.props;
    const { termsPageType, languagePickerOpen } = this.state;
    return (
      <Layout simple className="user-form">
        <div className={`form ${className}`}>
          <header>
            <img
              className="logo"
              alt="Easy Scroll logo"
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
            />
            <h1>{title}</h1>
          </header>
          <div className="form-content">
            <form>
              {children}
            </form>
          </div>
        </div>
        <div className="footer">
          <div className="info">
            <Tooltip title={__('settings.changeLanguage')}>
              <IconButton onClick={() => this.setState({ languagePickerOpen: true })}>
                <LanguageIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="info">
            <Button
              onClick={() => this.handleTermsPageOpen('privacy-policy')}
            >
              {__('account.privacyPolicy')}
            </Button>
            <Button
              onClick={() => this.handleTermsPageOpen('terms-of-service')}
            >
              {__('account.termsOfService')}
            </Button>
          </div>
          <span className="copyright info">© {__('app.copyrightText')}</span>
        </div>
        <TermsPopup
          type={termsPageType}
          onClose={() => this.handleTermsPageClose()}
        />
        <LanguagePicker
          open={languagePickerOpen}
          onSelect={lang => intl.setLang(lang)}
          onClose={() => this.setState({ languagePickerOpen: false })}
        />
      </Layout>
    )
  }
}

export default UserForm;
