import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { MemberStatus } from '../../types/member';

interface Props extends SvgIconProps {
  status: MemberStatus;
}

const MemberStatusIcon: React.SFC<Props> = (props) => {
  const { status, ...restProps } = props;
  switch (props.status) {
    case 'normal':
      return <CheckCircleIcon {...restProps} />;
    case 'canceled':
      return <CloseIcon {...restProps} />;
    case 'pending':
      return <HelpIcon {...restProps} />;
    case 'removed':
      return <DeleteIcon {...restProps} />;
    default:
      return null;
  }
}

export default MemberStatusIcon;
