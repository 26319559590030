import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { compose } from 'recompose';
import { Typography, Avatar, Tooltip, IconButton, Badge } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { TreeItem } from '@material-ui/lab';
import {
  mdiAccountMultiple,
  mdiArchive,
  mdiAccountGroup,
  mdiFolder,
} from '@mdi/js';

import { __ } from '../../utils/intl';
import { ChatContact } from '../../types/chat';
import { getUsername } from '../../utils/data';
import { getTargetName } from '../../utils/notification';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import UserAvatar from '../Common/UserAvatar';
import RedAlertIcon from '../Common/RedAlertIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  minified: {
    textAlign: 'center',
  },
  content: {
    borderRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: theme.spacing(1),
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  avatar: {
    width: 24,
    height: 24,
    fontSize: 14,
  },
  icon: {
  },
  labelText: {
    marginLeft: theme.spacing(1),
    fontWeight: 'inherit',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

interface OwnProps {
  minified?: boolean;
  item: ChatContact;
  onClick: (event: React.MouseEvent, item: ChatContact) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class ContactListItem extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  getName() {
    const { item } = this.props;
    const { target } = item;
    if (item.type === 'target' && target) {
      return getTargetName(target);
    } else if (item.type === 'group' && item.name) {
      // INTL:template __('chat.contacts.user.dossiers')
      // INTL:template __('chat.contacts.user.teams')
      // INTL:template __('chat.contacts.team.dossiers')
      // INTL:template __('chat.contacts.team.members')
      return __(item.name);
    }
    return '';
  }

  renderIcon() {
    const { classes, minified, item } = this.props;
    const commonProps: SvgIconProps = {
      className: minified ? '' : classes.icon,
    };
    if (item.type === 'group') {
      const groupType = item.name && _.last(item.name.split('.')) || '';
      if (groupType === 'dossiers') {
        return <SimpleSvgIcon {...commonProps} path={mdiArchive} />;
      } else if (groupType === 'teams') {
        return <SimpleSvgIcon {...commonProps} path={mdiAccountGroup} />;
      } else if (groupType === 'members') {
        return <SimpleSvgIcon {...commonProps} path={mdiAccountMultiple} />;
      } else {
        return <SimpleSvgIcon {...commonProps} path={mdiFolder} />;
      }
    } else if (item.type === 'target' && item.target) {
      const { target } = item;
      if (target.type === 'dossier') {
        return <SimpleSvgIcon {...commonProps} path={mdiFolder} />;
      } else if (target.type === 'team') {
        return <SimpleSvgIcon {...commonProps} path={mdiAccountMultiple} />;
      } else if (target.type === 'user' && target.user) {
        return (
          <UserAvatar
            className={clsx(classes.avatar, { [classes.icon]: !minified })}
            user={target.user}
          />
        );
      }
    }
    return null;
  }

  handleClick = (event: React.MouseEvent) => {
    const { item, onClick } = this.props;
    onClick(event, item);
  }

  renderLabel() {
    const { classes, item } = this.props;
    return (
      <div className={classes.labelRoot} onClick={this.handleClick}>
        <Badge badgeContent={item.unreadMessageCount ? <RedAlertIcon size="medium" /> : undefined}>
          {this.renderIcon()}
        </Badge>
        <Typography variant="body2" className={classes.labelText}>
          {this.getName()}
        </Typography>
        <Typography variant="caption" color="inherit">
        </Typography>
      </div>
    );
  }

  renderMinified() {
    const { classes, item, onClick } = this.props;
    return (
      <Tooltip title={this.getName()} placement="left">
        <div
          className={classes.minified}
          onClick={event => onClick(event, item)}
        >
          <IconButton>
            <Badge badgeContent={item.unreadMessageCount ? <RedAlertIcon size="medium" /> : undefined}>
              {this.renderIcon()}
            </Badge>
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  render() {
    const { classes, minified, item, ...restProps } = this.props;
    if (minified) {
      return this.renderMinified();
    }
    return (
      <TreeItem
        nodeId={item._id}
        label={this.renderLabel()}
        classes={{
          group: classes.group,
          content: classes.content,
        }}
      >
        {item.children && item.children.map(child => (
          <ContactListItem
            key={child._id}
            classes={classes}
            item={child}
            {...restProps}
          />
        ))}
      </TreeItem>
    );
  }

}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(ContactListItem);
