import _ from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton, Tooltip, MenuItem, Menu, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';

import { ClaimData } from '../../types/claim';
import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import {
  fetchListRemote as fetchClaimList,
  createItemRemote as createClaim,
  removeItemRemote as removeClaim,
  updateItemRemote as updateClaim
} from '../../store/claim/actions';
import ClaimListItem from './ClaimListItem';
import ClaimListDialog from './ClaimListDialog';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(1, 0),
  },
  menuItem: {
    width: 260,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

interface OwnProps {
  claimIds: string[];
  onChange: (ids: string[]) => void;
}

interface StateProps {
  claimList: ClaimData[];
}

interface DispatchProps {
  fetchClaimList: () => Promise<void>;
  createClaim: (data: Partial<ClaimData>) => Promise<ClaimData>;
  updateClaim: (claimId: string, data: Partial<ClaimData>) => Promise<ClaimData>;
  removeClaim: (claimId: string) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

interface State {
  editAllDialogOpen: boolean;
  addMenuAnchorEl: HTMLElement | null;
}

class ClaimList extends React.Component<Props, State> {

  state: State = {
    editAllDialogOpen: false,
    addMenuAnchorEl: null,
  }

  componentDidMount() {
    const { fetchClaimList } = this.props;
    fetchClaimList();
  }

  handleChange(id: string, value: string) {
    const { updateClaim } = this.props;
    updateClaim(id, { content: value });
  }

  handleListRemove(id: string) {
    const { claimIds, onChange } = this.props;
    onChange(_.without(claimIds, id));
  }

  handleRemove = (id: string) => {
    const { removeClaim } = this.props;
    this.handleListRemove(id);
    removeClaim(id);
  }

  handleOpenAddMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      addMenuAnchorEl: event.currentTarget,
    });
  }

  async handleCreate() {
    const { createClaim } = this.props;
    const claim = await createClaim({ content: __('claimList.defaultNewItemName') });
    this.handleAdd(claim._id);
  }

  handleAdd(id: string) {
    const { claimIds, onChange } = this.props;
    onChange([...claimIds, id]);
  }

  handleAddMenuSelect = async (item: ClaimData | null) => {
    if (item) {
      this.handleAdd(item._id);
    } else {
      await this.handleCreate();
    }
    this.setState({ addMenuAnchorEl: null });
  }

  handleEditAll = () => {
    this.setState({ editAllDialogOpen: true });
  }

  render() {
    const { classes, claimIds, claimList, createClaim, updateClaim, removeClaim } = this.props;
    const { editAllDialogOpen, addMenuAnchorEl } = this.state;
    const selectedList = claimList.filter(item => claimIds.includes(item._id));
    const restList = _.difference(claimList, selectedList);
    return (
      <div className={classes.root}>
        {selectedList.map(item => (
          <ClaimListItem
            key={item._id}
            value={item.content}
            onChange={value => this.handleChange(item._id, value)}
            onDelete={() => this.handleListRemove(item._id)}
          />
        ))}
        <div className={classes.actions}>
          <Tooltip title={__('claimList.editAll')} onClick={this.handleEditAll}>
            <IconButton>
              <ListIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={__('claimList.removeItem')} onClick={this.handleOpenAddMenu}>
            <IconButton>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          keepMounted
          anchorEl={addMenuAnchorEl}
          open={Boolean(addMenuAnchorEl)}
          onClose={() => this.setState({ addMenuAnchorEl: null })}
        >
          <MenuItem onClick={() => this.handleAddMenuSelect(null)}>
            {__('claimList.addEmpty')}
          </MenuItem>
          {restList.map(item => (
            <MenuItem key={item._id} onClick={() => this.handleAddMenuSelect(item)}>
              <Typography className={classes.menuItem}>{item.content}</Typography>
            </MenuItem>
          ))}
        </Menu>
        <ClaimListDialog
          open={editAllDialogOpen}
          onClose={() => this.setState({ editAllDialogOpen: false })}
          claimList={claimList}
          createClaim={createClaim}
          updateClaim={updateClaim}
          removeClaim={async (id) => this.handleRemove(id)}
        />
      </div>
    );
  }
}

const mapStateToProps = (states: RootState, ownProps: OwnProps): StateProps => ({
  claimList: states.claim.list,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchClaimList: () => dispatch(fetchClaimList()),
  createClaim: (data) => dispatch(createClaim(data)),
  removeClaim: (id) => dispatch(removeClaim(id)),
  updateClaim: (id, data) => dispatch(updateClaim(id, data)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ClaimList);
