import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { KeyValueMap } from '../../types/common';

import { FileData } from '../../types/file';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 0,
    width: 0,
    overflow: 'hidden',
  },
});

interface OwnProps {
  accept: string;
  getUploadUrl: () => Promise<string>;
  onUploadFinish: (doc: FileData) => void;
  getRef?: (ref: SimpleUploader) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class SimpleUploader extends React.Component<Props, State> {

  static defaultProps: OwnProps = {
    accept: '*/*',
    getUploadUrl: async () => '',
    onUploadFinish: (doc: FileData) => {},
    getRef: () => {},
  };

  private fileInput: HTMLInputElement | null = null;
  private target: string = '';
  private timer: number | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    const { getRef } = this.props;
    getRef && getRef(this);
    this.refreshUploadUrl();
    this.timer = window.setInterval(() => this.refreshUploadUrl(), 1500 * 1000);
  }

  componentWillUnmount() {
    window.clearInterval(this.timer);
  }

  async refreshUploadUrl() {
    const { getUploadUrl } = this.props;
    this.target = await getUploadUrl();
  }

  async handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { onUploadFinish } = this.props;
    const input = event.target;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        const form = new FormData();
        form.append('file', file);
        try {
          const response = await fetch(this.target, {
            method: 'POST',
            body: form,
          });
          const data = await response.json();
          onUploadFinish(data);
        } catch(error) {
          console.error(error);
        }
      }
    }
    setTimeout(() => {
      input.value = '';
    })
    event.preventDefault();
  }

  openBrowse() {
    const { fileInput } = this;
    if (!fileInput) return;
    fileInput.removeAttribute('capture');
    fileInput.click();
  }
  
  openCapture() {
    const { fileInput } = this;
    if (!fileInput) return;
    fileInput.setAttribute('capture', 'capture');
    fileInput.click();
  }

  render() {
    const { classes, accept } = this.props;
    return (
      <div className={classes.root}>
        <input
          ref={ref => this.fileInput = ref}
          multiple
          type="file"
          accept={accept}
          onChange={event => this.handleFileChange(event)}
        />
      </div>
    );
  }
}

export { SimpleUploader as PureSimpleUploader };

export default withStyles(styles)(SimpleUploader);
