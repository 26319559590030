import _ from 'lodash';
import clsx from 'clsx';
import React from 'react';
import { compose } from 'recompose';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { __ } from '../../utils/intl';
import { MessageAttachment } from '../../types/chat';
import { getAttachmentName, getAttachmentTypeTitle, openTarget } from '../../utils/notification';
import AttachmentIcon from './AttachmentIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    width: 200,
  },
  type: {
    padding: theme.spacing(0, 0.5),
    color: grey[500],
    fontSize: '12px',
  },
  typeSelf: {
    textAlign: 'right',
  },
  content: {
    cursor: 'pointer',
    marginTop: 4,
    border: '1px solid #e0e0e0',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  },
  icon: {
    width: 36,
    height: 36,
  },
  title: {
    flexGrow: 1,
    overflow: 'hidden',
  },
});

interface OwnProps {
  isSelf: boolean;
  attachment: MessageAttachment;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class Attachment extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  handleClick = () => {
    const { attachment } = this.props;
    if (attachment.type === 'target' && attachment.target) {
      openTarget(attachment.target);
    }
  }

  render() {
    const { classes, isSelf, attachment } = this.props;
    const typeTitle = getAttachmentTypeTitle(attachment);
    const title = getAttachmentName(attachment);
    // INTL:template __('chat.message.shareAttachment');
    const tip = __('chat.message.shareAttachment', { title: typeTitle });
    return (
      <div className={classes.root}>
        <div className={clsx(classes.type, { [classes.typeSelf]: isSelf })}>
          {tip}
        </div>
        <div className={classes.content} onClick={this.handleClick}>
          <div className={classes.icon}>
            <AttachmentIcon item={attachment} fontSize="large" />
          </div>
          <div className={classes.title} title={title}>
            {title}
          </div>
        </div>
      </div>
    );
  }

}

export default compose<Props, OwnProps>(
  withStyles(styles),
)(Attachment);
