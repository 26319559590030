import React from 'react';
import { Route } from 'react-router';
import _ from 'lodash';
import { IconButton, ClickAwayListener, Chip, Tooltip, Button } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Remove';
// import { Icon } from '@mdi/react';
import { mdiPin, mdiPinOff } from '@mdi/js';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';

import { __ } from '../../utils/intl';
import { TreeItem } from '../../types/tree';
import { DossierData } from '../../types/dossier';
import { RootState } from '../../store/reducers';
import { SinglePanelStatus, RightPanelType } from '../../store/ui/reducers';
import Sidebar, { Props as SidebarProps } from '../Common/Sidebar';

import { KeyValueMap } from '../../types/common';
import { DossierTemplateData } from '../../types/dossier.template';
import {fieldNameToPath, Path} from '../../utils/template'
import { fetchItemRemote, setItem } from '../../store/dossierTemplate/actions'
import FormControl from '../Form/FormControl';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  topActions: {
  },
  tabs: {
    backgroundColor: '#e0e0e0',
  },
  container: {
    padding: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  tab: {
    minWidth: 80,
  },
  tabContent: {
  },
});

interface StateProps {
  dossierTemplate: DossierTemplateData | null;
  dossier: DossierData | null;
}

interface DispatchProps {
  fetchItemRemote: (id: string) => Promise<DossierTemplateData>;
  setItem: (template: DossierTemplateData) => void;
}

interface OwnProps extends Partial<SidebarProps> {
  onChange: (value?: KeyValueMap) => void;
  onWidthChange: (width: number) => void;
  onClose: () => void;
  onUnlink: () => void;
  onPin: () => void;
  open: boolean;
  pinned?: boolean;
  fields: string[];
  context: KeyValueMap;
}

type Props = OwnProps & WithStyles<typeof styles> & DispatchProps & StateProps;

interface State {
  value?: KeyValueMap
  fields: string[]
}

class RightPanel extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      fields: props.fields
    }
  }

  componentDidMount() {
    const {fetchItemRemote, setItem, dossier, dossierTemplate} = this.props
    if (dossier) {
      fetchItemRemote(dossier.dossier_template_id)
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.fields !== this.props.fields) {
      this.setState({fields: nextProps.fields})
    }
  }

  renderContent() {
    const {
      context,
      dossierTemplate,
    } = this.props; 
    const {
      value,
      fields
    } = this.state
    if (!dossierTemplate || !context) {
      return null
    }
    const filters = this.renderFields(fields)
    return (<FormControl
      items={dossierTemplate.form}
      value={context}
      pathFilters={filters.length ? filters : null}
      onChange={(v) => this.props.onChange(v)}
    />)
  }

  handleClickAway() {
    const {onClose, pinned} = this.props
    if (!pinned) {
      onClose();
    }
  }

  renderFields(fields: string[]) {

    const {dossierTemplate, dossier} = this.props
    if (!dossierTemplate || !dossier) return []
    return fields.reduce((prev: Path[], f) => {
      const result = fieldNameToPath(f.split('+'), dossier.context, dossierTemplate.map || {})
      return [...prev, ...result]
    }, []).map(f => f.filter((f) => f !== '$').join('.'))
  }

  handleDelete = (fieldName: string) => {
    this.setState({fields: _.difference(this.state.fields, [fieldName])})
  }

  render() {
    const {
      classes,
      onClose,
      open,
      pinned,
      onPin,
      onWidthChange,
    } = this.props;
    const {
      fields
    } = this.state;

    return (
      <ClickAwayListener onClickAway={() => this.handleClickAway()}>
        <Sidebar
          className={classes.root}
          align="right"
          open={open}
          onWidthChange={onWidthChange}
          resizeBounds={{
            left: -200,
            right: 100,
          }}
        >
          <div className={classes.container}>

            <div className={classes.topActions}>
              <Tooltip title={__('panels.close')}>
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={__('panels.pin')}>
                <IconButton size="small" onClick={onPin}>
                  <SimpleSvgIcon path={pinned ? mdiPin : mdiPinOff} />
                </IconButton>
              </Tooltip>
            </div>
            {fields.map((f) => (
              <Chip label={f.replace('+', '')} onDelete={() => this.handleDelete(f)}/>
            ))}
            {!!fields.length && <Button color="secondary" onClick={this.props.onUnlink}>取消关联</Button>}
            <div className={classes.tabContent}>
              {this.renderContent()}
            </div>
          </div>
        </Sidebar>
      </ClickAwayListener>
    )
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  dossierTemplate: states.dossierTemplate.current,
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchItemRemote: (id) => dispatch(fetchItemRemote(id)),
  setItem: (item) => dispatch(setItem(item)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RightPanel);
