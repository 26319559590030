
const regISODate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;

export const dateParser = (key: string, value: any) => {
  if (typeof value === 'string' && regISODate.test(value)) {
    return new Date(value);
  }
  return value;
};

export function JSONParseDate(obj: any) {
  return JSON.parse(obj, dateParser);
}
