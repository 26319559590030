import React from 'react'
// import { Collapse, Icon, Card, Popover, PopoverInteractionKind, PopoverPosition, PopperBoundary } from '@blueprintjs/core'

// import { UIContext } from '../../context'
import Sidebar from '../Common/Sidebar'
import { __ } from '../../utils/intl'
import './Assistant.scss'
import _ from 'lodash'
import { getDemoData } from '../../demo';

const { similarCaseData, relevantLawData } = getDemoData();

interface Props {

}

interface State {
  similarCaseOpen: boolean
  relevantLawOpen: boolean
}

class AssistantPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
  }

  public state = {
    similarCaseOpen: true,
    relevantLawOpen: true,
  }

  toggleSimiCaseOpen = () => {
    this.setState({ similarCaseOpen: !this.state.similarCaseOpen })
  }

  toggleRelevantLawOpen = () => {
    this.setState({ relevantLawOpen: !this.state.relevantLawOpen })
  }

  render() {
    return (
      null
      // <UIContext.Consumer>
      //   {({panelStatus}) => (
      //     <Sidebar
      //       align="left"
      //       className="assistant-panel"
      //       title={__('assistant.title')}
      //       open={panelStatus.assistant}
      //     >
      //       <div className="bp3-tree-node-content" onClick={this.toggleSimiCaseOpen}>
      //         <Icon icon="info-sign" className="bp3-icon bp3-tree-node-caret" />
      //         <span className="bp3-tree-node-label">Similar Case</span>
      //         <Icon icon="chevron-right" className={this.state.similarCaseOpen ?'bp3-tree-node-caret bp3-tree-node-caret-open' : 'bp3-tree-node-caret bp3-tree-node-caret-close'}/>
      //       </div>
      //       <Collapse open={this.state.similarCaseOpen}>
      //         {similarCaseData.map((caseData) => (
      //           <Popover
      //             key={caseData.title}
      //             position={PopoverPosition.LEFT}
      //             interactionKind={PopoverInteractionKind.CLICK}
      //             boundary="window"
      //             content={<div>
      //               <img src="/images/demo/similar-case.png" width="500" alt=""/>
      //             </div>}
      //           >
      //             <Card className="card-item" interactive>
      //               <a href="javascript:void()"><span>{caseData.title}</span></a>
      //               <br />
      //               <strong>Date:</strong><span>{caseData.date}</span>
      //               <br />
      //               <strong>Number:</strong><span>{caseData.number}</span>
      //             </Card>
      //           </Popover>
      //         ))}
      //       </Collapse>
      //       <div className="bp3-tree-node-content" onClick={this.toggleRelevantLawOpen}>
      //         <Icon icon="info-sign" className="bp3-icon bp3-tree-node-caret" />
      //         <span className="bp3-tree-node-label">Relevant laws and regulations</span>
      //         <Icon icon="chevron-right" className={this.state.relevantLawOpen ?'bp3-tree-node-caret bp3-tree-node-caret-open' : 'bp3-tree-node-caret bp3-tree-node-caret-close'}/>
      //       </div>
      //       <Collapse open={this.state.relevantLawOpen}>
      //         {relevantLawData.map((lawData) => (
      //           <Popover
      //             position={PopoverPosition.LEFT}
      //             interactionKind={PopoverInteractionKind.CLICK}
      //             boundary="window"
      //             content={<div style={{
      //                 maxWidth: '400px',
      //                 padding: '43px',
      //                 maxHeight: '505px',
      //                 overflow: 'scroll',
      //               }}>
      //               <strong>{lawData.article}</strong>
      //               <br />
      //               <strong>{lawData.version}</strong>
      //               <br />
      //               <a href="javascript:void()"><span>{lawData.title}</span></a>
      //               <p>{lawData.content}</p>
      //             </div>}
      //           >
      //             <Card className="card-item" interactive>
      //               <a href="javascript:void()"><span>{lawData.title}</span></a>
      //               <br />
      //               <p>{_.truncate(lawData.content, {length: 100})}</p>
      //               <strong>{lawData.article}</strong>
      //               <br />
      //               <strong>{lawData.version}</strong>
      //             </Card>
      //           </Popover>
      //         ))}
      //       </Collapse>
      //     </Sidebar>
      //   )}
      // </UIContext.Consumer>
    )
  }
}

export default AssistantPanel
