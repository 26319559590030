import { ClientError } from '../store/client';
import { KeyValueMap } from '../types/common';
import { __ } from './intl';

export function parseValidationError(error: ClientError): KeyValueMap<string> | undefined {
  if (error.errors) {
    const result: KeyValueMap<string> = {};
    error.errors.forEach((fieldError) => {
      const { reason, property, message } = fieldError;
      const field = property.replace(/^@\./, '');
      if (reason) {
        result[field] = __(reason, undefined, undefined, { fallback: message });
      } else {
        result[field] = message;
      }
    });
    return result;
  }
  return undefined;
}
