import { KeyValueMap } from './common';

export interface FormItemInterface {
  $id?: string;
  key: string;
  label: string;
  syncValueTo?: string | string[];
  condition?: Condition;
  optional?: boolean;
  readOnly?: boolean;
  defaultValue?: any;
  required?: boolean;
  native?: boolean;
}

export interface TextFormItem extends FormItemInterface {
  type: 'text';
}

export interface TextAreaFormItem extends FormItemInterface {
  type: 'textarea';
}

export interface NumberFormItem extends FormItemInterface {
  type: 'number';
  min?: number;
  max?: number;
  step?: number;
}

export interface DateFormItem extends FormItemInterface {
  type: 'date';
  rangeStart?: Date;
  rangeEnd?: Date;
}

export interface TimeFormItem extends FormItemInterface {
  type: 'time';
  rangeStart?: Date;
  rangeEnd?: Date;
}

export interface DateTimeFormItem extends FormItemInterface {
  type: 'datetime';
  rangeStart?: Date;
  rangeEnd?: Date;
}

export interface BooleanFormItem extends FormItemInterface {
  type: 'boolean';
  control?: 'checkbox' | 'radio' | 'switch';
  trueLabel?: string;
  falseLabel?: string;
}

export interface SelectOption {
  label: string;
  value: string;
}

export interface SelectFormItem extends FormItemInterface {
  type: 'select';
  optionsType?: 'pair' | 'simple' | 'remote';
  options?: (SelectOption | string)[];
  control?: 'radio' | 'select' | 'checkbox' | 'autocomplete';
  remoteUrl?: string;
  query?: string | KeyValueMap<string>;
  multiple?: boolean;
}

export interface GroupFormItem extends FormItemInterface {
  type: 'group';
  defaultExpanded?: boolean;
  items: FormItem[];
}

export interface PersonFormItem extends FormItemInterface {
  type: 'person';
  items?: FormItem[];
}

export interface OrganizationFormItem extends FormItemInterface {
  type: 'organization';
  items?: FormItem[];
}

export interface ArrayFormItem extends FormItemInterface {
  type: 'array';
  item: FormItem;
  defaultExpanded?: boolean;
  primaryKey?: string;
}

export interface FormItemReference extends FormItemInterface {
  type: 'reference';
  $ref: string;
}

export type FormItem = (
  TextFormItem |
  TextAreaFormItem |
  NumberFormItem |
  DateFormItem |
  TimeFormItem |
  DateTimeFormItem |
  BooleanFormItem |
  SelectFormItem |
  PersonFormItem |
  OrganizationFormItem |
  GroupFormItem |
  ArrayFormItem |
  FormItemReference
);

export type FormItemType = FormItem['type'];

export type SimpleFormItem = (
  TextFormItem
  | TextAreaFormItem
  | NumberFormItem
  | DateFormItem
  | BooleanFormItem
  | SelectFormItem
)

export type SimpleFormItemType = SimpleFormItem['type'];

export const SimpleFormItemTypes = ['number', 'boolean', 'text', 'textarea', 'date', 'select'];

export type CombinedFormItem = (
  PersonFormItem
  | OrganizationFormItem
  | GroupFormItem
  | ArrayFormItem
)

export type CombinedFormItemType = CombinedFormItem['type'];

export const CombinedFormItemTypes = ['person', 'organization', 'group', 'array'];

export const FormItemTypes = [...SimpleFormItemTypes, ...CombinedFormItemTypes];

export type FormDefinitions = KeyValueMap<FormItem>;

export interface FormDefine {
  definitions?: FormDefinitions;
  items: FormItem[];
}

export interface LogicalCondition {
  operator: 'AND' | 'OR';
  value: Condition[];
}

export interface NotCondition {
  operator: 'NOT';
  value: Condition;
}

export interface ExactCondition {
  operator: '=';
  key: string;
  value: any;
}

export interface InCondition {
  operator: 'IN';
  key: string;
  value: any[];
}

export interface HasCondition {
  operator: 'HAS';
  key: string;
}

export interface TruthyCondition {
  operator: 'TRUTHY';
  key: string;
}

export type Condition = (
  LogicalCondition |
  NotCondition |
  ExactCondition |
  InCondition |
  HasCondition |
  TruthyCondition
);
