import _ from 'lodash';

import { DossierData, DossierListQuery, DossierOwnership } from '../../types/dossier';
import { ThunkActionType } from '../types';
import client from '../client';
import { TransferOptionType } from '../../components/Team/TransferDialog';
import { updateListItem as updateMemberListItem } from '../member/actions';

// Action Definition
export interface SetCurrentItem {
  type: '@@dossier/SET_CURRENT_ITEM';
  item: DossierData;
}

export interface ClearCurrentItem {
  type: '@@dossier/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@dossier/SET_LIST';
  list: DossierData[];
}

export interface UpdateListItem {
  type: '@@dossier/UPDATE_LIST_ITEM';
  item: DossierData;
}

export interface DeleteListItem {
  type: '@@dossier/REMOVE_LIST_ITEM';
  id: string;
}

export interface CreateItem {
  type: '@@dossier/CREATE_ITEM_START',
}

export interface CreateItemFailure {
  type: '@@dossier/CREATE_ITEM_FAILURE',
  error: Error;
}

export interface CreateItemSuccess {
  type: '@@dossier/CREATE_ITEM_SUCCESS',
  item: DossierData,
}

// Union Action Types
export type Action =
  | SetCurrentItem
  | ClearCurrentItem
  | SetList
  | UpdateListItem
  | DeleteListItem
  | CreateItem
  | CreateItemSuccess
  | CreateItemFailure;

// Action Creators
export const setItem = (item: DossierData): SetCurrentItem => ({
  type: '@@dossier/SET_CURRENT_ITEM',
  item,
});

export const clearItem = (): ClearCurrentItem => ({
  type: '@@dossier/CLEAR_CURRENT_ITEM',
});

export const setList = (list: DossierData[]): SetList => ({
  type: '@@dossier/SET_LIST',
  list,
});

export const updateListItem = (item: DossierData): UpdateListItem => ({
  type: '@@dossier/UPDATE_LIST_ITEM',
  item,
});

export const removeListItem = (id: string): DeleteListItem => ({
  type: '@@dossier/REMOVE_LIST_ITEM',
  id,
});

export const fetchItemRemote = (dossierId: string): ThunkActionType<DossierData> => {
  return async (dispatch) => {
    const item = await client.get<DossierData>('/dossiers/:dossierId', {
      params: { dossierId },
    });
    dispatch(setItem(item));
    return item;
  }
}

export const updateItemRemote = (data: Partial<DossierData>): ThunkActionType<DossierData> => {
  return async (dispatch) => {
    const item = await client.put<DossierData>('/dossiers/:dossierId', {
      params: { dossierId: data._id },
      data,
    });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
    return item;
  }
}

export const removeItemRemote = (id: string, password: string): ThunkActionType => {
  return async (dispatch) => {
    await client.delete('/dossiers/:dossierId', {
      params: { dossierId: id },
      data: { password },
    });
    dispatch(removeListItem(id));
  }
}

export const fetchListRemote = (query: DossierListQuery): ThunkActionType<DossierData[]> => {
  return async (dispatch) => {
    const list = await client.get<DossierData[]>('/dossiers', {
      query,
    });
    dispatch(setList(list));
    return list;
  }
}

export const createItemRemote = (data: Partial<DossierData>): ThunkActionType<DossierData> => {
  return async (dispatch) => {
    dispatch({ type: '@@dossier/CREATE_ITEM_START' })
    const result = await client.post<DossierData>(`/dossiers`, { data })
    dispatch({
      type: '@@dossier/CREATE_ITEM_SUCCESS',
      item: result,
    });
    return result;
  }
}

export const transferDossierRemote = (dossierId: string, type: TransferOptionType['type'], id: string, password: string): ThunkActionType => {
  return async (dispatch, getState) => {
    const owner: DossierOwnership = {
      type
    }
    if (type === 'team') {
      owner.team_id = id;
    } else if (type === 'user') {
      owner.user_id = id
    }
    const result = await client.post<DossierData>('/dossiers/:dossierId/transfer', {
      params: { dossierId },
      data: { owner, password: password },
    });
    // TODO move data side affect into subscription
    const { user, dossier, member } = getState();
    if (dossier.current) {
      dispatch(setItem({
        ...result,
      }));
    }
    if (type === 'user') {
      if (user.current) {
        const currentMember = _.find(member.list, { type: 'dossier', dossier_id: dossierId, user_id: user.current._id });
        if (currentMember) {
          dispatch(updateMemberListItem({ ...currentMember, role: 'admin' }));
        }
      }
      const newOwner = _.find(member.list, { type: 'team', dossier_id: dossierId, user_id: id });
      if (newOwner) {
        dispatch(updateMemberListItem({ ...newOwner, role: 'owner' }));
      }
    }
    
    dispatch(removeListItem(dossierId));
  }
}
