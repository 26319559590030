import _ from 'lodash';
import { ThunkActionType } from '../types';
import client from '../client';
import { ContactPersonData } from '../../types/contact.person';

// Action Definition
export interface SetCurrentItem {
  type: '@@person/SET_CURRENT_ITEM';
  item: ContactPersonData;
}

export interface ClearCurrentItem {
  type: '@@person/CLEAR_CURRENT_ITEM';
}

export interface SetList {
  type: '@@person/SET_LIST';
  list: ContactPersonData[];
}

export interface UpdateListItem {
  type: '@@person/UPDATE_LIST_ITEM';
  item: ContactPersonData;
}

export interface DeleteListItem {
  type: '@@person/REMOVE_LIST_ITEM';
  id: string;
}

export interface CreateItem {
  type: '@@person/CREATE_ITEM_START',
}

export interface CreateItemFailure {
  type: '@@person/CREATE_ITEM_FAILURE',
  error: Error;
}

export interface CreateItemSuccess {
  type: '@@person/CREATE_ITEM_SUCCESS',
  item: ContactPersonData,
}

// Union Action Types
export type Action = SetCurrentItem
  | ClearCurrentItem
  | SetList
  | UpdateListItem
  | DeleteListItem
  | CreateItem
  | CreateItemSuccess
  | CreateItemFailure;

// Action Creators
export const setItem = (item: ContactPersonData): SetCurrentItem => {
  return { type: '@@person/SET_CURRENT_ITEM', item };
}

export const clearItem = (): ClearCurrentItem => {
  return { type: '@@person/CLEAR_CURRENT_ITEM' };
}

export const setList = (list: ContactPersonData[]): SetList => {
  return { type: '@@person/SET_LIST', list };
}

export const updateListItem = (item: ContactPersonData): UpdateListItem => {
  return { type: '@@person/UPDATE_LIST_ITEM', item };
}

export const removeListItem = (id: string): DeleteListItem => {
  return { type: '@@person/REMOVE_LIST_ITEM', id };
}

export const fetchItemRemote = (id: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<ContactPersonData>(`/contacts.person/${id}`);
    dispatch(setItem(item));
  }
}

export const updateItemRemote = (id: string, data: Partial<ContactPersonData>): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.put<ContactPersonData>(`/contacts.person/${id}`, { data });
    dispatch(setItem(item));
    dispatch(updateListItem(item));
  }
}

export const removeItemRemote = (id: string): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    await client.delete(`/contacts.person/${id}`);
    dispatch(removeListItem(id));
  }
}

export const fetchListRemote = (): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    const item = await client.get<ContactPersonData[]>('/contacts.person');
    dispatch(setList(item));
  }
}

export const createItemRemote = (data: Partial<ContactPersonData>): ThunkActionType => {
  return async (dispatch): Promise<void> => {
    try {
      dispatch({ type: '@@person/CREATE_ITEM_START' });
      const item = await client.post<ContactPersonData>(`/contacts.person`, { data });
      dispatch({
        type: '@@person/CREATE_ITEM_SUCCESS',
        item,
      })
      dispatch(updateListItem(item));
    } catch (error) {
      dispatch({
        type: '@@person/CREATE_ITEM_FAILURE',
        error: error,
      });
    }
  }
}
