import { __ } from '../utils/intl';
import { IBaseData } from './base';
import { MemberIdentity, MemberRole } from './member';
import { PublicUserInfo } from './user';
import { DossierData } from './dossier';
import { TeamData } from './team';

export type InvitationStatus = 'pending' | 'canceled' | 'accepted' | 'rejected';
export type InvitationTypeFilter = 'received' | 'sent';
export type InvitationStatusFilter = InvitationStatus | 'all';
export type InvitationUserType = 'existed' | 'mobile';

export interface InvitationStatusQuery {
  type?: InvitationTypeFilter;
  status?: InvitationStatusFilter;
  last?: number;
  size?: number;
}

export const getDefaultQuery = (query: InvitationStatusQuery): InvitationStatusQuery => {
  let { type, status } = query;
  if (!type) {
    type = 'received';
  }
  if (!status) {
    status = type === 'received' ? 'pending' : 'all';
  }
  return { type, status };
}

export const invitationStatusLabels = {
  pending: __('invitation.status.pending'),
  accepted: __('invitation.status.accepted'),
  rejected: __('invitation.status.rejected'),
  canceled: __('invitation.status.canceled'),
}

export interface InvitationData extends IBaseData, MemberIdentity {
  __type?: 'Invitation';
  from: string;
  from_user?: PublicUserInfo;
  to: string;
  to_user?: PublicUserInfo;
  status: InvitationStatus;
  dossier?: DossierData;
  team?: TeamData;
  dateCreated: Date;
  dateUpdated: Date;
}

export interface InvitationOptions extends Partial<MemberIdentity> {
  userType: InvitationUserType;
  mobile?: string;
  from?: string;
  to?: string;
  role: MemberRole;
  name?: string;
}
