import React from 'react';
import _ from 'lodash';
import { Menu, MenuItem, Button, Box } from '@material-ui/core';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { mdiExport } from '@mdi/js';

import { TreeItem } from '../../types/tree';
import DocumentTree from './DocumentTree';
import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { DossierData } from '../../types/dossier';
import { DocumentData } from '../../types/document';
import { ViewMode } from '../../types/view';
import { setItem, getCachedItem as getDocument } from '../../store/document/actions';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import { PanelProps } from './common';
import { DossierContext } from './dossierContext'
import Panel from './Panel';
import ExportDialog from './ExportDialog';
import Loading from '../Common/Loading';


const styles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(1, 0),
    height: '100%',
  },
  treeView: {
    flexGrow: 1,
    overflow: 'auto',
  },
  actions: {
    padding: theme.spacing(2),
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
  },
});

interface StateProps {
  dossier: DossierData | null,
}

interface DispatchProps {
  setItem: (document: DocumentData) => void;
  getDocument: (dossierId: string, documentId: string) => Promise<DocumentData | undefined>;
}

interface OwnProps extends PanelProps {
  tree: TreeItem;
  selectedItem: TreeItem | null;
  onSelect: (item: TreeItem) => void;
}

interface Params {
  dossierId: string;
  viewMode: ViewMode;
}

export type RouteProps = RouteComponentProps<Params>;

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

interface MousePosition {
  x: number;
  y: number;
}

interface State {
  contextMenuPosition: MousePosition | null;
  contextMenuItem: TreeItem | null;
  exportDialogOpen: boolean;
}

class ExplorerPanel extends React.Component<Props, State> {

  state: State = {
    contextMenuPosition: null,
    contextMenuItem: null,
    exportDialogOpen: false,
  }

  handleContextMenu = async (event: React.MouseEvent, item: TreeItem) => {
    this.setState({
      contextMenuPosition: {
        x: event.clientX,
        y: event.clientY,
      },
      contextMenuItem: item,
    });
  }

  handleCloseMenu = () => this.setState({ contextMenuPosition: null })

  handleClickMenu = () => {
    this.handleCloseMenu()
  }

  render() {
    const { classes, open, dossier, tree, selectedItem, onSelect } = this.props;
    const { contextMenuPosition, contextMenuItem, exportDialogOpen } = this.state;
    if (!dossier) return null;
    return (
      <DossierContext.Consumer>
        {({
          routeToDocumentEditor,
          openDocumentPanel,
          uploadDocument,
          createFromTemplate,
          moveDocument,
          downloadDocument,
        }) => (
          <Panel open={open} className={classes.root}>
            <div className={classes.treeView}>
              <DocumentTree
                sortable
                tree={tree}
                selectedItem={selectedItem}
                onSelect={onSelect}
                onMoveIndex={moveDocument}
                onContextMenu={this.handleContextMenu}
              />
            </div>
            <div className={classes.actions}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ exportDialogOpen: true })}
              >
                {__('panels.export.title')}
                <SimpleSvgIcon className={classes.buttonIcon} path={mdiExport} />
              </Button>
            </div>
            {contextMenuPosition && contextMenuItem &&
              <Menu
                open
                id="simple-menu"
                keepMounted
                onClose={this.handleCloseMenu}
                onClickCapture={this.handleClickMenu}
                anchorReference="anchorPosition"
                anchorPosition={{
                  left: contextMenuPosition.x,
                  top: contextMenuPosition.y,
                }}
              >
                {contextMenuItem.type === 'file' ?
                  <>
                    <MenuItem onClick={() => routeToDocumentEditor()}>{__('document.edit')}</MenuItem>
                    <MenuItem onClick={() => openDocumentPanel(null, 'inspector')}>{__('document.viewInfo')}</MenuItem>
                    <MenuItem onClick={() => openDocumentPanel(null, 'versions')}>{__('document.viewVersions')}</MenuItem>
                    <MenuItem onClick={() => uploadDocument(null, true)}>{__('document.uploadNewVersion')}</MenuItem>
                    <MenuItem onClick={() => uploadDocument()}>{__('document.uploadSameClass')}</MenuItem>
                    <MenuItem onClick={() => createFromTemplate()}>{__('document.createSameClass')}</MenuItem>
                    <MenuItem onClick={() => downloadDocument()}>{__('document.download')}</MenuItem>
                  </>
                  :
                  <>
                    {/* <MenuItem onClick={this.handleCloseMenu}>{__('document.export')}</MenuItem> */}
                    {/* <MenuItem onClick={this.handleCloseMenu}>{__('document.createFromTemplate')}</MenuItem> */}
                  </>
                }
              </Menu>
            }
            <ExportDialog
              open={exportDialogOpen}
              onClose={() => this.setState({ exportDialogOpen: false })}
              dossier={dossier}
              selectedItem={selectedItem}
            />
          </Panel>
        )}
      </DossierContext.Consumer>
    )
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  dossier: states.dossier.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  setItem: (document: DocumentData) => dispatch(setItem(document)),
  getDocument: (dossierId, documentId) => dispatch(getDocument(dossierId, documentId))
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ExplorerPanel);
