/* eslint-disable react/no-multi-comp */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

import { intl, __ } from '../../utils/intl';
import ResponsiveDialog from '../Common/ResponsiveDialog';

interface CountdownDialogProps {
  countdown: number;
  onCancel: () => void;
}

class CountdownDialog extends React.Component<CountdownDialogProps> {

  render() {
    const { countdown, onCancel } = this.props;
    return (
      <Dialog
        open
        onClose={onCancel}
      >
        <DialogTitle>
          {__('settings.languageDialog.timeout.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {__('settings.languageDialog.timeoutMessage', { second: countdown })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>
            {__('dialog.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

interface Props {
  open: boolean;
  onSelect: (language: string) => void;
  onClose: (state?: string) => void;
}

interface State {
  selectedValue: string;
  countdown: number;
  showingCountdownDialog: boolean;
}

class LanguagePicker extends React.Component<Props, State> {
  
  changeLanguageTimer: any = null;

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.setState(this.getInitialState());
    }
  }

  getInitialState() {
    return {
      countdown: 5,
      selectedValue: intl.getStoredLanguage(),
      showingCountdownDialog: false,
    }
  }

  handleSubmit() {
    const { onClose } = this.props;
    const { selectedValue } = this.state;
    if (selectedValue === intl.language) {
      onClose();
      return;
    }
    this.setState({
      countdown: 5,
      showingCountdownDialog: true,
    });
    this.changeLanguageTimer = setInterval(() => {
      const countdown = this.state.countdown - 1;
      if (countdown === 0) {
        this.setState({ countdown, showingCountdownDialog: false });
        clearInterval(this.changeLanguageTimer);
        setTimeout(() => {
          this.props.onSelect(selectedValue);
        }, 200);
      } else {
        this.setState({ countdown, showingCountdownDialog: true });
      }
    }, 1000);
  }

  cancelLanguageChange = () => {
    clearInterval(this.changeLanguageTimer);
    this.setState({ showingCountdownDialog: false }, () => {
      this.props.onClose('cancelled');
    });
  }

  render() {
    const { open, onClose } = this.props;
    const { countdown, selectedValue } = this.state;
    const currentLang = intl.getStoredLanguage();
    if (countdown === 0) {
      return null;
    }
    return !this.state.showingCountdownDialog ?
      <ResponsiveDialog
        modal
        open={open}
        title={__({ id: 'settings.changeLanguage' })}
        onClose={() => onClose()}
        actions={
          <>
            <Button
              color="inherit"
              onClick={() => onClose()}
            >
              {__('dialog.cancel')}
            </Button>
            <Button
              color="inherit"
              onClick={() => this.handleSubmit()}
            >
              {__('dialog.ok')}
            </Button>
          </>
        }
      >
        <FormControl
        >
          <FormLabel component="legend">{__('settings.selectLanguage')}</FormLabel>
          <RadioGroup
            value={selectedValue}
            onChange={(event, value) => this.setState({ selectedValue: value })}
          >
            <FormControlLabel value="auto" control={<Radio />} label={intl.getLanguageName('auto')} />
            {intl.getLanguageList().map(locale => (
              <FormControlLabel
                key={locale.lang}
                value={locale.lang}
                label={intl.getLanguageName(locale.lang)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </ResponsiveDialog>
    :
      <CountdownDialog countdown={countdown} onCancel={this.cancelLanguageChange} />
    ;
  }
}

export default LanguagePicker;
