import clsx from 'clsx';
import React from 'react';
import classNames from 'classnames';
import { AnyAction } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, Route } from 'react-router';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

import { DossierData } from '../../types/dossier';
import { UserData } from '../../types/user';
import { RootState } from '../../store/reducers';
import {
  PanelStatus,
  PanelSide,
  SinglePanelStatus,
  getContainerPosition,
} from '../../store/ui/reducers';
import {
  updatePanelStatus,
} from '../../store/ui/actions';
import TopNav from '../TopNav/TopNav';
import SideNav from '../SideNav/SideNav';
import { isInDossierRoute } from '../Dossier/utils';
import Chat from '../Chat/Chat';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(0deg, #d8d8d8 0%, #eee 100%)',
  },
  main: {
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    top: 64,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'auto',
    transition: 'all 0.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      top: 56,
    },
  },
});

interface OwnProps {
  className?: string;
  mainClassName?: string;
  simple?: boolean;
}

interface StateProps {
  user: UserData | null;
  dossier: DossierData | null;
  panelStatus: PanelStatus;
}

interface DispatchProps {
  updatePanelStatus: (side: PanelSide, status: Partial<SinglePanelStatus>) => void,
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps & WithWidth & WithStyles<typeof styles>;

class Layout extends React.Component<Props> {

  componentDidMount() {
    this.onWidthChange(this.props);
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.width !== this.props.width) {
     this.onWidthChange(nextProps);
    }
  }

  onWidthChange(props: Props) {
    const { width, updatePanelStatus } = props;
    const inDossierRoute = isInDossierRoute();
    if (isWidthDown('sm', width)) {
      updatePanelStatus('side', { open: false, pinned: false, minified: false });
      updatePanelStatus('chat', { open: false, pinned: false, minified: false });
    } else if (isWidthDown('md', width)) {
      updatePanelStatus('side', { open: true, pinned: true, minified: true });
      updatePanelStatus('chat', { open: true, pinned: true, minified: true });
    } else if (isWidthDown('lg', width)) {
      updatePanelStatus('side', { open: true, pinned: true, minified: inDossierRoute });
      updatePanelStatus('chat', { open: true, pinned: true, minified: true });
    } else if (isWidthDown('xl', width)) {
      updatePanelStatus('side', { open: true, pinned: true, minified: false });
      updatePanelStatus('chat', { open: true, pinned: true, minified: true });
    } else {
      updatePanelStatus('side', { open: true, pinned: true, minified: false });
      updatePanelStatus('chat', { open: true, pinned: true, minified: false });
    }
    if (isWidthDown('md', width)) {
      updatePanelStatus('left', { open: false, pinned: false });
      updatePanelStatus('right', { open: false, pinned: false });
    } else if (isWidthDown('lg', width)) {
      updatePanelStatus('left', { open: true, pinned: true });
      updatePanelStatus('right', { open: false, pinned: false });
    } else {
      updatePanelStatus('left', { open: true, pinned: true });
      updatePanelStatus('right', { open: true, pinned: true });
    }
  }

  render() {
    const { classes, className, mainClassName, simple, panelStatus, user, children } = this.props;
    const inDossierRoute = isInDossierRoute();
    const position = getContainerPosition(panelStatus, inDossierRoute);
    return (
      <div className={classNames(classes.root, className)}>
        {!simple && (
          inDossierRoute ?
          <Route path="/dossier/:dossierId/:viewMode?" component={TopNav}/>
          :
          <Route path="*" component={TopNav}/>
        )}
        <main
          className={clsx(classes.main, mainClassName)}
          style={{
            top: simple ? 0 : undefined,
            ...position,
          }}
        >
          {children}
        </main>
        {!simple &&
          <Route path="*" component={SideNav} />
        }
        {!simple && user &&
          <Chat />
        }
      </div>
    )
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  user: states.user.current,
  dossier: states.dossier.current,
  panelStatus: states.ui.panelStatus,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  updatePanelStatus: (side, status) => dispatch(updatePanelStatus(side, status)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(Layout);
