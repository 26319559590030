import _ from 'lodash';
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { __ } from '../../utils/intl';

import { FormItem } from '../../types/form';
import { ClientError } from '../../store/client';
import { FormControl } from '../Form';
import { TeamData } from '../../types/team';


const styles = (theme: Theme) => createStyles({
  dialogContent: {
    width: 320,
  },
  errorMessage: {
    color: '#f44336',
  },
});

interface OwnProps {
  mode: 'create' | 'edit';
  open: boolean;
  data?: Partial<TeamData> | null;
  onClose: () => void;
  onSave: (data: Partial<TeamData>) => Promise<void>;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  data: Partial<TeamData>;
  errorMessage: string;
}

class TeamEditDialog extends React.Component<Props> {

  state: State = {
    ...TeamEditDialog.getStateFromProps(this.props),
    errorMessage: '',
  }

  static getStateFromProps(props: Props) {
    return {
      data: props.data ? _.pick(props.data, ['name', 'description', 'avatar']) : {
        name: '',
        description: '',
        avatar: '',
      },
    };
  }

  static getDerivedStateFromError(error: any): Partial<State> | null {
    if (error instanceof ClientError) {
      return {
        errorMessage: error.format(),
      };
    }
    return null;
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.data !== this.props.data) {
      this.setState(TeamEditDialog.getStateFromProps(nextProps));
    }
  }

  buildFormItems(): FormItem[] {
    return [
      { key: 'name', label: __('team.field.name'), type: 'text' },
      { key: 'description', label: __('team.field.description'), type: 'textarea' },
    ]
  }
  
  async handleSave() {
    const { onClose, onSave } = this.props;
    const { data } = this.state;
    try {
      await onSave(data);
      onClose();
    } catch (err) {
      const newState = TeamEditDialog.getDerivedStateFromError(err);
      if (newState) {
        this.setState(newState);
      }
    }
  }

  render() {
    const { classes, mode, open, onClose } = this.props;
    const { data, errorMessage } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{mode === 'create' ? __('team.create') : __('team.edit')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl
            items={this.buildFormItems()}
            value={data}
            onChange={value => this.setState({ data: value, errorMessage: '' })}
          />
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{__('dialog.cancel')}</Button>
          <Button color="primary" onClick={() => this.handleSave()}>{__('dialog.save')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

export default withStyles(styles)(TeamEditDialog);
