import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { InvitationStatus } from '../../types/invitation';

interface Props extends SvgIconProps {
  status: InvitationStatus;
}

const InvitationStatusIcon: React.SFC<Props> = (props) => {
  const { status, ...restProps } = props;
  switch (props.status) {
    case 'accepted':
      return <CheckIcon {...restProps} />;
    case 'canceled':
      return <CancelIcon {...restProps} />;
    case 'pending':
      return <HelpIcon {...restProps} />;
    case 'rejected':
      return <CloseIcon {...restProps} />;
    default:
      return null;
  }
}

export default InvitationStatusIcon;
