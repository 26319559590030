import _ from 'lodash';
import React from 'react';
import clsx from 'clsx';
import { List, ListSubheader, Divider, Button, Typography } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import ListIcon from '@material-ui/icons/List';
import { mdiAccountMultiple } from '@mdi/js';

import { __ } from '../../utils/intl';
import { TeamData } from '../../types/team';
import { DossierListQuery } from '../../types/dossier';
import SimpleSvgIcon from '../Common/SimpleSvgIcon';
import SimpleListItem from '../Common/SimpleListItem';

const styles = (theme: Theme) => createStyles({
  root: {
  },
  list: {
    overflow: 'auto',
  },
  actions: {
    margin: theme.spacing(1),
  },
});

interface OwnProps {
  list: TeamData[];
  onCreateTeam: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
}

class TeamList extends React.Component<Props, State> {

  state: State = {
    selectedItem: null,
  }

  render() {
    const { classes, list, onCreateTeam } = this.props;
    return (
      <div className={classes.root}>
        <List
          className={classes.list}
          subheader={<ListSubheader>{__('team.teams')}</ListSubheader>}
        >
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={() => onCreateTeam()}
            >
              {__('team.create')}
            </Button>
          </div>
          {list.map(item => (
            <SimpleListItem
              key={item._id}
              title={item.name}
              icon={<SimpleSvgIcon path={mdiAccountMultiple} />}
              link={`/team/view/${item._id}`}
            />
          ))}
        </List>
      </div>
    );
  }

}

export default withStyles(styles)(TeamList);
