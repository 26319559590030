import _ from 'lodash';
import React from 'react';
import flat from 'flat';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  List,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import client from '../../store/client';
import { getJSON } from '../../utils/ajax';
import { getPublicUrl } from '../../utils/url';
import { __ } from '../../utils/intl';
import Loading from '../Common/Loading';

const styles = (theme: Theme) => createStyles({
  listSubheader: {
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
});

interface BuildInfo {
  trigger: string;
  version: string;
  time: string;
  git: {
    commit: string;
    branch: string;
  }
}

interface FlatBuildInfo {
  trigger: string;
  version: string;
  time: string;
  'git.commit': string;
  'git.branch': string;
}

interface OwnProps {
  open: boolean;
  onClose: () => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  apiInfo: BuildInfo | null;
  uiInfo: BuildInfo | null;
}

class BuildInfoDialog extends React.Component<Props, State> {

  state = this.getInitialState();

  getInitialState() {
    return {
      apiInfo: null,
      uiInfo: null,
    }
  }

  componentDidMount() {
    if (this.props.open) {
      this.loadData();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.open && !this.props.open) {
      this.loadData();
    }
  }

  async loadData() {
    this.setState(this.getInitialState());
    const apiInfo = await client.get<BuildInfo>('/build-info');
    const uiInfo = await getJSON(getPublicUrl('/build-info.json'));
    this.setState({
      apiInfo,
      uiInfo,
    })
  }

  renderInfo(title: string, info: BuildInfo | null) {
    const { classes } = this.props;
    if (!info){
      return <Loading />
    }
    const map = flat<BuildInfo, FlatBuildInfo>(info);
    return (
      <List
        subheader={
          <ListSubheader className={classes.listSubheader}>
            {title}
          </ListSubheader>
        }
      >
        {_.keys(map).map(key => {
          let value = (map as any)[key];
          if (key === 'time' && value !== 'unknown') {
            value = (new Date(value)).toLocaleString();
          }
          return (
            <ListItem key={key}>
              <ListItemText
                primary={key}
                secondary={value}
              />
            </ListItem>  
          )
        })}
      </List>
    )
  }

  render() {
    const { open, onClose } = this.props;
    const { apiInfo, uiInfo } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Build Info</DialogTitle>
        <DialogContent>
          <Typography>
            LOL you found our secret! These information are for developers only.
          </Typography>
          {this.renderInfo('Api Info', apiInfo)}
          {this.renderInfo('UI Info', uiInfo)}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            {__('dialog.close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(BuildInfoDialog);
