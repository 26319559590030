import React from 'react';
import { ViewMode } from '../../types/view';
import { RightPanelType } from '../../store/ui/reducers';
import { DossierTreeItem } from '../../types/dossier';
import { TargetIdentity } from '../../types/notification';

export interface DownloadDocumentOptions {
  page?: number;
  version?: number;
}
export interface DossierState {
  viewMode?: ViewMode;
  dossierId?: string;
  setCurrentItem: (item: DossierTreeItem) => void;
  routeToDocumentEditor: (item?: DossierTreeItem | null) => Promise<void>;
  routeToEvidenceEditor: (item?: DossierTreeItem | null) => Promise<void>;
  openDocumentPanel: (item?: DossierTreeItem | null, type?: RightPanelType) => void;
  uploadDocument: (item?: DossierTreeItem | null, newVersion?: boolean) => Promise<void>;
  createFromTemplate: (item?: DossierTreeItem | null, empty?: boolean) => Promise<void>;
  moveDocument: (itemId: string, movement: number) => Promise<void>;
  downloadDocument: (item?: DossierTreeItem | null, options?: DownloadDocumentOptions) => Promise<void>;
  shareInDossier: (target: TargetIdentity) => void;
}

const defaultDossierContext: DossierState = {
  viewMode: ViewMode.Scroll,
  setCurrentItem: () => {},
  routeToDocumentEditor: async () => {},
  routeToEvidenceEditor: async () => {},
  openDocumentPanel: () => {},
  uploadDocument: async () => {},
  createFromTemplate: async () => {},
  moveDocument: async () => {},
  downloadDocument:  async () => {},
  shareInDossier: () => {},
}

export const DossierContext = React.createContext<DossierState>(defaultDossierContext);
