import _ from 'lodash';
import qs from 'qs';
import clsx from 'clsx';
import React from 'react';
import moment from 'moment';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { List, Divider, IconButton, Box, InputBase, Tooltip } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import { blue, grey } from '@material-ui/core/colors';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import OpenIcon from '@material-ui/icons/OpenInNew';
import { RouteComponentProps } from 'react-router-dom';

import { ChatThreadData, ChatMessageData, ChatMessageOptions } from '../../types/chat';
import { UserData, PublicUserInfo } from '../../types/user';
import { __ } from '../../utils/intl';
import { getUsername } from '../../utils/data';
import { scrollIntoViewById } from '../../utils/ui';
import { openTarget, getTargetName, getTargetTitle } from '../../utils/notification';
import { RootState } from '../../store/reducers';
import { PanelStatus, getContainerPosition } from '../../store/ui/reducers';
import {
  setThread,
  fetchMessageListRemote,
  setThreadReadRemote,
} from '../../store/chat/actions';
import { socket } from '../../store/socket';
import UserAvatar from '../Common/UserAvatar';
import { isInDossierRoute } from '../Dossier/utils';
import { history } from '../../store';
import Attachment from './Attachment';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: 320,
    height: 440,
    right: 16,
    bottom: 16,
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transform: 'scale(0)',
    transformOrigin: 'bottom right',
    transition: 'all ease-in-out 0.2s',
  },
  open: {
    transform: 'scale(1)',
  },
  header: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    lineHeight: '26px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  headerButton: {
    margin: theme.spacing(1),
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    backgroundColor: grey[100],
    padding: theme.spacing(0, 1),
  },
  divider: {
    margin: theme.spacing(1),
    fontSize: '12px',
    textAlign: 'center',
    color: grey[500],
  },
  message: {
    margin: theme.spacing(1.5, 0),
    display: 'flex',
    flexDirection: 'row',
  },
  messageSelf: {
    flexDirection: 'row-reverse',
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: '1rem',
  },
  content: {
    margin: theme.spacing(0, 1),
    maxWidth: 220,
  },
  text: {
    borderRadius: 4,
    padding: theme.spacing(1, 1.5),
    backgroundColor: '#fff',
  },
  textSelf: {
    backgroundColor: blue[300],
    '&::selection': {
      background: blue[700],
      color: '#fff',
    },
  },
  footer: {
    padding: theme.spacing(0.5),
    display: 'flex',
  },
  input: {
    flex: 1,
    margin: '0 10px',
  },
  sendButton: {

  },
});

interface StateProps {
  panelStatus: PanelStatus;
  user: UserData | null;
  thread: ChatThreadData | null;
  messageList: ChatMessageData[];
}

interface DispatchProps {
  setThread: (thead: ChatThreadData | null) => void;
  fetchMessageListRemote: (threadId: string) => Promise<ChatMessageData[]>;
  setThreadReadRemote: (threadId: string, date: Date) => Promise<void>;
}

interface OwnProps { }

interface OwnProps {
}

interface Params {

}

type Props = StateProps & DispatchProps & OwnProps & WithWidth & WithStyles<typeof styles> & RouteComponentProps<Params>;

interface State {
  inputValue: string;
}

class ChatWindow extends React.Component<Props, State> {

  state: State = {
    inputValue: '',
  };

  async componentWillReceiveProps(nextProps: Props) {
    const { thread, setThreadReadRemote } = this.props;
    if (nextProps.thread && (!thread || thread._id !== nextProps.thread._id)) {
      await this.loadData(nextProps);
    }
    if (nextProps.messageList !== this.props.messageList) {
      setTimeout(() => this.scrollToBottom(), 0);
      const lastMessage = _.last(nextProps.messageList);
      if (nextProps.thread && lastMessage) {
        setThreadReadRemote(nextProps.thread._id, new Date);
      }
    }
  }

  getMessageDomId(message: ChatMessageData) {
    return `chat-message-${message._id}`;
  }

  scrollToBottom() {
    const { messageList } = this.props;
    const lastMessage = _.last(messageList);
    if (lastMessage) {
      const elementId = this.getMessageDomId(lastMessage);
      scrollIntoViewById(elementId);
    }
  }

  async loadData(props: Props) {
    const { fetchMessageListRemote } = this.props;
    const { thread } = props;
    if (!thread) return;
    await fetchMessageListRemote(thread._id);
  }

  handleOpenTarget = () => {
    const { thread } = this.props;
    if (thread && thread.type === 'target' && thread.target) {
      openTarget(thread.target);
    }
  }

  handleClose = () => {
    const { setThread } = this.props;
    setThread(null);
  }

  handleSend() {
    const { user, thread } = this.props;
    const { inputValue } = this.state;
    if (!user || !thread || !inputValue) return;
    const options: ChatMessageOptions = {
      from: {
        user_id: user._id,
      },
      to: {
        type: 'thread',
        thread_id: thread._id,
      },
      message: inputValue,
    };
    socket.emit('im:send', options);
    this.setState({ inputValue: '' });
  }

  getUser(userId: string): PublicUserInfo | null {
    const { thread } = this.props;
    let user: PublicUserInfo | null = null;
    if (thread) {
      const participant = thread.participants.find(item => item.user_id === userId);
      if (participant) {
        user = participant.user || null;
      }
    }
    return user;
  }

  getTitle(): string {
    const { thread, user: currentUser } = this.props;
    if (!thread || !currentUser) return '';
    if (thread.type === 'pair') {
      const item = thread.participants.find(item => item.user_id !== currentUser._id);
      const username = getUsername(item && item.user);
      // __('chat.title.pair')
      return __('chat.title.pair', { username });
    } else if (thread.type === 'target' && thread.target) {
      const { target } = thread;
      const name = getTargetName(target);
      if (target.type === 'team') {
        // __('chat.title.team')
        return __('chat.title.team', { name });
      } else if (target.type === 'dossier') {
        // __('chat.title.dossier')
        return __('chat.title.dossier', { name });
      } else {
        return name;
      }
    }
    return '';
  }

  renderMessages() {
    const { classes, messageList } = this.props;
    const sorted = _.sortBy(messageList, 'dateCreated');
    const groupByTime = _.groupBy(sorted, item => moment(item.dateCreated).format('lll'));
    const list: (React.ReactElement | null)[] = [];
    _.each(groupByTime, (messages, time) => {
      list.push(
        <div key={`time-divider-${time}`} className={classes.divider}>
          {time}
        </div>
      );
      messages.forEach(message => {
        list.push(this.renderMessage(message));
      });
    });
    return list;
  }

  renderMessage = (message: ChatMessageData) => {
    const { classes, user: currentUser } = this.props;
    const user = this.getUser(message.from.user_id);
    if (!currentUser || !user) return null;
    const isSelf = message.from.user_id === currentUser._id;
    return (
      <div
        key={message._id}
        id={this.getMessageDomId(message)}
        className={clsx(classes.message, { [classes.messageSelf]: isSelf })}
      >
        <UserAvatar title={getUsername(user)} className={classes.avatar} user={user} />
        <div className={classes.content}>
          {message.message &&
            <div className={clsx(classes.text, { [classes.textSelf]: isSelf })}>
              {message.message}
            </div>
          }
          {message.attachment &&
            <Attachment isSelf={isSelf} attachment={message.attachment} />
          }
        </div>
      </div>
    );
  }

  render() {
    const { classes, panelStatus, user, thread } = this.props;
    const { inputValue } = this.state;
    if (!user || !thread) return null;
    const inDossierRoute = isInDossierRoute();
    const position = getContainerPosition(panelStatus, inDossierRoute);
    const title = this.getTitle();
    return (
      <div
        className={clsx(classes.root, { [classes.open]: open })}
        style={{
          right: position.right + 16,
        }}
      >
        <header className={classes.header}>
          <div className={classes.title} title={title}>{title}</div>
          {thread.type === 'target' && thread.target &&
            <Tooltip placement="top" title={__('target.open', { target: getTargetTitle(thread.target) })}>
              <IconButton className={classes.headerButton} size="small" onClick={this.handleOpenTarget}>
                <OpenIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          <Tooltip placement="top" title={__('dialog.close')}>
            <IconButton className={classes.headerButton} size="small" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </header>
        <div className={classes.body}>
          {this.renderMessages()}
        </div>
        <footer className={classes.footer}>
          <InputBase
            autoFocus
            className={classes.input}
            placeholder={__('chat.inputPlaceholder')}
            value={inputValue}
            onChange={(event: any) => this.setState({
              inputValue: event.target.value,
            })}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                this.handleSend();
              }
            }}
          />
          <IconButton
            color="primary"
            className={classes.sendButton}
            onClick={() => this.handleSend()}
          >
            <SendIcon />
          </IconButton>
        </footer>
      </div>
    );
  }

}

const mapStateToProps = (states: RootState): StateProps => {
  const { thread, messages } = states.chat;
  return {
    panelStatus: states.ui.panelStatus,
    user: states.user.current,
    thread,
    messageList: thread && messages[thread._id] || [],
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  setThread: (item) => dispatch(setThread(item)),
  fetchMessageListRemote: (id) => dispatch(fetchMessageListRemote(id)),
  setThreadReadRemote: (id, date) => dispatch(setThreadReadRemote(id, date)),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ChatWindow);
