import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

interface Props extends BoxProps {
  className?: string;
  outerProps?: BoxProps;
  innerProps?: BoxProps;
  paper?: boolean;
}

class CenterBox extends React.Component<Props> {
  render() {
    const { children, className, outerProps, innerProps, paper } = this.props;
    return (
      <Box
        width="100%"
        padding={[0, 4]}
        className={className}
        {...outerProps}
      >
        <Box
          component={paper ? Paper : 'div'}
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          maxWidth={720}
          marginX="auto"
          padding={[0, 4]}
          {...innerProps}
        >
          {children}
        </Box>
      </Box>
    );
  }
}

export default CenterBox;
