import _ from 'lodash';
import React from 'react';
import Layout from '../Common/Layout';
import qs from 'qs';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Paper, Fab } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/AccountCircle';
import { RouteComponentProps, Route, Switch } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { getFullName } from '../../utils/data';
import { RootState } from '../../store/reducers';
import { fetchListRemote } from '../../store/person/actions';
import { ContactPersonData } from '../../types/contact.person';
import PersonEditor from './PersonEditor';
import PersonListPartial from './PersonListPartial';

const styles = (theme: Theme) => createStyles({
  root: {
    // minHeight: 400,
    paddingBottom: 64,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 56px - 56px)',
    },
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
});

interface StateProps {
  current: ContactPersonData | null;
  list: ContactPersonData[];
}

interface DispatchProps {
  fetchListRemote: () => Promise<void>,
}

interface OwnProps {};

interface Params {
  personId: string;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithWidth & WithStyles<typeof styles>;

interface State {
}

class PersonList extends React.Component<Props, State> {

  componentDidMount() {
    const { fetchListRemote } = this.props;
    fetchListRemote();
  }

  render() {
    const { classes, width, history, list, location } = this.props;
    return (
      <Switch>
        <Route
          exact
          path="/contacts/person"
          render={() => (
            <Paper
              className={classNames(classes.root, { hidden: location.pathname !== '/contacts/person' })}
              square={isWidthDown('xs', width)}
            >
              <PersonListPartial
                hasFilter
                title={__('contacts.personList')}
                list={list}
              />
              <Fab
                className={classes.fab}
                color="primary"
                onClick={() => history.push('/contacts/person/create')}
              >
                <AddIcon />
              </Fab>
            </Paper>
          )}
        />
        <Route path="/contacts/person/create" component={PersonEditor} />
        <Route path="/contacts/person/item/:personId" component={PersonEditor} />
      </Switch>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  current: states.person.current,
  list: _.sortBy(states.person.list, item => getFullName(item.profile)),
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchListRemote: () => dispatch(fetchListRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withWidth(),
  withStyles(styles),
)(PersonList);
