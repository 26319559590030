import _ from 'lodash'
import moment from 'moment'
// import faker from 'faker'

// export default _.orderBy(_.times(10, n => ({
//   label: faker.lorem.word(),
//   desc: faker.lorem.sentence(),
//   startAt: faker.date.past(),
//   endAt: faker.date.recent(),
//   color: '#e6f0ef',
// })), ['startAt'], ['asc'])

export default _.orderBy(_.map([
  {
    label: 'Plaintiff’s Home',
    desc: 'Purchased Tickets for skiing',
    position: { lat: 34.293430, lng: -109.805138 },
    date: '3, 13, 2017 8:00 AM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Boarded defendant’s premises',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 9:30 AM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Skis fell to the ground',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 11:29 AM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Began Waving arms and yelling',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 11:32 AM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Standing at the observation window',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 11:51 AM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'The lift operator did not stop the lift',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 12:17 PM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Fell under the platform',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 12:45 PM',
  },

  {
    label: 'Defendant’s premises',
    desc: 'Carried plaintiff down the mountain on a sled',
    position: { lat: 33.9106043, lng: -109.5872813 },
    date: '3, 13, 2017 1:40 PM',
  },

  {
    label: 'White Mountain Hospital',
    desc: 'Sent plaintiff to hospital',
    position: { lat: 34.131646, lng: -109.2878447, },
    date: '3, 13, 2017 2:10 PM',
  },
], item => ({
  ...item,
  startAt: moment(item.date, 'M, D, YYYY hh:ss a').toDate(),
  endAt: moment(item.date, 'M, D, YYYY hh:ss a').toDate(),
  color: '#e6f0ef',
})), ['startAt'], ['asc'])
