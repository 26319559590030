import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { __ } from '../../utils/intl';

import { getUsername } from '../../utils/data';
import { FormItem } from '../../types/form';
import { MemberData, memberRoleLabels } from '../../types/member';
import { PublicUserInfo } from '../../types/user';
import { InvitationOptions } from '../../types/invitation';
import { ClientError } from '../../store/client';
import { buildOptionsFromMap } from '../Form/utils';
import { FormControl } from '../Form';

const memberRoleOptions = buildOptionsFromMap(memberRoleLabels).filter(option => option.value !== 'owner');

const styles = (theme: Theme) => createStyles({
  dialogContent: {
    width: 320,
  },
  errorMessage: {
    color: '#f44336',
  },
});

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onLoadCollaborators: () => Promise<PublicUserInfo[]>;
  onInvite: (options: InvitationOptions) => Promise<MemberData>;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  collaborators: PublicUserInfo[];
  data: InvitationOptions;
  errorMessage: string;
}

class InviteMember extends React.Component<Props> {

  state: State = {
    collaborators: [],
    data: {
      userType: 'existed',
      to: '',
      mobile: '',
      role: 'normal',
      name: '',
    },
    errorMessage: '',
  }

  static getDerivedStateFromError(error: any): Partial<State> | null {
    // __('errors.invitation.member_existed')
    if (error instanceof ClientError) {
      return {
        errorMessage: error.format(),
      };
    }
    return null;
  }

  async componentDidMount() {
    const { onLoadCollaborators: loadCollaborators } = this.props;
    const collaborators = await loadCollaborators();
    this.setState({ collaborators });
  }

  buildFormItems(): FormItem[] {
    const { collaborators } = this.state;
    const options = collaborators.map(user => ({
      label: getUsername(user) || '',
      value: user._id,
    }));
    return [
      {
        key: 'userType', label: __('invite.userType'), type: 'select', control: 'radio',
        options: [
          { value: 'existed', label: __('invite.userType.existed') },
          { value: 'mobile', label: __('invite.userType.mobile') },
        ],
      },
      {
        key: 'to', label: __('invite.userType.existed'), type: 'select', multiple: false, options,
        condition: { key: 'userType', operator: '=', value: 'existed' },
      },
      {
        key: 'mobile', label: __('profile.field.mobilePhone'), type: 'text',
        condition: { key: 'userType', operator: '=', value: 'mobile' },
      },
      {
        key: 'name', label: __('profile.field.name'), type: 'text',
        condition: { key: 'userType', operator: '=', value: 'mobile' },
      },
      {
        key: 'role', label: __('member.role'), type: 'select',
        options: memberRoleOptions,
      },
    ]
  }
  
  async handleInvite() {
    const { onClose, onInvite } = this.props;
    const { data } = this.state;
    try {
      await onInvite(data);
      onClose();
    } catch (err) {
      const newState = InviteMember.getDerivedStateFromError(err);
      if (newState) {
        this.setState(newState);
      }
    }
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { data, errorMessage } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle>{__('member.invite')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl
            items={this.buildFormItems()}
            value={data}
            onChange={value => this.setState({ data: value, errorMessage: '' })}
          />
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{__('dialog.cancel')}</Button>
          <Button color="primary" onClick={() => this.handleInvite()}>{__('dialog.ok')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

export default withStyles(styles)(InviteMember);
