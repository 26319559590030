import _ from 'lodash';
import { KeyValueMap } from '../../types/common';
import {
  FormItem,
  SimpleFormItemTypes,
  FormDefinitions,
  FormItemReference,
  SelectFormItem,
  SelectOption,
} from '../../types/form';

export function isFlatFormItem(item: FormItem) {
  return ['group', 'person', 'organization'].includes(item.type) && !item.key;
}

export function getFormItemPath(path: string, item: FormItem) {
  const { key } = item;
  if (key) {
    return path ? `${path}.${key}` : key;
  } else {
    return path;
  }
}

export function normalizeSelectOptions(item: SelectFormItem): (SelectOption | string)[] {
  const { options} = item;
  if (!options) return [];
  return options.map(option => {
    switch (item.optionsType) {
      case 'simple': {
        return _.isString(option) ? option : option.value;
      }
      case undefined:
      case 'pair':
        return _.isString(option) ? {
          label: option,
          value: option,
        } : option;
      default:
        return option;
    }
  });
}

export function buildSelectOptions(item: SelectFormItem): SelectOption[] {
  const { options } = item;
  if (!options) return [];
  switch (item.optionsType) {
    case 'simple': {
      return options.map(option => {
        if (!_.isString(option)) {
          console.error(option);
          throw new TypeError('SelectFormItem: invalid options for single options');
        }
        return {
          label: option,
          value: option,
        };
      });
    }
    case undefined:
    case 'pair':
      return options.map(option => {
        if (!_.isObject(option) || !_.isString(option.label) || !_.isString(option.value)) {
          console.error(option);
          throw new TypeError('SelectFormItem: invalid option for pair select');
        }
        return option;
      });
    default:
      return [];
  }
}

export function shouldFormItemShow(pathFilters: string[] | null, path: string, item: FormItem) {
  return !pathFilters || (
    item.type && SimpleFormItemTypes.includes(item.type)
    ? pathFilters.includes(path)
    : pathFilters.find(p => p.indexOf(path) === 0)
  )
}

export function buildOptionsFromMap(map: KeyValueMap<string>) {
  return _.map(_.toPairs(map), ([value, label]) => ({ value, label }));
}

export function getDefinition(definitions: FormDefinitions | undefined, ref: string): FormItem | undefined {
  if (!definitions || !ref) return undefined;
  const match = /^#\/definitions\/([a-z]\w+)$/.exec(ref);
  if (!match) return undefined;
  const key = match[1];
  return definitions[key];
}

export function parseReference(definitions: FormDefinitions | undefined, item: FormItemReference): FormItem {
  const ref = item.$ref;
  const referenceItem = getDefinition(definitions, ref);
  if (!referenceItem) {
    throw new Error(`undefined reference "${item.$ref}"`);
  }
  return {
    ...referenceItem,
    ..._.omit(item, ['type', '$ref']),
  };
}
