import React from 'react';
import LazyLoad from 'react-lazyload';
import Loading from './Loading';

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  src?: string;
  scrollContainer?: string | Element;
}

interface State {
  loading: boolean;
}

export default class LazyloadImage extends React.Component<Props, State> {

  private image: HTMLImageElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  loadImage() {
    const { src } = this.props;
    if (!src) return;
    const image = document.createElement('img');
    image.src = src;
    image.addEventListener('load', () => this.setState({ loading: false }));
    this.image = image;
  }

  render() {
    const { src, scrollContainer, ...props } = this.props;
    const { loading } = this.state;
    return (
      <LazyLoad placeholder={<Loading />} scrollContainer={scrollContainer}>
        {loading ?
          <Loading onLoad={() => this.loadImage()} />
          :
          <img src={src} {...props} />
        }
      </LazyLoad>
    )
  }
}
