import _ from 'lodash';
import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { compose } from 'recompose';
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/AccountCircle';
import ContactsIcon from '@material-ui/icons/ContactPhone';
import GroupIcon from '@material-ui/icons/GroupWork';
import { RouteComponentProps } from 'react-router-dom';

import { __ } from '../../utils/intl';
import { RootState } from '../../store/reducers';
import { UserData } from '../../types/user';
import { fetchCurrentUser } from '../../store/user/actions';
import { fetchListRemote as fetchPersonListRemote } from '../../store/person/actions'
import { fetchListRemote as fetchOrganizationListRemote } from '../../store/organization/actions'
import Layout from '../Common/Layout';
import UserAvatar from '../Common/UserAvatar';
import OrganizationList from './OrganizationList';
import PersonList from './PersonList';

const styles = (theme: Theme) => createStyles({
  nav: {
    position: 'fixed',
    width: 200,
    height: 'calc(100vh - 64px)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  avatar: {
    display: 'block',
    margin: '20px auto',
    width: 128,
    height: 128,
  },
  bigIcon: {
    fontSize: 64,
    margin: 32,
  },
  main: {
    flex: 1,
    marginLeft: 200,
    // padding: theme.spacing(4),
    overflow: 'auto',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  container: {
    maxWidth: 640,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0',
    },
  },
  bottomNav: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
});

interface StateProps {
  currentUser: UserData | null;
}

interface DispatchProps {
  fetchCurrentUser: () => Promise<void>,
  fetchPersonListRemote: () => Promise<void>,
  fetchOrganizationListRemote: () => Promise<void>,
}

interface OwnProps {};

interface Params {
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<Params> & WithStyles<typeof styles>;

interface State {
}

class Contacts extends React.Component<Props, State> {

  componentDidMount() {
    const { fetchPersonListRemote } = this.props;
    fetchPersonListRemote();
    fetchOrganizationListRemote();
  }

  render() {
    const { classes, history, location } = this.props;
    const subRoute = location.pathname.split('/')[2];
    return (
      <Layout className="account-layout">
        <Paper component="nav" className={classes.nav} square>
          <List>
            <div>
              <Avatar className={classes.avatar}>
                <ContactsIcon className={classes.bigIcon} />
              </Avatar>
            </div>
          </List>
          <List>
            <ListItem
              button
              selected={subRoute === 'organization'}
              onClick={() => history.push('/contacts/organization')}
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={__('contacts.organization')} />
            </ListItem>
            <ListItem
              button
              selected={subRoute === 'person'}
              onClick={() => history.push('/contacts/person')}
            >
              <ListItemIcon>
                <UserIcon />
              </ListItemIcon>
              <ListItemText primary={__('contacts.person')} />
            </ListItem>
          </List>
        </Paper>
        <section className={classes.main}>
          <div className={classes.container}>
            <Switch>
              <Route path="/contacts/organization" component={OrganizationList} />
              <Route path="/contacts/person" component={PersonList} />
              <Route
                path="/contacts"
                render={({ location }) => {
                  if (location.pathname === '/contacts') {
                    return <Redirect to="/contacts/organization" />
                  } else {
                    return null;
                  }
                }}
              />
            </Switch>
          </div>
        </section>
        <Paper
          square
          className={classes.bottomNav}
        >
          <BottomNavigation
            showLabels
            value={subRoute}
            onChange={(event, newValue) => {
              history.push(`/contacts/${newValue}`);
            }}
          >
            <BottomNavigationAction
              value="organization"
              label={__('contacts.organization')}
              icon={<GroupIcon/>}
            />
            <BottomNavigationAction
              value="person"
              label={__('contacts.person')}
              icon={<UserIcon/>}
            />
          </BottomNavigation>
        </Paper>
      </Layout>
    );
  }
}

const mapStateToProps = (states: RootState): StateProps => ({
  currentUser: states.user.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  fetchPersonListRemote: () => dispatch(fetchPersonListRemote()),
  fetchOrganizationListRemote: () => dispatch(fetchOrganizationListRemote()),
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(Contacts);
