import _ from 'lodash';
import { KeyValueMap } from '../types/common';
import { Condition } from '../types/form';
import { FieldMap } from '../types/template';
import { transformField } from './template';

export type ContextScope = KeyValueMap;

export function getContextValue(scopes: ContextScope[], key: string, map?: FieldMap) {
  for (let i = scopes.length - 1; i >= 0; i--) {
    const value = transformField(key, map, scopes[i])
    if (!_.isUndefined(value)) {
      return value;
    }
  }
  return undefined;
}

export function execCondition(condition: Condition | undefined, scopes: ContextScope[], map?: FieldMap): boolean {
  if (!condition || _.isEmpty(condition)) {
    return true;
  }
  let value: any = null;
  if (['IN', '=', 'HAS', 'TRUTHY'].includes(condition.operator)) {
    value = getContextValue(scopes, _.get(condition, 'key'), map);
  }
  switch (condition.operator) {
    case 'IN':
      return condition.value.includes(value);
    case '=':
      return value == condition.value;
    case 'AND':
      return _.every(condition.value, con => execCondition(con, scopes, map));
    case 'OR':
      return _.some(condition.value, con => execCondition(con, scopes, map));
    case 'NOT':
      return !execCondition(condition.value, scopes, map);
    case 'HAS':
      return !_.isUndefined(value);
    case 'TRUTHY':
      return !!value;
    default:
      throw true;
  }

}
