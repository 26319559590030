import React from 'react';
import { PropTypes } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { __ } from '../../utils/intl';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

export interface OwnProps {
  open: boolean;
  title?: React.ReactNode;
  content?: React.ReactNode;
  closeButtonText: string;
  confirmButtonText: string;
  closeButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  onClose?: () => void;
  onConfirm?: () => void;
}

const styles = (theme: Theme) => createStyles({
  root: {
    overflow: 'visible'
  }
})

type Props = OwnProps & WithStyles<typeof styles>;

export interface State {

}

class Confirm extends React.Component<Props, State> {

  static defaultProps: Props = {
    open: false,
    title: '',
    content: '',
    closeButtonText: __('dialog.cancel'),
    confirmButtonText: __('dialog.ok'),
    closeButtonProps: { color: 'default' },
    confirmButtonProps: { color: 'primary' },
    onClose: () => {},
    onConfirm: () => {},
    classes: { root: 'root' }
  }

  render() {
    const {
      open,
      title,
      content,
      closeButtonText,
      confirmButtonText,
      closeButtonProps,
      confirmButtonProps,
      onClose,
      onConfirm,
      classes,
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paperScrollPaper: classes.root}}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent className={ classes.root }>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} {...closeButtonProps}>
            {closeButtonText}
          </Button>
          <Button onClick={onConfirm} autoFocus {...confirmButtonProps}>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Confirm)
