import { TreeItem } from '../../types/tree';
import { IStage } from '../../types/document.template';
import { DossierData } from '../../types/dossier';
import { DemoData } from '../types';

import { documentTemplates } from './documents';

const demoData: DemoData = {
  users: [
    // { username: 'Wang', avatar: `${process.env.PUBLIC_URL}/images/avatar/wang.jpg`},
    // { username: 'Fang', avatar: `${process.env.PUBLIC_URL}/images/avatar/fang.jpg`},
  ],
  chat: [
    { _id: 'msg-1', sender: 'Wang', content: 'Fang, please come to the scene as soon as possible. I need photos of the building\'s appearance.' },
    { _id: 'msg-2', sender: 'Fang', content: 'All right, Wang. I\'m near the scene now. I can take photos and send them to you right away.' },
    { _id: 'msg-3', sender: 'Fang', content: 'This is the staircase of the crime scene.', attachment: `${process.env.PUBLIC_URL}/images/demo/scene.jpg` },
    { _id: 'msg-4', sender: 'Wang', content: 'Thank you very much. I can\'t get away from studying the case information of Shylock and Antonio today.' },
    { _id: 'msg-5', sender: 'Fang', content: 'Is there any progress?' },
    { _id: 'msg-6', sender: 'Wang', content: 'Yes, it\'s very interesting for you to look at this part of the debit note.', attachment: `${process.env.PUBLIC_URL}/images/demo/receipt.jpg` },
    { _id: 'msg-7', sender: 'Fang', content: 'Indeed. Let\'s study it with evidence whiteboard.' },
    { _id: 'msg-8', sender: 'Wang', content: 'Okay, I have some video material here. Let\'s take a look at it.', attachment: `${process.env.PUBLIC_URL}/images/demo/video.jpg` },
  ],
  documentTemplates,
  templateList: [
    { childIndex: 0, _id: 'f1t1', type: 'file', name: 'Civil Procedure / First Trial' },
    { childIndex: 0, _id: 'f1t2', type: 'file', name: 'Civil Procedure / Appellate' },
    { childIndex: 0, _id: 'f2t1', type: 'file', name: 'Criminal procedure /county court' },
    { childIndex: 0, _id: 'f2t2', type: 'file', name: 'Criminal procedure / appellate' },
    { childIndex: 0, _id: 'f3t1', type: 'file', name: 'Labor Arbitration' },
    { childIndex: 0, _id: 'f3t2', type: 'file', name: 'Commercial Arbitration' },
  ],

  basicForm: [
    'cause of action',
    'law firm+name',
    'law firm+bank+name',
    'law firm+bank+account',
    'case number',
    'date of receipt',
    'lawyer+name',
    'lawyer+phone number',
    'lawyer+assistant',
  ],

  rolesData: {
    "民事": {
      stage: [{
        name: "一审",
        roles: ["原告", "被告", "第三人"]
      }, {
        name: "二审",
        roles: ["上诉人", "被上诉人", "第三人"]
      }, {
        name: "再审",
        roles: ["申请人", "被申请人", "第三人"]
      }]
    },
    "arbitration": {
      stage: [{
        name: "first stage",
        roles: ["Applicant", "Defendant"]
      }]
    }
  },

  files: [
    { childIndex: 0, _id: 'f1', type: 'file', name: 'Shylock v. Bassanio' },
    { childIndex: 0, _id: 'f2', type: 'file', name: 'Revlon, Inc. v. MacAndrews & Forbes Holdings, Inc.' },
    { childIndex: 0, _id: 'f3', type: 'file', name: 'Marbury v. Madison' },
    { childIndex: 0, _id: 'f4', type: 'file', name: 'People of the State of California v. Orenthal James Simpson' },
    { childIndex: 0, _id: 'f5', type: 'file', name: 'Brown v. Board of Education of Topeka' },
    { childIndex: 0, _id: 'f6', type: 'file', name: 'Moore v. Regents of the University of California' },
    //childIndex: 0,  { _id: 'f7', type: 'file', name: 'The Saga of Van Valkenburgh v. Lutz: Animosity and Adverse Possession in Yonkers' },
    { childIndex: 0, _id: 'f8', type: 'file', name: 'Tulk v. Moxhay' },
    { childIndex: 0, _id: 'f9', type: 'file', name: 'Sanborn v. McLean' },
    { childIndex: 0, _id: 'f10', type: 'file', name: 'The Symphony Space，Inc. v. Pergola Properties，Inc.' },
  ],

  documentTree: {
    _id: '1',
    type: 'folder',
    name: 'Case File',
    childIndex: 0,
    children: [
      {
        _id: '',
        childIndex: 0,
        type: 'file',
        name: 'Cover',
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Inside Material',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'Notes' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Records of Lawyers',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'Work Log' },
          { _id: '', childIndex: 0, type: 'file', name: 'Meeting Notes' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Applicant Material',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'Application for Arbitration' },
          { _id: '', childIndex: 0, type: 'file', name: 'Contracts' },
          { _id: '', childIndex: 0, type: 'file', name: 'Power of Attorney' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Defendant\'s Material',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'Evidence: Loan Receipt' },
          { _id: '', childIndex: 0, type: 'file', name: 'evidence 1: contract' },
          { _id: '', childIndex: 0, type: 'file', name: 'evidence 2: transfer voucher' },
          { _id: '', childIndex: 0, type: 'file', name: 'evidence 3: witness' },
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Arbitration',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'Arbitration' }
        ]
      },
      {
        _id: '',
        childIndex: 0,
        type: 'folder',
        name: 'Case Research',
        children: [
          { _id: '', childIndex: 0, type: 'file', name: 'case 1: supreme court case' },
          { _id: '', childIndex: 0, type: 'file', name: 'case 2: the precedent arbitration' },
          { _id: '', childIndex: 0, type: 'file', name: 'case 3: other instructions\'s arbitration' },
        ]
      }
    ],
  },

  // documentTree: {
  //   _id: '1',
  //   type: 'folder',
  //   name: 'Case File',
  //   childIndex: 0,
  //   children: [
  //     {
  //       _id: '',
  //       childIndex: 0,
  //       type: 'folder',
  //       name: "Plaintiff's",
  //       children: [
  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: "Complaint",
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Arguments',
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Identity files',
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Motions',
  //           },
  //         ],
  //     },

  //     {
  //       _id: '',
  //       childIndex: 0,
  //       type: 'folder',
  //       name: "Defentant's",
  //       children: [
  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: "Answer",
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Arguments',
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Identity files',
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Motions',
  //           },
  //         ],
  //     },

  //     {
  //       _id: '',
  //       childIndex: 0,
  //       type: 'folder',
  //       name: 'Evidences',
  //       children: [
  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: "Testimony",
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Documentary',
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Physical',
  //           },
  //         ],
  //     },

  //     {
  //       _id: '',
  //       childIndex: 0,
  //       type: 'folder',
  //       name: 'Court files',
  //       children: [
  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: "Orders",
  //           },

  //           {
  //             _id: '',
  //             childIndex: 0,
  //             type: 'folder',
  //             name: 'Notices',
  //           },
  //         ],
  //     },

  //     {
  //       _id: '',
  //       childIndex: 0,
  //       type: 'folder',
  //       name: 'Researches files',
  //     },
  //   ],
  // },

  similarCaseData: [{
    title: 'PRIEST v. SOCIAL SECURITY ADMINISTRATION COMMISSIONER',
    date: 'December 16, 2016',
    number: '1:2016cv00440'
  }, {
    title: 'NTERNAL REVENUE SERVICE v. MURPHY',
    date: 'September 7, 2016',
    number: '2:2016cv00008'
  }, {
    title: 'GOING v. LAPREL et al',
    date: 'November 14, 2014',
    number: '2:2014cv00031'
  }, {
    title: 'GAGNON v. SOCIAL SECURITY ADMINISTRATION COMMISSIONER',
    date: 'July 15, 2014',
    number: '2:2013cv00213'
  }],

  relevantLawData: [{
    article: 'COMMERCE AND TRADE',
    title: '§8. Trusts in restraint of import trade illegal; penalty',
    content: 'Every combination, conspiracy, trust, agreement, or contract is declared to be contrary to public policy, illegal, and void when the same is made by or between two or more persons or corporations, either of whom, as agent or principal, is engaged in importing any article from any foreign country into the United States, and when such combination, conspiracy, trust, agreement, or contract is intended to operate in restraint of lawful trade, or free competition in lawful trade or commerce, or to increase the market price in any part of the United States of any article or articles imported or intended to be imported into the United States, or of any manufacture into which such imported article enters or is intended to enter. Every person who shall be engaged in the importation of goods or any commodity from any foreign country in violation of this section, or who shall combine or conspire with another to violate the same, is guilty of a misdemeanor, and on conviction thereof in any court of the United States such person shall be fined in a sum not less than $100 and not exceeding $5,000, and shall be further punished by imprisonment, in the discretion of the court, for a term not less than three months nor exceeding twelve months.',
    version: '2012 Ed. and Supplement V (1/12/2018)'
  }],

  entities: [
    { type: 'person', name: 'Shylock Smith', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'plaintiff' },
    { type: 'person', name: 'Jimmy White', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'plaintiff', attorney: 'Dezhi LLP' },
    { type: 'person', name: 'John Bassanio', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'person', name: 'Jimmy White', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'person', name: 'John Smith', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'person', name: 'Jimmy White', sex: 'M', birthdate: new Date('1965-01-01'), phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'organization', name: 'Apple Computer Inc', legalRepName: 'Tim Cook', phoneNumber: '(408) 996-1010', idNumber: 'US0378331005', source: 'Link-here', role: 'plaintiff' },
    { type: 'organization', name: 'Tesla Motors', legalRepName: 'Elon Musk', phoneNumber: '55555555', idNumber: '789342659876', source: 'Link-here', role: 'plaintiff' },
    { type: 'organization', name: 'Microsoft Inc', legalRepName: 'Bill Gates', phoneNumber: '555-555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'organization', name: 'Google LLC', legalRepName: 'Sundar Pichai', phoneNumber: '55555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'organization', name: 'Amazon.com Inc', legalRepName: 'Jeff Bezos', phoneNumber: '55555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'organization', name: 'Facebook Inc', legalRepName: 'Mark Zuckerberg', phoneNumber: '55555555', idNumber: '789342659876', source: 'Link-here', role: 'defendant' },
    { type: 'location', name: 'Victim\'s Home', address: '121 East Road, San Francisco, CA', coordinates: { latitude: 37.72, longitude: -122.36 } },
    { type: 'location', name: 'Next Coffee', address: '34 34th West Ava, New York, NY', coordinates: { latitude: 40.75, longitude: -74.01 } },
  ],

  facts: [],
}

export default demoData;
