import _ from 'lodash';
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import OrganizationIcon from '@material-ui/icons/FolderShared';

import { __ } from '../../utils/intl';
import { ContactOrganizationData } from '../../types/contact.organization';
import { history } from '../../store/configureStore';
import ListFilter from '../Common/ListFilter';
import { partialListStyles } from './common';

const styles = partialListStyles;

interface OwnProps {
  className?: string;
  hasFilter?: boolean;
  title?: string;
  list: ContactOrganizationData[];
};

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  filterValue: string;
}

class OrganizationListPartial extends React.Component<Props, State> {

  state = {
    filterValue: '',
  }

  render() {
    const { classes, hasFilter, className, title, list: _list } = this.props;
    const { filterValue } = this.state;
    let list = _list;
    if (hasFilter) {
      const keyword = filterValue.toLowerCase();
      list = _list.filter(item => {
        const name = item.profile.name;
        return name ? name.toLowerCase().includes(keyword) : false;
      });
    }
    return (
      <List
        className={className}
        subheader={
          <ListSubheader className={classes.listHeader}>
            <div>{title || __('contacts.organizationList')}</div>
            {hasFilter &&
              <div className={classes.filterContainer}>
                <ListFilter
                  className={classes.filter}
                  onSearch={value => this.setState({ filterValue: value })}
                />
              </div>
            }
          </ListSubheader>
        }
      >
        {list.map(item => (
          <ListItem
            button
            key={item._id}
            onClick={() => history.push(`/contacts/organization/item/${item._id}`)}
          >
            <ListItemIcon>
              <OrganizationIcon />
            </ListItemIcon>
            <ListItemText
              primary={item.profile.name}
            />
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(OrganizationListPartial);
