import { UserData, UserProfile } from '../types/user';
import { intl } from './intl';

export function getEmailUsername(email?: string) {
  if (!email) {
    return null;
  } else if (!/@/.test(email)) {
    return email;
  } else {
    return email.split('@')[0];
  }
}

export function getUsername(user?: Partial<UserData> | null): string {
  if (!user) {
    return 'nobody';
  }
  if (user.profile && (user.profile.firstName || user.profile.lastName)) {
    return getFullName(user.profile) || '';
  } else {
    return getEmailUsername(user.email) || user.mobile || 'anybody';
  }
}

export const nonLatinPattern = /[^\u0000-\u007f]/;
export const cjkPattern = /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/;

export function hasNonLatinChar(text: string) {
  return nonLatinPattern.test(text);
}

export function getFullName(data?: UserProfile | null) {
  if (!data || (!data.firstName && !data.lastName)) {
    return null;
  }
  if (!data.firstName) {
    return data.lastName;
  } else if (!data.lastName) {
    return data.firstName;
  } else if (cjkPattern.test(data.firstName) || cjkPattern.test(data.lastName)) {
    return `${data.lastName}${data.firstName}`;
  } else {
    return `${data.firstName} ${data.lastName}`;
  }
}

// https://stackoverflow.com/a/18197341/1131408
export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
