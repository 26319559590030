import _ from 'lodash';
import React from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

import { __ } from '../../utils/intl';
import { DocumentData, DocVersion } from '../../types/document';
import { TreeItem } from '../../types/tree';
import { RootState } from '../../store/reducers';
import client from '../../store/client';
import DocumentIcon from './DocumentIcon';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  actions: {
    margin: theme.spacing(2),
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontSize: '14px',
  },
  list: {
  },
});

interface OwnProps {
}

interface StateProps {
  selectedItem: TreeItem | null;
  document: DocumentData | null;
}

interface DispatchProps {
}

interface Params {
  dossierId: string,
  viewMode: string,
}

type RouteProps = RouteComponentProps<Params>

type Props = OwnProps & StateProps & DispatchProps & RouteProps & WithStyles<typeof styles>;

type EditableValue = Partial<Pick<DocumentData, 'name'>>;

interface State {
  loading: boolean;
  versions: DocVersion[];
}

class VersionsPanel extends React.Component<Props, State> {

  state: State = {
    loading: false,
    versions: [],
  }

  constructor(props: Props) {
    super(props);
    this.loadVersions(props);
  }

  async loadVersions(props: Props) {
    const { document } = props;
    if (!document) return;
    this.setState({ loading: true });
    const versions = await client.get<DocVersion[]>(`/dossiers/${document.dossier_id}/documents/${document._id}/versions`);
    this.setState({
      loading: false,
      versions,
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.document && nextProps.document !== this.props.document) {
      this.loadVersions(nextProps);
    }
  }

  handleOpenVersion(version: number) {
    const { history, document, match: { params: { dossierId, viewMode }} } = this.props;
    if (!document) return;
    history.push(`/dossier/${dossierId}/${viewMode}/document/${document._id}/view/version/${version}`);
  }

  render() {
    const { classes, document } = this.props;
    const { loading, versions } = this.state;
    if (!document) {
      return null;
    }
    return (
      <div className={classes.root}>
        <div className={classes.actions}>
          <Typography className={classes.title}>
            {__('panels.versions.allVersions')}
          </Typography>
        </div>
        {loading ?
          <div>loading...</div>
        :
          <List className={classes.list}>
            {versions.map(version => {
              const selected = version.version === document.version;
              const versionName = selected ?
                __('document.currentVersion') :
                `${__('document.version')} ${version.version}`;
              return (
                <ListItem
                  button
                  key={version.version}
                  onClick={() => this.handleOpenVersion(version.version)}
                >
                  <ListItemIcon>
                    <DocumentIcon document={document} />
                  </ListItemIcon>
                  <ListItemText
                    primary={version.name}
                    secondary={`(${versionName}) ${version.date.toLocaleDateString()}`}
                  />
                </ListItem>
              );
            })}
          </List>
        }
      </div>
    )
  }
}

const mapStateToProps = (states: RootState, props: OwnProps): StateProps => ({
  selectedItem: states.ui.selectedTreeItem,
  document: states.document.current,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
});

export default compose<Props, OwnProps>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(VersionsPanel);
